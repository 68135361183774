import { Oauth2RessourceService } from '../../oauth2/oauth2-resources.service';
import { Injectable } from '@angular/core';
import { ICreatePersonInterface, IProspectGPRefCli, IProspectProRefCli } from './interfaces';
import { RefCliCivility, RefCliType } from '../../external/refcli/interfaces';
import { Observable } from 'rxjs';
import { Customer } from '../../checkout/cart/customer/customer';
import { CustomerCategory } from '../../checkout/cart/customer/customer.interface';

@Injectable({
  providedIn: 'root',
})
export class ProspectService {
  constructor(protected oauth2Ressource: Oauth2RessourceService) {}

  public static formatData(
    customerContextService: Customer,
  ): IProspectGPRefCli<RefCliCivility> | IProspectProRefCli<RefCliCivility> {
    if (customerContextService.category === CustomerCategory.pro) {
      return {
        type: RefCliType.entreprise,
        numeroTel: customerContextService.phoneNumber || null,
        email: customerContextService.email || null,
        siren: customerContextService.company.noSiren,
        raisonSociale: customerContextService.company.raisonSociale,
        dateCreation: customerContextService.company.dateCreation,
        effectif: customerContextService.company.codeEffectif,
        situationEntreprise: customerContextService.company.situationEntreprise,
        codeApeNaf: customerContextService.company.codeApeNaf,
        formeJuridique: customerContextService.company.formeJuridique,
        representantLegal: {
          civilite: <RefCliCivility>customerContextService.civility.replace('.', ''),
          nom: customerContextService.lastname,
          prenom: customerContextService.firstname,
          dateNaissance: customerContextService.dob,
          departementNaissance: customerContextService.birthplace?.split(' ')[0],
        },
      };
    }

    return {
      type: RefCliType.individu,
      civilite: <RefCliCivility>customerContextService.civility.replace('.', ''),
      nom: customerContextService.lastname,
      prenom: customerContextService.firstname,
      dateNaissance: customerContextService.dob,
      departementNaissance: customerContextService.birthplace?.split(' ')[0],
      numeroTel: customerContextService.phoneNumber || null,
      email: customerContextService.email || null,
    };
  }

  public update(
    data: IProspectGPRefCli<RefCliCivility> | IProspectProRefCli<RefCliCivility>,
    personId: number,
  ): Observable<ICreatePersonInterface> {
    data['departementNaissance'] = data['departementNaissance']?.split(' ')[0];
    return this.oauth2Ressource.personnes(personId).put(data);
  }

  public create(
    data: IProspectGPRefCli<RefCliCivility> | IProspectProRefCli<RefCliCivility>,
  ): Observable<ICreatePersonInterface> {
    data['departementNaissance'] = data['departementNaissance']?.split(' ')[0];
    return this.oauth2Ressource.useSalesApi().setLocalService(true).ventes().prospect().post(data);
  }
}
