import { Component, OnDestroy, OnInit } from '@angular/core';
import { VirtualProduct } from '../../../contextualized-catalog/models/virtual-product';
import { IPostCatalogResponse } from '../../../contextualized-catalog/dtos/contextualized-product-output.dto';
import { GlobalLoaderService } from '../../../base/services/global-loader.service';
import { CartService } from '../../../checkout/cart/cart.service';
import { ISousCategory, CategoryPlanService } from './category-plan-service';
import { CustomerService } from '../../../checkout/cart/customer/customer.service';
import { PriceTypes } from '../../products/interface/price-type.enum';
import { Product } from '../../products/product';
import { Scheme } from '../../../checkout/cart/scheme.class';
import { Observable, of, forkJoin } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { Mobile } from '../../products/subscription/plan/mobile';
import { Sim } from '../../products/equipement/sim';
import { Router } from '@angular/router';
import { CategoriesService } from '../../categories.services';
import { Catalog } from '../../products/catalog';
import { Sensation } from '../../products/subscription/plan/premium/sensation';
import { Prepaid } from '../../products/subscription/plan/prepaid';
import { CheckoutStepperService } from '../../../checkout/checkout-stepper.service';
import { Plan } from '../../products/subscription/plan';

const LOADING_ACTIONS = {
  processProduct: '[CategoryPlanComponent] processProduct',
};

@Component({
  selector: 'rcbt-category-plan',
  templateUrl: './category-plan.component.html',
  styleUrls: ['./category-plan.component.scss'],
})
export class CategoryPlanComponent implements OnInit, OnDestroy {
  public virtualPlans: VirtualProduct[] = [];
  public subCategories: ISousCategory[] = [];
  public subCategory: ISousCategory;
  public isPro: boolean;
  public msg: string;

  constructor(
    private globalLoaderService: GlobalLoaderService,
    private cartService: CartService,
    private customerService: CustomerService,
    private router: Router,
    private categoryPlanService: CategoryPlanService,
    private checkoutStepperService: CheckoutStepperService,
  ) {}

  public ngOnInit(): void {
    this.isPro = this.customerService.customer.isPro();
    this.subCategories = CategoriesService.getPlans(this.isPro);
    if (this.subCategories.find((menu: ISousCategory) => menu.selected)) {
      this.selectMenu(this.subCategories.find((menu: ISousCategory) => menu.selected));
    } else {
      this.selectMenu(this.subCategories[0]);
    }
  }

  public ngOnDestroy(): void {
    this.globalLoaderService.deleteStatusByActionTypes(LOADING_ACTIONS.processProduct);
  }

  public selectMenu(menu: ISousCategory): void {
    this.subCategory = menu;
    this.subCategories.forEach(menu2 => (menu2.selected = false));
    menu.selected = true;
    this.refreshPlans();
  }

  public refreshPlans(): void {
    this.msg = null;
    this.virtualPlans = [];
    this.globalLoaderService
      .showLoaderUntilFinalize(
        this.categoryPlanService.getContextualizedPlans(this.subCategory.category.replace('_pro', '')),
        LOADING_ACTIONS.processProduct,
      )
      .subscribe(
        (contextualizedPlans: IPostCatalogResponse[]) => {
          contextualizedPlans.forEach(contextualizedPlan => {
            if (this.canShowPlan(contextualizedPlan, this.subCategory)) {
              this.virtualPlans.push(new VirtualProduct(PriceTypes.everyMonth).setData(contextualizedPlan));
            }
          });
          if (this.virtualPlans.length) {
            this.sortVirtualPlans(this.virtualPlans);
          } else {
            this.msg = 'Aucune offre paramétrée dans cette catégorie';
          }
        },
        () => (this.msg = 'Une erreur technique est survenue. Veuillez réessayer plus tard.'),
      );
  }

  public canAddToCart(virtualPlan: VirtualProduct): boolean {
    return (
      virtualPlan.ctxProduct.type !== Sensation.planType &&
      (virtualPlan.ctxProduct.type !== Prepaid.planType ||
        !this.cartService.getCurrentScheme().getProductByType(Prepaid))
    );
  }

  public addProductToCart(virtualPlan: VirtualProduct): void {
    const plan: Plan = Catalog.getInstanceByType<Plan>(virtualPlan.ctxProduct.type, null, null, {
      obligation: virtualPlan.ctxProduct.dureeEngagement,
    });
    plan.gencode = virtualPlan.ctxProduct.gencode;
    const reAddObs: Observable<boolean>[] = this.reAddDeletedProduct(plan);
    this.globalLoaderService
      .showLoaderUntilFinalize(
        this.cartService.add(plan, 1, true).pipe(
          mergeMap(() => forkJoin(reAddObs)),
          mergeMap(() => this.cartService.refreshCart()),
        ),
        LOADING_ACTIONS.processProduct,
      )
      .subscribe(() => {
        this.checkoutStepperService.reset();
        this.router.navigate(['/panier']);
      });
  }

  private reAddDeletedProduct(newPlan: Product): Observable<boolean>[] {
    const reAddObs: Observable<boolean>[] = [of(true)];
    const currentScheme: Scheme = this.cartService.getCurrentScheme();
    const oldPlan: Mobile = currentScheme.getProductByType(Mobile);

    if (oldPlan && newPlan instanceof Mobile) {
      newPlan.setConfiguration(oldPlan.getFilteredConfiguration());
      const pConflicts: Product[] = currentScheme.rules.getConflictProject(currentScheme, newPlan);

      pConflicts.forEach((pDeleted: Product) => {
        if (pDeleted instanceof Sim) {
          reAddObs.push(this.cartService.add(pDeleted, 1, true, this.cartService.currentSchemeUniqueId, false));
        }
      });
    }

    return reAddObs;
  }

  /**
   * @param contextualizedPlan
   * @private
   */
  private canShowPlan(contextualizedPlan: IPostCatalogResponse, subCategory: ISousCategory): boolean {
    const { excluPro, categories } = contextualizedPlan;
    const { category } = subCategory;

    if (excluPro) {
      return categories.some((productCategory: string) => {
        const isMatchingCategory = productCategory === category;
        const isMatchingCategoryPro = productCategory + '_pro' === category;
        const isIncludingCategory = ['plansensation_rcbt', 'simo_rcbt'].includes(category);

        return (isMatchingCategory && !isIncludingCategory) || isMatchingCategoryPro;
      });
    } else {
      return categories.includes(category);
    }
  }

  private sortVirtualPlans(virtualPlans = this.virtualPlans): void {
    virtualPlans.sort((plan1: VirtualProduct, plan2: VirtualProduct) => {
      const dataEnvelope1: string = plan1.ctxProduct.libelleEnveloppeData;
      const dataEnvelope2: string = plan2.ctxProduct.libelleEnveloppeData;
      if (!dataEnvelope1 || !dataEnvelope2) {
        return -1;
      }
      const data1: number =
        dataEnvelope1.toUpperCase().indexOf('GO') !== -1
          ? Number(dataEnvelope1.match(/\d+/g).join('')) * 1000
          : Number(dataEnvelope1.match(/\d+/g).join(''));
      const data2: number =
        dataEnvelope2.toUpperCase().indexOf('GO') !== -1
          ? Number(dataEnvelope2.match(/\d+/g).join('')) * 1000
          : Number(dataEnvelope2.match(/\d+/g).join(''));

      if (data1 === data2) {
        return 0;
      } else {
        return data1 > data2 ? -1 : 1;
      }
    });
  }
}
