<div class="login-component">
    <div class="login-header">
        <span class="icon is-large"><i class="tri-at-plus-circle" aria-hidden="true"></i></span>
        <h2 class="title is-size-3">Je crée mon login</h2>
    </div>
    <div *ngIf="faiService.reservedLogin">
        <span class="text is-size-5">Un login est déjà réservé : {{faiService.reservedLogin}}</span>
    </div>
    <div *ngIf="!faiService.reservedLogin">
        <h3 class="login-choice-label">Choix du login</h3>
        <weto-login #widgetWetoLogin manual-render="true"></weto-login>

        <ng-container *ngIf="errors.length > 0">
            <div class="alert has-body is-error error-margin" *ngFor="let error of errors">
                <span class="icon"><i class="tri-exclamation-circle" aria-hidden="true"></i></span>
                <div class="body">
                    <p class="text">{{error}}</p>
                </div>
            </div>
        </ng-container>
    </div>
</div>