import { Injectable } from '@angular/core';

import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { FundingErrorComponent } from '../components/funding-error/funding-error.component';

@Injectable({
  providedIn: 'root',
})
export class FundingErrorService {
  constructor(private modalService: NgbModal) {}

  public buildFundingErrorComponent(): FundingErrorComponent {
    const options = this.buildNgbModalOptions('semi-opacity', 'modal-funding-error');
    return this.modalService.open(FundingErrorComponent, options).componentInstance as FundingErrorComponent;
  }

  private buildNgbModalOptions(backdropClass: string, windowClass: string): NgbModalOptions {
    return {
      backdrop: 'static',
      size: 'lg',
      backdropClass,
      windowClass,
      keyboard: false,
    } as NgbModalOptions;
  }
}
