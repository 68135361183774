<div class="is-tri modal-compatibility">
    <div class="bloc-row title is-3 is-centered">Souscription impossible</div>
    <div class="bloc-row body">
        <span class="icon is-medium"><i class="tri-comment-exclamation-circle" aria-hidden='true'></i></span>
        <div>
            La souscription n'est pas possible pour ce client. Veuillez annuler votre parcours de vente
        </div>
    </div>
    <div class="bloc-row actions">
        <button class="button is-info" (click)="cancelCart()">Annuler la vente</button>
    </div>
</div>
