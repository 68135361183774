import { HttpRequest, HttpErrorResponse } from '@angular/common/http';
import * as moment from 'moment';
import { FrontLog } from '../interceptor/Interceptor.interface';

export class LogService {
  public frontStorageLogs: FrontLog[] = [];

  public getFrontStorageLogs(): FrontLog[] {
    return this.frontStorageLogs;
  }

  public setFrontStorageLogs(resquest: HttpRequest<unknown>, event: HttpErrorResponse): void {
    this.frontStorageLogs.push({
      time: moment().format('LLL'),
      request: {
        name: resquest.url,
        method: resquest.method,
        body: resquest.body,
        headers: resquest.headers,
      },
      error: {
        code: event.status ? event.status : undefined,
        message: event.statusText ? event.statusText : undefined,
      },
    });
  }
}
