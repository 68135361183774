import { Component, Input } from '@angular/core';
import { catchError, concatMap, mergeMap, tap } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';
import { RCheckMail } from '../../../customer/identity/identity.interface';
import { ConfigService } from '../../../../../config.service';
import { Oauth2RessourceService } from '../../../../../oauth2/oauth2-resources.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CartService } from '../../../cart.service';
import { FormPatterns } from '../../../../../base/class/form-patterns';
import { Scheme } from '../../../scheme.class';
import { GlobalLoaderService } from '../../../../../base/services/global-loader.service';
import { CustomerService } from '../../../customer/customer.service';

const LOADING_ACTIONS = {
  checkEmail: '[ModalEmailComponent] checkEmail',
};

@Component({
  selector: 'rcbt-modal-email',
  templateUrl: './modal-email.component.html',
  styleUrls: ['./modal-email.component.scss'],
})
export class ModalEmailComponent {
  @Input()
  public onValidClick: () => Observable<boolean>;

  @Input()
  public onCloseClick: () => void;

  public email: string;
  public rCheckMailError: string;
  public loading: boolean;
  public emailValid: boolean;
  public regexpMail: RegExp = FormPatterns.emailBasic;

  constructor(
    private configService: ConfigService,
    private oauth2RessourceService: Oauth2RessourceService,
    private activeModal: NgbActiveModal,
    private cartService: CartService,
    private globalLoaderService: GlobalLoaderService,
    private customerService: CustomerService,
  ) {
    if (!this.onValidClick) {
      this.onValidClick = (): Observable<boolean> => of(true);
    }
  }

  public validate(): void {
    this.loading = true;
    this.globalLoaderService
      .showLoaderUntilFinalize(
        this.checkMail().pipe(
          concatMap((res: boolean) => {
            if (res) {
              return this.updateCustomerEmail();
            }
          }),
          concatMap(() => this.onValidClick()),
          tap(() => this.closeModal()),
        ),
        LOADING_ACTIONS.checkEmail,
      )
      .subscribe();
  }

  public closeModal(): void {
    this.activeModal.close();
  }

  public regexEmail(): void {
    this.emailValid = this.regexpMail.test(this.email);
  }

  private checkMail(): Observable<boolean> {
    if (this.email && this.isRcheckMailActive()) {
      this.rCheckMailError = '';
      return this.oauth2RessourceService
        .adresses()
        .validationEmail(encodeURIComponent(this.email))
        .get()
        .pipe(catchError(err => (Number(err.status) >= 500 ? of(null) : throwError(err))))
        .pipe(
          mergeMap((data: RCheckMail) => {
            if (data) {
              if (
                !data.domaine ||
                !data.domaine.domaineExistant ||
                data.emailTemporaire ||
                !data.emailValide ||
                !data.syntaxeEmailCorrecte ||
                data.utilisateurGenerique
              ) {
                this.rCheckMailError =
                  'L’adresse e-mail renseignée n’existe pas, ' + 'veuillez saisir une nouvelle adresse e-mail.';
                return of(false);
              } else if (data.emailContact) {
                this.rCheckMailError =
                  'L’e-mail saisi existe déjà dans la base de données clients, ' +
                  'veuillez saisir un e-mail différent ou laisser le champ vide';
                return of(false);
              }
            }
            return of(true);
          }),
        );
    } else {
      return of(true);
    }
  }

  private isRcheckMailActive(): boolean {
    return !this.configService.data.rcheckMail || this.configService.data.rcheckMail.active !== false;
  }

  private updateCustomerEmail(): Observable<boolean> {
    const currentScheme: Scheme = this.cartService.getCurrentScheme();
    this.customerService.customer.email = this.email;
    // todo vérifier que le nouvel email descend bien à l'api de vente
    return this.cartService.updateScheme(currentScheme);
  }
}
