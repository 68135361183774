import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TicketComponent } from './ticket.component';
import { BaseModule } from '../../base/base.module';
import { PromotionModule } from '../promotion/promotion.module';
import { AgilityModule } from '../agility/agility.module';
import { FormsModule } from '@angular/forms';
import { NewSchemeComponent } from '../newscheme/newscheme.component';
import { ToggleRegularisationComponent } from './toggle-regularisation/toggle-regularisation.component';
import { TicketRenewPromotionsComponent } from './ticket-renew-promotions/ticket-renew-promotions.component';
import { ConsumerLoanModule } from '../../consumer-loan/consumer-loan.module';
import { CggcComponent } from './cggc/cggc.component';
import { FundingComponent } from './funding/funding.component';
import { EdpComponent } from './edp/edp.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  imports: [CommonModule, BaseModule, PromotionModule, AgilityModule, ConsumerLoanModule, FormsModule, NgbModule],
  declarations: [
    TicketComponent,
    NewSchemeComponent,
    ToggleRegularisationComponent,
    TicketRenewPromotionsComponent,
    CggcComponent,
    FundingComponent,
    EdpComponent,
  ],
  exports: [TicketComponent, FundingComponent],
})
export class TicketModule {}
