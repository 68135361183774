<div class="popin-content is-tri" data-cy="unreservation-modal">
    <div class="body columns">
        <div class=" column is-12">
            <div class="paragraph text is-2">
                Le produit que vous venez de scanner est déjà associé à un panier :
                <ul class="paragraph text is-3">
                    <li>N° de panier : {{productUnreservationDto.idPanier}}</li>
                    <li>Login vendeur : {{productUnreservationDto.login}}</li>
                    <li>Prénom Nom du client : {{productUnreservationDto.prenom }} {{productUnreservationDto.nom }}</li>
                    <li>Date de création : {{productUnreservationDto.dateCreation | date:"dd/MM/yyyy HH'h'mm" }}</li>
                    <li>Code PDV et libellé de la boutique : {{user.codeEns}}{{user.codePdv}}{{' - '}}{{user.nomBoutique}}</li>
                    <li>Gencod : {{productUnreservationDto.gencode}}</li>
                    <li>Numéro de série : {{productUnreservationDto.imei}}</li>
                    <li>Libellé : {{productUnreservationDto.label}}</li>
                </ul>
                Êtes-vous sûr(e) de vouloir dé-réserver ce produit et annuler l'ensemble du panier précédent ?
            </div>
            <div class="modal-actions">
                <button class="button is-primary" data-cy="unreservation-close" (click)="onCloseClick()">{{cancelButtonText}}</button>
                <button class="button is-info" data-cy="unreservation-valid" (click)="onValidClick()">{{validButtonText}}</button>
            </div>
        </div>
    </div>
</div>
