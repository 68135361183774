export enum DematStatusEnums {
  creationDossierEnCours,
  scanDesPieces,
  scanEnCours,
  analysePjEnCours,
  scanTermine,
  creditEnCours,
  creditTermine,
  envoiContratsEnCours,
  contratsEnvoyes,
  generationTokenEnCours,
  tokenGenere,
  signatureContratsEnCours,
  contratsSignes,
  erreur,
  replay,
  impressionContratsEnCours,
  contratsImprimes,
  paperMode,
  replaceSimCheckOk,
}

export enum IDDocTypeEnums {
  ID = 'ID',
  PASSPORT = 'PASSPORT',
  DRIVING_LICENSE = 'DRIVING_LICENSE',
  RESIDENCE_PERMIT = 'RESIDENCE_PERMIT',
}

export enum LoanStatusEnums {
  consentementEnCours,
  initDossierCreditEnCours,
  contractualisationCreditEnCours,
  contractualisationCreditTerminee,
  cancelled,
  refused,
  erreur,
}

export interface Demat {
  id: number;
  shopInfos: IShopInfos;
  cartId?: number;
  folderId?: string;
  encryptionKey?: string;
  status?: DematStatusEnums;
  contracts?: IProduceDocuments;
  customerSummary: ICustomerSummary;
  quoteSummary: IQuoteSummary[];
  evidences: Evidence[];
  evidencesFolder: {
    folder: string;
    onboardingUid: string;
    token: string;
    evidences: string[];
    evidencesDic: IEvidence[];
  };
  deletedAt?: Date;
  createdAt?: Date;
  updatedAt?: Date;
  data?: unknown;
}

export interface IShopInfos {
  vendorId: string;
  shopId: string;
  deviceId: string;
}

export interface IProduceDocuments {
  pandoraRequest: unknown;
  medocResponse: {
    documents: IElectronicDocument[];
  };
  aiboDocuments?: IElectronicDocument[];
}

export interface ISigningPosition {
  topLeftX: number;
  topLeftY: number;
  bottomRightX: number;
  bottomRightY: number;
  pageNumber: number;
}

export interface ICommand {
  idParcoursVente: string;
}

export interface IElectronicDocument {
  document: string;
  idDocument?: string;
  typeDocument: string;
  panier?: ICommand;
  positionSignature?: ISigningPosition;
}

export interface ICustomerSummary {
  civility: string;
  lastName: string;
  firstName: string;
  birthDate: string;
  birthPlace: string;
  addresses: IAddresses;
  infos: IInfos;
}

export interface IInfos {
  mail?: string;
  phoneNumber?: string;
  iban?: string;
}

export interface IAddresses {
  client: IClientAddress;
  installation: string;
}

export interface IClientAddress {
  streetNumber: string;
  street: string;
  street2: string;
  residence: string;
  building: string;
  stair: string;
  floor: string;
  city: string;
  postCode: string;
  country: string;
}

export interface IQuoteSummary {
  items: IItem[];
}

export interface IItem {
  offerId?: string;
  scanCode?: string;
  options?: string[];
  promos?: string[];
  msisdn?: string[];
  rio?: string;
  login?: string;
  rdvId?: string;
  edp: boolean;
  type?: string;
  final?: boolean;
  active?: boolean;
}

export interface Evidence {
  id: number;
  folderId: string;
  documentId: string;
  documentType: string;
  pathS3?: string;
  status?: string;
  deleteAt?: Date;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface IDematResponse {
  demat?: Demat;
  updDmt?: Demat;
  status: DematStatusEnums;
  token?: {
    token: string;
    redirect: string;
  };
  data?: {
    evidences?: string[];
    paperMode?: boolean;
    ariadNexInProcess?: boolean;
    errorDetail?: IErrorDetail;
    replay?: unknown;
    firstInit?: unknown;
    sdkWebUrl?: string;
    scannedData?: IScannedDematData;
    isReplayed?: boolean;
  };
}

export interface IScannedDematData {
  customerIdentities?: ICustomerData[];
  info?: {
    documentType?: IDDocTypeEnums;
    documentNumber?: string;
    issuingCountry?: string;
  };
}

export interface ICustomerData {
  uid?: string;
  identityData?: IIdentityData;
  addressData?: IIdentityData;
  financeData?: IIdentityData;
  legalEntityData?: IIdentityData;
}

export interface IIdentityData {
  [key: string]: ICustomerIdentitiesValue;
}

export interface ICustomerIdentitiesValue {
  label?: string;
  value?: string;
  origin?: string;
  values?: string[];
  originUid?: string;
}

export interface IMessage {
  message: string;
  key: string;
}
export interface IErrorDetail {
  type: string;
  blocking: boolean;
  errorMessages: string[] | IMessage[];
}

export interface IEvidence {
  key?: string;
  label?: string;
  status?: number;
}
