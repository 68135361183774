import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseModule } from '../../../base/base.module';
import { AccessoriesComponent } from './accessories.component';
import { AccessoriesRoutingModule } from './accessories-routing.module';
import { Nl2BrPipe } from './nl2br.pipe';

@NgModule({
  imports: [CommonModule, BaseModule, AccessoriesRoutingModule],
  declarations: [AccessoriesComponent, Nl2BrPipe],
  exports: [AccessoriesComponent],
})
export class AccessoriesModule {}
