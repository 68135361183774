import { Injectable } from '@angular/core';
import { IPortability, MsiSdnComponentEnum } from '../catalog/products/subscription/IPlanConfiguration';

@Injectable()
export class PortabilityService {
  public static preparePostMsiSdn(codeRio: string, phoneNumber: string, datePortage: string): IPortability {
    return {
      codeRio: codeRio,
      noTelephoneAPorter: phoneNumber.replace('0', '+33'),
      datePortage: datePortage,
      typePortabilite: codeRio.match(/^03P2/i)
        ? MsiSdnComponentEnum.migrationFluide
        : MsiSdnComponentEnum.portabiliteEntrante,
    };
  }
}
