<div class="row">
    <div class="wrapper-chekout col-12">
        <div class="row wrapper-step">
            <rcbt-checkout-stepper class="col-12"></rcbt-checkout-stepper>
        </div>
        <div class="row order-wrap">
            <div class="col-12 col-xl-9 order-infos">
                <router-outlet></router-outlet>
            </div>
            <div class="col-12 col-xl-3 ticket">
                <rcbt-ticket class="wrapper-ticket is-tri"></rcbt-ticket>
            </div>
        </div>
    </div>
</div>
