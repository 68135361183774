/* eslint-disable no-console */
import { Observable, throwError, timer } from 'rxjs';
import { mergeMap, finalize } from 'rxjs/operators';

export const genericRetryStrategy =
  ({
    maxRetryAttempts = 3,
    durationBeforeRetry = 1000,
    excludedStatusCodes = [],
  }: {
    maxRetryAttempts?: number;
    durationBeforeRetry?: number;
    excludedStatusCodes?: unknown[];
  } = {}) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (attempts: Observable<any>): Observable<number> =>
    attempts.pipe(
      mergeMap((response, retryAttempt) => {
        const isMaxAttemptsReached = maxRetryAttempts !== -1 && retryAttempt + 1 > maxRetryAttempts;
        const isExcludedStatusCode = excludedStatusCodes.includes(response.status);

        // if maximum number of retries have been met
        // or response is a status code we don't wish to retry, throw error
        if (isMaxAttemptsReached || isExcludedStatusCode) {
          return throwError(response);
        }

        console.log(`Attempt ${retryAttempt}: retrying in ${durationBeforeRetry}ms`);
        // retry after 1s, 2s, etc...
        return timer(durationBeforeRetry);
      }),
      finalize(() => console.log('We are done!')),
    );
