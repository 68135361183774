import { HttpBackend, HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ConfigurationInterface } from '../../configuration.interface';
import { CONFIG_DEFAULT, CONFIG_DEFAULT_CYPRESS, CYPRESS } from './app-initializer.config';
import { WebComponentUser } from '../../../web-component/web-component.model';

export const appInitializerFactory =
  (httpBackend: HttpBackend, document: Document): (() => Promise<ConfigurationInterface>) =>
  (): Promise<ConfigurationInterface> =>
    new HttpClient(httpBackend)
      .get<ConfigurationInterface>(getUrl())
      .pipe(
        tap(appConfig => {
          document.defaultView.appConfig = appConfig;
          initWebComponentConfig();
        }),
      )
      .toPromise();

const getUrl = (): string => (window.hasOwnProperty(CYPRESS) ? CONFIG_DEFAULT_CYPRESS : CONFIG_DEFAULT);

const initWebComponentConfig = (): void => {
  window.webComponentConfig = {
    hostApp: 'rcbt',
    refreshToken: new BehaviorSubject<string>(''),
    accessToken: new BehaviorSubject<string>(''),
    idToken: new BehaviorSubject<string>(''),
    trackerId: new BehaviorSubject<string>(''),
    localSalesApi: '',
    envPortailVente: '',
    oauth2: { base: '', api: '' },
    user: {} as WebComponentUser,
    prismeSt: 'portailvente_rcbt',
    isPrismeActive: false,
    isAccapActive: false,
    isSimulatorActive: false,
    authUrl: '',
  };
};
