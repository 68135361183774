import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseModule } from '../base/base.module';
import { ScanditComponent } from './scandit.component';
import { ScanditSdkModule, ScanditSdkService } from 'scandit-sdk-angular';
import { ConfigService } from '../config.service';
import { ConfigurationModule } from '../configuration/configuration.module';
import { FormsModule } from '@angular/forms';

const engineLocation = '/assets/tools/scanditSdk/build';

export function setLicenseScandit(configService: ConfigService): ScanditSdkService {
  return new ScanditSdkService({
    licenseKey: configService.data.scanditLicense,
    options: { engineLocation, preloadEngine: true },
  });
}

@NgModule({
  imports: [CommonModule, BaseModule, ConfigurationModule, ScanditSdkModule, FormsModule],
  declarations: [ScanditComponent],
  exports: [ScanditComponent],
})
export class ScanditModule {
  public static forRoot(): ModuleWithProviders<ScanditModule> {
    return {
      ngModule: ScanditModule,
      providers: [
        {
          provide: 'ScanditSdkModuleInstance',
          useFactory: ScanditSdkModule.factory,
        },
        {
          provide: ScanditSdkService,
          useFactory: setLicenseScandit,
          deps: [ConfigService],
        },
      ],
    };
  }
}
