import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  templateUrl: './equipment-modal.component.html',
  styleUrls: ['./equipment-modal.component.scss'],
})
export class EquipmentModalComponent {
  @Input()
  public types: { internal: string; display: string }[];

  @Input()
  public scanCode: string;

  @Input()
  public onTypeClick: (type: { internal: string; display: string }) => void;

  constructor(private activeModal: NgbActiveModal) {}

  public closeModal(): void {
    this.activeModal.close();
  }
}
