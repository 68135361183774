import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { BaseModule } from '../../../../base/base.module';

@NgModule({
  imports: [CommonModule, BaseModule, FormsModule],
  declarations: [],
  exports: [],
})
export class ModalsModule {}
