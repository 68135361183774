import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { IS_MOBILE } from './odr.config';

export const odrContactFormValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  if (!control) {
    return null;
  }

  const emailError = Validators.email(control);
  if (emailError === null) {
    return null;
  }

  const phoneNumberError = phoneNumberValidator(control.value);
  if (phoneNumberError === null) {
    return null;
  }

  return emailError ?? phoneNumberError;
};

const phoneNumberValidator = (phoneNumber: string): ValidationErrors | null => {
  if (!IS_MOBILE.test(phoneNumber)) {
    return { phone: true };
  }
  return null;
};
