<div class="dialog-modal-container is-tri">
    <div class="modal-header is-centered">
        <h3 class="title is-level-3">Erreur mode de financement</h3>
    </div>

    <div class="modal-text">
        <p class="text is-flex is-centered">
           Une erreur s'est produite lors de l'enregistrement du mode de financement.<br>
           Souhaitez-vous réessayer ou modifier le mode de financement ?
        </p>
    </div>

    <br>
    <div class="modal-actions">
        <button class="button is-primary" (click)="switchToCash()">Passer au comptant</button>
        <button class="button is-info" (click)="retry()">Réessayer</button>
    </div>

</div>
