import { IMsisdn } from './IPlanConfiguration';
import { Product } from '../product';
import { IOptionConfiguration } from './IOptionConfiguration';
import { ProductSerialized } from '../interface/configurable';
import { Subscription } from '../subscription';

export class Option extends Subscription {
  public optionCategory: string;
  public msisdns: IMsisdn[];
  public linkedOptions: Product[];
  public secondaryPhoneLinePro: string;
  public idCartWebShop: string;
  public isPartner?: boolean;

  public getConfiguration(): IOptionConfiguration {
    return <IOptionConfiguration>{
      // eslint-disable-next-line @typescript-eslint/naming-convention
      option_category: this.optionCategory,
      msisdns: this.msisdns,
      linkedOptions: this.linkedOptions,
      secondaryPhoneLinePro: this.secondaryPhoneLinePro,
      idCartWebShop: this.idCartWebShop,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      is_partner: this.isPartner,
      ...super.getConfiguration(),
    };
  }

  public setConfiguration(data: IOptionConfiguration): void {
    super.setConfiguration(data);
    this.msisdns = data.msisdns || this.msisdns;
    this.optionCategory = data.option_category || this.optionCategory;
    this.linkedOptions = data.linkedOptions || this.linkedOptions;
    this.secondaryPhoneLinePro = data.secondaryPhoneLinePro || this.secondaryPhoneLinePro;
    this.idCartWebShop = data.idCartWebShop || this.idCartWebShop;
    this.isPartner = data.is_partner || this.isPartner;
  }

  public serialize(): ProductSerialized {
    return Object.assign(super.serialize(), this.getConfiguration());
  }
}
