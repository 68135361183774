/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CheckoutComponent } from './checkout.component';
import { ContainerComponent } from '../container/container.component';
import { ContainerBootstrapComponent } from '../base/container-bootstrap/container-bootstrap.component';
import { GmapsService } from '../address/gmaps.service';
import { PromotionResolverService } from '../promotions/promotion-resolver.service';
import { CheckoutChainService } from './checkout-chain.service';

const routes: Routes = [
  {
    path: '',
    component: ContainerBootstrapComponent,
    children: [
      {
        path: '',
        resolve: { checkoutChain: CheckoutChainService },
        component: ContainerComponent,
        children: [
          {
            path: 'panier',
            component: CheckoutComponent,
            loadChildren: () => import('./cart/cart.module').then(m => m.CartModule),
            resolve: { eligPromoData: PromotionResolverService },
          },
          {
            path: 'panier/appointment',
            component: CheckoutComponent,
            loadChildren: () => import('./cart/appointment/appointment.module').then(m => m.AppointmentModule),
          },
          {
            path: 'panier/reprise-mobile',
            component: CheckoutComponent,
            loadChildren: () => import('./cart/mtb/mtb.module').then(m => m.MtbModule),
          },
          {
            path: 'panier/options',
            component: CheckoutComponent,
            loadChildren: () => import('./cart/options/options.module').then(m => m.OptionsModule),
          },
          {
            path: 'panier/equipements',
            component: CheckoutComponent,
            loadChildren: () => import('./cart/equipments/equipments.module').then(m => m.EquipmentsModule),
          },
          {
            path: 'panier/livraison',
            component: CheckoutComponent,
            loadChildren: () => import('./cart/delivery/delivery.module').then(m => m.DeliveryModule),
          },
          {
            path: 'panier/insurances',
            component: CheckoutComponent,
            loadChildren: () => import('./cart/cross-selling/cross-selling.module').then(m => m.CrossSellingModule),
          },
          {
            path: 'panier/ventes-complementaires',
            component: CheckoutComponent,
            loadChildren: () => import('./cart/cross-selling/cross-selling.module').then(m => m.CrossSellingModule),
          },
          {
            path: 'panier/services',
            component: CheckoutComponent,
            loadChildren: () => import('./cart/cross-selling/cross-selling.module').then(m => m.CrossSellingModule),
          },
          {
            path: 'panier/crv',
            component: CheckoutComponent,
            loadChildren: () => import('./cart/crv/crv.module').then(m => m.CrvModule),
          },
          {
            path: 'panier/accessories',
            component: CheckoutComponent,
            loadChildren: () => import('./cart/cross-selling/cross-selling.module').then(m => m.CrossSellingModule),
          },
          {
            path: 'panier/replace-sim',
            component: CheckoutComponent,
            loadChildren: () => import('./cart/replace-sim/replace-sim.module').then(m => m.ReplaceSimModule),
          },
          {
            path: 'panier/titulaire',
            component: CheckoutComponent,
            loadChildren: () => import('./cart/customer/customer.module').then(m => m.CustomerModule),
            resolve: { gmaps: GmapsService },
          },
          {
            path: 'panier/recapitulatif',
            component: CheckoutComponent,
            loadChildren: () => import('./cart/summary/summary.module').then(m => m.SummaryModule),
          },
          {
            path: 'panier/commander',
            component: CheckoutComponent,
            loadChildren: () => import('./cart/ordering/ordering.module').then(m => m.OrderingModule),
          },
          {
            path: 'panier/pieces-justificatives-v2',
            component: CheckoutComponent,
            loadChildren: () =>
              import('./cart/justificatory-v2/justificatory-v2.module').then(m => m.JustificatoryV2Module),
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class CheckoutRoutingModule {}
