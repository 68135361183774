import { Step } from '../../stepper/step.abstract';
import { Injectable } from '@angular/core';
import { CartService } from '../cart/cart.service';

@Injectable({
  providedIn: 'root',
})
export class CustomerStepService extends Step {
  public code = 'customer';
  public path = '/panier/titulaire';
  public label = 'Titulaire';

  constructor(private cartService: CartService) {
    super();
  }

  get isAllowed(): boolean {
    const currentScheme = this.cartService.getCurrentScheme(false);
    return !!currentScheme && currentScheme.products.length > 0;
  }
  set isAllowed(val: boolean) {
    return;
  }
}
