import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { GlobalLoaderService } from '../../../base/services/global-loader.service';
import { Step } from '../../../stepper/step.abstract';
import { UntypedFormGroup } from '@angular/forms';
import { CheckoutStepperService } from '../../checkout-stepper.service';
import { finalize, tap, catchError, mergeMap } from 'rxjs/operators';
import { Observable, of, from, Subscription } from 'rxjs';
import { Equipment } from './equipment.class';
import { EquipmentsService } from './equipments.service';
import { EquipmentError, EquipmentErrorType } from './equipment.error';
import { ScanditService } from '../../../scandit/scandit.service';
import { InputForScan, ScanditFieldInterface } from '../../../scandit/scandit.interface';
import { CartService } from '../cart.service';
import { BrowseConfigService } from '../../../context/browse-config.service';
import { FaimUnlimited } from '../../../catalog/products/subscription/plan/fai/faim-unlimited';
import { Option } from '../../../catalog/products/subscription/option';
import { ConfigService } from '../../../config.service';
import { JsonCatalog } from '../../../catalog/products/interface/context';

const LOADING_ACTIONS = {
  processEquipment: '[EquipmentsComponent] processEquipment',
  getSims: '[EquipmentsComponent] getSims',
};

@Component({
  templateUrl: './equipments.component.html',
  styleUrls: ['./equipments.component.scss'],
})
export class EquipmentsComponent extends Step implements OnInit, OnDestroy {
  @ViewChild('scanCodeElement')
  public scanCodeElement: ElementRef;

  public isEquipmentReady = false;
  public scanCode = '';
  public isOnTablete = false;
  public isLoading$: Observable<boolean>;
  public errorsGlobal: string[] = [];
  public warnings: string[] = [];
  public warningSav = '';

  public deviceForm: UntypedFormGroup;
  public shippingMethodMessage: string;

  public equipments: Equipment[] = [];

  public hasErrorOnGetListEquipment = false;
  public submitButtonType = 'camera';
  private scanListener: Subscription;
  private simList: string[] = [];

  constructor(
    private readonly stepperService: CheckoutStepperService,
    private readonly scanditService: ScanditService,
    private readonly equipmentService: EquipmentsService,
    private readonly globalLoaderService: GlobalLoaderService,
    private readonly cartService: CartService,
    private browseConfigService: BrowseConfigService,
    private configService: ConfigService,
  ) {
    super();
    this.setCurrentStepComponent(stepperService);
    this.globalLoaderService.dispatchLoadingStatus({ actionType: LOADING_ACTIONS.processEquipment, isLoading: false });
  }

  public ngOnInit(): void {
    const scheme = this.cartService.getCurrentScheme();
    this.isOnTablete = this.scanditService.isOnTablet() || this.browseConfigService.browseConfig.debug;
    if (scheme.isBigtrustSav()) {
      this.warningSav = 'Ce parcours est exceptionnel. La durée du prêt est de 15 jours.';
    }
    this.isLoading$ = this.globalLoaderService.isAppOnLoadingStatus$;
    this.shippingMethodMessage = this.equipmentService.getShippingMethodMessage();
    if (this.shippingMethodMessage === '') {
      if (this.hasBigTrust() || scheme.getProductByType(FaimUnlimited) || scheme.isBigtrustSav()) {
        this.loadContextualizedSimAndProcessUnitEquipment();
      } else {
        this.processInitEquipments();
      }
    } else {
      this.isEquipmentReady = true;
    }
    this.bindScanAction();
  }

  public bindScanAction(): void {
    if (this.isOnTablete) {
      this.scanListener = this.scanditService.scanForField.subscribe((data: ScanditFieldInterface) => {
        if (data.field.match(InputForScan.scanEquipment)) {
          const val: string = data.scanCodes ? data.scanCodes?.join(',') : data.scanCode;
          this.deviceForm.patchValue({ scanCode: val });
          this.processScan();
        }
      });
    }
  }

  public ngOnDestroy(): void {
    this.globalLoaderService.deleteStatusByActionTypes(LOADING_ACTIONS.processEquipment);
  }

  public onChangeForm(): void {
    this.stepperService.changesCurrentStep.next(null);
  }

  public scanOpen(): void {
    if (!this.isOnTablete || this.submitButtonType !== 'camera') {
      return;
    }
    this.scanditService.openScanForField(InputForScan.scanEquipment, true);
  }

  public processScan(): void {
    this.errorsGlobal = [];
    this.warnings = [];
    const valueScanCodes: string = this.deviceForm.controls.scanCode.value;
    const scanCodes: string[] = valueScanCodes?.split(',');
    this.globalLoaderService
      .showLoaderUntilFinalize(
        from(scanCodes).pipe(
          mergeMap((scanCode: string) =>
            this.equipmentService
              .selectEquipment(scanCode, this.equipments)
              .pipe(catchError(err => of(this.manageServiceResponseError(err)))),
          ),
          finalize(() => {
            this.resetScanCodeField();
            this.onChangeForm();
          }),
        ),
        LOADING_ACTIONS.processEquipment,
      )
      .subscribe();
  }

  public processSubmitButton(): void {
    const length: number = this.deviceForm.controls.scanCode.value.length;
    this.submitButtonType = length === 13 || length === 15 ? 'ok' : 'camera';
  }

  public clearEquipment(equipment: Equipment): void {
    equipment.free();
    this.onChangeForm();
  }

  public submit(): Observable<boolean> {
    if (this.shippingMethodMessage) {
      return of(true);
    }
    return this.loadJsonEquipments().pipe(mergeMap(() => this.equipmentService.saveDevices(this.equipments)));
  }

  public valideSubmit(): boolean {
    if (this.hasErrorOnGetListEquipment) {
      return false;
    }
    if (this.shippingMethodMessage) {
      return true;
    }
    if (
      !this.equipments.some(equipment => equipment.error !== '') &&
      !this.errorsGlobal.length &&
      this.equipments.every(eq => eq.isFilled())
    ) {
      return true;
    }
    return false;
  }

  private loadJsonEquipments(): Observable<JsonCatalog> {
    const listGencode = this.equipments.map(equipment => equipment.getGencode());
    listGencode.push('DEVICE_FAI_GENERIC');
    return this.equipmentService.loadJsonEquipments(listGencode);
  }

  private consoleLogAllGencodes(equipments: Equipment[]): void {
    const gencodeList = [];
    equipments.forEach(eq => {
      const list = '[' + eq.element.contraintesRemises.map(cr => cr.gencode).join(', ') + ']';
      gencodeList.push(list);
    });
    // eslint-disable-next-line no-console
    console.log('gencode for launcher: [' + gencodeList.join(', ') + ']');
  }

  private processInitEquipments(): void {
    this.globalLoaderService
      .showLoaderUntilFinalize(
        this.equipmentService.buildEquipments(this.simList).pipe(
          tap(
            (equipments: Equipment[]) => {
              this.equipments = equipments;
              this.equipments.forEach(eq => eq.consoleLogGencodes());
              this.consoleLogAllGencodes(equipments);
              this.deviceForm = this.equipmentService.buildForm();
              setTimeout(() => {
                this.resetScanCodeField();
              }, 0);
            },
            e => {
              this.manageServiceResponseError(e);
              this.hasErrorOnGetListEquipment = true;
            },
          ),
          finalize(() => {
            this.onChangeForm();
            this.isEquipmentReady = true;
          }),
        ),
        LOADING_ACTIONS.processEquipment,
      )
      .subscribe();
  }

  private loadContextualizedSimAndProcessUnitEquipment(): void {
    this.globalLoaderService
      .showLoaderUntilFinalize(
        this.equipmentService.loadContextualizedSim().pipe(
          tap(
            (res: string[]) => {
              this.simList = res;
              this.processInitEquipments();
            },
            e => {
              this.manageServiceResponseError(e);
              this.hasErrorOnGetListEquipment = true;
            },
          ),
        ),
        LOADING_ACTIONS.getSims,
      )
      .subscribe();
  }

  private manageServiceResponseError(e: Error): void {
    if (e instanceof EquipmentError) {
      if (e.type === EquipmentErrorType.global) {
        this.errorsGlobal.push(e.message);
      } else if (e.type === EquipmentErrorType.warn) {
        this.warnings.push(e.message);
      }
    } else {
      this.errorsGlobal.push('Erreur technique non-attendue !');
    }
  }

  private resetScanCodeField(): void {
    this.deviceForm.patchValue({ scanCode: '' });
    this.processSubmitButton();
  }

  private hasBigTrust(): boolean {
    const listGencodeBigTrust = this.configService.data?.listGencodeBigTrust || [];
    const options = this.cartService.getCurrentScheme().getProductsByType(Option);
    return options.some(option => listGencodeBigTrust.find(x => x === option.data.gencode));
  }
}
