import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'rcbt-funding-error',
  templateUrl: './funding-error.component.html',
  styleUrls: ['./funding-error.component.scss'],
})
export class FundingErrorComponent {
  @Input() switchToCash: Function;

  constructor(private readonly activeModal: NgbActiveModal) {}

  public retry(): void {
    window.location.reload();
  }

  public closeModal(): void {
    this.activeModal.close();
  }
}
