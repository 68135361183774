import { CategoryPlan } from './products/interface/categoryPlan';

export class CategoriesService {
  public static getPlans(isPro: boolean = false): CategoryPlan[] {
    const categories = [
      { category: 'plansensation_rcbt', label: 'Forfaits avec mobile', selected: !isPro, pro: false },
    ];
    if (isPro) {
      categories.push({
        category: 'plansensation_rcbt_pro',
        label: 'Forfaits Pro avec mobile',
        selected: true,
        pro: true,
      });
    }
    categories.push({ category: 'simo_rcbt', label: 'Forfaits sans mobile', selected: false, pro: false });
    categories.push({ category: 'omb_rcbt', label: 'Prompto', selected: false, pro: false });
    return categories.concat([
      { category: 'byou_rcbt', label: 'B&You', selected: false, pro: false },
      { category: 'prepaid_rcbt', label: 'Prépayé', selected: false, pro: false },
      { category: 'faim_old_rcbt', label: 'Data sans engagement', selected: false, pro: false },
      { category: 'faim_premium_rcbt', label: 'Data avec engagement', selected: false, pro: false },
    ]);
  }
}
