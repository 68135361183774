import { RulesRenewPremium } from './rules-renew-premium.class';
import { RulesRenewSowo } from './rules-renew-sowo.class';
import { Rules } from './rules.class';
import { Scheme } from '../scheme.class';
import { Premium } from '../../../catalog/products/subscription/plan/premium';
import { RulesSAV } from './rules-sav.class';
import { BasicObject } from '../../../base/base.interfaces';

export class RulesFactory {
  /**
   * List of rules class
   * @type {{default: Rules; renewPremium: RulesRenewPremium; renewSowo: RulesRenewSowo}}
   */
  public static rulesClass: BasicObject = {
    continuiteDeService: RulesSAV,
    default: Rules,
    renewPremium: RulesRenewPremium,
    renewSowo: RulesRenewSowo,
  };

  /**
   * Return instance of RulesXXXXX
   * @param key
   * @return {Rules}
   */
  public static getNewInstanceOf(key: string, scheme: Scheme): Rules {
    return new RulesFactory.rulesClass[key](scheme);
  }

  /**
   * Check a scheme and return rule for product and context of it
   * All is define in one function and not in rulesXXXX.class for performance
   * @param scheme
   * @return {Rules}
   */
  public static getRulesFor(scheme: Scheme): Rules {
    // => if Renew
    if (scheme.isRenew()) {
      // ==> if Premium
      for (const product of scheme.products) {
        if (product instanceof Premium) {
          return new RulesRenewPremium(scheme);
        }
      }
      // ==> else SOWO
      return new RulesRenewSowo(scheme);
    }
    if (scheme.isBigtrustSav()) {
      return new RulesSAV(scheme);
    }
    // => else Default
    return new Rules(scheme);
  }
}
