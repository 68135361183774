import { Component, Input, OnInit } from '@angular/core';
import { Product } from '../../../catalog/products/product';
import { OffresSecondaires } from '../../../context/child/renew-promo-context-serialized.interface';

@Component({
  selector: 'rcbt-ticket-renew-promotions',
  templateUrl: './ticket-renew-promotions.component.html',
  styleUrls: ['./ticket-renew-promotions.component.scss'],
})
export class TicketRenewPromotionsComponent implements OnInit {
  @Input() public product: Product;
  public osSecond: OffresSecondaires[] = [];

  public ngOnInit(): void {
    if (this.product.renewPromotion) {
      this.osSecond = this.product.renewPromotion.offresSecondairesCibles;
    }
  }
}
