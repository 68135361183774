import { Directive, Input } from '@angular/core';
import { ElementRef } from '../../types/angular/core/elementRef';

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: '[btlSvg]' })
export class SvgDirective {
  /**
   * [Input description]
   * @return {[type]} [description]
   */
  @Input()
  public role: string;
  /**
   * [Input description]
   * @return {[type]} [description]
   */
  @Input()
  public title: string;
  /**
   * [Input description]
   * @return {[type]} [description]
   */
  @Input()
  public file: string;
  /**
   * [Input description]
   * @return {[type]} [description]
   */
  @Input()
  public name: string;
  /**
   * [Input description]
   * @return {[type]} [description]
   */
  @Input()
  public class: string;

  protected sufixUrl = '';

  constructor(protected el: ElementRef) {}

  /**
   * [ngAfterViewInit description]
   * @return {[type]} [description]
   */
  public ngAfterViewInit(): void {
    const nodeParent = this.el.nativeElement.parentNode;
    nodeParent.removeChild(this.el.nativeElement);

    if (!this.role) {
      this.role = 'img';
    }

    if (!this.title) {
      this.title = ' ';
    }

    const xhref = `${this.sufixUrl}/assets/svgs-def/${this.file}.svg#${this.name}`;
    nodeParent.innerHTML = `<svg role="${this.role}" title="${this.title}" class="icon ${this.class}">
        <use xlink:href="${xhref}"></use></svg>`;
  }
}
