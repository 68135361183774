import { Component, OnInit } from '@angular/core';
import { CartService } from '../../../checkout/cart/cart.service';
import { BrowseType } from '../../../checkout/cart/browse-type';
import { CustomerService } from '../../../checkout/cart/customer/customer.service';

@Component({
  selector: 'rcbt-nav-bar',
  templateUrl: 'navBar.component.html',
  styleUrls: ['./navBar.component.scss'],
})
export class NavBarComponent implements OnInit {
  public telephoneRoute: string[];
  public isRenew = false;
  public category: string;
  public hasSim: boolean;
  constructor(
    private cartService: CartService,
    private customerService: CustomerService,
  ) {}

  public ngOnInit(): void {
    // les CA on le droit d'acceder à l'onglet accessoire pas cohenrent avec la sag vs pratique
    // const codeEns = this.contextService.get<UserContextService>(UserContextService).codeEns;
    // if (Number(codeEns) === USER_ENS_TYPE.CA) {
    //     this.isCA = true;
    // }
    this.isRenew = this.cartService.getCurrentScheme().browseType === BrowseType.renew;
    this.telephoneRoute = this.isRenew ? ['/category/', 'telephones', 'renew'] : ['/category/', 'telephones'];
    this.hasSim =
      this.customerService.customer.ligneMarche === 'FNB' ||
      (['1', '2'].indexOf(this.customerService.customer.ligneMarche) !== -1 &&
        this.customerService.customer.telephonePrepaye.length > 0);
  }
}
