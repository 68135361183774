import { Injectable } from '@angular/core';
import { SchemeSerialized } from './scheme.interface';
import { JsonProduct } from '../../catalog/products/interface/context';
import { Product } from '../../catalog/products/product';
import { Catalog } from '../../catalog/products/catalog';
import { PromotionFactory } from '../../promotions/promotionFactory.class';
import { Scheme } from './scheme.class';
import { RenewService } from './renew.service';
import { ProductSerialized } from '../../catalog/products/interface/configurable';
import { BrowseType } from './browse-type';
import { RenewScheme } from './renew-scheme';
import { AcquisitionScheme } from './acquisition-scheme';
import { SavScheme } from './sav-scheme';
import { FaiScheme } from './fai-scheme';
import { Observable, of } from 'rxjs';
import { Oauth2RessourceService } from '../../oauth2/oauth2-resources.service';
import { Cart } from './cart';
import { ContextSerializedInterface } from '../../context/context-serialized.interface';
import { BrowseConfigService } from '../../context/browse-config.service';
import { CustomerService } from './customer/customer.service';
import { UserService } from '../../user/user.service';
import { ScoringService } from '../../scoring/scoring.service';
import { PromotionsService } from '../../promotions/promotionsService';
import { PromoRenewService } from './promo-renew.service';
import { Plan } from '../../catalog/products/subscription/plan';
import { mergeMap } from 'rxjs/operators';
import { CustomerContextSerializedInterface } from '../../context/child/customer-context-serialized.interface';

@Injectable({
  providedIn: 'root',
})
export class SchemeService {
  constructor(
    private oauth2RessourceService: Oauth2RessourceService,
    private browseConfigService: BrowseConfigService,
    private customerService: CustomerService,
    private renewService: RenewService,
    private userService: UserService,
    private scoringService: ScoringService,
    private promotionsService: PromotionsService,
    private promoRenewService: PromoRenewService,
  ) {}

  public getNewScheme(browseType: string, uniqueId?: string, rulesType?: string): Scheme {
    let scheme: Scheme;
    switch (browseType) {
      case BrowseType.renew:
        scheme = new RenewScheme(rulesType, uniqueId);
        break;
      case BrowseType.acquisitionFix:
        scheme = new FaiScheme(rulesType);
        break;
      case BrowseType.sav:
        scheme = new SavScheme(rulesType);
        break;
      case BrowseType.acquisition:
        scheme = new AcquisitionScheme(rulesType);
        break;
      default:
        throw new Error('Type de parcours non défini');
    }
    scheme.browseType = browseType;
    return scheme;
  }

  public createScheme(scheme: Scheme, cart: Cart): Observable<SchemeSerialized> {
    const renewObs = scheme.isRenew() ? this.promoRenewService.initRenewPromotions(scheme.contractId) : of(null);
    return renewObs.pipe(
      mergeMap(() =>
        this.oauth2RessourceService
          .ventes()
          .panier(cart.cartId)
          .parcours()
          .setLocalService()
          .useSalesApi()
          .post(this.serialize(scheme)),
      ),
    );
  }

  public unserialize(schemeSerialized: SchemeSerialized): Scheme {
    const scheme: Scheme = this.getNewScheme(
      schemeSerialized.contextProduit.contextService.parcours.typeParcours,
      schemeSerialized.idUnique,
      schemeSerialized.typeRegles,
    );
    scheme.setRules(schemeSerialized.typeRegles).setUniqueId(schemeSerialized.idUnique);
    scheme.quoteId = schemeSerialized.idParcours;
    scheme.verrouiller = schemeSerialized.verrouiller;
    scheme.addedInsurances = schemeSerialized.addedInsurances || [];
    scheme.totals = schemeSerialized.totals;
    scheme.bytelTotals = schemeSerialized.bytelTotals;
    scheme.paymentAccount = schemeSerialized.contextProduit.contextService.client.comptePayeur;
    for (const key in schemeSerialized.produits) {
      if (schemeSerialized.produits[key].type_id === 'edp') {
        continue;
      }
      const productToInstanciate: JsonProduct = schemeSerialized.produits[key].data;
      const p: Product = Catalog.getInstance(
        { ...productToInstanciate, ...schemeSerialized.produits[key] },
        schemeSerialized.produits[key].idUnique,
      );
      p.unserialise(schemeSerialized.produits[key]);
      if (p instanceof Plan) {
        p.updatePromotion(this.promoRenewService.renewPromotion);
      }
      p.promotions = PromotionFactory.createCollection(schemeSerialized.produits[key].promotions);
      scheme.products.push(p);
    }
    this.applyRenewPromotions(scheme);
    scheme.unserializeSpecificData(schemeSerialized);
    return scheme;
  }

  public serialize(scheme: Scheme): SchemeSerialized {
    const productsSerialized: ProductSerialized[] = [];
    for (const key in scheme.products) {
      productsSerialized.push(scheme.products[key].serialize());
    }
    return {
      idParcours: scheme.quoteId,
      contextProduit: {
        contextService: this.serializeContextService(scheme),
      },
      typeRegles: scheme.rules.key,
      idUnique: scheme.uniqueId,
      produits: productsSerialized,
      promotions: scheme.promotions,
      verrouiller: scheme.verrouiller,
      addedInsurances: scheme.addedInsurances,
    };
  }

  private serializeContextService(scheme: Scheme): ContextSerializedInterface {
    this.browseConfigService.browseConfig.personId = this.customerService.customer.personId;
    this.browseConfigService.browseConfig.contractId = scheme.contractId;
    const contextSerializedInterface = {
      parcours: this.browseConfigService.browseConfig.serialize(),
      client: this.getSerializedCustomerContext(scheme),
      acquisition: scheme.isAcquisitionMobile() ? { idContrat: scheme.contractId } : undefined,
      acquisitionFix: scheme.isAcquisitionFix()
        ? {
            contractId: scheme.contractId,
            idEligFai: (scheme as FaiScheme).faiEligId,
            installationCodePostal: (scheme as FaiScheme).installationPostalCode,
          }
        : undefined,
      renouvellement: scheme.isRenew()
        ? this.renewService.serialize(scheme as RenewScheme, this.customerService.customer.personId)
        : undefined,
      promo: scheme.isRenew() ? this.promoRenewService.serialize() : undefined,
      utilisateur: this.userService.user.serialize(),
      scoring: this.scoringService.serialize(),
      sav: scheme.isBigtrustSav()
        ? {
            contractId: scheme.contractId,
            ignorerElig: (scheme as SavScheme).ignoreElig,
            eligible: (scheme as SavScheme).eligible,
          }
        : undefined,
      promotion: this.promotionsService.serialize(),
    };
    contextSerializedInterface.parcours.typeParcours = scheme.browseType;
    return contextSerializedInterface;
  }

  private getSerializedCustomerContext(scheme: Scheme): CustomerContextSerializedInterface {
    const serializedCustomer = this.customerService.customer.serialize();
    serializedCustomer.comptePayeur = scheme.paymentAccount;
    // todo ancienne règle à vérifier avec un QA, peut être obsolète
    if (serializedCustomer.comptePayeur) {
      serializedCustomer.comptePayeur.rum = null;
    }
    return serializedCustomer;
  }

  private applyRenewPromotions(scheme: Scheme): void {
    const renewPromotionsAmount = this.promoRenewService.getRenewPromotionsAmount([scheme]);
    scheme.bytelTotals.everyMonth -= renewPromotionsAmount;
    scheme.totals.everyMonth -= renewPromotionsAmount;
  }
}
