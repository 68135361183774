/* eslint-disable */
import { Component, OnInit } from '@angular/core';

import { Oauth2Service } from '../../oauth2/oauth2.service';
import { WebComponentUser } from '../web-component.model';

@Component({
    selector: `rcbt-accap-host`,
    template: `<rcbt-accap accapAdapterDirective></rcbt-accap>`,
})
export class AccapAdpterComponent implements OnInit {

    constructor(private oauth2Service: Oauth2Service) {}

    public ngOnInit(): void {
        window.webComponentConfig = {
            ...window.webComponentConfig,
            ...{
                user: this.getWebComponentUserInfo()
            }
        };
    }

    private getWebComponentUserInfo(): WebComponentUser {
        const { deviceId, enseigne, login, point_vente, roles, user_type } =  this.oauth2Service.playLoad
        return {
            deviceId,
            enseigne,
            login,
            point_vente,
            roles: roles as any,
            user_type: user_type as any
        };
    }
}
