<div class="is-flex is-vertical is-aligned-center g-15">

    <div class="is-flex is-spaced-between is-aligned-center g-10" *ngIf="hasPartExchange">
        <span class="is-size-5" data-cy="phone-hasMobileTakeBack">Reprise Mobile</span>
        <span class="icon is-medium is-success"><i class="tri-check" aria-hidden="true"></i></span>
        <button class="button is-warning is-icon-only" (click)="removePartExchange()" [ngClass]="{'is-loading': scanLoading}">
            <span class="icon is-small"><i class="tri-trash" aria-hidden="true"></i></span>
        </button>
    </div>

    <rcbt-takeback-button [componentSource]="componentSourceTakeback" *ngIf="!hasPartExchange"></rcbt-takeback-button>
    <rcbt-bonus-reprise class="m-t-20" *ngIf="label" [etiquetteAnimCo]="label"></rcbt-bonus-reprise>    
</div>
