<rcbt-top-bar
    [isDispatchPage]="onDispatchPage"
    [isSummaryPage]="onSummaryPage"
    [isConsultAccessoryPricePage]="onAccessoryPricePage"
    [isEligStandalonePage]="onEligPage"
    [isCustomWelcomePage]="onCustomWelcomePage"
    [onCustomRecapPage]="onCustomRecapPage"
    ui-scrollpoint
></rcbt-top-bar>
<div class="container-fluid">
    <div class="row content">
        <div class="col-12">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>
