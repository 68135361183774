import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContainerComponent } from '../../container/container.component';
import { CategoryPlanComponent } from './categoryPlan/category-plan.component';
import { ContainerBootstrapComponent } from '../../base/container-bootstrap/container-bootstrap.component';
import { prismeLoggerResolver } from '../../shared/prisme/prisme-logger.resolver';
import { contextResolver } from '../../context/contextResolverFn';
import { renewResolver } from '../../checkout/cart/renewResolverFn';
import { CategoryComponent } from './category.component';

const routes: Routes = [
  {
    path: '',
    resolve: { context: contextResolver },
    component: ContainerComponent,
    children: [
      {
        path: '',
        component: ContainerBootstrapComponent,
        children: [
          {
            path: 'category/plan',
            resolve: {
              prismeLoggerResolver,
            },
            children: [
              {
                component: CategoryPlanComponent,
                path: '',
                resolve: { prismeLoggerResolver },
              },
            ],
          },
          {
            path: 'category/:category',
            resolve: {
              prismeLoggerResolver,
            },
            children: [
              {
                component: CategoryComponent,
                path: '',
                resolve: { prismeLoggerResolver },
              },
              {
                component: CategoryComponent,
                path: 'renew',
                resolve: { prismeLoggerResolver, renewResolver },
              },
            ],
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CategoryRoutingModule {}
