import { ComponentFactoryResolver, Injectable, Type, ViewContainerRef } from '@angular/core';

import { ComponentType } from './dynamic-component.interface';

@Injectable({ providedIn: 'root' })
export class DynamicComponentLoaderService {
  viewContainerRef: ViewContainerRef;

  constructor(private componentFactoryResolver: ComponentFactoryResolver) {}

  public setViewContainerRef(viewContainerRef: ViewContainerRef): void {
    this.viewContainerRef = viewContainerRef;
  }

  public createComponent(componentToCreate: Type<ComponentType>, componentData: ComponentType): void {
    if (!this.viewContainerRef) {
      throw new Error('Should initialize setViewContainerRef first');
    }
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentToCreate);
    this.viewContainerRef.clear();

    const componentRef = this.viewContainerRef.createComponent<ComponentType>(componentFactory);

    componentRef.instance.data = componentData.data;
  }

  public deleteComponent(): void {
    if (!this.viewContainerRef) {
      return;
    }
    this.viewContainerRef.clear();
  }
}
