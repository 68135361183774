import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ScanComponent } from './scan.component';
import { FormScanComponent } from './form-scan/form-scan.component';
import { ViewModule } from '../catalog/category/view/view.module';
import { ScanDirective } from './scan.directive';
import { BaseModule } from '../base/base.module';
import { StockChoiceModule } from './form-scan/stock-choice/modal-stock-choice.module';
import { ProductUnreservationModule } from './form-scan/modal-product-unreservation/modal-product-unreservation.module';

@NgModule({
  imports: [CommonModule, FormsModule, ViewModule, BaseModule, StockChoiceModule, ProductUnreservationModule],
  declarations: [ScanComponent, FormScanComponent, ScanDirective],
  exports: [ScanComponent],
})
export class ScanModule {}
