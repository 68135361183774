import { Plan } from '../plan';
import { IPlanConfiguration } from '../IPlanConfiguration';
export class Mobile extends Plan {
  public getFilteredConfiguration(): IPlanConfiguration {
    const filteredConfig: IPlanConfiguration = { ...this.getConfiguration() };
    delete filteredConfig.banking_offer;
    delete filteredConfig.msisdns;
    if (filteredConfig.rio) {
      filteredConfig.rio.reserved = false;
      filteredConfig.rio.phoneNumberTemp = '';
    }
    return filteredConfig;
  }
}
