import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'rcbt-sim-terminal-compatibility',
  templateUrl: './sim-terminal-compatibility.component.html',
  styleUrls: ['./sim-terminal-compatibility.component.scss'],
})
export class SimTerminalCompatibilityComponent {
  constructor(protected activeModal: NgbActiveModal) {}

  public cancel(): void {
    this.activeModal.dismiss();
  }

  public confirm(): void {
    this.activeModal.close();
  }
}
