<div class="is-tri agility-modal is-fullwidth is-flex is-vertical is-spaced-between is-aligned-left">
    <div class="header is-paddingless is-fullwidth is-flex is-justified-end is-aligned-start">
        <div class="title">Rabais Panier</div>
        <div (click)="close()">
            <span class="icon is-medium">
              <i class="tri-times" aria-hidden="true"></i>
            </span>
        </div>    
    </div>

    <form class="form is-fullwidth is-flex is-vertical is-spaced-between is-aligned-left" novalidate>
        <div class="form-row is-fullwidth is-flex is-aligned-center is-justified-start form-row-price">
            <div class="label">
                <label>Montant rabais</label>
            </div>
            <div class="input-data is-flex is-justified-start is-aligned-center">
                <input type="text" class="input is-fullwidth has-background-secondary-30" name="percent_discount_ca" id="percent_discount_ca" data-cy="agility-modal-percent_discount_ca" [value]="percentAgilityCA || null"
                        amountinput (amountChange)="onDiscountAmountChanged($event, true)">
                <span class="currency-symbol">%</span>
            </div>
            <div class="equals">
                <strong>=</strong>
            </div>
            <div class="input-data is-flex is-justified-start is-aligned-center">
                <input type="text" class="input is-fullwidth has-background-secondary-30" name="discount_ca" id="discount_ca" [value]="agilityAmountCA || null"
                        amountinput (amountChange)="onDiscountAmountChanged($event, false)">
                <span class="currency-symbol">€</span>
            </div>
            <label class="label has-text-danger">
                {{error}}
            </label>
        </div>
        <div class="form-row is-fullwidth is-flex is-aligned-center is-spaced-between">
            <div class="label">
                Sous Total Panier CA :
            </div>
            <div>
                <rcbt-price [price]="caTotals.today - agilityAmountCA" [oldPrice]="caTotals.today" [fontLevel]="4"></rcbt-price>
            </div>
        </div>
        <div class="form-row is-fullwidth is-flex is-aligned-center is-justified-end">
            <button type="button" data-cy="agility-modal-button" class="button is-info" (click)="onApplyAgilityCA()">
                <span class="text">Appliquer</span>
            </button>
        </div>
    </form>
</div>
