
<section class="accordions" *ngIf="hasGrantedLoan">
    <article class="accordion is-active">
        <div class="accordion-header">
            <h4 class="is-marginless is-uppercase">Financement panier à crédit</h4>
        </div>
        <div class="accordion-body accordion-body-modif has-background-white">
            <div class="accordion-content">
                <table class="table is-fullwidth is-marginless">
                    <thead></thead>
                    <tbody></tbody>
                    <tfoot>
                        <tr class="is-fullwidth">
                            <td class="is-fullwidth is-flex is-spaced-between is-aligned-center">
                                <span class="has-text-weight-bold is-uppercase">Financement à crédit</span>
                                <div class="is-flex is-aligned-center load-datas">
                                    <rcbt-price [price]="proposal.initialAmount" [fontLevel]="7"></rcbt-price>
                                    <span>&nbsp;+&nbsp;</span>
                                    <rcbt-price [price]="proposal.monthlyAmount" [fontLevel]="7"></rcbt-price>
                                    <span>&nbsp;x&nbsp;</span>
                                    <span class="has-text-weight-bold">{{proposal.nbrEcheances}} mois</span>
                                    <span>, TAEG&nbsp;</span>
                                    <span class="has-text-weight-bold">{{proposal.tAEG}}%</span>
                                    <span>&nbsp;(soit au total&nbsp;</span>
                                    <rcbt-price [price]="proposal.fundingFinalCost" [fontLevel]="7"></rcbt-price>
                                    <span>&nbsp;dont&nbsp;</span>
                                    <rcbt-price [price]="proposal.interestAmount" [fontLevel]="7"></rcbt-price>
                                    <span>&nbsp;d'intérêts)</span>
                                </div>
                                <button class="button is-warning is-icon-only" (click)="deleteGrantedLoan()">
                                    <span class="icon is-small"><i class="tri-trash" aria-hidden='true'></i></span>
                                </button>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    </article>
</section>
