import { Injectable } from '@angular/core';

import { StorageService } from '../../base/services/storage.service';
import { FundingStorageInterface } from '../interfaces/funding.interface';

@Injectable({
  providedIn: 'root',
})
export class FundingStorageService extends StorageService {
  public key: Record<string, string> = {
    fundingMode: 'fundingMode',
  };

  protected prefix: 'cart_' = 'cart_';

  protected sessionStorageKeys = [this.key.fundingMode];

  public serializeFundingMode(creditSessionDate: FundingStorageInterface): void {
    this.setItem(this.key.fundingMode, creditSessionDate);
  }

  public unserializeFundingMode(): FundingStorageInterface {
    return this.getItem(this.key.fundingMode);
  }
}
