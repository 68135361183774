import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbDateStruct, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subscription, forkJoin as observableForkJoin, of as observableOf } from 'rxjs';
import { catchError, finalize, mergeMap, tap } from 'rxjs/operators';
import { DateUniversal } from '../../../../base/class/date-universal.class';
import { SimCbt } from '../../../../catalog/products/equipement/sim/sim-cbt';
import {
  IMigration,
  IMsisdn,
  IPlanConfiguration,
  IRio,
  MsiSdnComponentEnum,
  IPhoneNumber,
  TypesPortability,
} from '../../../../catalog/products/subscription/IPlanConfiguration';
import { Plan } from '../../../../catalog/products/subscription/plan';
import { Prepaid } from '../../../../catalog/products/subscription/plan/prepaid';
import { Oauth2RessourceService } from '../../../../oauth2/oauth2-resources.service';
import { PortabilityService } from '../../../../portability/portability.service';
import { PromotionsService } from '../../../../promotions/promotionsService';
import { CheckoutStepperService } from '../../../checkout-stepper.service';
import { CartService } from '../../cart.service';
import { PromotionalEligibilityPromotionExtV2Dto } from '../../dto/promotional-eligibility-output.dto';
import { BasicObject, BooleanObject } from '../../../../base/base.interfaces';
import { Scheme } from '../../scheme.class';
import { PortabilityModalComponent } from './portability-modal/portability-modal.component';

@Component({
  selector: 'rcbt-msisdn',
  templateUrl: './msisdn.component.html',
  styleUrls: ['./msisdn.component.scss'],
})
export class MsisdnComponent implements OnInit, OnDestroy {
  public static maxMsiSdnNumber = 3;
  @Output() public emitMsisdns: EventEmitter<IMsisdn[]> = new EventEmitter<IMsisdn[]>();
  @Input() public plan: Plan;
  public typesPortability: typeof TypesPortability = TypesPortability;
  public type: TypesPortability;
  public loading = false;
  public msisdnSaveForm: UntypedFormGroup;
  public errors: string[] = [];
  public disabledPortability = false;
  public numTemp: IMsisdn = null;
  public msisdnAlreadyReserved = false;
  public yesMessage = false;
  public portaPromotions: PromotionalEligibilityPromotionExtV2Dto[] = [];
  public selectedDate: NgbDateStruct;
  public portabilityBlockMessage = '';
  public planConfig: IPlanConfiguration;
  public subscription = new Subscription();
  constructor(
    protected cartService: CartService,
    private oauth2RessourceService: Oauth2RessourceService,
    private formBuilder: UntypedFormBuilder,
    private stepperService: CheckoutStepperService,
    private modalService: NgbModal,
    protected promotionsService: PromotionsService,
  ) {}

  public ngOnInit(): void {
    this.initMsisdns();
    this.subscription.add(this.cartService.modifiedOffer.subscribe(() => this.initMsisdns()));
  }

  public onChangesSchemes(): void {
    const prepaidMsisdns = this.getPrepaidMsisdns();
    if (this.isPrepaid()) {
      if (prepaidMsisdns.length === 0) {
        this.disabledPortability = true;
      } else {
        this.disabledPortability = false;
        this.setPlanMsisdns(prepaidMsisdns);
      }
    }
    this.stepperService.changesCurrentStep.next(null);
  }

  public ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  public loadForm(): void {
    switch (this.type) {
      case TypesPortability.newNum:
        this.initNewNum();
        break;
      case TypesPortability.portability:
        this.initPortability();
        break;
    }
  }

  public isPrepaid(): boolean {
    return this.plan instanceof Prepaid;
  }

  public getPrepaidMsisdns(): IMsisdn[] {
    const currentScheme: Scheme = this.cartService.getCurrentScheme();
    const sim: SimCbt[] = currentScheme?.getProductsByType(SimCbt);
    if (sim[0] && sim[0].ericssonData.msisdn) {
      return [
        {
          id: sim[0].ericssonData.msisdn,
          dateFinValidite: new Date(),
          selected: false,
        },
      ];
    }
    return [];
  }

  public selectMsisdn(index: number): void {
    this.plan.msisdns.forEach((msisdn: IMsisdn, idx) => (msisdn.selected = idx === index));
    this.plan.setConfiguration(<IPlanConfiguration>{
      msisdns: this.plan.msisdns,
      rio: !this.isPrepaid() ? undefined : this.plan.rio,
    });
    this.cartService.save();
    this.planConfig = this.plan.getConfiguration();
    this.stepperService.changesCurrentStep.next(null);
  }

  public onChangeForm(): void {
    this.plan.rio = this.msisdnSaveForm.getRawValue();
    if (!!this.plan.rio && !!this.plan.rio.codeRio) {
      this.plan.rio.codeRio = this.plan.rio.codeRio.toUpperCase();
    }
    this.stepperService.changesCurrentStep.next(null);
  }

  public submit(): Observable<boolean> {
    this.errors = [];
    if (this.type === TypesPortability.portability && !this.plan.rio.reserved) {
      return this.oauth2RessourceService
        .eligibilitesPortabilite()
        .post(
          PortabilityService.preparePostMsiSdn(
            this.plan.rio.codeRio,
            this.plan.rio.phoneNumber,
            this.plan.rio.datePortage,
          ),
        )
        .pipe(
          mergeMap(res => {
            this.setMigrationFluide(res.contexteMigrationFluide);
            if (
              res.statut &&
              res.messages &&
              res.messages.some(message => message === MsiSdnComponentEnum.soldeCreditSuperieur50)
            ) {
              const options: NgbModalOptions = <NgbModalOptions>{
                backdrop: 'static',
                size: 'lg',
                keyboard: false,
              };
              const modal: PortabilityModalComponent = this.modalService.open(
                PortabilityModalComponent,
                options,
              ).componentInstance;
              modal.dateValidite = res.contexteMigrationFluide.dateLimiteValidite2;
              this.cartService.save();
              return observableOf(false);
            } else if (res.statut) {
              this.plan.rio.phoneNumberTemp = this.msisdnSaveForm.get('phoneNumberTemp').value;
              this.plan.setConfiguration(<IPlanConfiguration>{ msisdns: undefined, rio: this.plan.rio });
              this.cartService.save();
              return observableOf(true);
            } else {
              res.messages.forEach(message => {
                this.errors.push("Erreur d'éligibilité : " + message);
              });
              this.loading = false;
              return observableOf(false);
            }
          }),
          catchError(err => {
            this.errors.push(err.error.error_description);
            return observableOf(false);
          }),
          tap(() => this.stepperService.changesCurrentStep.next(null)),
        );
    }

    this.cartService.save();
    this.stepperService.changesCurrentStep.next(null);
    return observableOf(true);
  }

  public valideSubmit(): boolean {
    switch (this.type) {
      case TypesPortability.portability:
        return this.msisdnSaveForm.valid;
      case TypesPortability.newNum:
        return this.plan.msisdns && this.plan.msisdns.filter((msisdn: IMsisdn) => msisdn.selected).length === 1;
      default:
        return false;
    }
  }

  public setPortability(value: TypesPortability): void {
    this.type = value;
    this.loadForm();
    this.portaPromotions = this.getPortabilityPromotionAction();
    this.yesMessage = this.type === TypesPortability.portability;
    this.cartService.onChangesSchemes.next(this.cartService.cart.schemes);
  }

  public getPortabilityPromotionAction(): PromotionalEligibilityPromotionExtV2Dto[] {
    return this.cartService.getPortaPromotions(this.plan);
  }

  public onPortaDateChange(d: NgbDateStruct): void {
    const zerofilled = (n: number): string => ('0000' + n).slice(-2);
    const date = `${d.year}-${zerofilled(d.month)}-${zerofilled(d.day)}`;
    const datePicked = new DateUniversal(date);
    this.msisdnSaveForm.get('datePortage').setValue(datePicked.toYYYYMMJJ());
    this.msisdnSaveForm.get('datePortage').markAsTouched();
    this.onChangeForm();
  }

  public scrollToBottom(event?: Event): void {
    setTimeout(() => {
      window.scrollTo(0, document.body.scrollHeight);
    }, 0);
  }

  public checkPartnerRio(force: boolean): void {
    const codeRio: string = this.msisdnSaveForm?.controls.codeRio.value || '';
    if (codeRio.length === 12 || force) {
      if (this.plan.rio?.codeRio) {
        this.promotionsService.eligibilitePromotionnelle(this.cartService.getCartAsEligPromoBody()).subscribe(() => {
          this.cartService.onChangesSchemes.next(this.cartService.cart.schemes);
        });
      } else {
        this.promotionsService.hasRioPartner = false;
        this.cartService.onChangesSchemes.next(this.cartService.cart.schemes);
      }
    }
  }

  public setPlanConfig(param: IPlanConfiguration): void {
    this.planConfig = param;
  }

  private initMsisdns(): void {
    this.plan = this.cartService.getCurrentScheme().getProductByType<Plan>(Plan);
    if (this.plan.rio) {
      this.setPortability(TypesPortability.portability);
    } else if (this.plan.msisdns && this.plan.msisdns.length) {
      this.msisdnAlreadyReserved = this.plan.msisdns.some((msisdn: IMsisdn) => msisdn.reserved);
      this.setPortability(TypesPortability.newNum);
    } else {
      // default behavior case
      this.setPortability(TypesPortability.newNum);
    }
    this.disabledPortability = this.isPrepaid() && this.getPrepaidMsisdns().length === 0;
  }

  private setPlanMsisdns(msisdns: IMsisdn[]): void {
    this.emitMsisdns.emit(msisdns);
  }

  private initNewNum(): void {
    delete this.plan.rio;
    this.plan.portabilityType = TypesPortability.newNum;
    this.cartService.save();
    this.stepperService.changesCurrentStep.next(null);
    if (this.isPrepaid()) {
      this.setPlanMsisdns(this.getPrepaidMsisdns());
      return;
    }
    if (this.plan.msisdns && this.plan.msisdns.length >= MsisdnComponent.maxMsiSdnNumber) {
      this.scrollToBottom();
      return;
    }
    this.loading = true;
    this.msisdnAlreadyReserved = false;
    this.loadMsisdn3();
  }

  private loadMsisdns(quantity: number): Observable<{ items: IMsisdn[] }> {
    return this.oauth2RessourceService.msisdns(quantity).get();
  }

  private initPortability(): void {
    this.loading = true;
    this.plan.portabilityType = TypesPortability.portability;
    this.initForm();
    this.plan.rio = this.msisdnSaveForm.getRawValue();
    if (this.plan?.rio?.phoneNumberTemp) {
      this.loading = false;
      this.setDateToCalendar(this.plan.rio.datePortage);
      this.stepperService.changesCurrentStep.next(null);
      this.scrollToBottom();
    } else {
      observableForkJoin(this.getPortabilityObs())
        .pipe(
          finalize(() => {
            this.loading = false;
          }),
        )
        .subscribe(
          res => {
            this.setDateToCalendar(res[0].datePortageParDefaut);
            this.msisdnSaveForm.get('datePortage').setValue(res[0].datePortageParDefaut);
            if (!this.numTemp && ((res[1] && res[1].id) || (this.plan.msisdns && this.plan.msisdns.length > 0))) {
              this.numTemp = res[1] && res[1].id ? res[1] : this.plan.msisdns[0];
            }
            this.msisdnSaveForm.get('phoneNumberTemp').setValue(this.numTemp.id.replace('33', '0'));
            this.msisdnSaveForm.get('reserved').setValue(this.numTemp.reserved);
            this.stepperService.changesCurrentStep.next(null);
            this.cartService.save();
            this.scrollToBottom();
          },
          () => {
            this.errors.push('Erreur technique');
            this.type = null;
          },
        );
    }
  }

  private getPortabilityObs(): [Observable<BasicObject>, Observable<IPhoneNumber>] {
    let obsPhone: Observable<IPhoneNumber> = observableOf({} as IPhoneNumber);
    if (this.isPrepaid()) {
      const msisdns = this.getPrepaidMsisdns();
      obsPhone = observableOf(msisdns.length > 0 ? msisdns[0] : ({} as IPhoneNumber));
    } else if (!this.plan.msisdns) {
      obsPhone = this.oauth2RessourceService.msisdns(1).get();
    }
    return [this.oauth2RessourceService.datePortageParDefaut().get(), obsPhone];
  }

  private setDateToCalendar(date: string): void {
    const d: string[] = date.split('-');
    this.selectedDate = { year: parseInt(d[0], 10), month: parseInt(d[1], 10), day: parseInt(d[2], 10) };
  }

  private initForm(): void {
    const defaultValue: IRio = this.plan.rio || {
      phoneNumber: '',
      phoneNumberTemp: '',
      datePortage: '',
      codeRio: '',
      reserved: undefined,
    };
    this.msisdnSaveForm = this.formBuilder.group({
      phoneNumber: this.formBuilder.control(defaultValue.phoneNumber, [
        Validators.required,
        Validators.pattern('^(?:0)[6-7](?:[\\.\\-\\s]?\\d\\d){4}$'),
      ]),
      phoneNumberTemp: this.formBuilder.control(defaultValue.phoneNumberTemp, [Validators.required]),
      codeRio: this.formBuilder.control(defaultValue.codeRio, [
        Validators.required,
        Validators.minLength(12),
        Validators.maxLength(12),
        (control: AbstractControl): BooleanObject => {
          switch (true) {
            case control.value.toUpperCase().indexOf('03E') === 0:
              return { enterprise: true };
            case control.value.toUpperCase().indexOf('03P') === 0 && control.value.toUpperCase().indexOf('03P2') !== 0:
              return { client: true };
          }
          return null;
        },
      ]),
      datePortage: this.formBuilder.control(defaultValue.datePortage, [Validators.required]),
      reserved: this.formBuilder.control(defaultValue.reserved, []),
    });
  }

  private setMigrationFluide(migration: IMigration): void {
    if (this.plan.rio.codeRio.match(/^03P2/i)) {
      this.plan.setMigration(MsiSdnComponentEnum.migrationFluide, {
        dateLimiteValidite2: migration ? migration.dateLimiteValidite2 : '',
        soldeCreditPP: migration ? migration.soldeCreditPP : null,
      });
    } else {
      this.plan.setMigration(MsiSdnComponentEnum.portabiliteEntrante);
    }
  }

  private updatePlanPrice(): void {
    const oldRio: IRio = this.plan.rio;
    if (this.type === TypesPortability.portability) {
      this.plan.rio = { phoneNumber: '', phoneNumberTemp: '', datePortage: '', codeRio: '', reserved: undefined };
    } else {
      this.plan.rio = undefined;
    }
    // todo vérifier le role de setPromotions et le remplacer par un appel back pour avoir les prix à jours (gilles)
    // this.cartService.setPromotions();
    this.plan.rio = oldRio;
  }

  private loadMsisdn3(): void {
    if (this.cartService.getCurrentScheme().isAcquisitionMobile()) {
      if (!this.isPrepaid()) {
        this.loading = true;
        this.subscription.add(
          this.loadMsisdns(3)
            .pipe(
              tap((data: { items: IMsisdn[] }) => this.setPlanMsisdns(data.items)),
              finalize(() => {
                this.loading = false;
              }),
            )
            .subscribe(),
        );
      } else {
        this.onChangesSchemes();
      }
    }
  }
}
