import { Component, OnInit } from '@angular/core';
import { ComparatorService } from '../comparator.service';
import { Customer } from '../../checkout/cart/customer/customer';
import { User } from '../../user/user';
import { CustomerService } from '../../checkout/cart/customer/customer.service';
import { UserService } from '../../user/user.service';

@Component({
  selector: 'rcbt-comparator-result',
  templateUrl: './comparator-result.component.html',
  styleUrls: ['./comparator-result.component.scss'],
})
export class ComparatorResultComponent implements OnInit {
  public customer: Customer;
  public user: User;
  public validityComparatorDate: Date;
  public phoneAloneKey: string = ComparatorService.phoneAlone;

  constructor(
    public readonly comparatorService: ComparatorService,
    private customerService: CustomerService,
    private userService: UserService,
  ) {}

  public ngOnInit(): void {
    this.validityComparatorDate = new Date();
    this.user = this.userService.user;
    this.customer = this.customerService.customer;
  }
}
