<ng-container *ngIf="!!virtualScheme">
    <div class="is-flex is-vertical is-aligned-center is-justified-center">
        <div class="table-subtitle">Comptant</div>
        <div>Aujourd'hui :</div>
        <rcbt-price [product]="{price: phone.price, oldPrice: phone.oldPrice, priceType: 0}" [fontLevel]="4"></rcbt-price>
    </div>
    <p></p>
    <div *ngIf="!!edpPhone.edp" class="is-flex is-vertical is-aligned-center is-justified-center">
        <div class="table-subtitle">Avec EDP</div>
        <div>Aujourd'hui :</div>
        <rcbt-price [product]="{price: edpPhone.edp.apportInitial, priceType: 0}" [fontLevel]="4"></rcbt-price>
        <div>et <span class="edpPrice">{{ edpPhone.edp.montantMensualite }}€/mois</span> pdt <span class="edpPrice">{{ edpPhone.edp.nbrEcheances }} mois</span></div>
    </div>
</ng-container>
