<div class="modal-stock-choice is-tri" data-cy="modal-stock-choice">
    <div class="modal-header">
        <div class="head">
            <h2 class="title">Choisir un produit</h2>
        </div>
        <div class="modal-close" (click)="dismiss()">
            <span class="icon is-medium">
              <i class="tri-times" aria-hidden="true"></i>
            </span>
        </div>
    </div>

    <div class="modal-body">
        <div class="promos-ticket-item" *ngFor="let product of popinProducts">
            <h3 class="title is-size-5"><span *ngIf="product.type_id === 'produit_ca'">*&nbsp;</span>{{product.name}}</h3>
            <rcbt-price [product]="{price: product.price, oldPrice: product.oldPrice}"></rcbt-price>
            <button type="button"
                    class="button is-primary"
                    [attr.data-cy]="'btn-product-choice-'+(product.type_id === 'produit_ca' ? 'CA' : 'BT')"
                    (click)="choose(product)"
                    [disabled]="loading"
                    [ngClass]="{'action-feedback': loading}"
            >
                Choisir
            </button>
        </div>
    </div>
</div>
