import { ProductSerialized } from '../interface/configurable';
import { Equipement } from '../equipement';
import { ScanConfigInterface } from '../../../scan/interfaces/types';
import { ScanConfig } from '../../../scan/scan-config.class';
import { webShopEligibility } from '../../../fai-widget/fai-webshop.interface';
import { IGenericDeviceConfiguration } from './IGenericDeviceConfiguration';

/**
 * The Phone class
 */
export class Device extends Equipement {
  public scanConfig: ScanConfigInterface = ScanConfig.imei;
  public deviceElement: webShopEligibility.DeviceLstElement;

  public setConfiguration(data: IGenericDeviceConfiguration): void {
    super.setConfiguration(data);
    this.deviceElement = data.element;
    if (data.element && data.element.imei) {
      this.setScanCode(data.element.imei);
    }
  }

  public getConfiguration(): IGenericDeviceConfiguration {
    return <IGenericDeviceConfiguration>{
      element: this.deviceElement,
      ...super.getConfiguration(),
    };
  }

  public serialize(): ProductSerialized {
    return Object.assign(super.serialize(), this.getConfiguration());
  }
}
