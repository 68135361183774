import { Scheme } from '../scheme.class';
import { Product } from '../../../catalog/products/product';
import { Complex } from '../../../catalog/products/equipement/complex';
import { Sim } from '../../../catalog/products/equipement/sim';
import { SimReplace } from '../../../catalog/products/equipement/sim/sim-replace';
import { EsimReplace } from '../../../catalog/products/equipement/sim/esim-replace';
import { Plan } from '../../../catalog/products/subscription/plan';
import { Phone } from '../../../catalog/products/equipement/complex/phone';
import { Option } from '../../../catalog/products/subscription/option';
import { Shipping } from '../../../catalog/products/shipping';
import { Avc } from '../../../catalog/products/avc';
import { Fai } from '../../../catalog/products/subscription/plan/fai/fai';
import { BoxFaiGenerique } from '../../../catalog/products/subscription/box-fai-generique';
import { FaiService } from '../../../catalog/products/equipement/fai/fai-service';
import { FaiZneService } from '../../../catalog/products/equipement/fai/fai-zne-service';
import { Device } from '../../../catalog/products/equipement/device';
import { FaimUnlimited } from '../../../catalog/products/subscription/plan/fai/faim-unlimited';
import { FaiFmdService } from '../../../catalog/products/equipement/fai/fai-fmd-service';
import { Faim } from '../../../catalog/products/subscription/plan/fai/faim';
import { FaimSensation } from '../../../catalog/products/subscription/plan/premium/faim_sensation';
import { DataSim } from '../../../catalog/products/subscription/plan/dataSim';
import { Box } from '../../../catalog/products/equipement/complex/phone/box';
import { MobileTakeBack } from '../../../catalog/products/mobileTakeBack';

export class Rules {
  public key = 'default';

  constructor(public scheme: Scheme) {}

  /**
   * Return all product in conflict with prodct adding
   * @param scheme
   * @param product
   * @return {Array}
   */
  public getConflictProject(scheme: Scheme, product: Product): Product[] {
    const productsConflict: Product[] = [];

    // Double ComplexBase
    if (product instanceof Complex) {
      this.updateProductsList(scheme, productsConflict, Complex);
    }

    // double Sim
    if (product instanceof Sim) {
      this.updateProductsList(scheme, productsConflict, Sim);
    }

    // Replace Sim
    if ((product instanceof SimReplace || product instanceof EsimReplace) && !scheme.isRenew()) {
      if (
        scheme.isSowo() ||
        scheme.isAcquisitionPremium() ||
        scheme.isFaim() ||
        scheme.isFaimUnlimited() ||
        scheme.isEmpty() ||
        scheme.isPrepaid()
      ) {
        scheme.addedInsurances = [];
        this.updateProductsList(scheme, productsConflict, Plan);
        this.updateProductsList(scheme, productsConflict, Phone);
        this.updateProductsList(scheme, productsConflict, Option, true);
      }
    }

    // double MobileTakeBack
    if (product instanceof MobileTakeBack) {
      this.updateProductsList(scheme, productsConflict, MobileTakeBack);
    }

    // shipping
    if (product instanceof Shipping) {
      this.updateProductsList(scheme, productsConflict, Shipping);
    }

    if (product instanceof Avc) {
      this.updateProductsList(scheme, productsConflict, Avc);
    }

    if (product instanceof Fai) {
      this.updateProductsList(scheme, productsConflict, Fai);
      this.updateProductsList(scheme, productsConflict, BoxFaiGenerique);
      this.updateProductsList(scheme, productsConflict, FaiService, true);
      this.updateProductsList(scheme, productsConflict, FaiZneService);
      this.updateProductsList(scheme, productsConflict, Device, true);
    } else if (product instanceof FaimUnlimited) {
      this.updateProductsList(scheme, productsConflict, FaimUnlimited);
      this.updateProductsList(scheme, productsConflict, BoxFaiGenerique);
      this.updateProductsList(scheme, productsConflict, FaiFmdService);
      this.updateProductsList(scheme, productsConflict, Device, true);
    } else if (product instanceof Faim || product instanceof FaimSensation) {
      this.updateProductsList(scheme, productsConflict, Plan);
      this.updateProductsList(scheme, productsConflict, Sim);
      this.updateProductsList(scheme, productsConflict, Option, true);
    } else if (product instanceof Plan && !(product instanceof DataSim)) {
      this.updateProductsList(scheme, productsConflict, Plan);
      this.updateProductsList(scheme, productsConflict, Sim);
      this.updateProductsList(scheme, productsConflict, Option, true);
      this.updateProductsList(scheme, productsConflict, Box);
    }
    if (product instanceof Option) {
      const optionId: string = scheme.getProductByGencode(product.gencode);
      const option: Product = scheme.getProductById(optionId);
      if (option) {
        productsConflict.push(option);
      }
    }

    return productsConflict;
  }

  public getRelatedProductToDelete(scheme: Scheme, product: Product): Product[] {
    const productsToRemove: Product[] = [];

    if (product instanceof Fai) {
      this.updateProductsList(scheme, productsToRemove, BoxFaiGenerique);
      this.updateProductsList(scheme, productsToRemove, FaiZneService);
      this.updateProductsList(scheme, productsToRemove, Option, true);
      this.updateProductsList(scheme, productsToRemove, FaiService, true);
      this.updateProductsList(scheme, productsToRemove, Device, true);
      this.updateProductsList(scheme, productsToRemove, Shipping);
      this.updateProductsList(scheme, productsToRemove, Avc);

      const accessories = scheme.products.filter(p => p.type_id === 'accessory');
      if (accessories && accessories.length) {
        accessories.forEach(element => {
          productsToRemove.push(element);
        });
      }
    } else if (product instanceof FaimUnlimited) {
      this.updateProductsList(scheme, productsToRemove, BoxFaiGenerique);
      this.updateProductsList(scheme, productsToRemove, Option, true);
      this.updateProductsList(scheme, productsToRemove, FaiFmdService);
      this.updateProductsList(scheme, productsToRemove, Device, true);
      this.updateProductsList(scheme, productsToRemove, Avc);
    } else if (product instanceof Phone) {
      scheme.addedInsurances = [];
    }

    if (product instanceof Plan && !(product instanceof DataSim)) {
      this.updateProductsList(scheme, productsToRemove, Option, true);
      this.updateProductsList(scheme, productsToRemove, Sim, true);
      this.updateProductsList(scheme, productsToRemove, Avc);
    }

    return productsToRemove;
  }

  public isValid(): string[] {
    return [];
  }

  private updateProductsList(
    scheme: Scheme,
    productsList: Product[],
    productType: typeof Product,
    list: boolean = false,
  ): void {
    if (list) {
      const products: Product[] = scheme.getProductsByType(productType);
      if (products) {
        products.forEach(element => {
          productsList.push(element);
        });
      }
      return;
    }

    const product = scheme.getProductByType(productType);
    if (product) {
      productsList.push(product);
    }
  }
}
