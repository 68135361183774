import * as moment from 'moment/moment';
import Moment = moment.Moment;

/**
 * Created by fruysshaert on 04/12/15.
 * Edited by amadani on 26/08/16.
 */

export class DateUniversal {
  public value: Date;

  constructor(date?: string) {
    const FORMAT = {
      usSimple: /^[0-9]{4}-[0-1][0-9]-[0-3][0-9]$/, // NOSONAR
      gbSimple: /^[0-9]{4}-[0-1][0-9]-[0-3][0-9]$/, // NOSONAR
      frSimple: /^[0-3][0-9]\/[0-1][0-9]\/[0-9]{4}$/, // NOSONAR
      now: /now/, // NOSONAR
    };
    switch (true) {
      case FORMAT.now.test(date):
        this.value = new Date();
        break;
      case FORMAT.usSimple.test(date):
        this.value = new Date(date.replace(/-/g, '/')); // NOSONAR
        break;
      case FORMAT.frSimple.test(date):
        const dates = date.split('/');
        if (DateUniversal.isValid(date)) {
          this.value = new Date(`${dates[2]}-${dates[1]}-${dates[0]}`);
        } else {
          this.value = new Date('Invalid Date');
        }
        break;
      default:
        if (!date) {
          this.value = new Date('Invalid Date');
        } else {
          this.value = new Date(date.replace(/\+[0-9]+$/g, 'Z')); // NOSONAR
        }
    }
  }

  /**
   * Format the dob into mysql format
   * @param dateStr
   * @returns {string}
   * @private
   */
  public static formatDob(dateStr: string = ''): string {
    let dateObject: Moment = moment(dateStr);
    if (!dateObject.isValid()) {
      dateObject = moment(dateStr, 'DDMMYYYY');
    }
    return dateObject.isValid() ? dateObject.format('YYYY-MM-DD') : '';
  }

  /**
   * Check if dateIn is between dateA and dateB
   * @param dateA
   * @param dateB
   * @param dateIn
   * @param strict
   * @returns {boolean}
   */
  public static dateBetween(
    dateA: DateUniversal,
    dateB: DateUniversal,
    dateIn: DateUniversal,
    strict: boolean = true,
  ): boolean {
    if (!strict) {
      return dateIn.timestamp() >= dateA.timestamp() && dateB.timestamp() >= dateIn.timestamp();
    }
    return dateIn.timestamp() > dateA.timestamp() && dateB.timestamp() > dateIn.timestamp();
  }

  public static isValid(dateStr: string): boolean {
    return moment(dateStr, 'DD/MM/YYYY').isValid();
  }

  /**
   * @function addDay
   * @description Add x Month to date
   * @param number
   * @returns {date}
   */
  public addDay(number: number): DateUniversal {
    this.value.setDate(this.value.getDate() + number);
    return this;
  }

  /**
   * @function addMonth
   * @description Add x Month to date
   * @param number
   * @returns {date}
   */
  public addMonth(number: number): DateUniversal {
    this.value.setMonth(this.value.getMonth() + number);
    return this;
  }

  /**
   * @function addYear
   * @description Add x Year to date
   * @param number
   * @returns {date}
   */
  public addYear(number: number): DateUniversal {
    this.value.setFullYear(this.value.getFullYear() + number);
    return this;
  }

  /**
   * @function removeYear
   * @description Remove x Year to date
   * @param number
   * @returns {date}
   */
  public removeYear(number: number): DateUniversal {
    this.value.setFullYear(this.value.getFullYear() - number);
    return this;
  }

  /**
   * @function timestamp
   * @description return php timestamp.
   * @return {int}
   */
  public timestamp(): number {
    return this.value.getTime() / 1000;
  }

  /**
   * @function toSimpleFrench
   * @description return date with format dd/mm/yyyy.
   * @return {String}
   */
  public toSimpleFrench(): string {
    const month: string | number =
      this.value.getMonth() + 1 < 10 ? '0' + (this.value.getMonth() + 1) : this.value.getMonth() + 1;
    const day: string | number = this.value.getDate() < 10 ? '0' + this.value.getDate() : this.value.getDate();
    return `${day}/${month}/${this.value.getFullYear()}`;
  }

  /**
   * @function toYYYYMMJJ
   * @description return date with format YYYY-MM-JJ.
   * @return {String}
   */
  public toYYYYMMJJ(): string {
    const month: string | number =
      this.value.getMonth() + 1 < 10 ? '0' + (this.value.getMonth() + 1) : this.value.getMonth() + 1;
    const day: string | number = this.value.getDate() < 10 ? '0' + this.value.getDate() : this.value.getDate();
    return `${this.value.getFullYear()}-${month}-${day}`;
  }
}
