import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Product } from '../../../../../../catalog/products/product';
import { Phone } from '../../../../../../catalog/products/equipement/complex/phone';
import { Scheme } from '../../../../scheme.class';
import { PromotionsService } from '../../../../../../promotions/promotionsService';
import { CartService } from '../../../../cart.service';
import { Observable, of } from 'rxjs';
import { ConfigService } from '../../../../../../config.service';
import { IReason, PromoParams, PromotionTypes } from '../../../../../../promotions/promotions.interfaces';
import { catchError, finalize, mergeMap, tap } from 'rxjs/operators';
import { FundingService } from '../../../../../../fundings/services/funding.service';

@Component({
  selector: 'rcbt-modal-regularization',
  templateUrl: './modal-regularization.component.html',
  styleUrls: ['./modal-regularization.component.scss'],
})
export class ModalRegularizationComponent implements OnInit {
  public product: Product;
  public scheme: Scheme;
  public regularizedPrice: number;
  public regularizationAmount = 0;
  public error: string;
  public isOneOffprice: boolean;
  public loading: boolean;
  public regularizationPromoId: number;

  public listReasons: IReason[];
  public reasonCode = '';
  public comment: string;

  constructor(
    private activeModal: NgbActiveModal,
    private promoService: PromotionsService,
    private cartService: CartService,
    private configService: ConfigService,
    private fundingService: FundingService,
  ) {}

  public ngOnInit(): void {
    this.regularizedPrice = this.product.oldPrice;
    this.error = '';
    this.isOneOffprice = this.product instanceof Phone && this.scheme.hasEdp;
    this.regularizationPromoId = +this.configService.data.promotions.regulId;
    const reasons: IReason[] = <IReason[]>this.configService.data.reasons_regularization;
    this.listReasons = reasons.filter((reason: IReason) => reason.statut);
  }

  public cancel(): void {
    this.closeModal();
  }

  public updatePriceRegularized(val: number): void {
    this.regularizationAmount = this.product.oldPrice - val;
  }

  public getMinimumRegularisation(): number {
    if (this.isOneOffprice) {
      return Phone.minPriceOneOff;
    }
    return 0;
  }

  public onSubmit(event: Event): void {
    if (event && event.preventDefault) {
      event.preventDefault();
      this.error = '';
      if (this.regularizedPrice < this.getMinimumRegularisation() || this.regularizedPrice >= this.product.oldPrice) {
        this.error = this.isOneOffprice
          ? 'Cette régularisation est impossible : Etalement de paiement impossible sur ce montant'
          : 'Cette régularisation est impossible : le montant doit être strictement positif ou ne pas dépasser le prix de départ';
        this.loading = false;
        return;
      }
      if (!this.reasonCode) {
        this.error = 'Il faut sélectionner un motif pour une régularisation';
        this.loading = false;
        return;
      }
      this.loading = true;
      this.fundingService.launchWarningModalWithCallBackObservable(
        this.cartService,
        this.applyRegularization.bind(this),
      );
    }
  }

  private closeModal(): void {
    this.activeModal.close();
  }

  private applyRegularizationToProduct(): Observable<void> {
    const params: PromoParams = {
      amount: parseFloat(this.regularizationAmount.toFixed(2)),
      gencode: this.product.gencode,
      reasonCode: this.reasonCode,
      comment: this.comment,
      type: PromotionTypes.regularization,
    };

    return this.promoService.addPromoById(this.regularizationPromoId, this.cartService.cart.cartId, params);
  }

  private applyRegularization(): Observable<void> {
    return this.applyRegularizationToProduct().pipe(
      mergeMap(() => this.cartService.deleteGrantedLoan()),
      mergeMap(() => this.cartService.refreshCart()),
      catchError(() => {
        this.error = "Une erreur s'est produite, impossible d'appliquer la promotion";
        return of(null);
      }),
      tap(() => this.closeModal()),
      finalize(() => (this.loading = false)),
    );
  }
}
