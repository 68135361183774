<div class="plan-component">

    <div class="plan-header">
        <div [hidden]="addressDisplay">
            <span class="icon is-large has-text-tertiary" aria-label="Icone de sim">
                <i class="tri-sim-card" aria-hidden="true"></i>
            </span>
        </div>

        <div [hidden]="!addressDisplay">
            <span class="icon is-large has-text-tertiary" aria-label="Icone de Bbox">
                <i class="tri-box" aria-hidden="true"></i>
            </span>
        </div>

        <div class="plan-infos">
            <h2 class="title is-size-3">{{product.name}}</h2>
            <div class="is-size-5" [ngSwitch]="product.getData('obligation')">
                Engagement :
                <span *ngSwitchCase="'tokyo'">Sans engagement</span>
                <span *ngSwitchCase="'monthly12'">12 mois</span>
                <span *ngSwitchCase="'monthly24'">24 mois</span>
                <span *ngSwitchDefault> - </span>
            </div>
            <div class="is-size-5" [ngSwitch]="product.data.play" [hidden]="!playDisplay">
                Nombre de play :
                <span *ngSwitchCase="'undefined'">Box 4G</span>
                <span *ngSwitchCase="'_undefined'"></span>
                <span *ngSwitchCase="'3P'">TV - Téléphonie fixe - Internet</span>
                <span *ngSwitchCase="'2P'">Téléphonie fixe - Internet</span>
                <span *ngSwitchCase="'_'"></span>
                <span *ngSwitchDefault> - </span>
            </div>
        </div>

        <button class="button is-warning is-icon-only" (click)="qview()" *ngIf="!isFai && (!isRenew || hasPhone)">
            <span class="icon is-small"><i class="tri-pencil" aria-hidden='true'></i></span>
        </button>
        <button class="button is-warning is-icon-only" data-cy="modify-fai-plan" (click)="modifyFaiPlan()" *ngIf="faiModifyOfferEnabled && isFai && eligExists">
            <span class="icon is-small"><i class="tri-pencil" aria-hidden='true'></i></span>
        </button>
        <button class="button is-warning is-icon-only" data-cy="plan-remove" *ngIf="canRemovePlan()" (click)="remove()" [disabled]="loadingItem" [ngClass]="{'action-feedback': loadingItem}">
            <span class="icon is-small"><i class="tri-trash" aria-hidden='true'></i></span>
        </button>
    </div>

    <div class="others-informations">

        <p class="is-size-6" [hidden]="!addressDisplay">
            <span>Adresse de l'installation :</span><br>
            <span>{{installationAddress}}</span>
        </p>
        <p class="is-size-6" [hidden]="!additionnalFeesDisplay">
            <span>En fonction de la configuration de votre logement, des frais d'installation supplémentaires peuvent vous être réclamés</span>
        </p>

        <rcbt-sim #simComponent *ngIf="simDisplay" [isReplaceSimComponent]="false"></rcbt-sim>

    </div>

    <rcbt-price [product]="product" [fontLevel]="3"></rcbt-price>
    <rcbt-plan-promotions [product]="product"></rcbt-plan-promotions>

</div>

<i rcbtQview><i><!-- Necessary to add the quickview below -->
