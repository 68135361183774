import { Component, Input, OnInit } from '@angular/core';
import { DateUniversal } from '../../../../../base/class/date-universal.class';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CheckoutStepperService } from '../../../../checkout-stepper.service';

@Component({
  selector: 'rcbt-portability-modal',
  templateUrl: './portability-modal.component.html',
  styleUrls: ['./portability-modal.component.scss'],
})
export class PortabilityModalComponent implements OnInit {
  @Input()
  public dateValidite: string;

  constructor(
    private activeModal: NgbActiveModal,
    private checkoutStepperService: CheckoutStepperService,
  ) {}

  public ngOnInit(): void {
    this.dateValidite = new DateUniversal(this.dateValidite).toSimpleFrench();
  }

  public closeModal(): void {
    this.activeModal.close();
  }

  public goToNextStep(): void {
    this.activeModal.close();
    this.checkoutStepperService.goToStep(this.checkoutStepperService.getNextStep());
  }
}
