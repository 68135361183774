<section class="hero">
    <div class="hero-body">
        <div class="container">
            <div class="columns is-multiline">
                <div class="column is-paddingless is-offset-11 is-1" (click)="onClose()">
                    <span class="icon is-large"><i class="tri-times"></i></span>
                </div>
                <div class="column is-offset-2 is-8">
                    <b class="is-size-2 has-text-secondary">
                        Offre de remboursement
                    </b>
                </div>

                <div class="column is-12 is-flex">
                    <span class="has-text-grey is-justified-start">
                        Saisissez un email ou un numéro de téléphone pour
                        recevoir l'ODR
                    </span>
                </div>

                <div class="column is-6">
                    <div class="field">
                        <div class="control">
                            <input
                                [formControl]="contact"
                                class="input"
                                type="text"
                                placeholder="email@email.fr ou N° de portable"
                                aria-label="contact input"
                            />
                        </div>
                    </div>
                </div>
                <div class="column is-2">
                    <button class="button is-info is-marginless" [disabled]="contact.invalid"  (click)="onSubmit()">
                        Envoyer
                    </button>
                </div>
                <div class="column is-offset-1 is-3">
                    <p class="row text">Montant de l'ODR</p>
                    <div class="row">
                        <div class="price is-4">
                            <p class="main">{{ data.amount }}</p>
                            <div class="price-details">
                                <span class="centimes">€00</span>
                            </div>
                        </div>
                    </div>
                </div>

                <hr class="is-divider" />

                <div class="column is-offset-9 is-3">
                    <a [href]="data.file"  target="_blank" class="link">
                        <b>Télécharger l'ODR</b>
                    </a>
                </div>

            </div>
        </div>
    </div>
</section>
<div class="overlay"></div>
