import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RegularizationService {
  public inModeRegularisation = false;
  public onModeRegularisationChanges = new BehaviorSubject(false);

  public toggle(): void {
    this.inModeRegularisation = !this.inModeRegularisation;
    this.onModeRegularisationChanges.next(this.inModeRegularisation);
  }
}
