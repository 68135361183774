import { StringObject } from '../base.interfaces';
import { StorageInterface } from './storage.interface';

export class StorageService {
  public key: StringObject = {};
  protected sessionStorageKeys: string[] = [];
  protected localStorageKeys: string[] = [];
  protected prefix = '';

  /**
   * Use item of StorageInterface insterad of simple key value to make storage expires
   * @param key
   * @param value
   * @param expires timestamp
   */
  public setItem(key: string, value: {}, expires: number = null): void {
    const storageObject: StorageInterface = { data: value, expiresTimestamp: expires };
    this.removeItem(key);
    this._getStorageInstance(key).setItem(this.prefix + key, JSON.stringify(storageObject));
  }

  /**
   * Return item from storage
   * If expiresTimestamp is not null and lower then Now remove item and returns null
   * @param key
   * @returns {BasicObject}
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public getItem<T = any>(key: string): T {
    let item: T;
    const data: string = this._getStorageInstance(key).getItem(this.prefix + key);

    if (data !== null) {
      const storageObject: StorageInterface = JSON.parse(data);
      if (storageObject.expiresTimestamp !== null) {
        if (storageObject.expiresTimestamp < new Date().getTime()) {
          this.removeItem(key);
        } else {
          item = storageObject.data;
        }
      } else {
        item = storageObject.data;
      }
    }
    return item;
  }

  public removeItem(key: string): void {
    this._getStorageInstance(key).removeItem(this.prefix + key);
  }

  /**
   *
   * @param storageInstance
   */
  public clear(storageInstance?: Storage): void {
    if (storageInstance instanceof Storage) {
      this.clearAllPrefix(storageInstance);
    } else {
      this.clearAllPrefix(sessionStorage);
      this.clearAllPrefix(localStorage);
    }
  }
  /**
   *
   * @param storageInstance
   */
  public clearOauth2(): void {
    this.clearAllPrefixOauth2(sessionStorage);
  }

  /**
   *
   * @param key
   * @returns {Storage}
   * @private
   */
  protected _getStorageInstance(key: string): Storage {
    let storage: Storage;
    if (this.sessionStorageKeys.indexOf(key) !== -1) {
      storage = sessionStorage;
    } else if (this.localStorageKeys.indexOf(key) !== -1) {
      storage = localStorage;
    } else {
      storage = localStorage;
    }
    return storage;
  }

  protected clearAllPrefix(storageInstance: Storage): void {
    const keys: string[] = Object.keys(storageInstance);
    keys.forEach(key => {
      if (key.substring(0, this.prefix.length) === this.prefix) {
        storageInstance.removeItem(key);
      }
    });
  }

  protected clearAllPrefixOauth2(storageInstance: Storage): void {
    const keys: string[] = Object.keys(storageInstance);
    const keysSkip: string[] = ['oauth2_state', 'oauth2_nonce'];
    keys.forEach(key => {
      if (key.substring(0, this.prefix.length) === this.prefix && keysSkip.indexOf(key) < 0) {
        storageInstance.removeItem(key);
      }
    });
  }
}
