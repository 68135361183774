import { map, mergeMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ContextService } from '../context/context.service';
import { FaiService } from '../fai-widget/fai.service';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class CheckoutChainService {
  constructor(
    private contextService: ContextService,
    private faiService: FaiService,
  ) {}

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.contextService.init(route, state).pipe(
      mergeMap(() => this.faiService.resolve(state)),
      map(() => true),
    );
  }
}
