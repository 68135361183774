
<div class="product-item is-flex is-fullwidth is-vertical">
    <div class="device-tag is-flex is-fullwidth is-justified-center m-t-5" *ngIf="equipment?.etiquetteAnimCo">
        <rcbt-bonus-reprise [etiquetteAnimCo]="equipment.etiquetteAnimCo"></rcbt-bonus-reprise>
    </div>
    <div class="device-tag is-flex is-fullwidth is-justified-center m-t-5" *ngIf="equipment.odr">
        <span class="tag has-text-weight-semibold has-background-info has-text-white">
            ODR -{{equipment.odr.amount}}€
        </span>
    </div>

    <div class="device-display is-flex is-fullwidth is-justified-center is-aligned-center">
        <div class="image-container">
            <span class="icon icon-left is-stretched is-small" *ngIf="connectivityIconMap.get(equipment?.connectivite)"><i [class]="connectivityIconMap.get(equipment?.connectivite)" aria-hidden="true"></i></span>
            <span class="icon icon-right is-medium" *ngIf="equipment.financement.hasCredit"><i class="tri-mobile-financing" aria-hidden="true"></i></span>
            <img defaultImage="/assets/images/placeholder.png" alt=" " [src]="equipment.image | media">
        </div>
    </div>

    <div class="device-info p-l-5 is-flex is-vertical is-aligned-center">
        <div class="has-text-weight-semibold" [ngClass]="{'accessory' : equipment.type == 'accessory'}">
            <span>{{ equipment.marque}} {{ equipment.nom }}</span>
        </div>
        <div *ngIf="equipment.financement.edp">
            <span>à partir de</span>
        </div>
        <div *ngIf="equipment.financement">
            <span *ngIf="!equipment.financement.edp" class="price is-main-price is-level-5"
                [innerHTML]="equipment.financement.cash.price | customCurrency"></span>
            <span *ngIf="equipment.financement.edp" class="price is-main-price is-level-5"
                [innerHTML]="equipment.financement.edp.edp_apportInitial | customCurrency"></span>
        </div>
        <div class="has-text-weight-semibold" *ngIf="equipment.financement.edp">
            +<span [innerHTML]="equipment.financement.edp.edp_price | customCurrency"></span>
            /mois /{{equipment.financement.edp.edp_duration}} mois
        </div>
    </div>
</div>