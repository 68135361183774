import { Injectable } from '@angular/core';
import { ConfigService } from '../config.service';
import { Observable, of as observableOf } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { BasicObject, StringObject } from '../base/base.interfaces';
import {
  DemandeFinancementAutoriseIn,
  Resultat as DemandeFinancementResult,
  SwaggerSpec as DemandeFinancementSwaggerSpec,
  ControleRisqueVente as DemandeFinancementControleRisqueVente,
} from '@bytel/pt-ihm-api-egide-controle-risque-vente-demander-financements-autorises';
import { HttpClientSwaggerService } from '../oauth2/http-client-swagger.service';
import {
  Resultat as DemanderOffresAutoriseesResult,
  SwaggerSpec as DemanderOffresAutoriseesSwaggerSpec,
  DemandeOffresAutoriseesIn,
  ControleRisqueVente as DemandeOffresAutoriseesControleRisqueVente,
} from '@bytel/pt-ihm-api-egide-controle-risque-vente-demander-offres-autorisees';
import {
  DemanderAutorisationVenteIn,
  SwaggerSpec as DemanderAutorisationVenteSwaggerSpec,
  ControleRisqueVente as DemanderAutorisationVenteControleRisqueVente,
} from '@bytel/pt-ihm-api-egide-controle-risque-vente-demander-autorisation-vente';
import { ObjetControleInEnum } from './interfaces';

@Injectable({
  providedIn: 'root',
})
export class ScoringService {
  public cartId: number;
  public personId: number;
  public retryAfterSD = false;

  public scoringResult: DemanderAutorisationVenteControleRisqueVente;

  constructor(
    private configService: ConfigService,
    private httpService: HttpClientSwaggerService,
  ) {}

  public isScoringActive(): boolean {
    return !this.configService.data.scoring || this.configService.data.scoring.active !== false;
  }

  public loadAuthorizedFunding(body: DemandeFinancementAutoriseIn): Observable<DemandeFinancementResult[]> {
    return this.httpService
      .getClient(DemandeFinancementSwaggerSpec)
      .call('DemanderFinancementsAutorises', {
        body,
        queryParam: {},
        pathParam: {},
      })
      .pipe(
        map((data: DemandeFinancementControleRisqueVente) =>
          data.resultats.filter(result => result.type === 'ResultatGlobal'),
        ),
      );
  }

  public loadAuthorizedOffers(body: DemandeOffresAutoriseesIn): Observable<DemanderOffresAutoriseesResult[]> {
    return this.httpService
      .getClient(DemanderOffresAutoriseesSwaggerSpec)
      .call('DemanderOffresAutorisees', {
        body,
        queryParam: {},
        pathParam: {},
      })
      .pipe(
        map((data: DemandeOffresAutoriseesControleRisqueVente) =>
          data.resultats.filter(result => result.type === 'ResultatGlobal'),
        ),
      );
  }

  public loadSalesAuthorization(
    body: DemanderAutorisationVenteIn,
  ): Observable<DemanderAutorisationVenteControleRisqueVente> {
    return this.httpService
      .getClient(DemanderAutorisationVenteSwaggerSpec)
      .call('DemanderAutorisationVente', {
        body,
        queryParam: {},
        pathParam: {},
      })
      .pipe(map((data: DemanderAutorisationVenteControleRisqueVente) => data));
  }

  public getScoring(
    personId: number,
    cartId?: number,
    partial: boolean = false,
  ): Observable<DemanderAutorisationVenteControleRisqueVente> {
    this.personId = personId;
    this.cartId = cartId;
    return this.config(false, partial);
  }

  public config(
    force: boolean = false,
    partial: boolean = false,
  ): Observable<DemanderAutorisationVenteControleRisqueVente> {
    this.scoringResult = null;
    if (this.scoringResult && !force) {
      return observableOf(this.scoringResult);
    }
    if (!this.personId) {
      return observableOf(this.scoringResult);
    }

    const params: StringObject = { idPU: this.personId.toString() };
    if (this.cartId) {
      params.cartId = this.cartId.toString();
    }
    if (partial) {
      params.forcePartial = '1';
    }
    if (this.retryAfterSD) {
      params.retryAfterSD = '1';
      this.retryAfterSD = false;
    }
    return this.loadSalesAuthorization(this.getDemanderAutorisationVenteIn()).pipe(
      tap(
        (data: DemanderAutorisationVenteControleRisqueVente) => {
          this.scoringResult = data;
        },
        () => {
          this.scoringResult = null;
        },
      ),
      map(() => this.scoringResult),
    );
  }

  public forceUpdate(): Observable<DemanderAutorisationVenteControleRisqueVente> {
    return this.config(true);
  }

  /**
   * Le scoring n'est pas valide :
   * si et seulement si dans le retour on a un nœud "ResultatGlobal" sous "resultats" dont le champ "champApplication" est de type "ParActeDemande" et que ce nœud est de type KO
   *
   * @param {CartService} cartService
   * @returns {boolean}
   */
  public isScoringValid(): boolean {
    if (!this.scoringResult || !this.scoringResult.resultats) {
      return true;
    }

    const isNotValid = !this.scoringResult.resultats.some(
      resultat =>
        resultat.champApplication?.type === 'ParActeDemande' &&
        resultat.type === 'ResultatGlobal' &&
        ['KO_SAUF_ACTION_REALISEE', 'KO'].includes(resultat.resultat),
    );

    return isNotValid;
  }

  public serialize(): BasicObject {
    return {
      scoringResult: this.scoringResult,
      personId: this.personId,
      cartId: this.cartId,
    };
  }

  public unserialize(data: BasicObject): void {
    this.scoringResult = data.scoringResult;
    this.personId = data.personId;
    this.cartId = data.cartId;
    return null;
  }

  private getDemanderAutorisationVenteIn(): DemanderAutorisationVenteIn {
    return {
      enregistrementCommande: false,
      objetControle: {
        id: this.cartId.toString(),
        type: ObjetControleInEnum.PANIER,
      },
    };
  }
}
