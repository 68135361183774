import { StringObject } from '../base/base.interfaces';
import { webShopEligibility } from '../fai-widget/fai-webshop.interface';
import { WallGetResponseDetailProductDto } from '@bytel/pt-ihm-api-portailvente-sapic-catalogue/dist/models/components/schemas/WallGetResponseDetailProductDto';

export class FaiEligibilityModel {
  public adsl: FaiTechnoModel = new FaiTechnoModel(FaiTechno.xdsl);
  public vdsl: FaiTechnoModel = new FaiTechnoModel(FaiTechno.xdsl);
  public xdsl: FaiTechnoModel = new FaiTechnoModel(FaiTechno.xdsl);
  public ftth: FaiFtthTechnoModel = new FaiFtthTechnoModel(false, []);
  public box4g: FaiBoxTechnoModel = new FaiBoxTechnoModel(FaiTechno.box4G, false, [], '');
  public box5g: FaiBoxTechnoModel = new FaiBoxTechnoModel(FaiTechno.box5G, false, [], '');

  public housing?: {
    predecessorName?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    NDRouting?: string;
    accessStatusDSL?: string;
    status?: string;
    accessStatusFTTH?: string;
    redList?: boolean;
    selected?: HousingModel;
  };

  public reservedLogin: string;
  public reservedVoip: string;
  public searchData?: {
    pto?: string;
    nd?: string;
  };

  constructor(
    public id: string,
    public address: AddressFullModel,
    public alertCodes: string[],
    data: Partial<FaiEligibilityModel>,
  ) {
    Object.assign(this, data);
  }
}

export class FaiTechnoModel {
  debitUpMax?: number;
  debitUpMin?: number;
  debitDownMax?: number;
  debitDownMin?: number;
  collectionNetwork?: string;

  // eslint-disable-next-line no-useless-constructor
  constructor(
    public technoType: FaiTechno,
    public status: boolean = false,
    // eslint-disable-next-line no-empty-function
    public offers: string[] = [],
  ) {}
}

/**
 * Extends Fai Techno Model
 * Includes additionnals data for pto web end event
 */
export class FaiFtthTechnoModel extends FaiTechnoModel {
  public ptoStatut?: PtoStatus;
  public pto?: string;
  public commercialOpeningDate?: string;
  public idAccess?: string;
  public isPtoRequired = false;
  public verticaleStructure?: VerticalStructureModel;
  public shouldBypass = false;
  public buildings?: BuildingModel[];
  public forecast?: ForecastModel;
  public groupeCategorieRaccoLogement?: string;
  public housingType?: string;
  public pmLocationType?: string;
  public ponTechnologies?: string[];

  constructor(
    status: boolean,
    offers: string[],
    ptoStatus?: PtoStatus,
    pto?: string,
    commercialOpeningDate?: string,
    idAccess?: string,
    verticaleStructure?: VerticalStructureModel,
  ) {
    super(FaiTechno.ftth, status, offers);

    this.ptoStatut = ptoStatus;
    this.pto = pto;
    this.commercialOpeningDate = commercialOpeningDate;
    this.idAccess = idAccess;
    this.verticaleStructure = verticaleStructure;
  }
}

/**
 * Extends Fai Techno Model
 * Includes additionnals data for 4G/5G Box in web end event
 */
export class FaiBoxTechnoModel extends FaiTechnoModel {
  public homeZone: string;

  constructor(techno: FaiTechno, status: boolean, offers: string[], homeZone: string) {
    super(techno, status, offers);
    this.homeZone = homeZone;
  }
}

export class SimpleAddressModel {
  // @todo: coder le else
  // @ts-ignore
  get proposition(): string {
    return this._proposition ? this._proposition : '';
  }

  // @ts-ignore
  set proposition(value: string) {
    this._proposition = value;
  }

  // eslint-disable-next-line no-useless-constructor
  constructor(
    public zipCode: string,
    public city: CityModel,
    public street: SimpleStreetModel,
    public streetNumber?: SimpleStreetNumberModel,
    // eslint-disable-next-line no-empty-function
    private _proposition?: string,
  ) {}

  static set = (
    zipCode: string,
    city: string,
    inseeCode: string,
    street: string,
    streetNumber?: string,
    complement?: string,
  ): SimpleAddressModel =>
    new SimpleAddressModel(
      zipCode,
      new CityModel(city, inseeCode),
      new SimpleStreetModel(street),
      streetNumber ? new SimpleStreetNumberModel(streetNumber, complement) : undefined,
    );
}

export class AddressFullModel extends SimpleAddressModel {
  constructor(
    public zipCode: string,
    public city: CityModel,
    public street: StreetModel,
    public streetNumber?: StreetNumberModel,
    _proposition?: string,
  ) {
    super(zipCode, city, street, streetNumber, _proposition);
  }
}

export class VerticalStructureModel {
  public building?: string;
  public stair?: string;
  public floor?: string;

  constructor(building?: string, stair?: string, floor?: string) {
    this.building = building;
    this.stair = stair;
    this.floor = floor;
  }

  public isEmpty(): boolean {
    if (!this.building && !this.stair && !this.floor) {
      return true;
    }
    return false;
  }
}

/* eslint-disable no-useless-constructor,no-empty-function */
export class CityModel {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  constructor(
    public label: string,
    public INSEECode: string,
  ) {}
}

/* eslint-disable no-useless-constructor,no-empty-function */
export class SimpleStreetNumberModel {
  public label: string;

  constructor(
    public number: string | undefined,
    public complement: string = '',
  ) {
    this.number = number !== '0' ? number : undefined;
    this.label = `${this.number ?? ''}${complement}`;
  }
}

/* eslint-disable no-useless-constructor,no-empty-function */
export class SimpleStreetModel {
  constructor(public label: string) {}
}

export enum FaiTechno {
  xdsl = 'xdsl',
  ftth = 'ftth',
  box4G = 'box_4g',
  box5G = 'box_5g',
}

export class BuildingModel {
  building: string;
  type: string;
  stairs: StairModel[];

  constructor(building: string, type: string, stairs: StairModel[]) {
    this.building = building;
    this.stairs = stairs;
    this.type = type;
  }

  public isPavillon(): boolean {
    return this.type.toLocaleLowerCase() === 'pavillon';
  }
}

export class StairModel {
  stair: string;
  stages: FloorModel[];

  constructor(stair: string, stages: FloorModel[]) {
    this.stair = stair;
    this.stages = stages;
  }
}

export class FloorModel {
  floor: string;
  nbActiveLine?: number;
  nbBuildLine?: number;
  nbPremises?: number;
  ptos: PtoModelFtth[];
  ptoRequired: boolean;

  constructor(floor: string, ptoRequired: boolean, ptos: PtoModelFtth[], data?: Partial<FloorModel>) {
    this.floor = floor;
    this.ptoRequired = ptoRequired;
    this.ptos = ptos;
    Object.assign(this, data);
  }
}

export class PtoModelFtth {
  pto: string;
  lineStatus: string;

  constructor(pto: string, lineStatus: string) {
    this.pto = pto;
    this.lineStatus = lineStatus;
  }
}

export class ForecastModel {
  public name?: string;
  public date?: string;

  constructor(name?: string, date?: string) {
    this.name = name;
    this.date = date;
  }
}

export class StreetModel extends SimpleStreetModel {
  constructor(
    public label: string,
    public rivoliCode: string,
  ) {
    super(label);
  }
}

export class StreetNumberModel extends SimpleStreetNumberModel {
  public label: string;

  constructor(
    public number: string,
    public complement: string,
    public hexaKey?: string,
    public id?: string,
  ) {
    super(number, complement);
    this.label = `${number}${complement}`;
  }
}

export interface QuickAddress {
  zipCode: string;
  inseeCode: string;
  rivoliCode: string;
  streetNumber?: string;
  extension?: string;
}

export enum PtoStatus {
  inconnu = 'INCONNU',
  inexistant = 'INEXISTANT',
}

export interface WidgetContextInterface {
  api: {
    baseUrl: string;
    token?: string;
    context?: string;
    xSource?: string;
    xMessageId?: string;
    xVersion?: string;
    xProcess?: string;
  };
  hiddenController: string;
  context: string;
  ihm: 'ECOMMERCE';
  typeClient: 'GP' | 'PRO' | 'GP_PRO';
  typeActeur: 'SUPPORT_MOA_GD' | 'CDC' | 'CDV' | 'CLIENT' | 'SUPPORT' | 'TLV' | 'CDV_GD';
  typeVente: 'VNA' | 'VA';
  disableStep: string[];
  hostApp: 'RCBT';
  enablePersistentData: boolean;
}

export class HousingModel {
  public id = '';
  public number = '';
  public building = '';
  public stairs = '';
  public stage = '';
  public lineStatus!: ETATLIGNE;
  public redlist = false;
  public logo = '';
  public gate = '';
  public predecessorName = '';
  public predecessorNameMask?: string;
  public residence = '';
  public checkSum: string;
  public isCdl = false;

  constructor(data: Partial<HousingModel>) {
    Object.assign(this, data);
    this.checkSum = Object.values(data).join('-');
  }
}

export enum ETATLIGNE {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  ACTIVE = 'ACTIVE',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  INACTIVE = 'INACTIVE',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  PRECABLE = 'PRECABLE',
}

export enum EDuplicateAction {
  nextStep = 'nextStep',
  cancel = 'cancel',
  reset = 'reset',
}

export enum WETOSTEPS {
  ptoVA = 'PTO_VA',
  housingVA = 'HOUSING_VA',
}

export interface IGetCommandsResponse {
  items: ICommandItem[];
  // eslint-disable-next-line @typescript-eslint/naming-convention
  _links?: ILinks;
}

export interface ILinks {
  [index: string]: {
    href?: string;
  };
}

export interface ICommandItem {
  offresAchetees: IOffreAchetee[];
  dateEnregistrement: string;
  id: string;
  statut: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  _links?: ILinks;
}

export interface IOffreAchetee {
  id: string;
  type: string;
  elementsCommandes?: IElementCommande[];
}

export interface IElementCommande {
  adresseInstallation?: IAdresseInstallation;
}

export interface IAdresseInstallation {
  numero: string;
  rue: string;
  codePostal: string;
  ville: string;
  codeInsee: string;
  codeRivoli: string;
}

export interface IIdAddress {
  id: string;
  address: IAdresseInstallation;
}

export const EQUIPMENT_LABELS: StringObject = {
  all: "Tous les types d'équipements",
  internet: '1 modem internet',
  /* eslint-disable */
    internet_tv : '1 modem internet et 1 décodeur TV',
    internet_box_tv : '1 modem internet et 1 décodeur Bbox 4K',
    internet_eole : '1 modem internet Bbox WIFI 6',
    internet_box_tv_eole : '1 modem internet Bbox WIFI 6 et 1 décodeur Bbox 4K',
    internet_box_tv_hdr: '1 modem internet Bbox Fibre Wifi 6 et 1 décodeur Bbox 4K HDR',
    internet_extended_box_tv_hdr: '1 modem internet Bbox Wifi 6E et 1 décodeur Bbox 4K HDR',
    internet_extended: '1 modem internet Bbox Wifi 6E',
    internet_box_tv_W6: '1 modem Bbox Wifi 6+ et 1 décodeur Bbox 4K',
    internet_box_tv_4KHDR: '1 modem internet et 1 décodeur TV 4K HDR',
    internet_box_tv_W7: '1 modem internet Bbox Wifi 7 et 1 décodeur Bbox 4K HDR',
    internet_box_W7: '1 modem internet Bbox Wifi 7'

    /* eslint-enable */
};

export enum EligType {
  address = 'ADRESSE',
  number = 'NUMBER',
}

export class FaiEligtech {
  idPanier: string;
  resultat: webShopEligibility.Resultat;
}

export enum TechnoList {
  ftth = 'ftth',
  vdsl = 'vdsl',
  adsl = 'adsl',
  tokyo = 'box 4G',
  tokyo5G = 'box 5G',
}

export enum OrderTechno {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  FTTH_3P = 'FTTH_3P',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  FTTH_2P = 'FTTH_2P',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  VDSL_3P = 'VDSL_3P',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  VDSL_2P = 'VDSL_2P',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  ADSL_3P = 'ADSL_3P',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  ADSL_2P = 'ADSL_2P',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  BOX_5G_undefined = 'BOX_5G_undefined',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  BOX_4G_undefined = 'BOX_4G_undefined',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  undefined_undefined = 'undefined_undefined',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  THD_3P = 'THD_3P',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  THD_2P = 'THD_2P',
}

export interface Techno {
  name: string;
  label: string;
  id: string;
  isEligible: boolean;
  isUnderConstruction: boolean;
  priseInteret?: boolean;
  lame?: string;
  debitUpMax?: number;
  debitDownMax?: number;
  hasXGSPONTechno?: boolean;
}

export interface ProductTabObject {
  [key: string]: WallGetResponseDetailProductDto[];
}

export interface ProductObject {
  [key: string]: WallGetResponseDetailProductDto;
}
