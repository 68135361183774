import { Inject, Injectable } from '@angular/core';
import { combineLatest, Observable, ReplaySubject } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { first, map } from 'rxjs/operators';

export interface ScriptAttr {
  [attributes: string]: string;
  src: string;
}

@Injectable({
  providedIn: 'root',
})
export class DomService {
  private resourceStatus: Record<string, ReplaySubject<boolean> | undefined> = {};

  constructor(@Inject(DOCUMENT) private document: Document) {}

  public isScriptLoaded(src: string): boolean {
    return !!this.resourceStatus[src];
  }

  public loadScript(scriptAttributes: ScriptAttr[]): Observable<boolean> {
    return combineLatest(scriptAttributes.map(elt => this.createScript(elt))).pipe(
      map(res => res.every(hasLoaded => hasLoaded)),
      first(),
    );
  }

  private createScript(scriptAttr: ScriptAttr): Observable<boolean> {
    if (this.resourceStatus[scriptAttr.src]) {
      return this.resourceStatus[scriptAttr.src].asObservable();
    }
    const scriptTag: HTMLScriptElement = this.document.createElement('script');
    scriptTag.type = 'text/javascript';
    scriptTag.defer = true;
    Object.entries(scriptAttr).forEach(([key, value]) => scriptTag.setAttribute(key, value));
    return this.loadSource(scriptTag, scriptAttr.src);
  }

  private loadSource(scriptTag: HTMLScriptElement, resourceId): Observable<boolean> {
    // todo voir si on doit utilier un resultSubjectonload et un resultSubjectonerror
    const resultSubject = new ReplaySubject<boolean>(1);
    scriptTag.onload = this.onScriptCreatedHandler(true, resourceId, resultSubject);
    scriptTag.onerror = this.onScriptCreatedHandler(false, resourceId, resultSubject);
    this.document.body.appendChild(scriptTag);
    return resultSubject;
  }

  private onScriptCreatedHandler(res, resourceId: string, resultSubject: ReplaySubject<boolean>): () => void {
    return (): void => {
      this.resourceStatus[resourceId] = resultSubject;
      this.resourceStatus[resourceId].next(res);
    };
  }
}
