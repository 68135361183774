import { Component, OnDestroy, OnInit } from '@angular/core';
import { StepperComponent } from '../stepper/stepper.component';
import { CheckoutStepperService } from '../checkout/checkout-stepper.service';
import { AlertService } from '../common/alert/alert.service';
import { Step } from '../stepper/step.abstract';
import { CustomerService } from './cart/customer/customer.service';
import { CustomerStepEnum } from './cart/customer/customer.interface';

@Component({
  selector: 'rcbt-checkout-stepper',
  templateUrl: 'checkout-stepper.component.html',
  styleUrls: ['./checkout-stepper.component.scss'],
})
export class CheckoutStepperComponent extends StepperComponent implements OnInit, OnDestroy {
  public loadingSubscription;
  public loading = false;

  constructor(
    protected stepperService: CheckoutStepperService,
    protected alertService: AlertService,
    protected customerService: CustomerService,
  ) {
    super(stepperService);
    this.loadingSubscription = this.alertService.isLoading.subscribe(
      (isLoading: boolean) => (this.loading = isLoading),
    );
  }

  public selectStep(step: Step): void {
    if (this.canSelectStep(step) && !this.loading) {
      this.stepperService.goToStep(step);
      let found = false;
      this.steps.forEach(el => {
        if (el.label === step.label) {
          found = true;
        }
        if (found) {
          el.isSubmitted = false;
        }
      });
      if (step.code === 'customer' && !this.loading) {
        this.customerService.changeCustomerStep({ step: CustomerStepEnum.identity });
      }
    }
  }

  public ngOnDestroy(): void {
    this.loadingSubscription.unsubscribe();
  }
}
