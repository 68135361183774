import { Component, OnDestroy } from '@angular/core';
import { DematService } from '../../justificatory-v2/demat.service';
import { LocationService } from '../../../../base/services/location.service';
import { CustomerService } from '../customer.service';
import { CartService } from '../../cart.service';
import { Subscription } from 'rxjs';
import { GlobalLoaderService } from '../../../../base/services/global-loader.service';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'auto-complete-scan',
  templateUrl: './auto-complete-scan.component.html',
})
export class AutoCompleteScanComponent implements OnDestroy {
  private subscriptions = new Subscription();
  constructor(
    protected dematService: DematService,
    private locationService: LocationService,
    protected customerService: CustomerService,
    protected cartService: CartService,
    protected globalLoaderService: GlobalLoaderService,
  ) {
    const params: { [index: string]: string } = this.locationService.params;
    if (Object.keys(params).length && params.web_scan_finished === 'OK') {
      this.subscriptions.add(
        this.globalLoaderService
          .showLoaderUntilFinalize(
            this.dematService.getScannedData().pipe(
              tap(() => {
                this.dematService.mapScannedDataEvent.emit(null);
              }),
            ),
            'globalLoading',
          )
          .subscribe(),
      );
    }
  }

  public processScan(): void {
    this.dematService.launchAutoCompleteScan();
  }

  public resetDemat(): void {
    this.dematService.resetDemat();
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
