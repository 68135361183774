import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { of as observableOf, forkJoin as observableForkJoin, Observable } from 'rxjs';
import { LocationService } from '../base/services/location.service';
import { CookieService } from 'ngx-cookie';
import { map } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { Oauth2RessourceService } from '../oauth2/oauth2-resources.service';
import { StringObject } from '../base/base.interfaces';

@Injectable({
  providedIn: 'root',
})
export class PricingUserService {
  constructor(
    private locationService: LocationService,
    protected cookie: CookieService,
    @Inject(DOCUMENT) private _document: Document,
    protected oauth2Ressource: Oauth2RessourceService,
    protected router: Router,
  ) {}

  public resolve(): Observable<PricingUserService> {
    const scriptsPath = [
      '/assets/tools/dynamicDebug.js',
      '/assets/tools/mcrypt.js',
      '/assets/tools/rijndael.js',
      '/assets/tools/Serpent.js',
      '/assets/tools/utf8-encode.js',
    ];

    let obs = observableOf(this);
    if (!this.cookie.get('SSO_ACTIVE')) {
      obs = observableForkJoin(scriptsPath.map(scriptPath => this.loadScript(scriptPath))).pipe(
        map(() => {
          this.loadFirstCall();
          return this;
        }),
      );
    }
    obs = obs.pipe(
      map(() => {
        const data = this.oauth2Ressource.getUserInfo();
        if (data && data['enseigne'] === '499') {
          this.router.navigateByUrl('/403');
          throw new Error('not allowed');
        }
      }),
      map(() => this),
    );
    return obs;
  }

  private loadScript(pathtoscript): Observable<boolean> {
    return new Observable(observer => {
      const script = this._document.createElement('script');
      script.type = 'text/javascript';
      script.src = pathtoscript;

      script.onload = function (): void {
        observer.next(true);
        observer.complete();
      };
      this._document.body.appendChild(script);
    });
  }

  private loadFirstCall(): void {
    const params: StringObject = this.locationService.params;
    const script = this._document.createElement('script');
    script.type = 'text/javascript';
    script.text = `generateCookie('${params['code_ens']}','${params['code_pdv']}')`;
    this._document.body.appendChild(script);
  }
}
