import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BaseModule } from '../../../base/base.module';
import { LoaderModule } from '../../../loader/loader.module';
import { OdrModule } from '../../../odr/odr.module';
import { SummaryComponent } from './summary.component';
import { SummaryRoutingModule } from './summary-routing.module';
import { SchemeComponent } from './scheme/scheme.component';
import { ScoringModule } from '../../../scoring/scoring.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ModalAgilityCaComponent } from './modals/modal-agility-ca/modal-agility-ca.component';
import { ModalEmailComponent } from './modals/modal-email/modal-email.component';
import { ModalScoringDetailedComponent } from './modals/modal-scoring/modal-scoring-detailed.component';
import { ModalUpdateWarningComponent } from '../../../fundings/services/modal-update-warning/modal-update-warning.component';
import { PartnerModule } from '../../../partner/partner.module';
import { BtnRegularizationComponent } from './scheme/btn-regularisation/btn-regularization.component';
import { ModalRegularizationComponent } from './scheme/btn-regularisation/modal-regularisation/modal-regularization.component';
import { ModalDiscountCaComponent } from './scheme/btn-regularisation/modal-discount-ca/modal-discount-ca.component';
import { SchemeRenewPromotionsComponent } from './scheme/scheme-renew-promotions/scheme-renew-promotions.component';
import { SchemeCAComponent } from './scheme-ca/scheme-ca.component';
import { SummaryFundingComponent } from './funding/summary-funding/summary-funding.component';
import { ValidatedFundingComponent } from './funding/validated-funding/validated-funding.component';
import { ScoringPartialComponent } from './modals/modal-scoring/scoring-partial/scoring-partial.component';

@NgModule({
  imports: [
    CommonModule,
    BaseModule,
    NgbModule,
    SummaryRoutingModule,
    ScoringModule,
    PartnerModule,
    FormsModule,
    ReactiveFormsModule,
    OdrModule,
    LoaderModule,
  ],
  declarations: [
    SchemeComponent,
    SummaryComponent,
    ModalScoringDetailedComponent,
    ScoringPartialComponent,
    BtnRegularizationComponent,
    ModalRegularizationComponent,
    SchemeRenewPromotionsComponent,
    ModalDiscountCaComponent,
    SchemeCAComponent,
    ModalAgilityCaComponent,
    SummaryFundingComponent,
    ValidatedFundingComponent,
    ModalEmailComponent,
    ModalUpdateWarningComponent,
  ],
  exports: [SummaryComponent, SchemeComponent],
})
export class SummaryModule {}
