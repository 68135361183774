import { Component, Input, OnInit } from '@angular/core';
import { CartService } from '../../cart.service';
import { Product } from '../../../../catalog/products/product';
import { Scheme } from '../../scheme.class';
import { StockType } from '../../../../scan/interfaces/types';
import { BoxFaiGenerique } from '../../../../catalog/products/subscription/box-fai-generique';
import { CheckoutStepperService } from '../../../checkout-stepper.service';
import { EQUIPMENT_LABELS } from '../../../../fai-widget/fai.interfaces';
import { Fai } from '../../../../catalog/products/subscription/plan/fai/fai';
import { FaimUnlimited } from '../../../../catalog/products/subscription/plan/fai/faim-unlimited';
import { StringObject } from '../../../../base/base.interfaces';

export interface ScanSimForm {
  sim: string;
}

@Component({
  selector: 'rcbt-fai-box',
  templateUrl: './fai-box.component.html',
  styleUrls: ['./fai-box.component.scss'],
})
export class FaiBoxComponent implements OnInit {
  /**
   * [Input description]
   * @return {[type]} [description]
   */
  @Input()
  public product: Product;
  public scheme: Scheme;
  /**
   * [error description]
   * @type {string}
   */
  public error: string;
  /**
   * [message description]
   * @type {string}
   */
  public message: string;
  /**
   * [now description]
   * @return {[type]} [description]
   */
  public uniqueId = '';

  public scanCode: string;
  /**
   * [stockData description]
   * @type {StockType}
   */
  public stockData: StockType;

  public genericBoxFai: Product;

  public loadingItem: boolean;
  public equipmentLabels: StringObject = EQUIPMENT_LABELS;
  public faiPlan: Fai | FaimUnlimited;
  public imageSrc: string;
  public nbDevice: string;

  /**
   * [constructor description]
   * @param  {CartService} cart [description]
   * @return {[type]}           [description]
   */
  constructor(
    protected cartService: CartService,
    protected stepperService: CheckoutStepperService,
  ) {}

  public ngOnInit(): void {
    this.scheme = this.cartService.getCurrentScheme();
    this.faiPlan = this.scheme.getProductByType(Fai) ?? this.scheme.getProductByType(FaimUnlimited);
    this.imageSrc = this.faiPlan.getData('small_image');
    this.nbDevice = this.getNbDevice();
    this.cartService.afterRefresh.subscribe(() => {
      this.handleAfterRefreshCart();
    });
  }

  /**
   * [remove description]
   * @return {[type]} [description]
   */
  public remove(): void {
    this.loadingItem = true;
    this.cartService.remove(this.product.uniqueId, this.scheme.uniqueId).subscribe(
      () => {
        this.loadingItem = false;
        if (!this.stepperService.getCurrentStep().isAllowed) {
          this.stepperService.goToStep(this.stepperService.getNextStep());
        }
      },
      () => (this.loadingItem = false),
    );
  }

  private getNbDevice(): string {
    if (this.product && this.product instanceof BoxFaiGenerique) {
      const config = this.product.getConfiguration();
      switch (config.play) {
        case 'undefined':
        case '3P':
          return '2';
        case '2P':
          return '1';
        default:
          return null;
      }
    }
    return null;
  }

  private handleAfterRefreshCart(): void {
    this.scheme = this.cartService.getCurrentScheme();
  }
}
