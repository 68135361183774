import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseModule } from '../base/base.module';
import { PricingAccessoryComponent } from './pricing-accessory/pricing-accessory.component';
import { PricingRoutingModule } from './pricing.routing';
import { PricingProductsComponent } from './pricing-products/pricing-products.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [CommonModule, BaseModule, PricingRoutingModule, FormsModule],
  declarations: [PricingAccessoryComponent, PricingProductsComponent],
  exports: [PricingAccessoryComponent, PricingProductsComponent],
})
export class PricingModule {}
