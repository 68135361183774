<div class="phone-component" [ngClass]="{'isReco': isReco}">
    <div *ngIf="isReco" class="isRecoTitle">Sélectionné pour votre client</div>
    <div class="phone-header">
        <div class="visual-detail">
            <img class="visual" [src]="product.getData('small_image') | media" alt="" />
        </div>

        <div class="phone-infos">
            <h2 class="title is-size-3">{{product.getData('manufacturer')}} {{product.name}}</h2>
            <div class="is-size-5" *ngIf="product.getData('capacity')">Capacité : {{product.getData('capacity')}}</div>
            <div class="is-size-5" *ngIf="product.getData('color')">Couleur : {{product.getData('color')}}</div>
        </div>

        <button class="button is-warning is-icon-only" (click)="remove()">
            <span class="icon is-small"><i class="tri-trash" aria-hidden="true"></i></span>
        </button>
    </div>

    <div class="imei-confirmed">
        <span class="imei-info title is-size-2 has-text-centered is-vcentered">IMEI</span>
        <span class="icon is-medium is-success"><i class="tri-check" aria-hidden='true'></i></span>
        <span class="is-size-5" >{{ product.getScanCode() }}</span>
    </div>

    <div class="refund-offer" *ngIf="product.getData('odr')">
        <span class="is-size-5 has-text-weight-bold has-text-tertiary" data-cy="tag-odr">ODR: {{product.getData('odr').amount}}€ remboursés</span>
    </div>

    <rcbt-price [product]="product"></rcbt-price>

    <rcbt-mobile-take-back [scheme]="scheme"></rcbt-mobile-take-back>
</div>
