<div class="is-tri crv-page" *ngIf="!loadingCrvListError" [ngClass]="{'margin': showHeader}">
  <div id="gencode_{{crv.code}}" class="card" *ngFor="let crv of crvList" [ngClass]="{'added': crv.nb > 0}">
    <div class="card-content">
      <div class="crv-name">
        <label>{{crv.name}}</label>
      </div>
      <div class="crv-price">
        <rcbt-price [price]="crv.price" [fontLevel]="5"></rcbt-price>
      </div>
      <div class="crv-button">
        <span class="icon is-small is-info" aria-label="Ajout au panier" [attr.data-cy]="'crv_add_'+crv.code"
          (click)="addCrv(crv.code)">
          <i class="tri-plus-circle" aria-hidden="true"></i>
        </span>
        <label [attr.data-cy]="'crv_nb_'+crv.code">{{crv.nb}}</label>
        <span class="icon is-small is-info" [ngClass]="{'disabled': crv.nb===0, 'is-info': crv.nb > 0}" aria-label="Suppression du panier" [attr.data-cy]="'crv_remove_'+crv.code"
          (click)="removeCrv(crv.code)">
          <i class="tri-minus-circle" aria-hidden="true"></i>
        </span>
      </div>
    </div>
  </div>
</div>
<div class="is-tri row reload-button is-justified-center p-20" *ngIf="loadingCrvListError">
  <button class="button is-info" (click)="reload()" [disabled]="globalLoaderService.isAppOnLoadingStatus$ | async">
    <span class="icon is-small"><i class="tri-exclamation-redo" aria-hidden="true"></i></span> Recharger
  </button>
</div>
