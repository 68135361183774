import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

interface Suggestion {
  code: string;
  name: string;
}

@Component({
  selector: 'rcbt-auto-complete',
  templateUrl: './auto-complete.component.html',
  styleUrls: ['./auto-complete.component.scss'],
})
export class AutoCompleteComponent implements OnInit {
  @Input() inputControl = new FormControl();
  @Input() suggestions: Suggestion[] = [];
  @Input() label = '';
  @Input() idName = '';
  filteredOptions: Suggestion[] = [];
  showOptions = false;
  activeOptionIndex = 0;

  public ngOnInit(): void {
    this.filteredOptions = this.suggestions;
    this.inputControl.setValue(this.formatDisplayValue(this.inputControl.value));

    this.inputControl.valueChanges.subscribe(value => {
      this.filteredOptions = this.filterDepartments(value);
    });
  }

  private filterDepartments(value: string): Suggestion[] {
    const filterValue = value.toLowerCase();
    return this.suggestions.filter(
      department =>
        department.name.toLowerCase().includes(filterValue) || department.code.toLowerCase().includes(filterValue),
    );
  }

  public selectOption(option: Suggestion): void {
    this.inputControl.setValue(`${option.code} - ${option.name}`);
    this.showOptions = false;
  }

  public setActiveOptionIndex(index: number): void {
    this.activeOptionIndex = index;
  }

  public onInputChange(value: string): void {
    this.activeOptionIndex = 0;
    this.filteredOptions = this.filterDepartments(value);
  }

  public onInputBlur(): void {
    setTimeout(() => {
      this.showOptions = false;
    }, 200);
  }

  public onArrowDown(): void {
    if (this.activeOptionIndex < this.filteredOptions.length - 1) {
      this.activeOptionIndex++;
    }
  }

  public onArrowUp(): void {
    if (this.activeOptionIndex > 0) {
      this.activeOptionIndex--;
    }
  }

  public onEnter(): void {
    if (this.filteredOptions[this.activeOptionIndex]) {
      this.inputControl.setValue(
        `${this.filteredOptions[this.activeOptionIndex].code} - ${this.filteredOptions[this.activeOptionIndex].name}`,
      );
      this.showOptions = false;
    }
  }

  public getActiveDescendantId(index: number): string {
    return `option-${index}`;
  }

  private formatDisplayValue(value: string): string {
    if (typeof value === 'string' && value.trim() !== '') {
      const department = this.suggestions.find(dep => dep.code.toLowerCase() === value.trim().toLowerCase());
      return department ? `${department.code} - ${department.name}` : value.trim();
    } else {
      return value;
    }
  }
}
