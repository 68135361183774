export interface ICreatePersonInterface {
  noPersonne?: number;
  idIdentite?: number;
}

export interface IProspectGPRefCli<T> extends IClientREFCLI<T> {
  type: RefCliType;
  numeroTel: string;
  email: string;
}

export interface IProspectProRefCli<T> {
  type: string;
  numeroTel?: string;
  email?: string;
  siren: string;
  raisonSociale: string;
  dateCreation: string;
  effectif: string;
  situationEntreprise: string;
  codeApeNaf: string;
  formeJuridique: string;
  representantLegal: IClientREFCLI<T>;
}

export interface IClientREFCLI<T> {
  civilite: T;
  nom: string;
  prenom: string;
  dateNaissance: string;
  departementNaissance: string;
}

export enum RefCliCivility {
  m = 'M',
  mme = 'MME',
  mlle = 'MLLE',
}

export enum RefCliType {
  individu = 'INDIVIDU',
  entreprise = 'ENTREPRISE',
}
