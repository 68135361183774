import { Injectable, Injector } from '@angular/core';
import { CartService } from '../../checkout/cart/cart.service';
import { AngularPrismeLogger } from '@bytel/prisme-logger/lib/angular';
import { ConfigurationService } from '../../configuration/configurationService';
import { FaiEligService } from '../../fai-widget/fai-elig.service';
import { PrismeLogType } from './prisme-log-type.enum';
import { CustomerService } from '../../checkout/cart/customer/customer.service';
import { UserService } from '../../user/user.service';
import { BasicObject } from '../../base/base.interfaces';

@Injectable({
  providedIn: 'root',
})
export class PrismeLoggerService {
  public ihm: string;

  constructor(
    protected cartService: CartService,
    protected angularPrismeLogger: AngularPrismeLogger,
    protected config: ConfigurationService,
    private injector: Injector,
    private faiEligService: FaiEligService,
    private customerService: CustomerService,
    private userService: UserService,
  ) {}

  public sendDataToPrisme(message: PrismeLogType, data: BasicObject): void {
    // TODO manage circular dependencies with faiEligService
    const isEligStandalone = this.faiEligService.isStandalone || this.injector.get(FaiEligService).isStandalone;
    this.ihm = isEligStandalone ? 'standalone' : 'rcbt';
    if ((!!this.config.prismeConfig && this.config.prismeConfig.monitoring?.active) || isEligStandalone) {
      const customerContextService = this.customerService.customer;
      const userContextService = this.userService.user;
      if (userContextService) {
        this.angularPrismeLogger.logToPrisme(message, {
          cartId: this.cartService.cart?.cartId,
          actorLogin: userContextService.login,
          actorSignCode: userContextService.codeEns,
          actorPointOfSaleCode: userContextService.codePdv,
          device: userContextService.deviceId,
          client: {
            typeVisitor: customerContextService?.type,
            categoryClient: customerContextService?.category,
          },
          ihm: this.ihm,
          ...data,
        });
      }
    }
  }
}

export enum EnumFaiSteps {
  stepeligibility = 'ETAPE_FORMULAIRE_ELIGIBILITE',
  stepeligibilityreturn = 'ETAPE_FORMULAIRE_ELIGIBILITE_RETOUR',
  stephousing = 'ETAPE_LOGEMENTS',
  stephousingreturn = 'ETAPE_LOGEMENTS_RETOUR',
  steppto = 'ETAPE_PTO',
  stepptoreturn = 'ETAPE_PTO_RETOUR',
  steptechs = 'ETAPE_TECHNOS',
  steptechsreturn = 'ETAPE_TECHNOS_RETOUR',
  stepoffers = 'ETAPE_OFFRE_AFFICHAGE',
  stepoffersreturn = 'ETAPE_OFFRE_AFFICHAGE_RETOUR',
  stepcart = 'ETAPE_OFFRE_SELECTION_OFFRE',
}

export enum EnumActions {
  actionBytelFTTHMap = 'ACTION_CARTE_FTTH_BYTEL',
  actionDuplicateCommandCancel = 'ACTION_DOUBLON_COMMANDE_ANNULER',
  actionDuplicateCommandChange = 'ACTION_DOUBLON_COMMANDE_MODIFIER',
}
