import { IProductConfiguration } from '../../IProductConfiguration';
import { ProductSerialized } from '../../interface/configurable';
import { JsonProduct } from '../../interface/context';
import { Accessory } from '../accessory';

export class AccessOnlineOrder extends Accessory {
  public ecoTax: string;
  public longName: string;
  public status: string;
  protected aoData;

  public setConfiguration(data: IProductConfiguration | JsonProduct): void {
    this.aoData = data;
    const response = this.aoData.innerResponse;
    if (response && response.commandeAccessOnLine) {
      this.name = this.aoData.name;
      this.longName = this.aoData.longName;
      this.price = this.aoData.final_price;
      this.oldPrice = this.aoData.final_price;
      this.ecoTax = response.commandeAccessOnLine.ecotaxe;
      this.data.ecotax = this.ecoTax;
      this.status = this.aoData.status;
    }
  }

  public getConfiguration(): IProductConfiguration {
    return this.aoData;
  }

  public serialize(): ProductSerialized {
    return Object.assign(super.serialize(), this.getConfiguration());
  }
}
