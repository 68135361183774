export class FormPatterns {
  /**
   * String type check
   */
  public static emailBasic =
    /^[A-Za-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-zA-Z0-9]([a-zA-Z0-9-]*[a-zA-Z0-9])+(\.[a-zA-Z0-9]([a-zA-Z0-9-]*[a-zA-Z0-9])?)+$/;
  // public static STRINGS_ONLY: RegExp = /\D/;
  /**
   * Number type check
   */
  public static num = /(\d)/;
  public static number = /^\d+$/;
  public static imei = /^\d{15}$/;

  /**
   * Alphanum type check
   */
  public static iban = /^FR\d{12}[0-9A-Z]{11}\d{2}$/;
}
