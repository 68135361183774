import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { DynamicComponentHostDirective } from './dynamic-component/dynamic-component-host.directive';
import { OdrQuickViewComponent } from './odr-quick-view/odr-quick-view.component';
import { OdrRecapComponent } from './odr-recap/odr-recap.component';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule],
  declarations: [DynamicComponentHostDirective, OdrRecapComponent, OdrQuickViewComponent],
  exports: [DynamicComponentHostDirective, OdrRecapComponent, OdrQuickViewComponent],
})
export class OdrModule {}
