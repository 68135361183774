import { from, Subscription } from 'rxjs';
import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Barcode, Camera, ScanResult, ScanSettings, CameraAccess } from 'scandit-sdk';
import { ScanditService } from './scandit.service';
import { ScanditFieldInterface, InputForScan } from './scandit.interface';
import { DOCUMENT } from '@angular/common';
import { Quadrilateral } from 'scandit-sdk/build/main/lib/quadrilateral';
import { ImageSettings } from 'scandit-sdk/build/main/lib/imageSettings';

const SIM_PLAN_CHECKOUT_PATTERN = /^\d{13}$/;
const SCAN_TOP_BAR_PATTERN = /^\d{13}(\d{2})?$/;
const SCAN_EQUIPMENT_PATTERN = /^\d{13}(\d{2})?$/;
const SIM_REPLACE_PATTERN = /^\d{13}$/;
const MOBILE_TAKE_BACK_PATTERN = /^BTEL[0-9A-Z]{13}$|^\d{15}$/;
const CPU_PATTERN = /^[0-9a-zA-Z]+?$/;

@Component({
  selector: 'rcbt-scandit',
  templateUrl: './scandit.component.html',
  styleUrls: ['./scandit.component.scss'],
})
export class ScanditComponent implements OnInit, OnDestroy {
  @ViewChild('scanContainer', { static: false }) public barcodePickerElement;
  @ViewChild('validScan', { static: false }) public validScanBtn;
  @ViewChild('scanCodeList', { static: false }) public scanCodeList;
  public loadScan: boolean;
  public settings: ScanSettings;
  public scanCodes: string[] = [];
  public scanCode: string;
  public subscription: Subscription;
  public field: InputForScan;
  public multiScan: boolean;
  public activeCamera: Camera;
  public codeBarPattern = SCAN_TOP_BAR_PATTERN;

  private autoScanTimeoutIdList: number[] = [];

  constructor(
    private scanditService: ScanditService,
    @Inject(DOCUMENT) private document: Document,
  ) {
    if (this.scanditService.isOnTablet()) {
      from(CameraAccess.getCameras()).subscribe(cameras => {
        this.activeCamera = cameras.find(camera => camera.cameraType === 'back');
      });
    }
    this.settings = new ScanSettings({
      enabledSymbologies: [
        Barcode.Symbology.EAN8,
        Barcode.Symbology.EAN13,
        Barcode.Symbology.UPCA,
        Barcode.Symbology.UPCE,
        Barcode.Symbology.CODE128,
        Barcode.Symbology.CODE39,
        Barcode.Symbology.CODE93,
        Barcode.Symbology.INTERLEAVED_2_OF_5,
        Barcode.Symbology.MAXICODE,
      ],
      codeDuplicateFilter: 0,
      maxNumberOfCodesPerFrame: 1,
      searchArea: { x: 0, y: 0, width: 1, height: 1 },
      gpuAcceleration: true,
      blurryRecognition: true,
    });
  }

  public ngOnInit(): void {
    if (this.scanditService.isOnTablet()) {
      from(CameraAccess.getCameras()).subscribe(cameras => {
        this.activeCamera = cameras.find(camera => camera.cameraType === 'back');
      });
    }
    this.scanCodes = [];
    this.subscription = this.scanditService.openingScanForField.subscribe((data: ScanditFieldInterface) => {
      this.scanCodes = [];
      this.loadScan = data.openingScan;
      this.field = data.field;
      this.codeBarPattern = this.codeBarPatternMap(this.field);
      this.multiScan = data.multiScan;
    });
  }

  public onScan(result: ScanResult): void {
    // affichage des zones scannées
    for (const barcode of result.barcodes) {
      this.showBarcodeLocation(barcode.location, result.imageSettings, 1000);
    }

    // récupération des codebarres
    const onlyUnique = (value, index, self): boolean => self.indexOf(value) === index;
    this.scanCodes = this.scanCodes
      .concat(result.barcodes.map(barcode => barcode.data))
      .filter(barcode => barcode.match(this.codeBarPattern)) // NOSONAR
      .filter(onlyUnique);
    if (this.scanCodes.length !== 1 || this.multiScan) {
      // désactivation de l'auto-scan
      if (this.autoScanTimeoutIdList.length > 0) {
        for (const autoScanTimeoutId of this.autoScanTimeoutIdList) {
          clearTimeout(autoScanTimeoutId);
        }
        this.autoScanTimeoutIdList = [];
      }
      this.scanCode = '';
      return;
    }

    // fin du traitement si le code scanné est le même
    if (this.scanCode === this.scanCodes[0]) {
      return;
    }
    this.scanCode = this.scanCodes[0];

    // déclenchement de l'auto-scan
    this.autoScanTimeoutIdList.push(
      setTimeout(() => {
        // même si le traitement est identique, on passe par la simulation du clic
        // car sinon la popup de terminal ne s'ouvre pas correctement
        // c'est très bizarre, donc c'est une sorte de paliatif
        // this.onSubmit();
        this.validScanBtn?.nativeElement.click();
      }, 1000) as unknown as number,
    );
  }

  public onError(_error: unknown): void {
    this.close();
  }

  public chooseScannedCode(data: string): void {
    this.scanditService.sendScanForField(data, this.field);
    this.field = undefined;
    this.multiScan = false;
    this.scanCode = '';
    this.loadScan = false;
  }
  public sendAllScannedCodes(): void {
    this.scanditService.sendScansForField(this.scanCodes, this.field);
    this.field = undefined;
    this.multiScan = false;
    this.scanCode = '';
    this.loadScan = false;
  }

  public close(): void {
    this.loadScan = false;
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public onSubmit(): void {
    this.chooseScannedCode(this.scanCode);
  }

  public showBarcodeLocation(barcodeLocation: Quadrilateral, imageSettings: ImageSettings, duration: number): void {
    const rect: DOMRect = this.barcodePickerElement.nativeElement.getBoundingClientRect();

    const zoomFactor = this.barcodePickerElement.nativeElement.clientWidth / imageSettings.width;

    const codeBarreArea = this.document.createElement('div');
    codeBarreArea.style.position = 'absolute';
    codeBarreArea.style.zIndex = '10000';
    codeBarreArea.style.width = `${Math.abs(barcodeLocation.topLeft.x - barcodeLocation.topRight.x) * zoomFactor}px`;
    codeBarreArea.style.height = `${Math.abs(barcodeLocation.topLeft.y - barcodeLocation.bottomLeft.y) * zoomFactor}px`;
    codeBarreArea.style.borderStyle = 'solid';
    codeBarreArea.style.borderWidth = '4px';
    codeBarreArea.style.borderColor = 'rgba(200, 0, 123, 0.6)';
    codeBarreArea.style.backgroundColor = 'rgba(200, 0, 123, 0.3)';
    codeBarreArea.style.top = `${this.convertToDomCoordinates(
      barcodeLocation.topRight.y,
      imageSettings.height,
      rect.height,
    )}px`;
    codeBarreArea.style.left = `${
      rect.right - this.convertToDomCoordinates(barcodeLocation.topRight.x, imageSettings.width, rect.right) - rect.left
    }px`;

    this.barcodePickerElement.nativeElement.prepend(codeBarreArea);
    setTimeout(function () {
      codeBarreArea.remove();
    }, duration);
  }

  private convertToDomCoordinates(val: number, maxScandit: number, maxDom: number): number {
    return (val * maxDom) / maxScandit;
  }

  private codeBarPatternMap(pattern: InputForScan): RegExp {
    switch (pattern) {
      case InputForScan.simPlanCheckout:
        return SIM_PLAN_CHECKOUT_PATTERN;
      case InputForScan.scanTopBar:
        return SCAN_TOP_BAR_PATTERN;
      case InputForScan.scanEquipment:
        return SCAN_EQUIPMENT_PATTERN;
      case InputForScan.simReplace:
        return SIM_REPLACE_PATTERN;
      case InputForScan.mobiletakeback:
        return MOBILE_TAKE_BACK_PATTERN;
      case InputForScan.cpu:
        return CPU_PATTERN;
    }
  }
}
