<!-- TOP-BAR-FILTER -->
<div *ngIf="componentSource === componentSourceList.topBarFilter" class="buttonTakeBack is-flex is-vertical">
    <button [disabled]="disableButton" class="button is-secondary m-y-10" data-cy="reprise-mobile-phone"
        (click)="takebackService.openModal()">
        Reprise Mobile
    </button>
    <div class="takebackEstimation has-text-secondary is-flex is-aligned-center" [ngClass]="{'withName': takebackService.takebackNameMobile && displayMobileName}"
        *ngIf="takebackService.takebackPriceEstimation !== undefined">
        <span *ngIf="takebackService.takebackPriceEstimation === 0">Recycler le mobile</span>
        <span *ngIf="takebackService.takebackPriceEstimation > 0">Rachat mobile jusqu'à : <strong>{{takebackService.takebackPriceEstimation}} € (capacité minimum).</strong></span>
        <span class="icon is-small has-text-secondary" aria-label="Icone de info" *ngIf="takebackService.takebackNameMobile" (click)="displayMobileName = !displayMobileName">
            <i class="tri-infos-circle" aria-hidden="true"></i>
        </span>
    </div>
    <div *ngIf="takebackService.takebackNameMobile && displayMobileName" class="takebackMobileName has-text-secondary">Pour le téléphone : <strong>{{takebackService.takebackNameMobile}}</strong></div>
</div>

<!-- MOBILE-TAKE-BACK -->
<div *ngIf="componentSource === componentSourceList.mobileTakeBack" class="is-flex is-aligned-center g-15">
    <div class="is-flex is-vertical">
        <div class="has-text-secondary is-flex is-aligned-center" *ngIf="takebackService.takebackPriceEstimation !== undefined">
            <div *ngIf="takebackService.takebackPriceEstimation === 0" class="is-flex is-aligned-center is-size-5">Recycler le mobile</div>
            <div *ngIf="takebackService.takebackPriceEstimation > 0" class="is-flex is-aligned-center is-size-5">
                <span class="icon is-medium has-text-secondary" aria-label="Icone de Tel"><i class="tri-mobile-euro" aria-hidden="true"></i></span>
                &nbsp;jusqu'à :&nbsp;<strong>{{takebackService.takebackPriceEstimation}} €</strong>
            </div>
            <span class="icon is-medium has-text-secondary" aria-label="Icone de info" *ngIf="takebackService.takebackNameMobile" (click)="displayMobileName = !displayMobileName">
                <i class="tri-infos-circle" aria-hidden="true"></i>
            </span>
        </div>
        <div *ngIf="takebackService.takebackNameMobile && displayMobileName" class="takebackMobileName has-text-secondary">Pour le téléphone : <strong>{{takebackService.takebackNameMobile}}</strong></div>
    </div>
    <button data-cy="reprise-mobile-phone" class="button is-info" data-cy="reprise-mobile-phone"
        (click)="takebackService.openModal()">
        {{takebackService.takebackPriceEstimation !== undefined ? 'Estimer' : 'Reprise mobile'}}
    </button>
</div>

