import { Step } from '../../stepper/step.abstract';
import { Injectable } from '@angular/core';
import { CartService } from '../cart/cart.service';
import { Plan } from '../../catalog/products/subscription/plan';
import { Scheme } from '../cart/scheme.class';
import { Prepaid } from '../../catalog/products/subscription/plan/prepaid';

@Injectable({
  providedIn: 'root',
})
export class OptionsStepService extends Step {
  public code = 'options';
  public path = '/panier/options';
  public label = 'Options';

  constructor(private cartService: CartService) {
    super();
  }

  get isAllowed(): boolean {
    const currentScheme: Scheme = this.cartService.getCurrentScheme(false);
    return (
      currentScheme &&
      currentScheme.getProductByType(Plan) &&
      !currentScheme.getProductByType(Prepaid) &&
      !currentScheme.isBigtrustSav()
    );
  }

  set isAllowed(val: boolean) {
    return;
  }
}
