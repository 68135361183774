import { Injectable } from '@angular/core';
import { Oauth2StorageService } from './oauth2-storage.service';
import { ConfigService } from '../config.service';
import { CookieService } from '../types/angular/cookie/cookie.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { throwError as observableThrowError, from as observableFrom, Observable } from 'rxjs';
import { Oauth2IframeClass } from './oauth2-iframe.class';
import { StringObject } from '../base/base.interfaces';

@Injectable({
  providedIn: 'root',
})
export class Oauth2Service {
  public _idToken = '';
  public accessToken = '';
  public playLoad: StringObject;
  protected baseUrl = '';
  protected clientId = 'magento.rcbt.bouyguestelecom.fr';
  private urlAuthorize = '/authorize';
  private responseType = 'id_token token';
  private nonceStateLength = 32;
  private cookieSSO = 'SSO_ACTIVE';
  private detectKeySuccess = 'access_token=';
  private detectKeyError = 'error=';
  private isRedirecting = false;

  constructor(
    protected cookie: CookieService,
    protected oauth2Storage: Oauth2StorageService,
    protected config: ConfigService,
  ) {
    this._setBaseUrl();
  }

  get idToken(): string {
    return this._idToken;
  }

  set idToken(data: string) {
    this._idToken = data;
    const jwtHelperService: JwtHelperService = new JwtHelperService();
    this.playLoad = jwtHelperService.decodeToken(data);
    /** Compatibility Angular-1 **/
    this.oauth2Storage.setItem(this.oauth2Storage.key['params'], {
      accessToken: this.accessToken,
      aud: this.clientId,
      iss: this.playLoad.iss,
      nonce: this.getNonce(),
    });
    this.oauth2Storage.setItem(this.oauth2Storage.key['idToken'], data);
  }

  public redirect = (url: string): void => {
    window.document.location.href = url;
  };

  /**
   *
   * @returns {boolean}
   */
  public isConnected(): boolean {
    const token: string =
      this.oauth2Storage.getItem(this.oauth2Storage.key['idToken']) ||
      localStorage.getItem(this.oauth2Storage.key['idToken']);
    return !new JwtHelperService().isTokenExpired(token);
  }

  /**
   * Check if you have SSO_ACTIVE in cookie and start redirect to Picasso
   */
  public autoLogin(): Observable<boolean> {
    this.useToken(window.location.hash);
    const token: string =
      this.oauth2Storage.getItem(this.oauth2Storage.key['idToken']) ||
      localStorage.getItem(this.oauth2Storage.key['accessToken']);
    if (!new JwtHelperService().isTokenExpired(token)) {
      // window.webComponentConfig.accessToken.next(this.oauth2Storage.key['accessToken']);
      return observableFrom([true]);
    }
    if (this.cookie.get(this.cookieSSO)) {
      return this.login();
    } else {
      window.location.href = this.getAuthorizeUrl();
      return observableThrowError('Connexion impossible 1');
    }
  }

  public logout(): void {
    const currentUrl = window.location.href.slice();
    const isAccap = window.location.href.indexOf('accap');
    const domain = new URL(currentUrl);
    this.oauth2Storage.clear();
    window.location.href =
      this.config.data.oauth2.logout + `?service=${domain.origin}` + (isAccap !== -1 ? '/accap/welcome' : '/dispatch');
  }

  /** def
   * Generate URL Authorize
   */
  public getAuthorizeUrl(
    redirect: string = window.document.location.href.replace(window.location.search, ''),

    prompt: boolean = false,
  ): string {
    const url: string =
      this.baseUrl +
      this.urlAuthorize +
      '?client_id=' +
      this.clientId +
      '&state=' +
      this.getNewState() +
      '&nonce=' +
      this.getNewNonce() +
      '&response_type=' +
      encodeURI(this.responseType) +
      '&redirect_uri=' +
      encodeURI(redirect);
    return prompt ? `${url}&prompt=none` : url;
  }

  protected _setBaseUrl(): this {
    this.baseUrl = this.config.getBaseUrl();
    return this;
  }

  /**
   * Retirec to Picasso
   */
  private login(): Observable<boolean> {
    window.webComponentConfig.authUrl = this.getAuthorizeUrl();
    return new Observable(obs => {
      let errorIframe = true;
      // eslint-disable-next-line prefer-const
      let oauthIframe: HTMLIFrameElement;
      setTimeout(() => {
        // ERROR
        if (!errorIframe) {
          return;
        }
        obs.error('Connexion impossible 2');
        obs.complete();
      }, 8000);
      window.addEventListener(
        'message',
        (e: MessageEvent) => {
          errorIframe = false;
          if (!oauthIframe || e.source !== oauthIframe.contentWindow || e.origin !== location.origin) {
            return;
          }
          const data = e.data || '';
          if (this.useToken(<string>data)) {
            obs.next(true);
          } else {
            if (!this.isRedirecting) {
              window.location.href = this.getAuthorizeUrl();
              obs.error('Connexion impossible 3');
              this.isRedirecting = true;
            }
          }
          window.removeEventListener('message', () => void 0);
          obs.complete();
        },
        false,
      );
      oauthIframe = Oauth2IframeClass.injectIframe(
        this.getAuthorizeUrl(window.document.location.origin + '/assets/oauth2.html', true),
      );
    });
  }

  private useToken(hash: string = ''): boolean {
    if (this.detectTokenSuccess(hash)) {
      // Succes oauth redirect
      const oauthParams: StringObject = this.extractParam(hash);
      if (oauthParams['state'] === this.getState()) {
        const token: string = oauthParams['id_token'] || localStorage.getItem(this.oauth2Storage.key['accessToken']);
        if (new JwtHelperService().isTokenExpired(token)) {
          return false;
        }
        this.oauth2Storage.setItem(this.oauth2Storage.key['accessToken'], oauthParams['access_token']);
        this.oauth2Storage.setItem(this.oauth2Storage.key['idToken'], oauthParams['id_token']);
        this.accessToken = oauthParams['access_token'];
        window.webComponentConfig.accessToken.next(this.accessToken);
        this.idToken = oauthParams['id_token'];
        window.webComponentConfig.idToken.next(this.idToken);
        return true;
      }
    } else if (this.detectTokenError(hash)) {
      // Error oauth redirect
      this.redirect(this.config.getFailureUrl());
      return false;
    } else if (this.oauth2Storage.getItem(this.oauth2Storage.key['idToken'])) {
      // Load from storage
      this.accessToken = this.oauth2Storage.getItem(this.oauth2Storage.key['accessToken']);
      window.webComponentConfig.accessToken.next(this.accessToken);
      this.idToken = this.oauth2Storage.getItem(this.oauth2Storage.key['idToken']);
      window.webComponentConfig.idToken.next(this.idToken);
    }
    return false;
  }

  /**
   * Check if hash is success response
   */
  private detectTokenSuccess(hash: string): boolean {
    return hash.indexOf(this.detectKeySuccess) !== -1;
  }

  /**
   * Check if hash is error response
   */
  private detectTokenError(hash: string): boolean {
    return hash.indexOf(this.detectKeyError) !== -1;
  }

  /**
   * State from storage
   * @returns {string}
   */
  private getState = function (): string {
    return this.oauth2Storage.getItem(this.oauth2Storage.key['state']);
  };

  /**
   * State from storage
   * @returns {string}
   */
  private getNonce = function (): string {
    return this.oauth2Storage.getItem(this.oauth2Storage.key['nonce']);
  };

  /**
   * Remove '#/'
   * Extract param from oauth hash
   */
  private extractParam(hash: string): StringObject {
    const tab: string[] = hash.split('&');
    const obj: StringObject = {};
    for (let i = 0; i < tab.length; i++) {
      tab[i] = tab[i].replace('#', '');
      tab[i] = tab[i].replace('/', '');
      tab[i] = tab[i].replace('?', '');
      const keyValue: string[] = tab[i].split('=');
      obj[keyValue[0]] = keyValue[1];
    }
    return obj;
  }

  /**
   * Generate key chaine and set in oauth2Storage for check after redirection
   */
  private getNewState(): string {
    const state: string = this.makeNonceOrState();
    this.oauth2Storage.setItem(this.oauth2Storage.key['state'], state);
    return state;
  }

  /**
   * Generate key chaine and set in oauth2Storage for check after redirection
   */
  private getNewNonce(): string {
    const nonce: string = this.makeNonceOrState();
    this.oauth2Storage.setItem(this.oauth2Storage.key['nonce'], nonce);
    return nonce;
  }

  /**
   * Generate key chaine for State
   */
  private makeNonceOrState(): string {
    let text = '';
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < this.nonceStateLength; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }
}
