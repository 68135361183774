import { EventEmitter, Injectable } from '@angular/core';

@Injectable()
export class DebugTabService {
  public eventActivation: EventEmitter<boolean> = new EventEmitter<boolean>();

  public emitActivationDebug(activation: boolean): void {
    this.eventActivation.emit(activation);
  }
}
