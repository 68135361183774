import { Component, Input } from '@angular/core';
import { PricingProductsInterface } from './pricing-products.interface';

@Component({
  selector: 'rcbt-pricing-products',
  templateUrl: './pricing-products.component.html',
  styleUrls: ['./pricing-products.component.scss', './../pricing-accessory/pricing-accessory.component.scss'],
})
export class PricingProductsComponent {
  @Input() products: PricingProductsInterface[];
}
