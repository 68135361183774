import { Injectable } from '@angular/core';
import { StorageService } from '../base/services/storage.service';
import { StringObject } from '../base/base.interfaces';

@Injectable({
  providedIn: 'root',
})
export class FaiStorageService extends StorageService {
  public key: StringObject = {
    fai: 'fai_widget',
  };
  protected prefix = 'fai_widget_';

  protected sessionStorageKeys: string[] = Object.values(this.key);

  public clear(): void {
    this.removeItem(this.key.fai);
    this.clearWetoCache();
  }

  private clearWetoCache(): void {
    let n = sessionStorage.length;
    while (n--) {
      const key = sessionStorage.key(n);
      if (/weto-/.test(key)) {
        sessionStorage.removeItem(key);
      }
    }
  }
}
