import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CartService } from '../../../cart.service';
import { finalize, mergeMap, tap } from 'rxjs/operators';
import { Scheme } from '../../../scheme.class';
import { MobileTakeBack } from '../../../../../catalog/products/mobileTakeBack';
import { ScanditService } from '../../../../../scandit/scandit.service';
import { Phone } from '../../../../../catalog/products/equipement/complex/phone';
import { CheckoutStepperService } from '../../../../checkout-stepper.service';
import { BrowseConfigService } from '../../../../../context/browse-config.service';
import { Subscription } from 'rxjs';
import { ProductSerialized } from '../../../../../catalog/products/interface/configurable';
import { EComponentSource } from '../../../../takeback/takeback.interface';

@Component({
  selector: 'rcbt-mobile-take-back',
  templateUrl: './mobileTakeBack.component.html',
  styleUrls: ['./mobileTakeBack.component.scss'],
})
export class MobileTakeBackComponent implements OnInit, OnDestroy {
  @Input() public scheme: Scheme;
  public scanLoading = false;
  public message: string;
  public scanCode: string;
  public hasPartExchange: boolean;
  public mobileTackebackBonusAmount: number;
  public onTablete = false;
  public subscriptions: Subscription = new Subscription();
  public label: string;
  public componentSourceTakeback = EComponentSource.mobileTakeBack;

  constructor(
    private cartService: CartService,
    private scanditService: ScanditService,
    private stepperService: CheckoutStepperService,
    private browseConfigService: BrowseConfigService,
  ) {}

  public ngOnInit(): void {
    this.message = '';
    this.hasPartExchange = this.cartHasPartExchange();
    this.onTablete = this.scanditService.isOnTablet() || this.browseConfigService.browseConfig.debug;
    this.subscriptions.add(
      this.cartService.onAddProduct.subscribe((product: ProductSerialized) => {
        if (product.type_id === 'mobile-take-back') {
          this.hasPartExchange = true;
        }
      }),
    );
    this.subscriptions.add(this.cartService.afterRefresh.subscribe(() => this.getMobileTakeBackBonus()));
    this.getMobileTakeBackBonus();
  }

  public getMobileTakeBackBonus(): void {
    this.label = this.cartService.getCurrentScheme().getProductByType(Phone)?.data?.label;
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public scanChange(): void {
    this.message = '';
  }

  public cartHasPartExchange(): boolean {
    return !!this.scheme.getProductByType(MobileTakeBack);
  }

  public removePartExchange(): void {
    if (this.cartHasPartExchange()) {
      const partExhange: MobileTakeBack = this.scheme.getProductByType(MobileTakeBack);
      this.cartService
        .remove(partExhange.uniqueId, this.scheme.uniqueId)
        .pipe(
          tap(() => {
            this.scanCode = '';
            this.hasPartExchange = false;
            this.message = '';
          }),
          mergeMap(() => this.cartService.refreshCart()),
          finalize(() => {
            this.scanLoading = false;
            this.stepperService.changesCurrentStep.next(null);
          }),
        )
        .subscribe();
    }
  }
}
