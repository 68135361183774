<rcbt-nav-bar></rcbt-nav-bar>
<div class="is-tri">
    <div class="filter-plain-component">
        <div class="segmented-control">
            <ng-container *ngFor="let menu of subCategories">
                <button class="segmented-control-item" data-segmentedcontrol-initialized="true" [attr.data-cy]="'categoryPlan_'+menu.category"
                        [ngClass]="{'is-active':menu.selected, 'pro':isPro}"
                        (click)="selectMenu(menu)"
                        *ngIf="!menu.isEmpty">
                    {{menu.label}}
                </button>
            </ng-container>
        </div>
    </div>
    <div class="columns">
        <div class="column is-12">
            <div class="row" *ngIf="msg">
                <div class="column is-12">
                    <p>{{msg}}</p>
                </div>
            </div>
            <div class="row">
                <div class="column is-12" *ngIf="subCategory?.category === 'omb_rcbt' && !msg">
                    <p>Attention : Ces offres sont réservées pour toute nouvelle souscription aux particuliers titulaires
                        d’un compte courant ouvert dans une agence du CIC ou dans une caisse de Crédit Mutuel participant à
                        l’opération.</p>
                    <p><br />Les promotions sur ces offres sont exclusives à l'ouverture d'une nouvelle ligne.</p>
                </div>
                <div class="column is-6" *ngFor="let plan of virtualPlans">
                    <div class="card">
                        <div class="card-content is-flex is-aligned-center is-justified-end g-20 min-height-card-content">
                            <div class="is-flex is-aligned-center m-r-a">
                                <h2 class="text is-size-5">
                                    {{plan.ctxProduct.nom}}
                                </h2>
                            </div>
                            <div class="is-flex is-vertical is-aligned-end g-5">
                                <div *ngIf="plan?.eligibilites?.length > 0 && plan?.eligibilites?.includes('Foyer')" class="tag is-small">
                                    <span>B.iG</span>
                                </div>
                                <rcbt-price [product]="{price: plan.price, oldPrice: plan.oldPrice, priceType: plan.priceType}"
                                    [fontLevel]="3"></rcbt-price>
                            </div>
                            <div class="button-reserved-space">
                                <button [attr.data-cy]="'plan_'+plan.ctxProduct.gencode" class="button is-info is-icon-only" [disabled]="isLoading$ | async"
                                        [ngClass]="{'action-feedback': (isLoading$ | async)}" *ngIf="canAddToCart(plan)"
                                        (click)="addProductToCart(plan)">
                                    <span  class="has-text-white is-white icon is-small"><i class="tri-shopping-cart" aria-hidden="true"></i></span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
