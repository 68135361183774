<div class="is-tri modal-list-pu">
    <div class="pu-header">
        <div class="title is-size-3 is-centered m-x-50 m-t-20 pu-title-height">
            Êtes-vous sûr qu'il s'agit bien de la personne suivante?
            Attention, il ne sera pas possible de modifier ensuite.
        </div>
    </div>
    <div class="body rows m-0">
        <div *ngFor="let pu of persons; let i = index" class="radio-tile is-horizontal pu-width is-centered row">
            <input type="radio" id="list-pu-{{i}}" [checked]="persons.length === 1" name="select-pu" (click)="selectPu(pu)">
            <label for="list-pu-{{i}}" class="radio-label">
                <ul class="list is-tertiary text is-size-5">
                    <li *ngIf="pu.type">Type de client : {{pu.type}} </li>
                    <li *ngIf="pu.civility">Civilité : {{pu.civility}}</li>
                    <li *ngIf="pu.name">Nom : {{pu.name}}</li>
                    <li *ngIf="pu.firstname">Prénom : {{pu.firstname}}</li>
                    <li *ngIf="pu.birthDate">Date de naissance : {{pu.birthDate}} </li>
                    <li *ngIf="pu.departmentOfBirth">Département de naissance : {{pu.departmentOfBirth}} </li>
                    <li *ngIf="pu.address.addressLinear">Adresse : {{pu.address.addressLinear}}</li>
                    <li *ngIf="pu.email">Mail de contact : {{pu.email}}</li>
                    <li *ngIf="pu.nbActiveLines > 0 || (!pu.hasExistingContracts || pu.areAllContractsTerminated)">
                        Client avec {{pu.nbActiveLines}} ligne(s) active(s)
                    </li>
                </ul>
            </label>
        </div>
    </div>
    <div class="actions">
        <button class="button is-primary" (click)="cancel()">Quitter sans rattacher</button>
        <button class="button is-info" [disabled]="!enableAttach" (click)="attach()">Rattacher</button>
    </div>
</div>