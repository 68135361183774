import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class TimeoutService {
  public timeout = new Subject<boolean>();

  public notify(): void {
    this.timeout.next(true);
  }

  public reset(): void {
    this.timeout.next(false);
  }
}
