import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComparatorToggleComponent } from './comparator-toggle.component';
import { BaseModule } from '../base/base.module';
import { FormsModule } from '@angular/forms';
import { ComparatorResultComponent } from './comparator-result/comparator-result.component';
import { VirtualSchemeComponent } from './virtual-scheme/virtual-scheme.component';
import { ComparatorParametersComponent } from './comparator-parameters/comparator-parameters.component';

@NgModule({
  imports: [CommonModule, FormsModule, BaseModule],
  declarations: [
    ComparatorToggleComponent,
    ComparatorResultComponent,
    ComparatorParametersComponent,
    VirtualSchemeComponent,
  ],
  exports: [ComparatorToggleComponent, ComparatorResultComponent, ComparatorParametersComponent],
})
export class ComparatorModule {}
