import {
  PromotionTypes,
  PromotionTypologies,
  UpdatePromoParams,
  UpdatePromoParamsActions,
} from '../promotions.interfaces';
import { Promotion } from '../promotion.class';

export class Manual extends Promotion {
  public type: PromotionTypes = PromotionTypes.manual;
  public typology: PromotionTypologies = PromotionTypologies.product;

  public isApplied(): boolean {
    return !!this.application.actionPromotion.isManuallyApplied;
  }

  public setParams(params: UpdatePromoParams): this {
    this.application.actionPromotion.isManuallyApplied = params.action === UpdatePromoParamsActions.add;
    return this;
  }
}
