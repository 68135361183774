<span class="tag is-success is-large" style="margin: 8px;" *ngIf='cartService.cart.dematId'>
    <span class="icon is-success">
        <i class="tri-check-circle" aria-hidden="true"></i>
    </span>
    Dématerialisation initiée
</span>
<button data-cy="scan-camera" type="button" class="button is-secondary" style="margin-right: 8px;"
    (click)="processScan()">
    <span class="icon is-smal">
        <i class="tri-camera" aria-hidden='true'></i>
    </span>
    Scanner les pièces justificatives</button>
<button class="button is-icon-only is-warning" (click)="resetDemat()" *ngIf='cartService.cart.dematId'>
    <span class="icon is-small"><i class="tri-trash" aria-hidden='true'></i></span>
</button>