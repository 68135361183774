import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'rcbt-modal-continuity',
  templateUrl: './modal-continuity.component.html',
  styleUrls: ['./modal-continuity.component.scss'],
})
export class ModalContinuityComponent {
  @Input() public onValidClick: () => void;
  public loading: boolean;
  public error: string;

  constructor(private activeModal: NgbActiveModal) {}

  public closeModal(): void {
    this.activeModal.close();
  }

  public validate(): void {
    this.activeModal.close();
    this.onValidClick();
  }
}
