import { Promotion } from '../promotion.class';
import { PromotionTypes, PromotionTypologies, UpdatePromoParams } from '../promotions.interfaces';

export class Automatic extends Promotion {
  public type: PromotionTypes = PromotionTypes.automatic;
  public typology: PromotionTypologies = PromotionTypologies.product;

  public isApplied(): boolean {
    return true;
  }

  public setParams(params: UpdatePromoParams): this {
    return this;
  }

  public isMobileTakeBack(): boolean {
    return JSON.stringify(this.condition).indexOf('RMBC000000') !== -1;
  }
}
