import { Injectable } from '@angular/core';
import { IPostCatalogResponse } from '../../../contextualized-catalog/dtos/contextualized-product-output.dto';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { CartService } from '../../../checkout/cart/cart.service';
import { CustomerService } from '../../../checkout/cart/customer/customer.service';
import { CatalogCtxService } from '../../../contextualized-catalog/services/catalog-ctx.service';
import { CatalogInputHelperDto } from '../../../contextualized-catalog/dtos/catalog-input-helper.dto';
import { ConsulterCatalogueContextualiseAvecPanierQuery } from '@bytel/pt-ihm-api-portailvente-sapic-catalogue';
import { CatalogOutputHelperDto } from '../../../contextualized-catalog/dtos/catalog-output-helper.dto';

export interface ISousCategory {
  category: string;
  label: string;
  selected: boolean;
  pro?: boolean;
  isEmpty?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class CategoryPlanService {
  public contextualizedPlans = new Map<string, IPostCatalogResponse[]>();

  constructor(
    private cartService: CartService,
    private customerService: CustomerService,
    private catalogCtxService: CatalogCtxService,
  ) {
    this.cartService.afterRefresh.subscribe(
      () => (this.contextualizedPlans = new Map<string, IPostCatalogResponse[]>()),
    );
  }

  public getContextualizedPlans(categoryCode: string): Observable<IPostCatalogResponse[]> {
    const categoryPlans = this.contextualizedPlans.get(categoryCode);
    if (categoryPlans) {
      return of(categoryPlans);
    }

    const queryParamCtx: ConsulterCatalogueContextualiseAvecPanierQuery = {
      modePourFinancement: 'min-one-off',
      limite: 20,
      categorie: categoryCode,
    };

    const catalogBodywithCart = CatalogInputHelperDto.buildBodyCtxWithCart(
      this.customerService.customer,
      this.cartService.cart,
      null,
      this.cartService.getCurrentScheme()?.uniqueId,
    );

    return this.catalogCtxService.postCatalogCtx(catalogBodywithCart, queryParamCtx).pipe(
      map(catalogResult => CatalogOutputHelperDto.convertCatalogResponse(catalogResult)),
      tap(result => this.contextualizedPlans.set(categoryCode, result)),
    );
  }
}
