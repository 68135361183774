import { JsonProduct } from './interface/context';
import { Product } from './product';

export class Service extends Product {
  public static typeAssistance = 'service_assistance';
  public static typeAccompaniment = 'service_accompaniment';
  public typeService: string;

  constructor(data?: JsonProduct, uniqueId: string = '') {
    super(data, uniqueId);
    super.setJsonData(data);
  }

  public setJsonData(data?: JsonProduct): this {
    this.typeService = data.type_service;
    return this;
  }
}

export enum TypeService {
  default = '',
  mobile = 'mobile',
  fai = 'fai',
}
