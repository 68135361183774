import { Injectable } from '@angular/core';
import { StorageService } from '../base/services/storage.service';
import { CartService } from './cart/cart.service';
import { Plan } from '../catalog/products/subscription/plan';
import { IPlanConfiguration } from '../catalog/products/subscription/IPlanConfiguration';
import { Scheme } from './cart/scheme.class';
import { InsuranceConfig } from '../partner/partner.dto';
import { StringObject } from '../base/base.interfaces';

@Injectable({
  providedIn: 'root',
})
export class CheckoutStorageService extends StorageService {
  public key: StringObject = {
    steps: 'checkout_steps',
    cart: 'cart',
    plansConfig: 'plansConfig',
    schemeInsurances: 'schemeInsurances',
  };
  protected prefix = 'cart_';

  protected sessionStorageKeys: string[] = [
    this.key['cart'],
    this.key['steps'],
    this.key['plansConfig'],
    this.key['schemeInsurances'],
  ];

  public serializePlansConfig(cartService: CartService): void {
    const plan: Plan = cartService.getCurrentScheme()?.getProductByType<Plan>(Plan);
    if (plan) {
      this.setItem(this.key['plansConfig'], { [plan.itemId]: plan.getConfiguration() });
    }
  }

  public unserializePlansConfig(cartService: CartService): void {
    const plansConfig: IPlanConfiguration = this.getItem(this.key['plansConfig']);
    const plan: Plan = cartService.getCurrentScheme()?.getProductByType<Plan>(Plan);
    if (
      plan &&
      plansConfig &&
      plansConfig[plan.itemId] &&
      (!plansConfig[plan.itemId].msisdns?.length || !plansConfig[plan.itemId].portabilityType)
    ) {
      plan.setConfiguration(plansConfig[plan.itemId]);
    }
  }

  public serializeSchemeInsurances(cartService: CartService): void {
    const serializedSchemeInsurances = {};
    cartService.cart.schemes.forEach(s => (serializedSchemeInsurances[s.quoteId] = s.addedInsurances));
    this.setItem(this.key['schemeInsurances'], serializedSchemeInsurances);
  }

  public unserializeSchemeInsurances(cartService: CartService): void {
    const schemeInsurances: InsuranceConfig[] = this.getItem(this.key['schemeInsurances']);
    if (schemeInsurances) {
      Object.keys(schemeInsurances).forEach((quoteId: string) => {
        const scheme = cartService.cart.schemes.find((s: Scheme) => s.quoteId + '' === quoteId);
        if (scheme) {
          scheme.addedInsurances = schemeInsurances[quoteId];
        }
      });
    }
  }
}
