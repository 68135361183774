import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContainerComponent } from '../container/container.component';
import { PretGaletComponent } from './pret-galet.component';
import { prismeLoggerResolver } from '../shared/prisme/prisme-logger.resolver';

const appRoutes: Routes = [
  {
    path: 'pret-galet',
    component: ContainerComponent,
    resolve: { prismeLoggerResolver },
    children: [
      {
        path: '',
        component: PretGaletComponent,
        resolve: { prismeLoggerResolver },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule],
})
export class PretGaletRoutingModule {}
