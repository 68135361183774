import { Product } from './product';
import { ScanConfigInterface } from '../../scan/interfaces/types';
import { ScanConfig } from '../../scan/scan-config.class';
import {
  IDataQuestion,
  IMobileTakeBackConfiguration,
  IMobileTakeBackQualificationCriterion,
  IMobileTakeBackSerialized,
  IMobileTakeBackTerminal,
} from './mobileTakeBack.interfaces';
import { JsonProduct } from './interface/context';
import { BasicObject } from '../../base/base.interfaces';

export class MobileTakeBack extends Product {
  public scanConfig: ScanConfigInterface = ScanConfig.mobileTakeBackCoupon;
  public orderPartnerId: string;
  public quoteProposalPartnerId: string;
  public dataQuestion: IDataQuestion;
  public terminal: IMobileTakeBackTerminal;
  public qualificationCriterion: IMobileTakeBackQualificationCriterion[];
  public mtbCessionLink: string;
  public mtbCessionCouponLink: string;
  public isMtbCessionCouponPrinted: boolean;
  public isPrePrinted: boolean;

  constructor(data?: JsonProduct, uniqueId: string = '') {
    super(data, uniqueId);
  }

  public isCessionCoupon(): boolean {
    return !!this.getScanCode();
  }

  public setConfiguration(data: IMobileTakeBackConfiguration | JsonProduct): void {
    this.setScanCode(data['scanCode'] || null);
    this.orderPartnerId = data['idCommandePartenaire'];
    this.quoteProposalPartnerId = data['idDevisPartenaire'];
    this.dataQuestion = data['dataQuestion'];
    this.terminal = data['terminal'];
    this.qualificationCriterion = data['criteresQualification'];
    this.mtbCessionCouponLink = data['lienBonDeCession'];
    this.isMtbCessionCouponPrinted = data['bonDeCessionImprime'];
    if ('prix' in data) {
      this.price = data['prix'];
      this.oldPrice = this.price;
    }
  }

  public getConfiguration(): BasicObject {
    return {
      prix: this.price,
      idCommandePartenaire: this.orderPartnerId,
      idDevisPartenaire: this.quoteProposalPartnerId,
      dataQuestion: this.dataQuestion,
      terminal: this.terminal,
      criteresQualification: this.qualificationCriterion,
      lienBonDeCession: this.mtbCessionCouponLink,
      bonDeCessionImprime: this.isMtbCessionCouponPrinted,
    };
  }

  // todo vérifier le prix posté
  // public updatePrice(): void {
  //   this.price = this.getConfiguration()['prix'];
  // }

  public serialize(): IMobileTakeBackSerialized {
    return Object.assign(super.serialize(), this.getConfiguration());
  }
}
