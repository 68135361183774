import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { CartService } from '../cart/cart.service';
import { CheckoutStepperService } from '../checkout-stepper.service';
import { FaiStorageService } from '../../fai-widget/fai-storage.service';
import { FaiStepperService } from '../../fai-widget/fai-stepper/fai-stepper.service';
import { FundingService } from '../../fundings/services/funding.service';
import { forkJoin, Observable, of } from 'rxjs';
import { Scheme } from '../cart/scheme.class';
import { GlobalLoaderService } from '../../base/services/global-loader.service';
import { tap } from 'rxjs/operators';

const LOADING_ACTIONS = {
  clearEmptySchemes: '[DispatchComponent] clearEmptySchemes',
};

@Component({
  selector: 'rcbt-newscheme',
  templateUrl: './newscheme.component.html',
  styleUrls: ['./newscheme.component.scss'],
})
export class NewSchemeComponent {
  public loading = false;
  constructor(
    protected router: Router,
    protected stepperService: CheckoutStepperService,
    protected faiStepperService: FaiStepperService,
    protected faiStorageService: FaiStorageService,
    protected cartService: CartService,
    protected fundingService: FundingService,
    private globalLoaderService: GlobalLoaderService,
  ) {}

  public newScheme(): void {
    this.fundingService.launchWarningModal(this.launchNewScheme.bind(this));
  }

  private launchNewScheme(): void {
    this.clearEmptySchemes()
      .pipe(
        tap(() => {
          this.cartService.save();
          this.loading = true;
          this.stepperService.reset();
          this.faiStepperService.reset();
          this.faiStorageService.clear();
          this.router.navigate(['/dispatch']);
        }),
      )
      .subscribe();
  }

  private clearEmptySchemes(): Observable<void> {
    const clearObs: Observable<void>[] = [];
    this.cartService.cart.schemes.forEach((scheme: Scheme) => {
      if (scheme.isEmpty()) {
        clearObs.push(this.cartService.deleteScheme(scheme));
      }
    });
    return clearObs.length
      ? this.globalLoaderService.showLoaderUntilFinalize(forkJoin(clearObs), LOADING_ACTIONS.clearEmptySchemes)
      : of(null);
  }
}
