<div data-cy="item-accessory" *ngFor="let product of products" class="column is-4">
  <div class="box">
    <header class="box-header" [ngClass]="{'noStock': product.quantity === 0}">
      <p class="is-marginless" data-cy="item-accessory-header" >{{ product.name }}</p>
    </header>
    <div class="box-content is-flex flex-column g-20">
      <h1 class="title is-level-1 is-marginless is-flex justify-content-center"><rcbt-price [product]="product"></rcbt-price></h1>
      <div class="is-flex justify-content-center g-25">
          <div class="is-flex align-items-center g-5">
            <p class="is-marginless">Disponibilité:</p>
            <h3 class="is-level-3 is-marginless">{{ product.quantity }}</h3>
          </div>
          <div class="is-flex align-items-center g-5">
            <p class="is-marginless">Gencode:</p>
            <h3 class="is-level-3 is-marginless">{{ product.gencode }}</h3>
          </div>
      </div>
    </div>
  </div>
</div>