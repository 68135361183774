/* eslint-disable */
import { Directive, ElementRef, HostListener,OnDestroy,OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { RouteChangeEvent, RouterEvent } from './accap-adapter.model';


@Directive({
    selector: '[accapAdapterDirective]',
})
export class AccapAdapterDirective implements OnInit, OnDestroy {

    private destroy$ = new Subject<void>();

    constructor(private element: ElementRef,
                private router: Router,
                private route: ActivatedRoute) {}

    public ngOnInit(): void {
        this.route.url.pipe(
                map(() => this.router.url),
                takeUntil(this.destroy$)
        ).subscribe(
            // Note: path passes as input to web component
            urlPath => this.element.nativeElement.urlPath = urlPath
        );
    }

    public ngOnDestroy(): void {
        this.destroy$.next();
    }

    @HostListener('routeChange', ['$event'])
    handleRouteChange(event: RouteChangeEvent): void {
        this.navigateToUrl(event?.detail);
    }

    private navigateToUrl(event: RouterEvent | undefined): void {
        if (event?.url && event.url.startsWith('/')) {
            this.router.navigateByUrl(event.url, {
                replaceUrl: event.replaceUrl || false,
            });
        }
    }
}
