import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseModule } from '../../../base/base.module';
import { CrvComponent } from './crv.component';
import { ReactiveFormsModule } from '@angular/forms';
import { CrvRoutingModule } from './crv-routing.module';

@NgModule({
  imports: [CommonModule, BaseModule, ReactiveFormsModule, CrvRoutingModule],
  declarations: [CrvComponent],
  exports: [CrvComponent],
})
export class CrvModule {}
