<div class="dialog-modal-container is-tri">

    <div class="modal-header is-centered">
        <h3 class="title is-level-3">Attention</h3>
    </div>
    <div class="modal-text is-flex is-centered">
            <p class="text" [innerHTML]="message"></p>
    </div>
    <br>
    <div class="modal-actions">
        <button [hidden]="hideCancelButton" class="button is-danger" (click)="closeModal()">{{cancelButtonText}}</button>
        <button [hidden]="hideValidButton" class="button is-info" (click)="onValidClick()">{{validButtonText}}</button>
    </div>

</div>
