import { ContractInterface } from '../../../context/contract.interface';
import { PandoraDocument } from '../../documents/PandoraDocument.abstract';
import { CheckoutNs } from '../../checkout.interface';
import { ControlGcp } from '../../../context/child/customer.interface';
import { IScreening } from '../../../screening/screening.interface';
import { CustomerCategory, CustomerType } from './customer.interface';
import { CustomerContextSerializedInterface } from '../../../context/child/customer-context-serialized.interface';
import { IPandoraDocumentSerialize } from '../../documents/IPandoraDocumentSerialize';

export class Customer {
  public lockedInfo: string[];
  public editable = true;
  public keySerialize = 'client';
  public type: CustomerType;
  public personId: number = null;
  public email = '';
  public civility = '';
  public dob: string = null;
  public firstname = '';
  public lastname = '';
  public birthplace = '';
  public phoneNumber = '';
  public category: CustomerCategory;
  public streetNumber: string = null;
  public street = '';
  public street2 = '';
  public residence: string;
  public building: string;
  public stair: string;
  public floor: string;
  public postcode: string = null;
  public city = '';
  public country = '';
  public possibleIbans: string[] = [];
  public idCt = '';
  public contracts: ContractInterface[] = [];
  public telephonePrepaye: string;
  public ligneMarche: string;
  public isClient: boolean;
  public addressDocument: PandoraDocument;
  public paymentAccount: CheckoutNs.IPayment;
  public identityDocumentType: PandoraDocument;
  public otherDocuments: PandoraDocument[];
  public identityDocumentNumber: string;
  public paymentDocumentType: PandoraDocument;
  public powerletter: boolean;
  public controlAddress: ControlGcp;
  public dateFromSI: boolean;
  public company: IScreening = {};
  public isEligibleConvergence = false;
  public isEligibleConvergenceMultiline = false;
  public isEligiblePackFamily = false;
  public vip?: boolean;
  public rna: string;
  public mobileContractsCount: number;
  public fixContractsCount: number;
  public idProspect: string;

  public isPro(): boolean {
    return this.category === CustomerCategory.pro;
  }

  public serialize(): CustomerContextSerializedInterface {
    return {
      editable: this.editable,
      contrats: this.contracts,
      telephonePrepaye: this.telephonePrepaye,
      ligneMarche: this.ligneMarche,
      lockedInfo: this.lockedInfo,
      personId: this.personId,
      categorie: this.category,
      type: this.type,
      nom: this.lastname,
      prenom: this.firstname,
      civilite: this.civility,
      email: this.email,
      dateNaissance: this.dob,
      departementNaissance: this.birthplace?.split(' ')[0],
      numeroRue: this.streetNumber,
      rue: this.street,
      residence: this.residence,
      batiment: this.building,
      escalier: this.stair,
      etage: this.floor,
      codePostal: this.postcode,
      complementAdresse: this.street2,
      ville: this.city,
      idPays: this.country,
      possibleIbans: this.possibleIbans,
      telephone: this.phoneNumber,
      powerletter: this.powerletter,
      documentIdentite: this.identityDocumentType ? this.identityDocumentType.serialize() : null,
      otherDocuments: this.otherDocuments ? this.serializeDocuments(this.otherDocuments) : null,
      documentIdentiteNumero: this.identityDocumentNumber,
      documentAdresse: this.addressDocument ? this.addressDocument.serialize() : null,
      documentPaiement: this.paymentDocumentType ? this.paymentDocumentType.serialize() : null,
      controlAddress: this.controlAddress,
      dateFromSI: this.dateFromSI,
      company: this.company,
      nbContratsFixe: this.fixContractsCount,
      nbContratsMobile: this.mobileContractsCount,
      vip: this.vip,
      rna: this.rna,
    };
  }

  private serializeDocuments(documents: PandoraDocument[]): IPandoraDocumentSerialize[] {
    const result: IPandoraDocumentSerialize[] = [];
    for (const document of documents) {
      result.push(document.serialize());
    }
    return result;
  }
}
