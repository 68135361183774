<section class="accordions" *ngIf="isDisplay">
    <article class="accordion is-active">
        <div class="accordion-header is-flex">
            <h4 class="is-marginless">* RABAIS PANIER DISTRIBUTEUR</h4>
            <button class="toggle" aria-label="toggle" (click)="isPromoSummaryExpanded = !isPromoSummaryExpanded"></button>
        </div>
        <div class="accordion-body accordion-body-modif has-background-white">
            <div class="accordion-content">
                <table class="table is-fullwidth is-marginless">
                    <thead></thead>
                    <tbody *ngIf="isPromoSummaryExpanded">
                        <tr>
                            <td class="name-column"><span>* Sous total</span></td>
                            <td><rcbt-price [price]="subTotalCaAmount" [fontLevel]="6" [isSecondaryNegatif]="true"></rcbt-price></td>
                            <td></td>
                        </tr>
                        <ng-container *ngFor="let promo of promotionsCa">
                            <tr *ngIf="promo.isApplied() && !promo.isAgilityCa()">
                                <td class="name-column is-italic">* <span [innerHTML]="promo.name"></span></td>
                                <td><rcbt-price [price]="-promo.application.actionPromotion.amount" [fontLevel]="6" [isSecondaryNegatif]="true"></rcbt-price></td>
                                <td>
                                    <button *ngIf="promo.isAgilityCa()" class="button is-icon-only is-warning" (click)="onDeleteAgilityCa(promo.id)">
                                        <span class="icon is-small"><i class="tri-trash" aria-hidden='true'></i></span>
                                    </button>
                                </td>
                            </tr>
                        </ng-container>
                        <tr *ngIf="agilityCaApplied">
                            <td class="name-column is-italic"><span>* Rabais panier</span></td>
                            <td><rcbt-price [price]="-agilityCaAmout" [fontLevel]="6" [isSecondaryNegatif]="true"></rcbt-price></td>
                            <td>
                                <button *ngIf="agilityCaApplied && canAppyAgilityCa" class="button is-icon-only is-warning" (click)="onDeleteAgilityCa(agilityCaId)">
                                    <span class="icon is-small"><i class="tri-trash" aria-hidden='true'></i></span>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                    <tfoot class="has-background-secondary-10">
                        <tr>
                            <td class="name-column title is-level-3">* TOTAL</td>
                            <td><rcbt-price [price]="cartService.cart.caTotals.today" [fontLevel]="5" [isSecondaryNegatif]="true"></rcbt-price></td>
                            <td></td>
                        </tr>
                    </tfoot>
                </table>
                <div class="has-text-center has-text-danger">{{ error }}</div>
            </div>
        </div>
    </article>
</section>
