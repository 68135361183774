import { IProductConfiguration } from '../IProductConfiguration';
import { webShopEligibility } from '../../../fai-widget/fai-webshop.interface';

export type IMsisdn = IPhoneNumber;

export interface IRio {
  phoneNumber: string;
  phoneNumberTemp: string;
  datePortage?: string;
  codeRio: string;
  reserved?: boolean;
  controlAddress?: string;
}

export interface ILogin {
  login: string;
  selected: boolean;
}

export interface IPlanConfiguration extends IProductConfiguration {
  msisdns?: IPhoneNumber[];
  rio?: IRio;
  logins?: ILogin[];
  portabilityType?: TypesPortability;
  isLoginReserved?: boolean;
  isVoipReserved?: boolean;
  eligTech?: webShopEligibility.EligeTech;
  eligTechTokyo?: webShopEligibility.EligTokyo;
  structureVerticale?: webShopEligibility.StructureVerticale;
  ptoRef?: string;
  equipedPto?: boolean;
  messageFraisPrevisionnels?: boolean;
  linkedSku?: string[];
  switchOc?: string;
  switchItemIdOc?: number;
  typeDegroupage?: string;
  typePortabilite?: 'MIGRATION_FLUIDE' | 'PORTABILITE_ENTRANTE';
  migrationFluide?: IMigration;
  checkCart?: ICheckCart;
  secondOffersIds?: { idOffre: string }[];
  // eslint-disable-next-line @typescript-eslint/naming-convention
  banking_offer?: boolean;
}

export enum MsiSdnComponentEnum {
  migrationFluide = 'MIGRATION_FLUIDE',
  portabiliteEntrante = 'PORTABILITE_ENTRANTE',
  soldeCreditSuperieur50 = 'SOLDE_CREDIT_SUPERIEUR_50€',
}

export interface ICheckCart {
  isOk: boolean;
  isByPass: boolean;
}

export interface IPortability {
  codeRio: string;
  noTelephoneAPorter: string;
  datePortage: string;
  typePortabilite: 'MIGRATION_FLUIDE' | 'PORTABILITE_ENTRANTE';
}

export interface IMigration {
  dateLimiteValidite2: string;
  soldeCreditPP: number;
}

export interface IPhoneNumber {
  id: string;
  dateFinValidite: Date;
  selected: boolean;
  reserved?: boolean;
}

export enum TypesLogin {
  select = 1,
  set = 2,
}

export enum TypesPortability {
  portability = 1,
  newNum = 2,
}
