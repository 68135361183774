export enum TypeParcoursEnums {
  RENOUVELLEMENT = 'RENOUVELLEMENT',
  ACQUISITION = 'ACQUISITION',
  ACQUISITIONFIX = 'ACQUISITIONFIX',
  CONTINUITE_DE_SERVICE = 'CONTINUITE_DE_SERVICE',
  REPLACE_SIM = 'REPLACE_SIM',
  SOUSCRIPTION_OFFRE = 'SOUSCRIPTION_OFFRE_ADDITIONNELLE',
  MIGRATIONFIX = 'MIGRATION',
  MOVEFIX = 'DEMENAGEMENT',
}

export enum ProductCatalog {
  bytel = 'BYTEL',
  partenaire = 'PARTENAIRE',
}

export class PromotionalEligibilityInputManualPromotionsDto {
  promotionsManuelles: number[]; // promotions id list
}

export class PromotionalEligibilityInputProductDto extends PromotionalEligibilityInputManualPromotionsDto {
  gencode: string;
  idVirtuel: number;
  rio: string;
  codePostalFaiInstallation: string;
  promotionsDemandees: PromotionInDemandInterface[];
  catalogue: ProductCatalog;
  ca?: boolean;
}

export interface PromotionInDemandInterface {
  type: string;
  valeur?: number;
  id?: string;
  typeRemise?: string;
  codeCoupon?: string;
}

export class PromotionalEligibilityInputSchemeDto extends PromotionalEligibilityInputManualPromotionsDto {
  idVirtuel: number;
  type: TypeParcoursEnums;
  produits: PromotionalEligibilityInputProductDto[];
}

export class PromotionalEligibilityInputCartDto extends PromotionalEligibilityInputManualPromotionsDto {
  parcours: PromotionalEligibilityInputSchemeDto[];
  coupons: string[];
}

export class PromotionalEligibilityInputProductToAddDto {
  ajouterDansLeParcours: number; // index of scheme to add the product
  gencode: string;
  rio?: string;
  codePostalFaiInstallation?: string;
  catalogue?: ProductCatalog;
}

export class PromotionalEligibilityInputDto {
  idPU: number;
  typeClient: string;
  idContract: number;
  panier: PromotionalEligibilityInputCartDto;
  produits: PromotionalEligibilityInputProductToAddDto[];
}
