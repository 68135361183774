import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { PricingProductsInterface } from '../pricing-products/pricing-products.interface';

@Injectable({
  providedIn: 'root',
})
export class PricingAccessoryService {
  public pricingAccessorySubject: BehaviorSubject<PricingProductsInterface[]> = new BehaviorSubject<
    PricingProductsInterface[]
  >(null);

  public sendMessage(message: PricingProductsInterface[]): void {
    this.pricingAccessorySubject.next(message);
  }

  public catchMessage(): Observable<PricingProductsInterface[]> {
    return this.pricingAccessorySubject.asObservable();
  }
}
