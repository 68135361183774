import { ConfigService } from '../../config.service';
import { Step } from '../../stepper/step.abstract';
import { Injectable } from '@angular/core';
import { CartService } from '../cart/cart.service';
import { SchemeHelper } from '../cart/scheme.helper';
import { Option } from '../../catalog/products/subscription/option';
import { UserService } from '../../user/user.service';

@Injectable({
  providedIn: 'root',
})
export class EquipementsStepService extends Step {
  public code = 'equipements';
  public path = '/panier/equipements';
  public label = 'Equipements';

  constructor(
    protected cartService: CartService,
    protected configService: ConfigService,
    private userService: UserService,
  ) {
    super();
  }

  get isAllowed(): boolean {
    const currentScheme = this.cartService.getCurrentScheme(false);
    if (!currentScheme || currentScheme.products.length === 0) {
      return false;
    }

    const isFai = currentScheme.isAcquisitionFix();
    const isSav = currentScheme.isBigtrustSav();
    const listGencodeBigTrust = this.configService.data?.listGencodeBigTrust || [];
    const hasBigTrust: boolean = currentScheme
      .getProductsByType(Option)
      .some(option => listGencodeBigTrust.find(x => x === option.data.gencode));
    const isPBO = this.userService.user.isPBO;
    const hasSimSav = SchemeHelper.hasSimSav(currentScheme);
    const hasFaimUnlimited = SchemeHelper.hasFaimUnlimited(currentScheme);

    return isSav || (isFai && (!isPBO || hasSimSav || hasFaimUnlimited || hasBigTrust));
  }
  set isAllowed(val: boolean) {
    return;
  }
}
