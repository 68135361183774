<div class="dialog-modal-container no-gutters is-tri">
    <div class="modal-header is-centered">
        <h1 class="title is-level-1">Client non éligible au prêt exceptionnel de clé 4G</h1>
    </div>
    <div class="modal-text">
        <p>
            <strong>Cause :</strong> {{ error }}
            <br><br>
            Souhaitez-vous continuer ?
        </p>
    </div>
    <br>
    <div class="modal-actions">
        <button class="button is-primary" (click)="closeModal()" data-cy="cancel-credit">Annuler</button>
        <button class="button is-info" (click)="validate()">Poursuivre</button>
    </div>

</div>
