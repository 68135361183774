import { Component, Input, OnInit } from '@angular/core';
import { Product } from '../../../../../catalog/products/product';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ModalRegularizationComponent } from './modal-regularisation/modal-regularization.component';
import { Scheme } from '../../../scheme.class';
import { ModalDiscountCaComponent } from './modal-discount-ca/modal-discount-ca.component';
import { UserService } from '../../../../../user/user.service';
import { RegularizationService } from '../../../../../promotions/regularizationService';

@Component({
  selector: 'rcbt-btn-regularization',
  templateUrl: './btn-regularization.component.html',
  styleUrls: ['./btn-regularization.component.scss'],
})
export class BtnRegularizationComponent implements OnInit {
  @Input() public product: Product;
  @Input() public scheme: Scheme;
  @Input() public loading: boolean;
  public canApplyDiscountCA: boolean;
  public canApplyRegularization: boolean;

  constructor(
    private modalService: NgbModal,
    private userService: UserService,
    public regularizationService: RegularizationService,
  ) {}

  public ngOnInit(): void {
    this.canApplyDiscountCA = this.userService.user.canApplyDiscountCA;
    this.canApplyRegularization = this.userService.user.canApplyRegularization;
  }

  public showProductRegularization(): void {
    const options: NgbModalOptions = <NgbModalOptions>{
      backdrop: 'static',
      size: 'lg',
      windowClass: 'modal-regularization',
      keyboard: false,
    };
    let modal;
    if (this.product.type_id === 'produit_ca') {
      modal = this.modalService.open(ModalDiscountCaComponent, options).componentInstance;
    } else {
      modal = this.modalService.open(ModalRegularizationComponent, options).componentInstance;
    }
    modal.product = this.product;
    modal.scheme = this.scheme;
  }
}
