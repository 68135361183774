import { Inject, Injectable } from '@angular/core';
import { BrowseConfig, BrowseConfigSerializedInterface } from './child/browse-config.class';
import { DateUniversal } from '../base/class/date-universal.class';
import { InterceptorsDirective } from '../base/directive/interceptors.directive';
import { LocationService } from '../base/services/location.service';
import { CheckoutStorageService } from '../checkout/checkout-storage.service';
import { ContextStorageService } from './context-storage.service';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class BrowseConfigService {
  get browseConfig(): BrowseConfig {
    return this._browseConfig;
  }

  public actionsTypes: string[] = ['IHMSD', 'IHMPRIXACCESSOIRE', 'ADMIN', 'ELIG', 'CUSTOM_WELCOME'];
  private _browseConfig: BrowseConfig;

  constructor(
    protected locationService: LocationService,
    protected checkoutStorageService: CheckoutStorageService,
    protected contextStorageService: ContextStorageService,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  public init(): void {
    this._browseConfig = new BrowseConfig();
    if (
      this.locationService.params['idPanierCommande'] ||
      this.actionsTypes.indexOf(this.locationService.params['typeAction']) !== -1
    ) {
      this.checkoutStorageService.clear();
      this._browseConfig.unserialize(<BrowseConfigSerializedInterface>this.locationService.params);
      if (this._browseConfig.dob) {
        const reformattedDob = DateUniversal.formatDob(this._browseConfig.dob);
        if (reformattedDob) {
          this._browseConfig.dob = reformattedDob;
        }
      }
      if (this._browseConfig.birthplace && this._browseConfig.birthplace.length === 1) {
        this._browseConfig.birthplace = '0' + this._browseConfig.birthplace;
      }
      if (this._browseConfig.debug) {
        new InterceptorsDirective(this.document).loadFiles();
      }
      this.contextStorageService.setItem(
        this.contextStorageService.key['browseConfig'],
        this._browseConfig.serialize(),
      );
    } else {
      this._browseConfig.unserialize(
        this.contextStorageService.getItem(this.contextStorageService.key['browseConfig']),
      );
    }
  }
}
