export enum Status {
  Actif = 'ACTIF',
  Terminated = 'RESILIE',
}

export enum PuType {
  Individu = 'Individu',
  Pro = 'Pro',
}

export interface Person {
  idPu: number;
  type: string;
  civility: string;
  firstname: string;
  name: string;
  email: string;
  birthDate: string;
  departmentOfBirth: string;
  address: Address;
  nbActiveLines: number;
  areAllContractsTerminated: boolean;
  paymentAccount: Payment;
  hasExistingContracts: boolean;
}

export interface Payment {
  iban?: string;
  bic?: string;
}

export interface Address {
  addressLinear: string;
  streetNumber: string;
  street: string;
  street2?: string;
  postalcode: string;
  city: string;
  country: string;
}

export class RCheckMail {
  domaine?: RCheckMailDomaine;
  emailContact?: boolean;
  emailGratuit?: boolean;
  emailTemporaire?: boolean;
  emailValide?: boolean;
  emailLogin?: boolean;
  syntaxeEmailCorrecte?: boolean;
  utilisateurGenerique?: boolean;
}

export class RCheckMailDomaine {
  domaineBbox?: boolean;
  domaineExistant?: boolean;
}

export interface SearchPersonsResult {
  personne: SearchPerson[];
}
export interface SearchPerson {
  _type: string;
  _actions: {
    modifierprospect: ModifierProspect;
  };
  comptesFacturation: ComptesFacturation[];
  idIdentite: string;
  idPersonneUnique: number;
  departementNaissance: string;
  dateNaissance: string;
  nom: string;
  prenom: string;
  login: string;
  email: string;
  civilite: string;
  representantLegal?: RepresentantLegal;
}

export interface RepresentantLegal {
  departementNaissance: string;
  dateNaissance: string;
  nom: string;
  prenom: string;
  login: string;
  email: string;
  civilite: string;
}

export interface ModifierProspect {
  action: string;
  type: string;
  method: string;
}

export interface ComptesFacturation {
  contratsPayes: ContratsPayes[];
  adresseDeclaree: adresseDeclaree;
  compteBancaire?: CompteBancaire;
  identifiantFonctionnelClient: string;
  nomBanque?: string;
  id?: number;
  codeClient?: string;
  ligneMarche?: string;
  modePaiement?: string;
  maskIban?: string;
  statut?: string;
  noCompte: string;
}

export interface adresseDeclaree {
  codePays: string;
  codePostal: string;
  numero: string;
  rue: string;
  ville: string;
}

export interface ContratsPayes {
  codeCauseChgtStatut: string;
  dateCreation: string;
  datePremiereActivation: string;
  estUtilisateur: boolean;
  idPfs: string;
  idTitulaire: string;
  ligneMarche: string;
  msisdn: string;
  noReference: string;
  offrePrincipale: OffrePrincipale;
  statut: string;
}

export interface OffrePrincipale {
  libelle: string;
  noOffre: string;
}
export interface CompteBancaire {
  iban?: string;
  bic?: string;
}
