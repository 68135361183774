import { ScanConfigInterface } from '../../../scan/interfaces/types';
import { ScanConfig } from '../../../scan/scan-config.class';
import { StockService } from '../../stock/stock.service';
import { HttpResponse as Response } from '@angular/common/http';
import { Observable } from 'rxjs';
import { webShopEligibility } from '../../../fai-widget/fai-webshop.interface';
import { IGenericDeviceConfiguration } from '../../../catalog/products/equipement/IGenericDeviceConfiguration';
import { ProductSerialized } from '../../../catalog/products/interface/configurable';
import { Equipement } from '../equipement';
import { CompatibilityMessages } from '../product';
import { BrowseType } from '../../../checkout/cart/browse-type';
import { AddResult, ResultAddMessage, TypeResult } from '../../../checkout/cart/cart.interfaces';
import { BasicObject } from '../../../base/base.interfaces';

export class Sim extends Equipement {
  public static prefix = '893320';
  public scanConfig: ScanConfigInterface = ScanConfig.sim;
  public deviceElement: webShopEligibility.DeviceLstElement;

  /**
   * Reserve product with schemeId
   * @param stockService
   * @param schemeUniqueId
   * @returns {Observable<Response>}
   */
  public reserve(stockService: StockService, schemeUniqueId: string): Observable<Response<BasicObject>> {
    return stockService.reserve(this.getScanCode(), this.scanConfig.code, schemeUniqueId);
  }

  /**
   *
   * @param stockService
   * @param schemeUniqueId
   * @returns {Observable<Response>}
   */
  public cancelReservation(stockService: StockService, schemeUniqueId: string): Observable<Response<BasicObject>> {
    return stockService.cancel(this.getScanCode(), this.scanConfig.code, schemeUniqueId);
  }

  /**
   * todo voir avec salma à quoi ça sert deviceElement et est ce qu'on peut supprimer cette méthode
   * @param data
   */
  public setConfiguration(data: IGenericDeviceConfiguration): void {
    this.setScanCode(data['scanCode'] || null);
    if ('prix' in data) {
      this.priceType = data['priceType'];
      this.price = data['prix'] as number;
    }
    this.deviceElement = data.element;
    if (data.element && data.element.imei) {
      this.setScanCode(data.element.imei);
    }
  }

  public getConfiguration(): IGenericDeviceConfiguration {
    return <IGenericDeviceConfiguration>{
      element: this.deviceElement,
      prix: this.price,
      priceType: this.priceType,
    };
  }

  public serialize(): ProductSerialized {
    return Object.assign(super.serialize(), this.getConfiguration());
  }

  public unserialise(productSerialized: ProductSerialized): void {
    super.unserialise(productSerialized);
    this.oldPrice = productSerialized.baseCost;
  }

  public isReservable(): boolean {
    return true;
  }

  public canAdd(browseType: BrowseType, isPrepaid: boolean, isFnb: boolean): AddResult {
    const result: AddResult = { status: true, productsConflict: [] };

    if (browseType === BrowseType.acquisitionFix && this.data.sim_model === 'bbox') {
      return result;
    }

    if (isFnb && this.data.sim_model === 'fnb') {
      return result;
    }

    result.status = false;
    result.addCheck = <ResultAddMessage>{ type: TypeResult.error, message: CompatibilityMessages.simTypeNotCompatible };

    return result;
  }

  public isQrEsim(): boolean {
    return ['qr_esim_replace', 'qr_esim'].includes(this.getData('type_id'));
  }
}
