import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PricingAccessoryComponent } from './pricing-accessory/pricing-accessory.component';
import { ContainerComponent } from '../container/container.component';
import { PricingUserService } from './pricing-user-service';
import { ContainerBootstrapComponent } from '../base/container-bootstrap/container-bootstrap.component';
import { contextResolver } from '../context/contextResolverFn';

const routes: Routes = [
  {
    path: '',
    component: ContainerComponent,
    children: [
      {
        path: '',
        component: ContainerBootstrapComponent,
        children: [
          {
            path: 'prix-accessoires',
            component: PricingAccessoryComponent,
            resolve: { context: contextResolver, userService: PricingUserService },
          },
        ],
      },
    ],
  },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class PricingRoutingModule {}
