import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseModule } from '../../../base/base.module';
import { ServicesComponent } from './services.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ServicesRoutingModule } from './services-routing.module';

@NgModule({
  imports: [CommonModule, BaseModule, ReactiveFormsModule, ServicesRoutingModule],
  declarations: [ServicesComponent],
  exports: [ServicesComponent],
})
export class ServicesModule {}
