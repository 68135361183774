import { IS_MOBILE, ODR_PRODUCT_TYPE } from './odr.config';
import { ContactType, Odr, OdrPostBody } from './odr.model';

export const buildBody = (odr: Odr, contact: string): OdrPostBody => ({
  ...buildContact(contact),
  ...buildBodyBase(odr),
});

export const formatPhoneNumber = (value: string): string =>
  IS_MOBILE.test(value) && !value.startsWith('+33') ? '+33' + value.slice(1) : value;

const buildContact = (contact: string): ContactType =>
  IS_MOBILE.test(contact) ? { msisdn: contact } : { email: contact };

const buildBodyBase = ({ amount, file, type }: Odr): OdrPostBody => ({
  odrValue: Number(amount),
  odrLink: file,
  odrProduct: type === 'phone_simple' ? ODR_PRODUCT_TYPE.phone : ODR_PRODUCT_TYPE.accessory,
});
