import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { PrismeLoggerService } from '../../shared/prisme/prisme-logger.service';
import { catchError, finalize } from 'rxjs/operators';
import { PrismeLogType } from '../../shared/prisme/prisme-log-type.enum';

@Injectable()
export class PrismeInterceptor implements HttpInterceptor {
  constructor(private prismeService: PrismeLoggerService) {}

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (
      !!request.url &&
      (request.url.match(/eligibilite\/[a-z0-9]*/) ||
        request.url.match(/entonnoir\/[a-z0-9]+/) ||
        request.url.indexOf('/creneaux-disponibles') !== -1)
    ) {
      // Because this request need this setting
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const authReq = request.clone({ setHeaders: { 'X-Source': 'portailvente_rcbt' } });
      return next.handle(authReq).pipe(
        finalize(() =>
          this.prismeService.sendDataToPrisme(PrismeLogType.customLog, {
            message: 'Intercepter la requête: ' + request.url,
          }),
        ),
      );
    }
    // To Log Http Error in prisme
    return next.handle(request).pipe(
      catchError(error => {
        this.prismeService.sendDataToPrisme(PrismeLogType.errorJs, {
          message: "Erreur Http avec l'url: " + request.url + ', Status :' + error.status,
        });

        return throwError(error);
      }),
    );
  }
}
