import { RulesRenew } from './rules-renew.class';
import { Product } from '../../../catalog/products/product';
import { Scheme } from '../scheme.class';

export class RulesRenewPremium extends RulesRenew {
  public key = 'renewPremium';

  public getConflictProject(scheme: Scheme, product: Product): Product[] {
    return super.getConflictProject(scheme, product);
  }
}
