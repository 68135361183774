import {
  IClickAndCollect,
  IRelay,
  IShippingAddress,
  IShippingConfiguration,
  IShippingMode,
} from './IShippingConfiguration';
import { ProductSerialized } from './interface/configurable';
import { Product } from './product';

export class Shipping extends Product {
  public firstname: string;

  public lastname: string;

  public civility: string;

  public streetNumber: string;

  public street: string;

  public street2: string;

  public postcode: string;

  public city: string;

  public country: string;

  public deliveryEstimatedDate: string;

  public deliveryDateEstimateAtTheLatest: string;

  public relayId: string;

  public relaySector: string;

  public relayLabel: string;

  public distributionSignCode: string;

  public distributionPointOfSale: string;

  public distributionName: string;

  public specific: boolean;

  public label?: string;

  public setConfiguration(data: IShippingConfiguration): this {
    super.setConfiguration(data);
    if (data.adresseLivraison) {
      this.firstname = data.adresseLivraison.prenom;
      this.lastname = data.adresseLivraison.nom;
      this.civility = data.adresseLivraison.civilite;
      this.streetNumber = data.adresseLivraison.numero;
      this.street = data.adresseLivraison.rue;
      this.street2 = data.adresseLivraison.complementAdresse;
      this.postcode = data.adresseLivraison.codePostal;
      this.city = data.adresseLivraison.ville;
      this.country = data.adresseLivraison.pays;
      this.specific = data.adresseLivraison.specific;
    }

    this.deliveryEstimatedDate = data.dateLivraisonEstimee;
    this.deliveryDateEstimateAtTheLatest = data.dateLivraisonEstimeeAuPlusTard;

    if (data.pointRelais) {
      this.relayId = data.pointRelais.codeIdentification;
      this.relaySector = data.pointRelais.codeSecteurLivraison;
      this.relayLabel = data.pointRelais.libelle;
    }

    if (data.entiteReseauDistribution) {
      this.distributionSignCode = data.entiteReseauDistribution.codeEnseigne;
      this.distributionPointOfSale = data.entiteReseauDistribution.codePointVente;
      if (data.entiteReseauDistribution.nom) {
        this.distributionName = data.entiteReseauDistribution.nom;
      }
    }
    if (data.methodeEnvoi) {
      this.price = data.methodeEnvoi.prix;
      this.gencode = data.methodeEnvoi.gencode;
      this.label = data.methodeEnvoi.libelle;
      this.deliveryEstimatedDate = data.methodeEnvoi.dateLivraisonEstimee;
      this.deliveryDateEstimateAtTheLatest = data.methodeEnvoi.dateLivraisonEstimeeAuPlusTard;
    }
    return this;
  }

  public getConfiguration(): IShippingConfiguration {
    const test: IShippingConfiguration = <IShippingConfiguration>{
      adresseLivraison: <IShippingAddress>{
        nom: this.firstname,
        prenom: this.lastname,
        civilite: this.civility,
        numero: this.streetNumber,
        rue: this.street,
        complementAdresse: this.street2,
        codePostal: this.postcode,
        ville: this.city,
        pays: this.country,
        specific: this.specific,
      },
      dateLivraisonEstimee: this.deliveryEstimatedDate,
      dateLivraisonEstimeeAuPlusTard: this.deliveryDateEstimateAtTheLatest,
      pointRelais: <IRelay>{
        codeIdentification: this.relayId,
        codeSecteurLivraison: this.relaySector,
        libelle: this.relayLabel,
      },
      entiteReseauDistribution: <IClickAndCollect>{
        codeEnseigne: this.distributionSignCode,
        codePointVente: this.distributionPointOfSale,
        nom: this.distributionName,
      },
      methodeEnvoi: <IShippingMode>{
        gencode: this.gencode,
        libelle: this.label,
        dateLivraisonEstimee: this.deliveryEstimatedDate,
        dateLivraisonEstimeeAuPlusTard: this.deliveryDateEstimateAtTheLatest,
        prix: this.price,
      },
    };
    return <IShippingConfiguration>Object.assign(test, super.getConfiguration());
  }

  public serialize(): ProductSerialized {
    return Object.assign(super.serialize(), this.getConfiguration());
  }
}
