import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { inject } from '@angular/core';
import { FaiEligService } from './fai-elig.service';
import { FaiEligibilityModel } from './fai.interfaces';
import { Observable } from 'rxjs';
import { FaiService } from './fai.service';

export const faiEligResolver: ResolveFn<void> = () => inject(FaiEligService).unserialize();

export const faiResolver: ResolveFn<Observable<FaiEligibilityModel>> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  const faiService = inject(FaiService);
  return faiService.resolve(state);
};

export const getInProgressCommandsResolver: ResolveFn<Observable<unknown>> = () => {
  const faiEligService = inject(FaiEligService);
  return faiEligService.getInProgressCommands();
};
