import { Phone } from './equipement/complex/phone';
import { AdditionalCatalogData, JsonProduct } from './interface/context';
import { Product } from './product';
import { Device } from './equipement/device';
import { DataSim } from './subscription/plan/dataSim';
import { Box } from './equipement/complex/phone/box';
import { AccessOnlineOrder } from './equipement/accessory/accessOnlineOrder';
import { Simo } from './subscription/plan/simo';
import { Sowo } from './subscription/plan/sowo';
import { Ideo } from './subscription/plan/premium/ideo';
import { Sensation } from './subscription/plan/premium/sensation';
import { Faim } from './subscription/plan/fai/faim';
import { FaimSensation } from './subscription/plan/premium/faim_sensation';
import { Accessory } from './equipement/accessory';
import { Option } from './subscription/option';
import { Insurance } from './subscription/insurance';
import { Sim } from './equipement/sim';
import { Esim } from './equipement/sim/esim';
import { QrEsim } from './equipement/sim/qr-esim';
import { SimCbt } from './equipement/sim/sim-cbt';
import { SimSav } from './equipement/sim/sim-sav';
import { SimReplace } from './equipement/sim/sim-replace';
import { EsimReplace } from './equipement/sim/esim-replace';
import { QrEsimReplace } from './equipement/sim/qr-esim-replace';
import { Shipping } from './shipping';
import { Service } from './service';
import { Avc } from './avc';
import { Fai } from './subscription/plan/fai/fai';
import { FaimUnlimited } from './subscription/plan/fai/faim-unlimited';
import { FaiService } from './equipement/fai/fai-service';
import { FaiZneService } from './equipement/fai/fai-zne-service';
import { FaiFmdService } from './equipement/fai/fai-fmd-service';
import { BoxFaiGenerique } from './subscription/box-fai-generique';
import { Crv } from './crv';
import { Prepaid } from './subscription/plan/prepaid';
import { MobileTakeBack } from './mobileTakeBack';
import { CaProduct } from './caProduct/caProduct';
import { InsurancePartner } from './subscription/insurance-partner';

/**
 * The Catalog class
 * See {@link Phone} to see how it is used
 */
export abstract class Catalog {
  public static accessOnlineGencode = '9999999200007';

  public static getInstance(product: JsonProduct, uniqueId: string = ''): Product {
    if (!product) {
      throw new Error('Impossible de créer un produit null');
    }
    if (!product.type_id) {
      throw new Error('cannot instanciate JsonProduct has not type_id');
    }
    product = JSON.parse(JSON.stringify(product));
    return this.getInstanceByType(product.type_id, product, uniqueId);
  }

  public static getInstanceByType<T extends Product>(
    type: string,
    product?: JsonProduct,
    uniqueId: string = '',
    additionalCatalogData?: AdditionalCatalogData,
  ): T {
    let p: Product;
    switch (type) {
      case 'DEVICE_FAI_GENERIC':
        p = new Device(product, uniqueId);
        break;
      case 'phone':
      case 'phone_simple':
        p = new Phone(product, uniqueId);
        break;
      case 'data_sim':
        p = new DataSim(product, uniqueId);
        break;
      case 'box':
        p = new Box(product, uniqueId);
        break;
      case 'access_online':
        p = new AccessOnlineOrder(product, uniqueId);
        break;
      case 'plan_sowo':
      case 'plan_sowo_renew':
        if (additionalCatalogData?.obligation && type === 'plan_sowo') {
          p = new Simo(product, uniqueId);
        } else {
          p = new Sowo(product, uniqueId);
        }
        break;
      case 'plan_ideo':
      case 'plan_ideo_renew':
      case 'plan_ideo_pro':
        p = new Ideo(product, uniqueId);
        break;
      case 'plan_premium':
      case 'plan_premium_renew':
        p = new Sensation(product, uniqueId);
        break;
      case 'faim':
        p = new Faim(product, uniqueId);
        break;
      case 'faim_premium':
        p = new FaimSensation(product, uniqueId);
        break;
      case 'accessory':
        p = new Accessory(product, uniqueId);
        break;
      case 'option':
      case 'bonus':
      case 'option_salable':
      case 'option_partner':
      case 'offer_partner':
      case 'grouped_offer':
        p = new Option(product, uniqueId);
        break;
      case 'insurance':
        p = new Insurance(product, uniqueId);
        break;
      case 'insurance_partner':
        p = new InsurancePartner(product, uniqueId);
        break;
      case 'sim':
        p = new Sim(product, uniqueId);
        break;
      case 'esim':
        p = new Esim(product, uniqueId);
        break;
      case 'qr_esim':
        p = new QrEsim(product, uniqueId);
        break;
      case 'sim_cbt':
        p = new SimCbt(product, uniqueId);
        break;
      case 'sim_sav':
        p = new SimSav(product, uniqueId);
        break;
      case 'sim_replace':
        p = new SimReplace(product, uniqueId);
        break;
      case 'esim_replace':
        p = new EsimReplace(product, uniqueId);
        break;
      case 'qr_esim_replace':
        p = new QrEsimReplace(product, uniqueId);
        break;
      case 'shipping':
        p = new Shipping(product, uniqueId);
        break;
      case 'service_assistance':
      case 'service_offer':
      case 'service_accompaniment':
        p = new Service(product, uniqueId);
        break;
      case 'avc':
        p = new Avc(product, uniqueId);
        break;
      case 'fai':
        p = new Fai(product, uniqueId);
        break;
      case 'faim_unlimited':
        p = new FaimUnlimited(product, uniqueId);
        break;
      case 'fai_service':
        p = new FaiService(product, uniqueId);
        break;
      case 'fai_zne':
        p = new FaiZneService(product, uniqueId);
        break;
      case 'fai_fmd':
        p = new FaiFmdService(product, uniqueId);
        break;
      case 'fai_box_generique':
        p = new BoxFaiGenerique(product, uniqueId);
        break;
      case 'crv':
        p = new Crv(product, uniqueId);
        break;
      case 'plan_prepaid':
        p = new Prepaid(product, uniqueId);
        break;
      case 'mobile-take-back':
        p = new MobileTakeBack(product, uniqueId);
        break;
      case 'produit_ca':
        p = new CaProduct(product, uniqueId);
        break;
      default:
        throw new Error(type + ' not valid type_id');
    }
    p.type_id = type;
    return <T>p;
  }

  /**
   * to get the category name of the json product
   * @param  {JsonProduct} product json product
   * @return {string}              telephones|accessoires...
   */
  public static getCategoryName(product: JsonProduct): string {
    switch (product.type_id) {
      case 'phone':
      case 'phone_simple':
        return 'telephones';
      case 'accessory':
        return 'accessoires';
      default:
        return 'defaut';
    }
  }
}
