import { Component, OnInit } from '@angular/core';
import { PricingAccessoryService } from './pricing-accessory.service';
import { PricingProductsInterface } from '../pricing-products/pricing-products.interface';
import { ProductsService } from '../../catalog/products.service';
import { JsonCatalog, JsonProduct } from '../../catalog/products/interface/context';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';
import { StoreStockService } from '../../catalog/stock/store-stock.service';
import { StockType } from '../../scan/interfaces/types';

@Component({
  selector: 'rcbt-pricing-accessory',
  templateUrl: './pricing-accessory.component.html',
  styleUrls: ['./pricing-accessory.component.scss'],
})
export class PricingAccessoryComponent implements OnInit {
  public products: PricingProductsInterface[] = [];
  public allproducts: PricingProductsInterface[] = [];
  public inStock: boolean;
  public searchChanged: Subject<string> = new Subject<string>();
  public accessorySearch = '';
  public inStockProductsKeys: StockType[];

  constructor(
    protected pricingAccessoryService: PricingAccessoryService,
    protected productsService: ProductsService,
    protected stockService: StoreStockService,
  ) {}

  public ngOnInit(): void {
    this.inStock = true; // par défault le toggle En Stock est activé
    this.stockService.getStoreStock(['ACCESSOIRE']).subscribe(result => {
      this.inStockProductsKeys = result['ACCESSOIRE'];
    });
    this.pricingAccessoryService.catchMessage().subscribe((data: PricingProductsInterface[]) => {
      if (data) {
        this.products = data;
        this.allproducts = this.products;
        this.updateStock();
      }
    });
    this.searchChanged.pipe(debounceTime(500), distinctUntilChanged()).subscribe(model => {
      this.accessorySearch = model;
      this.search();
    });
  }

  public onSearchChanges(query: string): void {
    this.searchChanged.next(query);
  }

  public search(): Subscription {
    this.products = [];
    if (!this.accessorySearch.length) {
      return;
    }
    return this.productsService.getProductsByCategories(['accessoires_rcbt']).subscribe((products: JsonCatalog) => {
      const regex = new RegExp(this.accessorySearch, 'i');
      for (const gencode in products) {
        if (regex.test(products[gencode].name) || regex.test(products[gencode].manufacturer) || regex.test(gencode)) {
          const stockProduct: StockType = this.inStockProductsKeys.find(
            (stock: StockType) => stock.gencode === gencode,
          );
          const quantity: number = stockProduct ? stockProduct.quantity : 0;
          this.products.push(this.toPricingProduct(products[gencode], quantity));
        }
      }
      this.sortByName();
      this.allproducts = this.products;
      this.updateStock();
    });
  }

  public updateStock(): void {
    if (this.inStock) {
      this.products = this.products.filter((product: PricingProductsInterface) => product.quantity > 0);
    } else {
      this.products = this.allproducts;
    }
  }

  private toPricingProduct(product: JsonProduct, quantity: number): PricingProductsInterface {
    return {
      name: product.name,
      thumbnail: product.name,
      price: product.price,
      oldPrice: product.price,
      quantity: quantity,
      gencode: product.gencode,
    };
  }

  private sortByName(): void {
    this.products.sort((accessoryA: PricingProductsInterface, accessoryB: PricingProductsInterface) => {
      if (accessoryA.name < accessoryB.name) {
        return -1;
      }
      if (accessoryA.name > accessoryB.name) {
        return 1;
      }
      return 0;
    });
  }
}
