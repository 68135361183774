import { Component, Input } from '@angular/core';

@Component({
  selector: 'rcbt-bonus-reprise',
  templateUrl: './bonus-reprise.component.html',
  styleUrls: ['./bonus-reprise.component.scss'],
})
export class BonusRepriseComponent {
  @Input() public readonly mobileTakebackBonusAmount: number;
  @Input() public readonly etiquetteAnimCo: string;
}
