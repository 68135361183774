import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseModule } from '../../../base/base.module';
import { FormsModule } from '@angular/forms';
import { StockChoiceComponent } from './modal-stock-choice.component';

@NgModule({
  imports: [CommonModule, BaseModule, FormsModule],
  declarations: [StockChoiceComponent],
  exports: [StockChoiceComponent],
})
export class StockChoiceModule {}
