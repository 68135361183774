import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InfiniteScrollComponent } from './product-list/infinite-scroll/infinite-scroll.component';
import { ViewModule } from './view/view.module';
import { CategoryRoutingModule } from './category-routing.module';
import { FilterModule } from './product-list/filter/filter.module';
import { BaseModule } from '../../base/base.module';
import { CategoryPlanComponent } from './categoryPlan/category-plan.component';
import { ProductListComponent } from './product-list/product-list.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { PartnerModule } from '../../partner/partner.module';
import { NavBarComponent } from './navBar/navBar.component';
import { CrvModule } from './crv/crv.module';
import { ReplaceSimModule } from './replaceSim/replace-sim.module';
import { ServicesModule } from './services/services.module';
import { AccessoriesModule } from './accessories/accessories.module';
import { ProductItemComponent } from './product-item/product-item.component';
import { LoaderModule } from '../../loader/loader.module';
import { CategoryComponent } from './category.component';
import { ComparatorModule } from '../../comparator/comparator.module';
import { CrossSellComponent } from './cross-sell/cross-sell.component';

@NgModule({
  imports: [
    CommonModule,
    CategoryRoutingModule,
    ViewModule,
    FilterModule,
    FormsModule,
    ReactiveFormsModule,
    BaseModule,
    LazyLoadImageModule,
    PartnerModule,
    ReplaceSimModule,
    ServicesModule,
    CrvModule,
    AccessoriesModule,
    ComparatorModule,
    LoaderModule,
  ],
  exports: [CategoryPlanComponent],
  declarations: [
    CategoryComponent,
    CategoryPlanComponent,
    ProductListComponent,
    NavBarComponent,
    ProductItemComponent,
    InfiniteScrollComponent,
    CrossSellComponent,
  ],
})
export class CategoryModule {}
