import { Injectable } from '@angular/core';
import { Oauth2RessourceService } from '../../oauth2/oauth2-resources.service';
import { ProductsService } from '../../catalog/products.service';
import { forkJoin, Observable, of, of as observableOf, throwError } from 'rxjs';
import { catchError, map, mergeMap, share } from 'rxjs/operators';
import { RenewScheme } from './renew-scheme';
import { DateUniversal } from '../../base/class/date-universal.class';
import { RenewContextSerializedInterface } from '../../context/child/renew-context-serialized.interface';
import { BasicObject } from '../../base/base.interfaces';

@Injectable({
  providedIn: 'root',
})
export class RenewService {
  constructor(
    private oauth2Ressource: Oauth2RessourceService,
    private productsService: ProductsService,
  ) {}

  public getRenewData(scheme: RenewScheme): Observable<this> {
    return forkJoin([
      this.oauth2Ressource.contracts(scheme.contractId).get(),
      this.oauth2Ressource
        .contracts(scheme.contractId)
        .eligibilitesRenouvellement()
        .get()
        .pipe(catchError(error => observableOf(error))),
      this.oauth2Ressource.contracts(scheme.contractId).abonnement().get(),
    ]).pipe(
      mergeMap((responses: BasicObject[]) => {
        const error = 'Offre actuelle inconnue ou problème de chargement de catalog';
        return this.productsService.getJsonProductsByFilter({ listGencode: responses[0].abonnement.idOffre }).pipe(
          catchError(() => throwError([error])),
          map(jsonCatalogResult => {
            const jsonProduct = jsonCatalogResult[responses[0].abonnement.idOffre];
            if (!jsonProduct) {
              throw new Error(error);
            }
            responses.push(jsonProduct);
            return responses;
          }),
        );
      }),
      mergeMap((responses: BasicObject[]) => {
        const errors: string[] = [];
        const contract = responses[0];
        const eligibilitesRenouvellement: BasicObject = responses[1].items ? responses[1].items[0] : { statut: false };
        const abonnement = responses[2];
        const offre = responses[3];

        const isSowo = offre && offre.type_id && offre.type_id.indexOf('plan_sowo') !== -1;

        scheme.status = eligibilitesRenouvellement.statut;
        scheme.phoneSeniority = eligibilitesRenouvellement.ancienneteTerminal || 0;
        scheme.idCt = contract.idCt;
        scheme.planType = offre ? offre.type_id.replace('_renew', '') : null;
        scheme.migrateTo = scheme.planType.replace('plan_sowo', 'plan_premium');
        scheme.renewType = null;
        scheme.gencodeOffre = offre.gencode;
        scheme.codeTac = null;
        scheme.collaborator = contract.contratCollaborateur === true;
        scheme.multiLigne = true;
        scheme.amountPaid = abonnement.montantPaye;
        scheme.insurance = true;
        scheme.hasRobbLossRestriction =
          contract.contexteContrat &&
          contract.contexteContrat.information &&
          contract.contexteContrat.information.some((info: string) => info === 'PERTE_ET_VOL');

        if (!scheme.status) {
          errors.push('Client non éligible au renouvellement');
          if (
            eligibilitesRenouvellement.messages &&
            eligibilitesRenouvellement.messages[0].code === 'COMMANDE_EN_COURS'
          ) {
            errors.push('Commande en cours');
          } else if (eligibilitesRenouvellement.dateEligibilitePrevisionnelleRenouvellement) {
            errors.push(
              RenewScheme.prevDateErrorMessagPrefix +
                eligibilitesRenouvellement.dateEligibilitePrevisionnelleRenouvellement,
            );
          }
        }

        scheme.renewType = this.getRenewType(eligibilitesRenouvellement, isSowo);
        if (errors.length > 0) {
          return throwError(errors);
        }
        return of(this);
      }),
      share(),
    );
  }

  private getRenewType(eligibilitesRenouvellement: BasicObject, isSowo: boolean): string {
    if (new Date() > new DateUniversal(eligibilitesRenouvellement.dateTarifPreferentiel).value || isSowo) {
      return RenewScheme.renewTypes.month24.label;
    }
    if (new Date() > new DateUniversal(eligibilitesRenouvellement.dateTarifPreferentielIntermediaire).value) {
      return RenewScheme.renewTypes.month18.label;
    }
    return RenewScheme.renewTypes.month12.label;
  }

  public serialize(scheme: RenewScheme, personId): RenewContextSerializedInterface {
    return {
      status: scheme.status,
      idCt: scheme.idCt,
      contractId: scheme.contractId,
      idPersonne: personId,
      planType: scheme.planType,
      migrateTo: scheme.migrateTo,
      type: scheme.renewType,
      gencodeOffre: scheme.gencodeOffre,
      codeTac: scheme.codeTac,
      collaborator: scheme.collaborator,
      amountPaid: scheme.amountPaid,
      insurance: scheme.insurance,
      multiLigne: scheme.multiLigne,
      client: scheme.getClientType(),
      phoneSeniority: scheme.phoneSeniority,
      idOffers: scheme.idOffers,
    };
  }
}
