import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { PrismeLogType } from '../prisme/prisme-log-type.enum';
import { PrismeLoggerService } from '../prisme/prisme-logger.service';

@Injectable()
export class GlobalErrorhandler implements ErrorHandler {
  constructor(private injector: Injector) {}

  public handleError(error): void {
    const prismeLogger = this.injector.get(PrismeLoggerService);
    prismeLogger.sendDataToPrisme(PrismeLogType.errorJs, error);
    // to retrace error on console
    throw error;
  }
}
