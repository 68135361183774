<div class="is-tri">
    <div id="rcbt-scan-container" class="rcbt-scan-container" *ngIf="loadScan">
        <div class="close-btn">
            <button class="button is-warning" aria-label="Close" (click)="close()">
                <span class="icon is-medium"><i class="tri-times" aria-hidden='true'></i></span>
            </button>
        </div>

        <div class="scan-container">
            <div #scanContainer id="scan-container" class="scanContainer">
                <scandit-sdk-barcode-picker #picker
                                            [camera]="activeCamera"
                                            [scanSettings]="settings"
                                            [playSoundOnScan]="false"
                                            [accessCamera]="true"
                                            [guiStyle]="'viewfinder'"
                                            (scan)="onScan($event)"
                                            (error)="onError($event)"></scandit-sdk-barcode-picker>
            </div>
            <div id="scanCodes" class="scanCodes" #scanCodeList>
                <ng-container *ngFor="let code of scanCodes">
                    <div class="scancode">
                        <button class="button is-info" (click)="chooseScannedCode(code)">{{ code }}</button>
                    </div>
                </ng-container>
            </div>
        </div>

        <form class="scan-bar-wraper" #scanForm="ngForm" (ngSubmit)="onSubmit()">
            <div class="scan-input field">
                <div class="control has-dynamic-placeholder">
                    <input scan role="textbox" type="tel" data-cy="scan-code-input-tablet"
                        class="input is-default"
                        name="scanCode"
                        placeholder="Code produit"
                        [(ngModel)]="scanCode"
                        [pattern]="codeBarPattern"
                        required>
                    <label>Code produit</label>
                </div>
                <button #validScan id="valid_scan" [disabled]="!scanForm.form.valid" type="submit" class="button is-primary" data-cy="scan-code-ok-tablet">OK</button>
            </div>

            <div class="switch">
                <input type="checkbox" id="multiScanMode" [(ngModel)]="multiScan" ngModel>
                <label for="multiScanMode" class="is-size-5">Mode Multiscan</label>
            </div>

            <div *ngIf="field === 'imei-'">
                <button id="multi_scan" [disabled]="scanCodes.length <= 0" (click)="sendAllScannedCodes()" class="button is-primary">Scan Terminé</button>
            </div>
        </form>
    </div>
</div>
