import { IPandoraDocumentSerialize } from './documents/IPandoraDocumentSerialize';
import { PriceTypes } from '../catalog/products/interface/price-type.enum';

export namespace CheckoutNs {
  export interface IPayment {
    quoteId?: number;
    cartId?: number;
    payerAccount?: number;
    customerCode?: string;
    iban?: string;
    bic?: string;
    rum?: string;
    paymentMode?: PaymentMode;
    paymentModeFMS?: PriceTypes;
    proofOfPayment?: string;
    maskIban?: string;
    type?: string;
    action?: string;
    document?: IPandoraDocumentSerialize;
    ligneMarche?: string;
  }

  export enum PaymentMode {
    prelevement = 'PRELEVEMENT',
    cbOuCheque = 'CB_OU_CHEQUE',
  }

  export enum LigneMarche {
    fnb = 'FNB',
    fai = 'FAI',
  }

  export interface IDocument {
    id: number;
    type?: string;
  }

  export interface ICompany {
    registrationNumber?: string;
    creationDate?: string;
    name?: string;
    screening?: IScreening;
  }

  export interface IScreening {
    workforceCode?: string; // code effectif
    prenom?: string;
    nom?: string;
    civilite?: string;
    situation: string;
  }

  export enum PaymentAction {
    rattachementNouveauCompte = 'RATTACHEMENT_NOUVEAU_COMPTE',
    rattachementAncienCompte = 'RATTACHEMENT_ANCIEN_COMPTE',
    creation = 'CREATION',
  }

  export interface IResponseIbanAttach {
    message: string;
  }

  export interface ImaskIban {
    mask: string;
    iban: string;
  }
}
