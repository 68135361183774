import { Directive, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { ScanService } from './scan.service';
import { ScanditService } from '../scandit/scandit.service';

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: '[scan]' })
export class ScanDirective implements AfterViewInit, OnDestroy {
  public subscriptions = [];

  /**
   * Creates an instance of ScanDirective.
   * @param {ElementRef} el
   * @param {Renderer} renderer
   * @param {ScanService} scanService
   * @param {CometeService} cometeService
   * @memberof ScanDirective
   */
  constructor(
    private el: ElementRef,
    private scanService: ScanService,
    private scanditService: ScanditService,
  ) {}
  /**
   * [ngAfterViewInit description]
   * @return {[type]} [description]
   */
  public ngAfterViewInit(): void {
    this.subscriptions.push(
      this.scanService.onBeginScan.subscribe((data: boolean) => {
        if (this.scanditService.isOnTablet()) {
          return;
        }
        this.el.nativeElement.focus();
      }),
    );

    this.subscriptions.push(
      this.scanService.onEndScan.subscribe((data: boolean) => (this.el.nativeElement.value = '')),
    );
  }

  public ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }
}
