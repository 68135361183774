<div class="voip-component">
    <div class="voip-header">
        <span class="icon is-large"><i class="tri-hand-mobile" aria-hidden="true"></i></span>
        <h2 class="title is-size-3">Je conserve mon numéro</h2>
    </div>

    <div *ngIf="faiService.reservedVoip || plan.rio;else elseBlock">
        <span class="text is-size-5">Un numéro est déjà réservé : {{faiService.reservedVoip ?? plan.rio.phoneNumber}}</span>
    </div>
    <ng-template #elseBlock>
        <div class="voip-choice">
        <div class="main-buttons">
            <button
                data-cy="btn-porta-yes"
                class="button is-info"
                [ngClass]="{'is-outlined': type !== typesPortability.portability}"
                (click)="togglePortabiltyType(typesPortability.portability)"
            >
                OUI
            </button>
            <button
                data-cy="btn-porta-no"
                class="button is-info"
                [ngClass]="{'is-outlined': type !== typesPortability.newNum}"
                (click)="togglePortabiltyType(typesPortability.newNum)"
            >
                NON
            </button>
        </div>

        <div class="voip-wide alert has-body is-info">
            <span class="icon"><i class="tri-infos-circle" aria-hidden="true"></i></span>
            <div class="body">
                <p class="text hidden"></p>
                <p class="text is-level-3">
                    Pensez à proposer la portabilité du numéro fixe !<br>
                    Bouygues Telecom se charge de résilier le contrat Internet du client auprès de son ancien opérateur.
                </p>
            </div>
        </div>

        <div *ngIf="type === typesPortability.newNum">
            <h3 class="num-choice-label">Choix du n°</h3>
            <weto-voip #widgetWetoVoip manual-render="true"></weto-voip>
        </div>

        <div class="voip-wide" *ngIf="type === typesPortability.portability">
            <weto-portability #widgetWetoPortability manual-render="true"></weto-portability>
            <p>Appeler le 3179 pour connaître votre n° de RIO </p>
        </div>

        <ng-container *ngIf="errors.size > 0">
            <div class="alert has-body is-error" *ngFor="let error of errors">
                <span class="icon"><i class="tri-exclamation-circle" aria-hidden="true"></i></span>
                <div class="body">
                    <p class="text">{{error}}</p>
                </div>
            </div>
        </ng-container>

    </div>
    </ng-template>
</div>
