/**
 * Created by fruysshaert on 11/07/2017.
 */
import { Rules } from './rules.class';
import { Product } from '../../../catalog/products/product';
import { Scheme } from '../scheme.class';
import { Phone } from '../../../catalog/products/equipement/complex/phone';
import { Plan } from '../../../catalog/products/subscription/plan';

export class RulesRenew extends Rules {
  public key = 'renewPremium';

  public getConflictProject(scheme: Scheme, product: Product): Product[] {
    return super.getConflictProject(scheme, product);
  }

  public isValid(): string[] {
    const errors: string[] = [];
    if (!this.scheme.getProductsByType(Phone).length) {
      errors.push('Vous devez avoir un terminal au panier, veuillez scanner un terminal');
    }
    if (!this.scheme.getProductsByType(Plan).length) {
      errors.push('Vous devez avoir un forfait au panier, veuillez scanner un terminal');
    }
    return errors;
  }
}
