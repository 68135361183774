import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseModule } from '../../../base/base.module';
import { FormsModule } from '@angular/forms';
import { ModalProductUnreservationComponent } from './modal-product-unreservation.component';

@NgModule({
  imports: [CommonModule, BaseModule, FormsModule],
  declarations: [ModalProductUnreservationComponent],
  exports: [ModalProductUnreservationComponent],
})
export class ProductUnreservationModule {}
