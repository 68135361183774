import { Subscription } from '../subscription';
import { ProductSerialized } from '../interface/configurable';
import { JsonPhoneProduct } from '../interface/context';

export class InsurancePartner extends Subscription {
  public static tmpId = 'TEMP';

  public idContratAssureur: string;
  public idDemandePartenaire: string;

  public setJsonData(data?: JsonPhoneProduct): this {
    this.idContratAssureur = data.idContratAssureur;
    this.idDemandePartenaire = data.idDemandePartenaire;
    return super.setJsonData(data);
  }

  public serialize(): ProductSerialized {
    const serializedProduct = super.serialize();
    serializedProduct.idContratAssureur = this.idContratAssureur;
    serializedProduct.idDemandePartenaire = this.idDemandePartenaire;
    return serializedProduct;
  }
}
