import { ContextService } from '../context/context.service';
import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

export const contextResolver: ResolveFn<Observable<void>> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  const contextService = inject(ContextService);
  return contextService.init(route, state);
};
