<div class="is-tri">
  <section class="accordions accordions-modif spaced-header" data-accordion-initialized="true">
  <article class="accordion accordion-modif" id="accessoire" data-collapsed="0" data-expanded="192"  [ngClass]="{'is-active': activeAccordions['accessoires']}">

      <div class="accordion-header" (click)="toggleAccordion('accessoires')">
        <div class="title is-size-1" data-cy="title-address">Accessoires</div>
        <button class="toggle" aria-label="toggle"></button>
      </div>

    <div data-accordion-body="true" class="accordion-body is-clipped">
      <div class="accordion-content">
        <rcbt-category-accessories></rcbt-category-accessories>
      </div>
    </div>
  </article>
  <article class="accordion accordion-modif" id="assurance" data-collapsed="57" data-expanded="NaN"  [ngClass]="{'is-active': activeAccordions['assurances']}">
    <div class="accordion-header" (click)="toggleAccordion('assurances')">
      <div class="title is-size-1" data-cy="title-address">Assurances</div>
      <button class="toggle" aria-label="toggle"></button>
    </div>

    <div data-accordion-body="true"  class="accordion-body is-clipped">
      <div class="accordion-content">
        <rcbt-partner-insurance-list [showHeader]="true"></rcbt-partner-insurance-list>
      </div>
    </div>
  </article>
  <article  class="accordion accordion-modif" id="service" data-collapsed="57" data-expanded="NaN"  [ngClass]="{'is-active': activeAccordions['services']}">
    <div class="accordion-header" (click)="toggleAccordion('services')">
      <div class="title is-size-1" data-cy="title-address">Services</div>
      <button class="toggle" aria-label="toggle"></button>
    </div>
    <div data-accordion-body="true" class="accordion-body is-clipped">
      <div class="accordion-content">
        <rcbt-category-services  [showHeader]="true"></rcbt-category-services>
      </div>
    </div>
  </article>
  <article class="accordion accordion-modif" id="crv" data-collapsed="57" data-expanded="NaN"  [ngClass]="{'is-active': activeAccordions['crv']}">
    <div class="accordion-header" (click)="toggleAccordion('crv')">
      <div class="title is-size-1" data-cy="title-address">Crv</div>
      <button class="toggle" aria-label="toggle"></button>
    </div>
    <div data-accordion-body="true" data-id="body-QUATRE" class="accordion-body is-clipped">
      <div class="accordion-content">
        <rcbt-category-crv></rcbt-category-crv>
      </div>
    </div>
  </article>
</section>
</div>
