import { Scheme } from './scheme.class';
import { BrowseType } from './browse-type';
import { SchemeSerialized } from './scheme.interface';
import { FaiEligtech } from '../../fai-widget/fai.interfaces';

export class FaiScheme extends Scheme {
  public browseType = BrowseType.acquisitionFix;
  public faiEligTech: FaiEligtech;
  public faiEligId: string;
  public installationPostalCode: string;

  public unserializeSpecificData(schemeSerialized: SchemeSerialized): void {
    this.contractId = schemeSerialized.contextProduit.contextService.acquisitionFix.contractId;
    this.faiEligTech = schemeSerialized.contextProduit.contextService.acquisitionFix.faiEligTech;
    this.faiEligId = schemeSerialized.contextProduit.contextService.acquisitionFix.idEligFai;
    this.installationPostalCode = schemeSerialized.contextProduit.contextService.acquisitionFix.installationCodePostal;
  }
}
