<div>
    <div *ngIf="isRenew && product.plan_bonus && !product.opSource">
        <div>
            Bonus acquis :
            <span>{{product.plan_bonus}}</span>
        </div>
    </div>

    <!-- remises auxquels le client souscrit avec cette offre -->
    <div class="options-feedback" [ngClass]="{'default': !isRenew, 'success': isRenew}" *ngIf="osSecond.length || (product.opSource && product.opSource.osDetenues) || appliedPromotions.length">
        <h4 class="feedback-title">Vos Remises <span *ngIf="isRenew" class="badge">New</span></h4>
        <ul class="option-feedback-items">
            <li *ngFor="let promo of promosToDisplay" class="option-feedback-item">
                {{ promo.name }}
                <span *ngIf="promo.price !== undefined" class="is-price has-text-tertiary">{{ promo.price }}<sup>€</sup></span>
                &nbsp;{{ promo.strAppliedDate }}
                &nbsp;{{ promo.strTimeLength }}
            </li>
        </ul>
    </div>

</div>
