import { Injectable } from '@angular/core';
import { StringObject } from '../base.interfaces';

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  private _params: StringObject;

  get params(): StringObject {
    if (window.location.toString().indexOf('?') !== -1) {
      this._params = this.extractParam(window.location.toString().split('?')[1]);
    } else if (window.location.toString().indexOf('#') !== -1) {
      this._params = this.extractParam(window.location.toString().split('#')[1]);
    } else {
      this._params = {};
    }
    return this._params;
  }
  set params(value: StringObject) {
    this._params = value;
  }

  /**
   * Remove '#/'
   * Extract param from oauth hash
   * Remove + from param values
   */
  private extractParam(hash: string): StringObject {
    const tab: string[] = hash.split('&');
    const obj: StringObject = {};
    for (let i = 0; i < tab.length; i++) {
      tab[i] = tab[i].replace('?', '');
      const keyValue: string[] = tab[i].split('=');
      obj[keyValue[0]] = decodeURIComponent(keyValue[1].replace(/\+/g, ' '));
    }
    return obj;
  }
}
