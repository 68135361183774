export class Oauth2IframeClass {
  public static injectIframe(src: string): HTMLIFrameElement {
    const iframe: HTMLIFrameElement = document.createElement('iframe');

    iframe.src = src;
    // sandox : read-only
    // iframe.sandbox = 'allow-scripts allow-same-origin';
    iframe.scrolling = 'no';
    iframe.style.display = 'none';

    document.querySelector('body').appendChild(iframe);

    return <HTMLIFrameElement>iframe;
  }

  public static removeEl(node: Node): void {
    document.querySelector('body').removeChild(node);
  }
}
