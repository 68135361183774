import { InsurancePartnerCodeEnum } from '../partner.dto';
import { JsonProduct } from '../../catalog/products/interface/context';

export class InsurancePartner {
  code: string;
  eligible: boolean;
  added: boolean;
  subscribed: boolean;
  imei: string;
  gencode: string;
}

export class Spb extends InsurancePartner {
  code: InsurancePartnerCodeEnum = InsurancePartnerCodeEnum.spb;
  subscribedGe24;
}

export class Medicis extends InsurancePartner {
  code: InsurancePartnerCodeEnum = InsurancePartnerCodeEnum.medicis;
}

export class Medi7 extends InsurancePartner {
  code: InsurancePartnerCodeEnum = InsurancePartnerCodeEnum.medi7;
  offers: { [gencode: string]: Medi7Offer } = {};
}

export interface Medi7Offer {
  gencode: string;
  eligible?: boolean;
  added?: boolean;
  disabled?: boolean;
  subscribed?: boolean;
  product?: JsonProduct;
}
