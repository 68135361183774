<section class="accordions" >
    <article class="accordion is-active">
        <div class="accordion-header is-flex">
            <h4 class="is-marginless is-uppercase">Financement panier à crédit</h4>
            <div class="actions switch" *ngIf="loanDatas">
                <input type="checkbox" id="switch-credit" data-cy="funding-switch-toggle-input" [(ngModel)]="isCreditActive" (click)="onToggleCredit()">
                <label class="switch-credit-label action" for="switch-credit" data-cy="funding-switch-toggle-label"></label>
            </div>
            <button class="toggle toggle-modif is-marginless" [ngClass]="{'rotate': !displayPanel}" aria-label="toggle" (click)="displayPanel = !displayPanel" *ngIf="loanDatas"></button>
        </div>
        <div class="accordion-body accordion-body-modif has-background-white">
            <div class="accordion-content" *ngIf="loanDatas">
                <table class="table is-fullwidth is-marginless">
                    <thead data-cy="funding-thead">
                        <tr class="table-title">
                            <th class="name-column">
                                <div class="is-fullwidth is-flex is-spaced-evenly">
                                    <div class="radio radio-modif" *ngFor="let loan of loanDatas; let i = index;">
                                        <input
                                            [ngClass]="{'active': loan.selected, 'disabled': !isCreditActive}"
                                            type="radio"
                                            [id]="'radio-loan-'+loan.nbMonth"
                                            name="loan"
                                            [checked]="loan.selected"
                                            [disabled]="!isCreditActive"
                                            (click)="onSelectLoan(loan.nbMonth)"
                                        >
                                        <label
                                            class="radio-label radio-label-modif"
                                            [for]="'radio-loan-'+loan.nbMonth"
                                            [tabIndex]="i"
                                        >{{ loan.nbMonth }} MOIS</label>
                                    </div>
                                </div>
                            </th>
                            <th class="has-text-centered is-uppercase">Aujourd'hui</th>
                            <th class="has-text-centered is-uppercase">Par mois</th>
                            <th class="has-text-centered is-uppercase">Sur facture</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="displayPanel" data-cy="funding-tbody">
                        <tr *ngFor="let loan of loanDatas; let i = index" >
                            <td class="cell-loan-data has-text-centered has-text-weight-bold" colspan="12"
                                [ngClass]="loan.selected && isCreditActive ? 'selected' : 'unselected'">
                                <i><span [outerHTML]="loan.message"></span></i>
                            </td>
                        </tr>
                        <ng-container *ngIf="isCreditActive">
                            <tr class="AccessOnlineHeader">
                                <td class="name-column">Total panier</td>
                                <td><rcbt-price [price]="totalCart" [fontLevel]="6" [isSecondaryNegatif]="true"></rcbt-price></td>
                                <td></td>
                                <td></td>
                            </tr>
    
                            <tr class="AccessOnlineHeader">
                                <td class="name-column">Montant financé</td>
                                <td><rcbt-price [price]="-amountToFund" [fontLevel]="6" [isSecondaryNegatif]="true"></rcbt-price></td>
                                <td></td>
                                <td></td>
                            </tr>
    
                            <tr class="AccessOnlineHeader">
                                <td class="name-column">Mensualité</td>
                                <td></td>
                                <td><rcbt-price [price]="monthlyToFund" [fontLevel]="6" [isSecondaryNegatif]="true"></rcbt-price></td>
                                <td></td>
                            </tr>
                        </ng-container>
                    </tbody>
                    <tfoot *ngIf="isCreditActive" data-cy="funding-tfoot">
                        <tr>
                            <td class="name-column has-text-weight-bold">FINANCEMENT PANIER* (à partir de)</td>
                            <td><rcbt-price [price]="payToday" [fontLevel]="5" [isSecondaryNegatif]="true"></rcbt-price></td>
                            <td><rcbt-price [price]="monthlyToFund" [fontLevel]="5" [isSecondaryNegatif]="true"></rcbt-price></td>
                            <td><rcbt-price [price]="0" [fontLevel]="5" [isSecondaryNegatif]="true"></rcbt-price></td>
                        </tr>
                        <tr>
                            <td class="is-size-8 nota-bene" colspan="12">
                                <i>*Un credit vous engage et doit être remboursé, vérifiez vos capacités de remboursement avant de vous engager.</i>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
            <div class="accordion-content" *ngIf="!loanDatas">
                <div class="is-flex is-fullwidth is-justified-center is-aligned-center">
                    <p class="is-size-5 has-text-weight-semibold">Panier non finançable à crédit</p>
                </div>
            </div>
        </div>
    </article>
</section>
