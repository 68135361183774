import { NgModule } from '@angular/core';
import { ContainerComponent } from './container.component';
import { RouterModule } from '@angular/router';
import { LoaderModule } from '../loader/loader.module';
import { TopBarModule } from '../topBar/topBar.module';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  imports: [CommonModule, TopBarModule, LoaderModule, RouterModule, NgbModule],
  declarations: [ContainerComponent],
  exports: [ContainerComponent],
})
export class ContainerModule {}
