import { Injectable } from '@angular/core';

import { Oauth2RessourceService } from '../oauth2/oauth2-resources.service';
import { Odr } from './odr.model';
import { buildBody } from './odr.utils';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class OdrService {
  constructor(private oauth2RessourceService: Oauth2RessourceService) {}

  public send(odr: Odr, contact: string): Observable<void> {
    return this.oauth2RessourceService.sendOdrNotification().post(buildBody(odr, contact));
  }
}
