import { of as observableOf, Observable } from 'rxjs';

import { map, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Oauth2RessourceService } from '../../oauth2/oauth2-resources.service';
import { CartService } from '../../checkout/cart/cart.service';

import { Option } from '../../catalog/products/subscription/option';
import { BasicObject } from '../../base/base.interfaces';

@Injectable({
  providedIn: 'root',
})
export class CanalService {
  public isValid = true;
  public message: string = undefined;
  constructor(
    protected oauth2RessourceService: Oauth2RessourceService,
    protected cartService: CartService,
  ) {}

  public getScoring(shemeId?: string): Observable<this> {
    this.message = undefined;
    const scheme = this.cartService.getCurrentScheme() || this.cartService.getSchemeById(shemeId);
    if (!scheme) {
      return observableOf(this);
    }
    // true reference to canal option
    const canalOption = scheme
      .getProductsByType(Option)
      .find(x => x.data['type_id'] === 'option_partner' || x.data['type_id'] === 'offer_partner');

    const needScoringCanal = scheme.isAcquisitionFix() && canalOption;
    if (!needScoringCanal) {
      return observableOf(this);
    }

    const params: BasicObject = { cartId: this.cartService.cart.cartId, schemeId: scheme.quoteId };

    return this.oauth2RessourceService
      .ventes()
      .useSalesApi()
      .setLocalService()
      .scoringCanal()
      .setParams(params)
      .get()
      .pipe(
        tap((data: BasicObject) => {
          if (data.items.length > 0) {
            let message = "L'acquisition de(s) l'option [ ";
            data.items.forEach(element => {
              const product = this.cartService.getCurrentScheme().getSchemeProductById(element);
              message = message + '"' + product.name + '" ';
              this.cartService.remove(product.uniqueId, scheme.uniqueId).subscribe();
            });
            message = message + '] ne peut être validée par le partenaire';
            this.isValid = false;
            this.message = message;
          }
        }),
        map(() => this),
      );
  }
}
