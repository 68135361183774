import { Step } from '../../stepper/step.abstract';
import { Injectable } from '@angular/core';
import { CartService } from '../cart/cart.service';
import { Subscription } from '../../catalog/products/subscription';
import { Scheme } from '../cart/scheme.class';
import { SimReplace } from '../../catalog/products/equipement/sim/sim-replace';

@Injectable({
  providedIn: 'root',
})
export class JustificatoryV2StepService extends Step {
  public code = 'justificatory-v2';
  public path = '/panier/pieces-justificatives-v2';
  public label = 'Pièces Justificatives';

  constructor(private cartService: CartService) {
    super();
  }

  get isAllowed(): boolean {
    return this.cartService.cart.schemes.some(
      (scheme: Scheme) => !!scheme.getProductByType(Subscription) || !!scheme.getProductByType(SimReplace),
    );
  }

  set isAllowed(val: boolean) {
    return;
  }
}
