import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CartService } from '../cart/cart.service';
import { Promotion } from '../../promotions/promotion.class';
import { PromotionsService } from '../../promotions/promotionsService';
import { Agility } from '../../promotions/promotion/agility.class';
import { ICheckAgility, Responses } from '../../promotions/agility/interfaces/agility.interfaces';
import { AgilityEnvelopeService } from '../AgilityEnvelopeService';
import { Observable, of as observableOf } from 'rxjs';
import { catchError, mergeMap, finalize } from 'rxjs/operators';
import { PromoParams, PromotionTypes } from '../../promotions/promotions.interfaces';
import { ConfigService } from '../../config.service';
import { FundingService } from '../../fundings/services/funding.service';
import { GlobalLoaderService } from '../../base/services/global-loader.service';

const LOADING_ACTIONS = {
  processAgility: '[AgilityComponent] add agility',
};

@Component({
  selector: 'rcbt-agility',
  templateUrl: './agility.component.html',
  styleUrls: ['./agility.component.scss'],
})
export class AgilityComponent implements OnInit {
  public promotions: Promotion[] = [];
  public error: string;
  public loading = false;
  public agilityPromoId: number;
  public agility: Agility;
  public agilityAmount = 0;
  public cartAmount = 0;

  constructor(
    private activeModal: NgbActiveModal,
    public cartService: CartService,
    private agilityEnvelopeService: AgilityEnvelopeService,
    public promotionService: PromotionsService,
    private configService: ConfigService,
    private fundingService: FundingService,
    private globalLoaderService: GlobalLoaderService,
  ) {}

  public ngOnInit(): void {
    this.agilityPromoId = +this.configService.data.promotions.agilityId;
    this.agilityAmount = this.agility?.application.actionPromotion.amount || 0;
    this.cartAmount = this.getCartAmountWithoutCredit();
  }

  public close(): void {
    this.activeModal.close();
  }

  public onSaveAgility(agilityInput: number): void {
    this.globalLoaderService.dispatchLoadingStatus({ actionType: LOADING_ACTIONS.processAgility, isLoading: true });
    this.fundingService.launchWarningModalWithCallBackObservable(
      this.cartService,
      this.saveAgility.bind(this),
      agilityInput,
    );
  }

  public saveAgility(agilityInput: number): Observable<void> {
    const params: PromoParams = { type: PromotionTypes.agility, amount: Number(agilityInput) };
    this.loading = true;
    return this.agilityEnvelopeService.callCheckAgilityEnvelope(params.amount).pipe(
      mergeMap((checkAgilityResponse: ICheckAgility) => {
        this.error = checkAgilityResponse.response === Responses.ok ? '' : checkAgilityResponse.message;
        if (!this.error) {
          return this.promotionService.addPromoById(this.agilityPromoId, this.cartService.cart.cartId, params).pipe(
            mergeMap(() => this.cartService.deleteGrantedLoan()),
            mergeMap(() => this.cartService.refreshCart()),
            catchError(() => {
              this.showError("Une erreur s'est produite, impossible d'appliquer la promotion");
              return observableOf(null);
            }),
            finalize(() => {
              this.loading = false;
              this.globalLoaderService.dispatchLoadingStatus({
                actionType: LOADING_ACTIONS.processAgility,
                isLoading: false,
              });

              this.close();
            }),
          );
        } else {
          return observableOf(null);
        }
      }),
    );
  }

  public onAgilityAmountChanged(val: number): void {
    this.agilityAmount = val;
  }

  public onSubmit(event: Event): void {
    if (event && event.preventDefault) {
      event.preventDefault();
      this.saveAgility(this.agilityAmount);
    }
  }

  protected showError(error: string): void {
    const errorDuration = 4000;
    this.error = error;
    setTimeout(() => {
      this.error = null;
    }, errorDuration);
  }

  private getCartAmountWithoutCredit(): number {
    // todo
    return this.cartService.cart.totals.today;
  }
}
