import { Component, OnDestroy, OnInit } from '@angular/core';
import { StringTabObject } from '../../base/base.interfaces';
import { CustomerService } from '../../checkout/cart/customer/customer.service';
import { ScoringService } from '../../scoring/scoring.service';
import { finalize, tap } from 'rxjs/operators';
import { CartService } from '../../checkout/cart/cart.service';
import { forkJoin, Observable, Subscription } from 'rxjs';
import {
  DemandeFinancementAutoriseIn,
  Resultat as FinancementResultat,
  ParLigneMarcheMetierParCategorie as FinancementsParLigneMarcheMetierParCategorie,
} from '@bytel/pt-ihm-api-egide-controle-risque-vente-demander-financements-autorises';
import {
  DemandeOffresAutoriseesIn,
  Resultat as OffresResultat,
} from '@bytel/pt-ihm-api-egide-controle-risque-vente-demander-offres-autorisees';
import { ObjetControleInEnum, ParLigneMarcheMetierParCategorieEnum, ResultatEnum } from '../../scoring/interfaces';
import { ConfigService } from '../../config.service';

@Component({
  selector: 'rcbt-scoring',
  templateUrl: './scoring.component.html',
  styleUrls: ['./scoring.component.scss'],
})
export class ScoringComponent implements OnInit, OnDestroy {
  public messages: StringTabObject = { edp: [], fai: [], mobile: [], avantages: [], credit: [] };
  public isConvergenceLoading = false;
  public isScoringLoading = false;
  public showConvergence = false;
  public showScoring = false;
  public hasConvergenceMsg = false;
  public hasScoringMsg = false;
  public subscriptions: Subscription = new Subscription();

  constructor(
    private scoringService: ScoringService,
    private customerService: CustomerService,
    private cartService: CartService,
    private configService: ConfigService,
  ) {}

  public ngOnInit(): void {
    if (this.customerService.customer.personId) {
      if (this.scoringService.isScoringActive()) {
        this.showScoring = true;
        this.initScoring();
      }
      this.initConvergence();
    }
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private initScoring(): void {
    if (this.customerService.customer.personId) {
      this.isScoringLoading = true;
      this.subscriptions.add(
        forkJoin([this.loadAuthorizedFunding(), this.loadAuthorizedOffers()])
          .pipe(finalize(() => (this.isScoringLoading = false)))
          .subscribe(),
      );
    }
  }

  private initConvergence(): void {
    this.isConvergenceLoading = true;
    this.subscriptions.add(
      this.customerService
        .checkConvergenceEligibility(this.customerService.customer.personId)
        .pipe(finalize(() => (this.isConvergenceLoading = false)))
        .subscribe(() => {
          if (
            this.customerService.customer.isEligibleConvergence ||
            this.customerService.customer.isEligibleConvergenceMultiline ||
            this.customerService.customer.isEligiblePackFamily
          ) {
            this.messages.avantages.push(
              `${
                this.customerService.customer.isEligibleConvergence
                  ? 'Le client est éligible à la remise convergence.'
                  : ''
              }${
                this.customerService.customer.isEligibleConvergence &&
                this.customerService.customer.isEligibleConvergenceMultiline
                  ? ' '
                  : ''
              }${
                this.customerService.customer.isEligibleConvergenceMultiline
                  ? this.configService.isToutatisActive()
                    ? 'Le client est éligible aux Economies Petit B.iG.'
                    : 'Le client est éligible à la remise multiligne.'
                  : ''
              }${
                this.customerService.customer.isEligiblePackFamily ? ' Le client est éligible aux Economies B.iG.' : ''
              }`,
            );
            this.hasConvergenceMsg = true;
          }
        }),
    );
  }

  private loadAuthorizedOffers(): Observable<OffresResultat[]> {
    return this.scoringService
      .loadAuthorizedOffers(this.getDemandeFinancementAutoriseIn() as DemandeOffresAutoriseesIn)
      .pipe(
        tap((authorizedOfferResults: OffresResultat[]) => {
          const faiResults = authorizedOfferResults.filter(
            result =>
              result.champApplication.type === 'ParLigneMarcheMetierParCategorie' &&
              (result.champApplication as FinancementsParLigneMarcheMetierParCategorie).categorie !== 'autre',
          );
          faiResults.forEach(result => {
            if ([ResultatEnum.KO, ResultatEnum.KOSAUFACTIONREALISEE].includes(result.resultat as ResultatEnum)) {
              if (
                (result.champApplication as FinancementsParLigneMarcheMetierParCategorie).ligneMarcheMetier === 'FAI'
              ) {
                this.hasScoringMsg = true;
                this.messages.fai.push(result.message);
              } else if (
                (result.champApplication as FinancementsParLigneMarcheMetierParCategorie).ligneMarcheMetier === 'FNB'
              ) {
                this.hasScoringMsg = true;
                this.messages.mobile.push(result.message);
              }
            }
          });
        }),
      );
  }

  private loadAuthorizedFunding(): Observable<FinancementResultat[]> {
    return this.scoringService.loadAuthorizedFunding(this.getDemandeFinancementAutoriseIn()).pipe(
      tap((authorizedFundingResults: FinancementResultat[]) => {
        const acquisitionResults = authorizedFundingResults.filter(
          result =>
            result.champApplication.type === 'ParLigneMarcheMetierParCategorie' &&
            (result.champApplication as FinancementsParLigneMarcheMetierParCategorie).categorie ===
              ParLigneMarcheMetierParCategorieEnum.acquisition &&
            [ResultatEnum.OK, ResultatEnum.OKAVECINFORMATION, ResultatEnum.NONDETERMINANT].includes(
              result.resultat as ResultatEnum,
            ),
        );
        acquisitionResults.forEach(result => {
          if (result.perimetre === 'EDP') {
            this.hasScoringMsg = true;
            this.messages.edp.push("L'Acquisition d'une nouvelle ligne avec EDP est possible.");
          } else if (result.perimetre === 'CREDIT') {
            this.hasScoringMsg = true;
            this.messages.credit.push(
              "L'acquisition d'une nouvelle ligne avec un crédit est possible. " +
                "Elle dépendra de l'offre et des produits sélectionnés et sera soumise à l'acceptation du partenaire financier.",
            );
          }
        });
      }),
    );
  }

  private getDemandeFinancementAutoriseIn(): DemandeFinancementAutoriseIn {
    return {
      objetControle: {
        id: this.cartService.cart.cartId.toString(),
        type: ObjetControleInEnum.PANIER,
      },
    };
  }
}
