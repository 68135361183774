import { Injectable } from '@angular/core';

import {
  ConsulterCatalogueContextualiseAvecPanierBodyRequest,
  ConsulterCatalogueContextualiseAvecPanierQuery,
  SwaggerSpec,
  ConsulterCatalogueContextualiseAvecPanier200Response,
  ConsulterCatalogueContextualiseQuery,
} from '@bytel/pt-ihm-api-portailvente-sapic-catalogue';
import { HttpClientSwaggerService } from '../../oauth2/http-client-swagger.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CatalogCtxService {
  constructor(private httpService: HttpClientSwaggerService) {}

  public postCatalogCtx(
    body: ConsulterCatalogueContextualiseAvecPanierBodyRequest,
    queryParam: ConsulterCatalogueContextualiseAvecPanierQuery,
  ): Observable<ConsulterCatalogueContextualiseAvecPanier200Response> {
    return this.httpService.getClient(SwaggerSpec).call('ConsulterCatalogueContextualiseAvecPanier', {
      body,
      queryParam,
      pathParam: {},
    });
  }

  public getCatalogCtx(queryParam: ConsulterCatalogueContextualiseQuery): void {
    this.httpService.getClient(SwaggerSpec).call('ConsulterCatalogueContextualise', {
      queryParam,
      pathParam: {},
    });
  }
}
