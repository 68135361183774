<div class="is-tri dialog-modal-container is-flex is-vertical is-aligned-center">
    <div class="is-fullwidth is-flex is-justified-center padding-vertical">
        <div class="title">Adresse e-mail non renseignée</div>
    </div>
    <p class="is-size-4 padding-vertical">
        Pour financer votre panier à crédit, l'adresse e-mail est obligatoire.
        <br>
        Veuillez la renseigner dans le champs ci-dessous :
    </p>
    <form class="is-fullwidth is-flex is-justified-center control padding-vertical">
        <input [(ngModel)]="email"
                placeholder="Renseigner un e-mail valide"
                class="input"
                type="email"
                (ngModelChange)="regexEmail()"
                required/>
    </form>
    <p class="is-fullwidth is-flex is-justified-center has-text-danger padding-vertical" *ngIf="rCheckMailError">{{ rCheckMailError }}</p>
    <div class="is-fullwidth is-flex is-spaced-around padding-vertical">
        <button class="button is-primary" (click)="closeModal()" data-cy="cancel-credit">Annuler</button>
        <button class="button is-info" (click)="validate()" [disabled]="loading || !emailValid">Valider</button>
    </div>

</div>
