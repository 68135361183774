import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CartService } from '../../cart.service';
import { AgilityCa } from '../../../../promotions/promotion/agilityCa.class';
import { PromotionsService } from '../../../../promotions/promotionsService';
import { Promotion } from '../../../../promotions/promotion.class';
import { catchError, finalize, mergeMap } from 'rxjs/operators';
import { FundingService } from '../../../../fundings/services/funding.service';
import { UserService } from '../../../../user/user.service';
import { GlobalLoaderService } from '../../../../base/services/global-loader.service';

const LOADING_ACTIONS = {
  deleteAgilityCa: '[SchemeCaComponent] delete agility Ca',
};

@Component({
  selector: 'rcbt-scheme-ca',
  templateUrl: './scheme-ca.component.html',
  styleUrls: ['./scheme-ca.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SchemeCAComponent implements OnInit {
  public isPromoSummaryExpanded = true;
  public loading: boolean;
  public error: string;
  public canAppyAgilityCa: boolean;
  public promotionsCa: Promotion[] = [];
  public subTotalCaAmount = 0;
  public isDisplay: boolean;
  public agilityCaApplied: boolean;
  public agilityCaId: number;
  public agilityCaAmout: number;

  constructor(
    public cartService: CartService,
    private fundingService: FundingService,
    protected promoService: PromotionsService,
    private userService: UserService,
    private globalLoaderService: GlobalLoaderService,
  ) {}

  public ngOnInit(): void {
    const userContext = this.userService.user;
    this.error = '';
    this.canAppyAgilityCa = userContext.canApplyDiscountCA;
    this.initData();
    this.cartService.onChangesSchemes.subscribe(() => {
      this.initData();
    });
  }

  public initData(): void {
    this.promotionsCa = this.cartService.cart.promotions.filter(promo => promo.siren || promo.isAgilityCa());
    this.isDisplay = this.showPromotionsCa();
    this.subTotalCaAmount = this.calculateSubCaTotal();
    const agilityCa = this.getAgilityCa();
    this.agilityCaApplied = agilityCa?.isApplied();
    this.agilityCaId = agilityCa?.id;
    this.agilityCaAmout = agilityCa?.application.actionPromotion.amount;
  }

  public onDeleteAgilityCa(promoId: number): void {
    this.globalLoaderService.dispatchLoadingStatus({
      actionType: LOADING_ACTIONS.deleteAgilityCa,
      isLoading: true,
    });
    this.fundingService.launchWarningModalWithCallBackObservable(
      this.cartService,
      this.deleteAgilityCa.bind(this),
      promoId,
    );
  }

  private calculateSubCaTotal(): number {
    const cartPromosCaTotal: number = this.cartService.cart.promotions
      .filter(promo => promo.siren || promo.isAgilityCa())
      .reduce((acc: number, current: Promotion) => {
        if (current.isApplied()) {
          acc += Number(current.application.actionPromotion.amount);
        }
        return acc;
      }, 0);
    return this.cartService.cart.caTotals.today + cartPromosCaTotal;
  }

  private showPromotionsCa(): boolean {
    return (
      this.cartService.cart.promotions &&
      this.cartService.cart.promotions.some(promo => (promo.siren || promo.isAgilityCa()) && promo.isApplied())
    );
  }

  private getAgilityCa(): AgilityCa {
    return this.cartService.getAgilityCa();
  }

  private deleteAgilityCa(promoId: number): Observable<void | string> {
    this.loading = true;
    return this.promoService.deletePromo(promoId, this.cartService.cart.cartId).pipe(
      mergeMap(() => this.cartService.deleteGrantedLoan()),
      mergeMap(() => this.cartService.refreshCart()),
      catchError(() => {
        this.error = "Une erreur s'est produite, impossible d'appliquer la promotion";
        this.loading = false;
        this.globalLoaderService.dispatchLoadingStatus({
          actionType: LOADING_ACTIONS.deleteAgilityCa,
          isLoading: false,
        });
        return of(null);
      }),
      finalize(() => {
        this.loading = false;
        this.globalLoaderService.dispatchLoadingStatus({
          actionType: LOADING_ACTIONS.deleteAgilityCa,
          isLoading: false,
        });
      }),
    );
  }
}
