import { Component, OnDestroy, OnInit } from '@angular/core';
import { of, Subscription } from 'rxjs';
import { concatMap, finalize, switchMap } from 'rxjs/operators';

import { FundingMethod } from '../../../../../fundings/models/funding.method';
import { FundingService } from '../../../../../fundings/services/funding.service';
import { CartService } from '../../../cart.service';
import { DEFAULT_LOAN_MONTH } from '../funding.config';
import { LoanData } from '../funding.model';
import { ConsumerLoanService } from '../../../../../consumer-loan/consumer-loan.service';
import { Scheme } from '../../../scheme.class';
import { FundingEnum } from '../../../../../fundings/interfaces/funding.interface';

@Component({
  selector: 'rcbt-summary-funding',
  templateUrl: './summary-funding.component.html',
  styleUrls: ['./summary-funding.component.scss'],
})
export class SummaryFundingComponent implements OnInit, OnDestroy {
  public loanDatas: LoanData[];
  public totalCart: number;
  public amountToFund: number;
  public monthlyToFund: number;
  public payToday: number;
  public isCreditActive = !!this.cartService.cart.creditData;
  public loading: boolean;
  public displayPanel = true;
  private subscription = new Subscription();

  constructor(
    private fundingService: FundingService,
    private cartService: CartService,
    private consumerLoanService: ConsumerLoanService,
  ) {}

  public ngOnInit(): void {
    this.updateLoanData(this.fundingService.getFundingYounitedForCart());
    this.subscription.add(
      this.fundingService.stateEligibleFunding
        .pipe(switchMap(() => of(this.fundingService.getFundingYounitedForCart())))
        .subscribe(fundingMethods => this.updateLoanData(fundingMethods)),
    );
    this.subscription.add(
      this.fundingService.stateCredit.subscribe(isCreditActive => {
        this.isCreditActive = isCreditActive;
        this.updateLoanData(this.fundingService.getFundingYounitedForCart());
      }),
    );
    this.onSaveConsentForCredit();
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public onSelectLoan(nbMonth: number): void {
    const selectedLoanData = this.loanDatas.find(loanData => loanData.nbMonth === nbMonth);
    this.updateAmounts(selectedLoanData);
    this.updateLoanDatasSelectedStatus(nbMonth);
    if (this.isCreditActive) {
      this.cartService.setCreditFundingMode(selectedLoanData.type);
      this.fundingService.selectedNbMonth$$.next(nbMonth);
    }
  }

  public onToggleCredit(): void {
    if (this.consumerLoanService.needConsent()) {
      return this.consumerLoanService.displayInfoPopup();
    }
    const postCashFunding: boolean = this.cartService.cart.schemes.some((s: Scheme) => s.hasEdp);
    this.isCreditActive = !this.isCreditActive;
    if (this.isCreditActive) {
      if (postCashFunding) {
        this.loading = true;
        this.fundingService
          .postFundingMode(this.cartService, true)
          .pipe(
            concatMap(() => this.cartService.refreshCart(null, false)),
            finalize(() => (this.loading = false)),
          )
          .subscribe();
      } else {
        this.activeCredit();
      }
    } else {
      this.cartService.removeCreditFundingMode();
      this.updateLoanDatasSelectedStatus();
    }
    this.fundingService.stateCredit.next(this.isCreditActive);
  }

  private buildLoanData(fundingMethods: FundingMethod[]): LoanData[] {
    return fundingMethods.reduce((acc, { bestCommercialProposal, type }) => {
      if (!this.hasLoan(acc, bestCommercialProposal.nbLoan)) {
        acc.push(
          this.buildLoanDatas(
            bestCommercialProposal.monthlyAmount,
            bestCommercialProposal.nbLoan,
            bestCommercialProposal.initialAmount,
            bestCommercialProposal.amountToFund,
            bestCommercialProposal.tAEG,
            bestCommercialProposal.interestAmount,
            bestCommercialProposal.fixedRate,
            type,
          ),
        );
      }
      return acc;
    }, []);
  }

  private hasLoan(loadDatas: LoanData[], nbMonthLoan: number): boolean {
    return !!loadDatas.find(({ nbMonth }) => nbMonth === nbMonthLoan);
  }

  private buildLoanDatas(
    monthlyToFund: number,
    nbMonth: number,
    payToday: number,
    amountToFund: number,
    taeg: number,
    interest: number,
    fixeRate: number,
    type: FundingEnum,
  ): LoanData {
    return {
      selected: false,
      totalCart: amountToFund + payToday,
      monthlyToFund,
      nbMonth,
      payToday,
      // eslint-disable-next-line max-len
      message: `A partir de <span class="has-text-info">${payToday}€</span> + <span class="has-text-info">${monthlyToFund}€</span> x <span class="has-text-info">${nbMonth} mois</span>, TAEG <span class="has-text-info">${taeg}%</span>, taux débiteur fixe <span class="has-text-info">${fixeRate}</span> (montant financé <span class="has-text-info">${amountToFund}€</span>, et <span class="has-text-info">${interest}€</span> d'intérêts)`,
      type,
    };
  }

  private getDefaultLoanMonth(): number {
    if (this.cartService.cart.creditData?.type) {
      return this.loanDatas.find(({ type }) => type === this.cartService.cart.creditData.type)?.nbMonth;
    }
    return this.loanDatas.find(({ nbMonth }) => nbMonth === DEFAULT_LOAN_MONTH)?.nbMonth ?? this.loanDatas[0].nbMonth;
  }

  private updateLoanDatasSelectedStatus(nbMonth?: number): void {
    if (!this.isCreditActive) {
      this.loanDatas.forEach(loanData => (loanData.selected = false));
      return;
    }
    this.loanDatas.forEach(loanData => {
      if (loanData.nbMonth !== nbMonth) {
        loanData.selected = false;
        return;
      }
      loanData.selected = true;
    });
  }

  private updateLoanData(fundingMethods): void {
    if (fundingMethods.length < 1) {
      this.loanDatas = undefined;
      return;
    }
    this.loanDatas = this.buildLoanData(fundingMethods);
    this.onSelectLoan(this.getDefaultLoanMonth());
  }

  private onSaveConsentForCredit(): void {
    this.subscription.add(
      this.consumerLoanService.onSaveConsentment.subscribe(res => {
        this.isCreditActive = false;
        if (res === true) {
          this.onToggleCredit();
        }
      }),
    );
  }

  private activeCredit(): void {
    this.fundingService.selectedNbMonth$$.next(this.getDefaultLoanMonth());
    this.updateLoanDatasSelectedStatus(this.getDefaultLoanMonth());
    const selectedLoanData = this.loanDatas.find(loanData => loanData.nbMonth === this.getDefaultLoanMonth());
    this.updateAmounts(selectedLoanData);
    this.cartService.setCreditFundingMode(selectedLoanData.type);
  }

  private updateAmounts(selectedLoanData: LoanData): void {
    this.totalCart = selectedLoanData.totalCart;
    this.amountToFund = selectedLoanData.totalCart - selectedLoanData.payToday;
    this.monthlyToFund = selectedLoanData.monthlyToFund;
    this.payToday = selectedLoanData.payToday;
  }
}
