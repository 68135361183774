import { Injectable } from '@angular/core';
import { NgbDatepickerConfig, NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { I18n } from './i18n.service';

// Setting calendar to french
@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {
  public i18nValues = {
    fr: {
      weekdays: ['Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa', 'Di'],
      months: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Aou', 'Sep', 'Oct', 'Nov', 'Déc'],
    },
  };

  constructor(
    private _i18n: I18n,
    private config: NgbDatepickerConfig,
  ) {
    super();
    this.config.minDate = {
      year: 1994,
      month: 10,
      day: 1,
    };
  }

  // Note: Due to upgrade ng-bootstrap/ng-bootstrap
  public getWeekdayLabel(weekday: number): string {
    return this.i18nValues[this._i18n.language].weekdays[weekday - 1];
  }

  public getMonthShortName(month: number): string {
    return this.i18nValues[this._i18n.language].months[month - 1];
  }

  public getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }

  public getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }
}
