<section class="is-tri scoring-details is-flex is-vertical" *ngIf="controlErrors.length > 0" data-cy="summary-scoring-modal">
  <h1 class="is-fullwidth is-flex is-justified-center is-aligned-center title is-size-3 padding-vertical">
    <span class="icon is-medium" aria-label="Icone de taille small">
      <i class="tri-alert" aria-hidden="true"></i>
    </span>
    <span>&nbsp;(Panier N°{{ cartService.cart.cartId }})</span>
  </h1>

  <div class="is-flex is-vertical scrollable-bloc">
    <ul class="subtitle padding-vertical">
      <li *ngFor="let control of controlErrors" class="is-fullwidth is-flex is-spaced-between is-aligned-center is-bordered">
        <span class="has-text-secondary has-text-weight-bold" [innerHTML]="getControlMessage(control)"></span>
        <div class="popover is-popover-left" *ngIf="control.unlockable">
          <span class="icon is-medium has-text-warning"><i class="tri-lock" aria-hidden="true"></i></span>
          <div class="popover-content"><p>Déblocable</p></div>
        </div>
        <div class="popover is-popover-left" *ngIf="!control.unlockable">
          <span class="icon is-medium has-text-danger"><i class="tri-lock" aria-hidden="true"></i></span>
          <div class="popover-content"><p>Bloquant !</p></div>
        </div>
      </li>
    </ul>

    <h2 class="is-fullwidth is-flex is-justified-center title is-size-4 padding-vertical">Information</h2>
    <rcbt-scoring-partial class="scoring-global"></rcbt-scoring-partial>

    <ul *ngIf="error" class="is-fullwidth is-flex is-vertical is-aligned-center padding-vertical">
      <li class="has-text-danger" [innerHTML]="error"></li>
    </ul>
  </div>
  <div class="is-fullwidth is-flex is-spaced-around padding-vertical">
    <button class="button is-primary" data-cy="update-scheme-scoring" (click)="updateScheme()" [disabled]="loading">Modifier le parcours courant</button>
    <button class="button is-primary" data-cy="add-avc-scoring" (click)="addAvc()" *ngIf="showAddAdvanceButton" [disabled]="loading">
      Ajouter une avance sur conso de {{ avcAmount }}
    </button>
    <button class="button is-primary" data-cy="retry-scoring" (click)="retryScoring()" *ngIf="showRetry" [disabled]="loading">Continuer suite appel SD</button>
  </div>
</section>
