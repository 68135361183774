import { DateUniversal } from '../class/date-universal.class';
import * as uuid from 'uuid';

export class ToolsService {
  public static civilityFormat(civility: string): string {
    const FORMAT = {
      mlle: /^mlle/i,
      mme: /^mme/i,
      madame: /^madam$/i,
      me: /^me$/i,
      mr: /^mr$/i,
      m: /^m$/i,
      mp: /^m.$/i,
      monsieur: /^m$/i,
    };
    switch (true) {
      case FORMAT.mlle.test(civility):
      case FORMAT.mme.test(civility):
      case FORMAT.madame.test(civility):
      case FORMAT.me.test(civility):
        return 'MME';
      case FORMAT.mr.test(civility):
      case FORMAT.m.test(civility):
      case FORMAT.monsieur.test(civility):
      case FORMAT.mp.test(civility):
        return 'M';
    }
    return '';
  }

  public static dateFormat(date: string, fr?: boolean): string {
    let dateUniversal: DateUniversal = new DateUniversal(date);
    if (dateUniversal.value.toString() === 'Invalid Date') {
      if (date) {
        const jj: string = date.substring(0, 2);
        const mm: string = date.substring(2, 4);
        const yyyy: string = date.substring(4, 8);
        dateUniversal = new DateUniversal(`${jj}/${mm}/${yyyy}`);
        if (dateUniversal.value.toString() !== 'Invalid Date') {
          return dateUniversal.toSimpleFrench();
        }
      }
      return date;
    }
    if (fr) {
      return dateUniversal.toSimpleFrench();
    }
    return DateUniversal.formatDob(dateUniversal.toYYYYMMJJ());
  }

  public static roundDigits(nb, precision): number {
    precision = precision || 2;
    const tmp = Math.pow(10, precision);
    return Math.round(nb * tmp) / tmp;
  }

  public static generateUniqueId(): string {
    return uuid.v1();
  }

  public static validateIban(ibanSaisi: string, parametredIbanMasks: Object): { iban: string; longueur: number } {
    let ibanObject: { iban: string; longueur: number } = { iban: '', longueur: 0 };
    for (let i = 0; i < ibanSaisi.length; i++) {
      ibanObject.iban = ibanObject.iban.concat(ibanSaisi.charAt(i));
      ibanObject = ToolsService.validateLastCharacter(ibanObject, parametredIbanMasks);
    }
    return ibanObject;
  }

  public static validateLastCharacter(
    ibanObject: { iban: string; longueur: number },
    parametredIbanMasks: Object,
  ): { iban: string; longueur: number } {
    const value: string = ibanObject.iban;
    let result: string = ibanObject.iban;
    let ibanMask: Array<string | RegExp>;
    let longueur = 0;
    if (value.length === 1) {
      if (!RegExp(/^[a-zA-Z]/).test(value)) {
        result = value.slice(0, value.length - 1);
      }
    } else if (value.length === 2) {
      if (!RegExp(/^[a-zA-Z][a-zA-Z]/).test(value)) {
        result = value.slice(0, value.length - 1);
      }
    } else {
      const prefix: string = value.slice(0, 2).toUpperCase();
      longueur = parametredIbanMasks['prefix' + prefix];

      if (longueur) {
        const countryMask: string = ToolsService.getCountryMask(prefix, longueur);
        ibanMask = countryMask.split(';');
        if (value.length > ibanMask.length) {
          result = value.slice(0, value.length - 1);
        } else {
          const indexTypedCaracter: number = value.length - 1;
          const typedCaracter: string = value.charAt(indexTypedCaracter);
          if (ibanMask[indexTypedCaracter] === ' ' || (<string>ibanMask[indexTypedCaracter]).trim() === "' '") {
            const regExp = RegExp((<string>ibanMask[indexTypedCaracter + 1]).trim());
            if (regExp.test(typedCaracter)) {
              result = value.slice(0, value.length - 1).concat(' ', typedCaracter);
            } else {
              result = value.slice(0, value.length - 1);
            }
          } else {
            const regExp = RegExp((<string>ibanMask[indexTypedCaracter]).trim());
            if (!regExp.test(typedCaracter)) {
              result = value.slice(0, value.length - 1);
            }
          }
        }
      } else {
        result = value.slice(0, 2);
      }
    }
    ibanObject.iban = result;
    ibanObject.longueur = ibanMask ? ibanMask.length : 3;
    return ibanObject;
  }

  public static getCountryMask(country: string, longueur: number): string {
    let mask = '';
    if (longueur) {
      mask =
        '[' +
        country.charAt(0).toLowerCase() +
        '|' +
        country.charAt(0).toUpperCase() +
        '];[' +
        country.charAt(1).toLowerCase() +
        '|' +
        country.charAt(1).toUpperCase() +
        ']';
      for (let i = 2; i < longueur; i++) {
        mask = mask.concat(';[0-9A-Za-z]');
        if ((i === 3 || i % 4 === 3) && i < longueur - 1) {
          mask = mask.concat(";' '");
        }
      }
    }
    return mask;
  }

  public static cleanIban(iban: string): string {
    return iban.replace(/\s/g, '').trim();
  }

  public static getMaskIban(iban: string): string {
    // characters that shoud be clear in the masked IBAN :
    // - the first two characters
    // - the first six characters of the last 8 characters
    if (iban && iban.length > 14 && RegExp(/\d+/).test(iban)) {
      iban = this.cleanIban(iban);
      const mask: string = Array(iban.length - 9).join('X');
      iban = iban.replace(iban.substring(2, mask.length + 2), mask);
      return iban.replace(iban.slice(iban.length - 2), 'XX');
    } else {
      return iban?.toUpperCase();
    }
  }

  public static validateRio(rioCode: string, phoneNumber: string, type: string = 'fai'): boolean {
    const sRioCodeLength = 11;

    if (rioCode.length !== sRioCodeLength + 1 && rioCode.length !== sRioCodeLength) {
      return false;
    }

    // WRN : The "+" char canno't be in the middle of the RIO.
    // At the beginning or the end only.
    if (type !== 'fai' && !rioCode.match(/^[a-zA-Z0-9]{2}[PEpe][a-zA-Z0-9]+[a-zA-Z0-9\\+]{0,3}$/)) {
      return false;
    }

    const sOperateur = rioCode.substring(0, 2);

    let sTypeContrat = '';
    let sRefClient = '';

    if (rioCode.length === sRioCodeLength) {
      sRefClient = rioCode.substring(2, 8);
      sTypeContrat = 'P';
      rioCode = `${sOperateur}${sTypeContrat}${sRefClient}${rioCode.slice(-3)}`;
    } else {
      const rioCodetmp = rioCode.substring(2, 3);
      sTypeContrat = rioCodetmp.toUpperCase();
      sRefClient = rioCode.substring(3, 9);
    }

    if (type !== 'fai' && sTypeContrat !== 'P' && sTypeContrat !== 'E') {
      return false;
    }

    const sOrdre = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789+';
    const sLoopLength = 19;
    const nRes = Array(0, 0, 0);
    const sTmp = (sOperateur + sTypeContrat + sRefClient + phoneNumber).toUpperCase();

    for (let n = 0; n < sLoopLength; n++) {
      const TEMP = sTmp.substring(n, n + 1);
      const nPos = sOrdre.indexOf(TEMP);
      nRes[0] = (nRes[0] + nPos) % 37;
      nRes[1] = (2 * nRes[1] + nPos) % 37;
      nRes[2] = (4 * nRes[2] + nPos) % 37;
    }

    const sCleCalculee = `${sOrdre.substring(nRes[0], nRes[0] + 1)}${sOrdre.substring(nRes[1], nRes[1] + 1)}${sOrdre.substring(nRes[2], nRes[2] + 1)}`;
    if (rioCode.substring(9).toUpperCase() !== sCleCalculee) {
      return false;
    }
    return true;
  }

  public static openNewTab(url: string, target: string = '_blank', features: string = ''): void {
    // TODO: Due to upgrade
    window.open(url, target, features);
  }

  public static getElementsByPropertyValue<T>(documents: T[], propertyName: keyof T, propertyValue: T[keyof T]): T[] {
    return documents.filter(element => element[propertyName] === propertyValue);
  }
}
