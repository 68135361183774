import { Component, Input, OnInit } from '@angular/core';
import { Product } from '../../catalog/products/product';
import { PriceTypes } from '../../catalog/products/interface/price-type.enum';

@Component({
  selector: 'rcbt-price',
  templateUrl: './price.component.html',
  styleUrls: ['./price.component.scss'],
})
export class PriceComponent implements OnInit {
  @Input() public product: Product;
  @Input() public fontLevel = 3;
  @Input() public fontLevelStrike: number;
  @Input() public mainPrice: boolean;

  @Input() public price: number;
  @Input() public oldPrice: number;
  @Input() public priceType: PriceTypes;
  @Input() public isSecondaryNegatif: boolean;

  public ngOnInit(): void {
    if (!this.fontLevelStrike) {
      this.fontLevelStrike = this.fontLevel + 2 > 7 ? 7 : this.fontLevel + 2;
    }
  }
}
