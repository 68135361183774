import { NgModule } from '@angular/core';
import { CookieModule } from 'ngx-cookie';
import { ContextModule } from '../context/context.module';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  imports: [ContextModule, CookieModule.forRoot(), HttpClientModule],
  declarations: [],
})
export class Oauth2Module {}
