import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PromotionComponent } from './promotion.component';
import { BaseModule } from '../../base/base.module';
import { FormsModule } from '@angular/forms';
import { LoaderModule } from '../../loader/loader.module';

@NgModule({
  imports: [CommonModule, BaseModule, FormsModule, LoaderModule],
  declarations: [PromotionComponent],
  exports: [PromotionComponent],
})
export class PromotionModule {}
