import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { EquipmentsComponent } from './equipments.component';
import { EquipmentsRoutingModule } from './equipments-routing.module';
import { BaseModule } from '../../../base/base.module';
import { LoaderModule } from '../../../loader/loader.module';
import { EquipmentModalComponent } from './modal/equipment-modal.component';

@NgModule({
  imports: [FormsModule, ReactiveFormsModule, CommonModule, EquipmentsRoutingModule, BaseModule, LoaderModule],
  declarations: [EquipmentsComponent, EquipmentModalComponent],
})
export class EquipmentsModule {}
