import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { GlobalLoaderService } from '../base/services/global-loader.service';
import { Catalog } from '../catalog/products/catalog';
import { CartService } from '../checkout/cart/cart.service';
import { Scheme } from '../checkout/cart/scheme.class';
import { CheckoutStepperService } from '../checkout/checkout-stepper.service';
import { ConfigService } from '../config.service';
import { SchemeService } from '../checkout/cart/scheme.service';

@Component({
  selector: 'rcbt-pret-galet',
  templateUrl: './pret-galet.component.html',
  styleUrls: ['./pret-galet.component.scss'],
})
export class PretGaletComponent implements OnInit {
  public message = '';
  private gencodeOP: string;
  private scheme: Scheme;
  private readonly isLoadingId = 'pretGalet';

  constructor(
    private configService: ConfigService,
    private cartService: CartService,
    private router: Router,
    private checkoutStepperService: CheckoutStepperService,
    private globalLoaderService: GlobalLoaderService,
    private schemeService: SchemeService,
  ) {}

  public ngOnInit(): void {
    this.changeLoader(true);

    this.gencodeOP = this.configService.data.pretGalet?.plan;
    if (!this.gencodeOP) {
      this.changeLoader(false);
      this.changeMsg("Erreur lors de la récupération de l'offre princinpale paramétrée");
      return;
    }

    this.generateSchemeFromProducts();
    this.cartService
      .removeAllProducts()
      .pipe(
        mergeMap(() => this.addPlan()),
        mergeMap(() => this.cartService.refreshCart()),
      )
      .subscribe(
        () => {
          this.goToCart();
          this.changeLoader(false);
        },
        err => {
          this.changeMsg("Erreur serveur, impossible d'ajouter le produit au panier !");
          this.changeLoader(false);
        },
      );
  }

  private changeMsg(msg: string): void {
    this.message = 'Continuité de service : ' + msg;
  }

  private changeLoader(param: boolean): void {
    this.globalLoaderService.dispatchLoadingStatus({ actionType: this.isLoadingId, isLoading: param });
  }

  private generateSchemeFromProducts(): void {
    this.scheme = this.schemeService.getNewScheme(this.cartService.getCurrentScheme().browseType);
    const planProduct = Catalog.getInstanceByType('data_sim');
    planProduct.gencode = this.gencodeOP;
    this.scheme.add(planProduct);
  }

  private addPlan(): Observable<boolean> {
    return this.cartService.add(this.scheme.products[0], 1, false, this.cartService.currentSchemeUniqueId, false);
  }

  private goToCart(): void {
    this.cartService.save();
    this.router.url.startsWith('/panier');
    this.checkoutStepperService.goToStep(this.checkoutStepperService.getStepByCode('equipements'));
    this.changeLoader(false);
  }
}
