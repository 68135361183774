import { Injectable } from '@angular/core';
import { Oauth2RessourceService } from '../../oauth2/oauth2-resources.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  OffresSecondaires,
  RenewPromoContextSerializedInterface,
  Simulation,
  SimulationResponse,
} from '../../context/child/renew-promo-context-serialized.interface';
import { RenewPromotions } from './promotions/promotion.interface';
import { RenewStorageService } from '../../context/renew-storage.service';
import { SchemeSerialized } from './scheme.interface';
import { Scheme } from './scheme.class';

@Injectable({
  providedIn: 'root',
})
export class PromoRenewService {
  public idOffers: string[] = [];
  public renewPromotion: RenewPromotions = null;

  constructor(
    private oauth2Ressource: Oauth2RessourceService,
    private renewStorageService: RenewStorageService,
  ) {}

  public getRenewPromotionsAmount(schemes: Scheme[]): number {
    let total = 0;
    schemes.forEach(s => {
      if (s.isRenew()) {
        s.products.forEach(p => {
          if (p.renewPromotion?.offresSecondairesCibles?.length) {
            p.renewPromotion.offresSecondairesCibles.forEach(renewPromo => {
              total -= renewPromo.tarifTTC;
            });
          }
        });
      }
    });
    return total;
  }

  public serialize(): RenewPromoContextSerializedInterface {
    this.renewStorageService.setItem(this.renewStorageService.key['promotions'], this.renewPromotion);
    return {
      promotions: this.renewPromotion,
      type: 'renouvellement',
    };
  }

  public unserialize(schemeSerialized?: SchemeSerialized): void {
    const storageData: RenewPromotions = this.renewStorageService.getItem(this.renewStorageService.key['promotions']);
    this.renewPromotion = schemeSerialized?.contextProduit.contextService.promo?.promotions || storageData;
    if (this.renewPromotion) {
      this.setIdOffers(this.renewPromotion.simulation, this.renewPromotion.opSource.idOffre);
    }
  }

  public initRenewPromotions(contractId: number): Observable<void> {
    return this.oauth2Ressource
      .useSalesApi()
      .contracts(contractId)
      .simulationImpactsOffres('RENOUVELLEMENT')
      .get<SimulationResponse>()
      .pipe(
        map((data: SimulationResponse) => {
          this.mapSimulations(data);
          this.serialize();
        }),
      );
  }

  public setIdOffers(simulations: Simulation[], offrePrincipaleDetenue: string): void {
    this.idOffers = simulations.reduce((acc, cur) => {
      acc.push(cur.offrePrincipaleCible.idOffre);
      return acc;
    }, []);
    this.idOffers = [...this.idOffers, offrePrincipaleDetenue];
  }

  private mapSimulations(data: SimulationResponse): void {
    if (data.offrePrincipaleDetenue) {
      this.renewPromotion = {
        opSource: {
          idOffre: data.offrePrincipaleDetenue.idOffre,
          libelleCommercial: data.offrePrincipaleDetenue.libelleCommercial,
          libelleSysteme: data.offrePrincipaleDetenue.libelleCommercial,
          dateEffet: data.offrePrincipaleDetenue.dateEffet,
          tarifRemiseHT: data.offrePrincipaleDetenue.tarifRemiseHT,
          tarifRemiseTTC: data.offrePrincipaleDetenue.tarifRemiseTTC,
          montantRemiseTTC: data.offrePrincipaleDetenue.montantRemiseTTC,
        },
      };
      if (data.offresSecondairesDetenues?.length) {
        this.renewPromotion.opSource.osDetenues = this.extractSecondOffers(data.offresSecondairesDetenues);
      }
    }
    if (data.simulations) {
      this.renewPromotion.simulation = data.simulations;
      this.renewPromotion.simulation.forEach(s => {
        s.offresSecondairesCibles = this.extractSecondOffers(s.offresSecondairesCibles || []);
      });
      this.setIdOffers(data.simulations, data.offrePrincipaleDetenue.idOffre);
    }
  }

  private extractSecondOffers(offers: OffresSecondaires[]): OffresSecondaires[] {
    return offers.filter(
      o => o.famillesVente?.some(f => f.idFamilleVente === '124') && o.tarifTTC < 0 && o.action !== 'RESILIATION',
    );
  }
}
