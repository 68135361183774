import { Promotion } from '../promotion.class';
import {
  PromoActionTypes,
  PromotionTypes,
  PromotionTypologies,
  UpdatePromoParams,
} from '../../promotions/promotions.interfaces';

export class Regularization extends Promotion {
  public type: PromotionTypes = PromotionTypes.regularization;
  public typology: PromotionTypologies = PromotionTypologies.product;

  public isApplied(): boolean {
    return this.application.actionPromotion.amount > 0;
  }

  public setParams(params: UpdatePromoParams): this {
    this.application.actionPromotion.type = params.type || PromoActionTypes.byFixed;
    this.application.actionPromotion.amount = params.amount || 0;
    this.application.actionPromotion.percent = params.percent || 0;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    this.application.produit.gencode = { IN: [params.gencode] };
    this.application.actionPromotion.reasonCode = params.reasonCode;
    this.application.actionPromotion.comment = params.comment;
    return this;
  }
}
