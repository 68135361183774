import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, of } from 'rxjs';
import { concatMap, finalize, tap } from 'rxjs/operators';

import { LoadingStatusAction } from './global-loader.interface';

@Injectable({
  providedIn: 'root',
})
export class GlobalLoaderService {
  public isAppOnLoadingStatus$: Observable<boolean>;
  public forceLoadingStatusOff$: Observable<boolean>;

  private loadingStatusMap = new Map<string, BehaviorSubject<boolean>>();
  private isAppOnLoadingStatus$$ = new BehaviorSubject(false);
  private forceLoadingStatusOff$$ = new BehaviorSubject(false);

  constructor() {
    this.isAppOnLoadingStatus$ = this.isAppOnLoadingStatus$$.asObservable();
    this.forceLoadingStatusOff$ = this.forceLoadingStatusOff$$.asObservable();
  }

  public dispatchLoadingStatus(loadingStatusAction: LoadingStatusAction): void {
    this.updateLoadingStatusMap(loadingStatusAction);
  }

  public getLoadingStatusByAction(actionType: string): boolean {
    if (!this.hasActionType(actionType)) {
      throw new Error(
        `The actionType ${actionType} does NOT exist! Please check your usage of the function is correct`,
      );
    }
    return this.loadingStatusMap.get(actionType).getValue();
  }

  public showLoaderUntilFinalize<T>(observable$: Observable<T>, actionType: string): Observable<T> {
    return of(null).pipe(
      tap(() => this.loadingOn(actionType)),
      concatMap(() => observable$),
      finalize(() => this.loadingOff(actionType)),
    );
  }

  public deleteStatusByActionTypes(actionTypes: string | string[]): void {
    if (!Array.isArray(actionTypes)) {
      this.loadingStatusMap.delete(actionTypes);
      this.updateIsAppOnLoadingStatus();
      return;
    }
    actionTypes.forEach(actionType => this.loadingStatusMap.delete(actionType));
    this.updateIsAppOnLoadingStatus();
  }

  public setForceLoadingStatusOff(param: boolean): void {
    this.forceLoadingStatusOff$$.next(param);
  }

  private loadingOn(actionType: string): void {
    this.updateLoadingStatusMap({ actionType: actionType, isLoading: true });
  }

  private loadingOff(actionType: string): void {
    this.updateLoadingStatusMap({ actionType: actionType, isLoading: false });
  }

  private updateLoadingStatusMap({ actionType, isLoading }: LoadingStatusAction): void {
    if (!this.loadingStatusMap.get(actionType)) {
      this.loadingStatusMap.set(actionType, new BehaviorSubject(isLoading));
      this.updateIsAppOnLoadingStatus();
      return;
    }

    this.loadingStatusMap.get(actionType).next(isLoading);
    this.updateIsAppOnLoadingStatus();
  }

  private updateIsAppOnLoadingStatus(): void {
    let isOnLoadingStatus = false;
    this.loadingStatusMap.forEach(loadingMapItem$$ => {
      if (!loadingMapItem$$.getValue()) {
        return;
      }
      isOnLoadingStatus = true;
    });
    this.isAppOnLoadingStatus$$.next(isOnLoadingStatus);
  }

  private hasActionType(actionType: string): boolean {
    return this.getAllActionTypes().includes(actionType);
  }

  private getAllActionTypes(): string[] {
    return Array.from(this.loadingStatusMap.keys());
  }
}
