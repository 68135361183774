import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
  alphaNumCheck,
  birthDateMajorValidate,
  emailCheck,
  identityNumberCheck,
  phoneMobileNumberCheck,
  validateDepartment,
  validateName,
  validatePhoneAndEmail,
} from '../../form/validators';
import { IAddressPro, IDataPro, IRepresentantLegal, IScreening } from '../../../../../screening/screening.interface';
import { ToolsService } from '../../../../../base/services/tools.service';
import { SchemeHelper } from '../../../scheme.helper';
import { CartService } from '../../../cart.service';
import { Customer } from '../../customer';
import { CustomerType } from '../../customer.interface';

export class IdentityProForm {
  public form: UntypedFormGroup;
  disabled = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private customer: Customer,
    private cartService: CartService,
  ) {}

  public build(inputData, identityNumberMandatory: boolean, emailAndPhoneNumberMandatory: boolean): UntypedFormGroup {
    const data: IDataPro = this.prepareDataPro(inputData);
    const controlsConfig = {
      civilite: [data.representantLegal.civilite, [Validators.required]],
      nom: [data.representantLegal.nom, [Validators.required, validateName]],
      prenom: [data.representantLegal.prenom, [Validators.required, validateName]],
      siren: [data.screening.noSiren, [Validators.required, Validators.minLength(9), Validators.maxLength(9)]],
      dateNaissance: [
        data.representantLegal.dateNaissance,
        [Validators.required, Validators.maxLength(10), birthDateMajorValidate],
      ],
      departementNaissance: [data.representantLegal.departementNaissance, [Validators.required, validateDepartment]],
      documentIdentite: [
        data.documentIdentite,
        identityNumberMandatory ? [Validators.required, Validators.min(1)] : [Validators.nullValidator],
      ],
      documentIdentiteNumero: [data.documentIdentiteNumero, [Validators.nullValidator]],
      powerletter: [data.powerletter, [Validators.nullValidator]],
    };

    if (SchemeHelper.hasPlan(this.cartService.getCurrentScheme())) {
      controlsConfig['email'] = [this.customer.email || '', [emailCheck]];
      controlsConfig['telephone'] = [
        this.customer.phoneNumber || '',
        [Validators.maxLength(10), phoneMobileNumberCheck],
      ];
    }

    this.form = emailAndPhoneNumberMandatory
      ? this.formBuilder.group(controlsConfig, { validator: validatePhoneAndEmail })
      : this.formBuilder.group(controlsConfig);
    this.form.controls.documentIdentiteNumero.setValidators(
      identityNumberMandatory
        ? [Validators.required, alphaNumCheck, identityNumberCheck(this.form)]
        : [alphaNumCheck, identityNumberCheck(this.form)],
    );

    this.manageActivationFields(inputData);
    return this.form;
  }

  private shouldEnableIdentityFields(): boolean {
    for (const key of ['civilite', 'nom', 'prenom', 'dateNaissance', 'departementNaissance']) {
      if (!this.form.controls[key].value || !this.form.controls[key].valid) {
        return true;
      }
    }
    return false;
  }

  private manageActivationFields(data: IScreening): void {
    if (this.shouldEnableIdentityFields()) {
      for (const key of ['civilite', 'nom', 'prenom', 'dateNaissance', 'departementNaissance']) {
        this.form.controls[key].enable();
      }
    }
    const activeInputs: string[] = [
      'powerletter',
      'documentIdentitySignatory',
      'documentIdentite',
      'documentIdentiteNumero',
    ];
    const disableInputs: string[] = ['civilite', 'nom', 'prenom', 'dateNaissance', 'departementNaissance'];

    if (this.customer.type === CustomerType.prospect) {
      activeInputs.push('email');
    }
    this.shoudBeDisabled(activeInputs, data);
    if (this.cartService.cart.schemes.length > 1) {
      for (const key of disableInputs) {
        if (this.form.controls[key].disabled) {
          this.disabled = true;
        }
      }

      if (this.disabled) {
        this.form.controls.powerletter.disable();
      }
    }
    if (this.customer.type === CustomerType.client) {
      this.form.controls.civilite.disable();
    }
  }

  private shoudBeDisabled(activeInputs: string[], data: IScreening): void {
    for (const key in this.form.controls) {
      if (
        !activeInputs.includes(key) &&
        ((this.form.controls[key].valid &&
          this.form.controls[key].value &&
          data.representantLegal &&
          !!data.representantLegal[key]) ||
          this.cartService.cart.schemes.length > 1)
      ) {
        this.form.controls[key].disable();
      }
    }
  }

  private prepareDataPro(data: IScreening, addressPro?: IAddressPro): IDataPro {
    return {
      screening: this.prepareDataProScreening(data),
      representantLegal: this.prepareDataProRepresentantLegal(data),
      documentIdentite: this.customer.identityDocumentType
        ? data.signatoryDocs.filter(x => x.name === this.customer.identityDocumentType.name)[0]
        : null,
      documentIdentiteNumero: this.customer.identityDocumentNumber ? this.customer.identityDocumentNumber : null,
      addressPro: addressPro,
      powerletter: this.customer.powerletter ? this.customer.powerletter : false,
    };
  }

  private prepareDataProScreening(data: IScreening): IScreening {
    return {
      raisonSociale: data.raisonSociale || this.customer.company.raisonSociale,
      noSiren: data.noSiren || this.customer.company.noSiren,
      dateCreation: ToolsService.dateFormat(data.dateCreation || this.customer.company.dateCreation, true),
      codeEffectif: this.formatEffectif(data.codeEffectif) || this.formatEffectif(this.customer.company.codeEffectif),
      situationEntreprise: data.situationEntreprise || this.customer.company.situationEntreprise,
      codeApeNaf: data.codeApeNaf || this.customer.company.codeApeNaf,
      formeJuridique:
        this.formatFormJuridique(data.formeJuridique) || this.formatFormJuridique(this.customer.company.formeJuridique),
    };
  }

  private prepareDataProRepresentantLegal(data: IScreening): IRepresentantLegal {
    return {
      civilite:
        data.representantLegal && data.representantLegal.civilite
          ? ToolsService.civilityFormat(data.representantLegal.civilite)
          : ToolsService.civilityFormat(this.customer.civility),
      nom: data.representantLegal ? data.representantLegal.nom : this.customer.lastname,
      prenom: data.representantLegal ? data.representantLegal.prenom : this.customer.firstname,
      dateNaissance:
        data.representantLegal && data.representantLegal.dateNaissance
          ? ToolsService.dateFormat(data.representantLegal.dateNaissance, true)
          : ToolsService.dateFormat(this.customer.dob, true),
      departementNaissance:
        data.representantLegal && data.representantLegal.departementNaissance
          ? data.representantLegal.departementNaissance?.split(' ')[0]
          : this.customer.birthplace?.split(' ')[0],
    };
  }

  private formatFormJuridique(formJuridique: string): string {
    const FORMAT = {
      autre: /^autre/i,
      administation: /^administation/i,
      sarl: /^sarl$/i,
      entrepriseIndividuelle: /^entreprise/i,
      sa: /^sa$/i,
      eurl: /^eurl$/i,
    };
    switch (true) {
      case FORMAT.autre.test(formJuridique):
        return 'AUTRE';
      case FORMAT.administation.test(formJuridique):
        return 'ADMINISTRATION';
      case FORMAT.sarl.test(formJuridique):
        return 'SARL';
      case FORMAT.entrepriseIndividuelle.test(formJuridique):
        return 'ENTREPRISE_INDIVIDUELLE';
      case FORMAT.sa.test(formJuridique):
        return 'SA';
      case FORMAT.eurl.test(formJuridique):
        return 'EURL';
    }
    return 'AUTRE';
  }

  private formatEffectif(n: number | string): string {
    if (n > 8) {
      return '8';
    }
    return <string>n;
  }
}
