import { BasicObject } from '../base.interfaces';

export class Tools {
  public static getObjProperty<T>(obj: BasicObject, key: string): T {
    return obj[key];
  }

  public static newGuid(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      // eslint-disable-next-line no-bitwise
      const r = (Math.random() * 16) | 0,
        v = c === 'x' ? r : (r & 0x3) | 0x8; // NOSONAR
      return v.toString(16);
    });
  }

  public static arrayReplace(array: string[], findValue: string[], replaceValue: string[]): string[] {
    for (let i = 0; i < findValue.length; i++) {
      while (array.indexOf(findValue[i]) !== -1) {
        const index = array.indexOf(findValue[i]);
        array[index] = replaceValue[i];
      }
    }
    return array;
  }

  public static distanceBetweenCoor(lat1: number, lng1: number, lat2: number, lng2: number): number {
    return google.maps.geometry.spherical.computeDistanceBetween(
      new google.maps.LatLng(lat1, lng1),
      new google.maps.LatLng(lat2, lng2),
    );
  }
}
