import { Injectable } from '@angular/core';
import { Oauth2RessourceService } from '../oauth2/oauth2-resources.service';
import { Observable, of as observableOf } from 'rxjs';
import { IMobileTakeBackCoupon } from './interfaces/types';
import { CartService } from '../checkout/cart/cart.service';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { Catalog } from '../catalog/products/catalog';
import { MobileTakeBack } from '../catalog/products/mobileTakeBack';
import { IDisposalTicket, IErrorDisposalTicket } from '../catalog/products/mobileTakeBack.interfaces';
import { HttpClientSwaggerService } from '../oauth2/http-client-swagger.service';
import { BlacklistElement200Response, SwaggerSpec } from '@bytel/pt-ihm-api-npbt-ressource-imei';
import { BlacklistElementQuery } from '@bytel/pt-ihm-api-npbt-ressource-imei/dist/models/BlacklistElementQuery';

@Injectable({
  providedIn: 'root',
})
export class MobileTakeBackService {
  constructor(
    private oauth2RessourceService: Oauth2RessourceService,
    private cartService: CartService,
    private httpService: HttpClientSwaggerService,
  ) {}

  public getMobileTakeBackCoupon(gencode: string): Observable<IMobileTakeBackCoupon> {
    return this.oauth2RessourceService.bonCession().post({ gencode });
  }

  public addMobileTakeBackToScheme(scanCode: string): Observable<boolean> {
    const mobileTakeBack: MobileTakeBack = Catalog.getInstanceByType('RMBC000000');
    return this.getMobileTakeBackCoupon(scanCode).pipe(
      mergeMap((mobileTakeBackCoupon: IMobileTakeBackCoupon) => {
        mobileTakeBack.oldPrice = -mobileTakeBackCoupon.tarif;
        mobileTakeBack.price = -mobileTakeBackCoupon.tarif;
        mobileTakeBack.setScanCode(scanCode);
        mobileTakeBack.orderPartnerId = mobileTakeBackCoupon.idCommandePartenaire;
        return this.cartService.add(mobileTakeBack, 1, false, this.cartService.getCurrentScheme().uniqueId);
      }),
    );
  }

  public callCheckDisposalTicket(imei: string, name?: string, firstname?: string): Observable<IDisposalTicket> {
    const body = {
      gencode: imei,
      nom: name,
      prenom: firstname,
    };
    return this.oauth2RessourceService.repriseBonDeCession().post(body);
  }

  public printDisposalTicket(link: string): Observable<unknown> {
    return this.oauth2RessourceService
      .useSalesApi()
      .setLocalService()
      .ventes()
      .imprimerBonDeCession()
      .setResponseType('blob')
      .post({ linkDisposalTicket: link });
  }

  public handleErrorDisposalTicket(status: number, codeError: string): string {
    switch (codeError) {
      case IErrorDisposalTicket.disposalTicketNotFound:
        return 'Rejeté : Saisie erronée ou bon de cession introuvable';
      case IErrorDisposalTicket.disposalTicketUsed:
        return "Rejeté : Bon de cession déjà validé lors d'une précédente vente encaissée";
      case IErrorDisposalTicket.disposalTicketExpired:
        return 'Rejeté : Bon de cession expiré.';
      case IErrorDisposalTicket.disposalTicketInvalid:
        return "Rejeté : Le bon de cession n'est pas au nom du titulaire";
      default:
        if (Oauth2RessourceService.httpForbidden === status) {
          return 'Accès non-autorisé';
        } else {
          return 'Un erreur technique est survenue lors de la recherche du bon de cession';
        }
    }
  }

  public updateMtbCommand(id: string, login: string, codeEns: string, codePdv: string): Observable<void> {
    const body = {
      acteurCreateur: {
        login,
      },
      entiteReseauDistributionOrigine: {
        codeEnseigne: codeEns,
        codePointVente: codePdv,
      },
    };
    return this.oauth2RessourceService
      .repriseMobileCommande(id)
      .put(body)
      .pipe(
        map(res => !!res),
        catchError(() => observableOf(null)),
      );
  }

  public checkIsMobileBlacklisted(queryParam: BlacklistElementQuery): Observable<BlacklistElement200Response> {
    return this.httpService.getClient(SwaggerSpec).call(
      'BlacklistElement',
      {
        queryParam,
        pathParam: {},
      },
      {
        headers: {
          'x-version': '1',
        },
      },
    );
  }
}
