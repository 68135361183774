import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CartComponent } from './cart.component';
import { prismeLoggerResolver } from '../../shared/prisme/prisme-logger.resolver';

const routes: Routes = [
  {
    path: '',
    resolve: {
      prismeLoggerResolver,
    },
    component: CartComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CartRoutingModule {}
