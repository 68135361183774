import { webShopEligibility } from '../../../fai-widget/fai-webshop.interface';
import { StockTypeDto } from './equipment.dto';

export class Equipment {
  public readonly element: webShopEligibility.DeviceLstElement;
  public error = '';
  public readonly needCheckEquipment: boolean;

  public initialScanCode: string;

  public temporaryImei = '';
  public temporaryGencode = '';
  public name = '';

  constructor(element: webShopEligibility.DeviceLstElement) {
    this.needCheckEquipment = !['ACCESSOIRE', 'SIM', 'TERMINAL'].includes(element.equipementTypeMIM);

    this.name = element?.name ?? '';

    this.element = element;
    if (this.element.imei === undefined) {
      this.element.imei = '';
    }
    this.initialScanCode = element.imei;

    this.temporaryImei = element.imei;
    this.temporaryGencode = element.contraintesRemises.find(cr => cr.selected)?.gencode || '';
  }

  public getScanCode(): string {
    return this.temporaryImei;
  }

  public getGencode(): string {
    return this.temporaryGencode;
  }

  public hasChanged(): boolean {
    return this.initialScanCode !== this.temporaryImei;
  }

  public isFilled(): boolean {
    return this.temporaryImei !== '';
  }

  public markAsSaved(): void {
    this.element.contraintesRemises.forEach((cr: webShopEligibility.ContraintesRemise) => {
      cr.selected = cr.gencode === this.temporaryGencode;
    });

    this.element.imei = this.temporaryImei;
    this.element.imeiToGenCode = this.temporaryGencode;
    this.initialScanCode = this.element.imei;
  }

  public isEligibleForGencode(gencode: string, stockType: StockTypeDto): boolean {
    return (
      this.getTypeStock() === stockType &&
      this.element.contraintesRemises.some(cr => cr.gencode === gencode) &&
      !this.isFilled()
    );
  }

  public hasGencode(gencode: string): boolean {
    // à n'utiliser que pour afficher des messages d'erreur scanné
    return this.element.contraintesRemises.some(cr => cr.gencode === gencode);
  }

  public select(gencode: string, scanCode: string): void {
    this.temporaryGencode = gencode;
    this.temporaryImei = scanCode;
    this.error = '';
  }

  public isAvailable(): boolean {
    return this.getScanCode() === '' && this.getGencode() === '';
  }

  public free(): void {
    this.temporaryImei = '';
    this.temporaryGencode = '';
  }

  public consoleLogGencodes(): void {
    // eslint-disable-next-line no-console
    console.log(
      'imei needed : ' +
        this.element.typeEquipement +
        ' : ' +
        this.element.contraintesRemises.map(cr => cr.gencode).join(', '),
    );
  }

  private getTypeStock(): string {
    switch (this.element.typeEquipement) {
      case 'TERMINAL':
        return StockTypeDto.imei;
      case 'BOX':
        return StockTypeDto.bbox;
      case 'SIM':
        return StockTypeDto.sim;
      case 'ACCESSOIRE':
        return StockTypeDto.gencode;
      default:
        return StockTypeDto.imei;
    }
  }
}
