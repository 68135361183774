import { Resolve } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { GlobalLoaderService } from '../base/services/global-loader.service';
import { PromotionalEligibilityResultDto } from '../checkout/cart/dto/promotional-eligibility-output.dto';
import { PromotionsService } from './promotionsService';
import { CartService } from '../checkout/cart/cart.service';
import { catchError } from 'rxjs/operators';

const LOADING_ACTIONS = {
  eligibilityPromotion: '[PromotionResolverService] eligibilityPromotion',
};

@Injectable({
  providedIn: 'root',
})
export class PromotionResolverService implements Resolve<PromotionalEligibilityResultDto[]> {
  constructor(
    protected promotionsService: PromotionsService,
    protected cartService: CartService,
    protected globalLoaderService: GlobalLoaderService,
  ) {}

  public resolve(): Observable<PromotionalEligibilityResultDto[] | null> {
    const res$ = this.cartService.cart.schemes.length
      ? this.promotionsService
          .eligibilitePromotionnelle(this.cartService.getCartAsEligPromoBody())
          .pipe(catchError(() => of(null)))
      : of(null);
    return this.globalLoaderService.showLoaderUntilFinalize(res$, LOADING_ACTIONS.eligibilityPromotion);
  }
}
