<ng-container *ngFor="let os of osList">
  <tr>
    <td class="has-text-secondary is-italic">{{ os.libelleCommercial }}</td>
    <td class="has-text-centered">
        <rcbt-price *ngIf="(product.priceType === 0 || product.priceType === undefined) && os.tarifTTC" [price]="os.tarifTTC" [fontLevel]="5" [isSecondaryNegatif]="true"></rcbt-price>
    </td>
    <td class="has-text-centered">
        <rcbt-price *ngIf="product.priceType === 1 && os.tarifTTC" [price]="os.tarifTTC" [fontLevel]="5" [isSecondaryNegatif]="true"></rcbt-price>
    </td>
    <td class="has-text-centered">
        <rcbt-price *ngIf="product.priceType === 3 && os.tarifTTC" [price]="os.tarifTTC" [fontLevel]="5" [isSecondaryNegatif]="true"></rcbt-price>
    </td>
  </tr>
</ng-container>
