// Original definition from backoffice
export interface Notification {
  id: string;
  titre: string;
  priorite: number;
  description: string;
  dateDebut: string;
  dateFin: string;
  estResolu: boolean;
}

// List of types of change of status
export enum ChangeStatus {
  new = 'new',
  updated = 'updated',
  unchanged = 'unchanged',
  resolved = 'resolved',
}

// Simplify definition for frontoffice
export interface SimpleNotification {
  id: string;
  title: string;
  description: string;
  descriptionHtml?: string;
  changeStatus?: ChangeStatus;
  isResolved: boolean;
  isOpened: boolean;
}
