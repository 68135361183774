import { Step } from '../../stepper/step.abstract';
import { Injectable } from '@angular/core';
import { CartService } from '../cart/cart.service';
import { SimSav } from '../../catalog/products/equipement/sim/sim-sav';
import { Plan } from '../../catalog/products/subscription/plan';
import { BoxFaiGenerique } from '../../catalog/products/subscription/box-fai-generique';
import { Phone } from '../../catalog/products/equipement/complex/phone';

@Injectable({
  providedIn: 'root',
})
export class CartStepService extends Step {
  public code = 'cart';
  public path = '/panier';
  public label = 'Offre';

  constructor(private cartService: CartService) {
    super();
  }

  get isAllowed(): boolean {
    const currentScheme = this.cartService.getCurrentScheme(false);
    return (
      currentScheme &&
      !currentScheme.isBigtrustSav() &&
      (!!currentScheme.getProductByType(Plan) ||
        !!currentScheme.getProductByType(BoxFaiGenerique) ||
        !!currentScheme.getProductByType(Phone)) &&
      !(currentScheme.getProductByType(SimSav) && currentScheme.products.length === 1)
    );
  }
  set isAllowed(val: boolean) {
    return;
  }
}
