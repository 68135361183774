<div class="is-tri accessories-page" [ngClass]="{'margin': showHeader}">

    <div class="card">
        <div class="card-content">
            <p class="text is-size-4">Veuillez utiliser le bouton "Scanner" pour ajouter un accessoire.</p>

            <button class="button is-secondary has-icon" (click)="goAccessODR()" [disabled]="loading">
                <span class="icon is-small"><i class="tri-new-tabbed-page" aria-hidden='true'></i></span>
                Accéder aux ODR Accessoires
            </button>

            <div class="accessories-list" *ngIf="schemeAccessories.length > 0">
                <div class="accessory-item" *ngFor="let accessory of schemeAccessories">

                    <span class="icon is-medium"><i class="tri-accessories" aria-hidden='true'></i></span>
    
                    <div class="accessory-label text is-size-4">
                        <span *ngIf="accessory.type_id === 'produit_ca'">*&nbsp;</span>
                        <span [innerHTML]="(accessory.longName || accessory.name) | nl2br"></span>
                        <span *ngIf="accessory.getData('odr')"> ({{ accessory.getData('odr').amount }}€ remboursés)</span>
                    </div>
    
                    <rcbt-price [product]="accessory"></rcbt-price>
    
                    <button [attr.data-cy]="accessory.gencode" class="button is-warning is-icon-only" (click)="remove(accessory, accessory.uniqueId, scheme)" [disabled]="loading">
                        <span class="icon is-small"><i class="tri-trash" aria-hidden='true'></i></span>
                    </button>
    
                </div>
            </div>
        </div>
    </div>

</div>
