import { Component, Input, OnInit } from '@angular/core';
import { FraudService } from '../fraud.service';
import { FormBuilder, FormGroup, UntypedFormGroup } from '@angular/forms';
import { ScanditService } from '../../../scandit/scandit.service';
import { CartService } from '../../cart/cart.service';

@Component({
  selector: 'rcbt-fraud',
  templateUrl: './fraud.component.html',
  styleUrls: ['./fraud.component.scss'],
})
export class FraudComponent implements OnInit {
  @Input() public identityForm: UntypedFormGroup;
  public fraudForm: FormGroup;
  public showFraudField: boolean;

  constructor(
    private fraudService: FraudService,
    private fb: FormBuilder,
    private scanditService: ScanditService,
    private cartService: CartService,
  ) {}

  public ngOnInit(): void {
    this.showFraudField = this.canShowFraudField();
    if (this.showFraudField) {
      this.fraudForm = this.fb.group({
        fraud: [this.fraudService.isFraudSelected],
      });
      this.identityForm.addControl('fraudForm', this.fraudForm);
    }
  }

  private canShowFraudField(): boolean {
    return (
      this.fraudService.isFraudActive &&
      this.scanditService.isOnTablet() &&
      this.cartService.getCurrentScheme().isContractual()
    );
  }
}
