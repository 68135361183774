<div class="topBarFilter is-flex is-fullwidth is-spaced-between g-5 m-l-10" [ngClass]="{'m-b-25': takebackService.takebackPriceEstimation !== undefined}">

  <div class="nbPhones is-flex is-aligned-bottom text is-size-6 has-text-weight-semibold">
    {{nbCurrentPhones}}/{{nbAllPhones}}&nbsp;téléphones 
  </div>

  <div class="selectPlans field is-flex is-aligned-center m-0">
    <div class="control has-dynamic-placeholder">
      <select id="select-banc" class="select has-text-tertiary select-banc p-l-10" [(ngModel)]="filterService.phoneFiltersAndSort.planSelected"
        (change)="emitFiltersAndSort()">
        <option *ngFor="let plan of plansToDisplay" [value]="plan.gencode">{{plan.name}}</option>
      </select>
      <label for="select-banc">Offre</label>
    </div>
  </div>

  <rcbt-takeback-button [componentSource]="componentSourceTakeback" [disableButton]="hasMobileTakeBack"
    *ngIf="isMtbStandAloneActive && !isRenew" class="buttonTakeBack"></rcbt-takeback-button>

  <div class="toggleComparator is-flex is-aligned-center" *ngIf="plansForComparator">
    <rcbt-comparator-toggle [plansForComparator]="plansForComparator"></rcbt-comparator-toggle>
  </div>

  <div class="selectSort field is-flex is-aligned-center m-0">
    <div class="control has-dynamic-placeholder">
      <select id="select-banc" class="select has-text-tertiary select-banc p-l-10" [(ngModel)]="filterService.phoneFiltersAndSort.sortSelected"
        (change)="emitFiltersAndSort()">
        <option *ngFor="let option of filterService.triOptions" [value]="option.key">{{option.label}}</option>
      </select>
      <label for="select-banc">Trié par</label>
    </div>
  </div>

</div>