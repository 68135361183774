<div class="is-tri modal-duplicate-command">
    <div class="bloc-row title is-3 is-centered">Commande en doublon</div>
    <div class="bloc-row body">
        <span class="icon is-medium"><i class="tri-alert" aria-hidden='true'></i></span>
        <div>
            <p>Une commande est déjà en cours pour ce client : <strong>{{idCommand}}</strong></p>
            <p>Il n'est pas possible de faire une nouvelle souscription sur cette adresse pour ce client.</p>
        </div>
    </div>
    <div class="bloc-row actions">
        <button class="button is-warning is-icon-only" (click)="cancel()">
            <span class="icon is-medium">
                <i class="tri-times" aria-hidden='true'></i>
            </span>
            Annuler l'acte
        </button>
    </div>
</div>
