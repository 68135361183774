export enum ResultatEnum {
  KO = 'KO',
  KOSAUFACTIONREALISEE = 'KO_SAUF_ACTION_REALISEE',
  NONDETERMINANT = 'NON_DETERMINANT',
  OK = 'OK',
  OKAVECINFORMATION = 'OK_AVEC_INFORMATION',
}

export enum ObjetControleInEnum {
  CONTRAT = 'CONTRAT',
  PANIER = 'PANIER',
  PERSONNE = 'PERSONNE',
}

export enum ParLigneMarcheMetierParCategorieEnum {
  venteTerminalNu = 'venteTerminalNu',
  renouvellement = 'renouvellement',
  acquisition = 'acquisition',
}
