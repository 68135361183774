<div class="sim-format">
    <div *ngIf="isRenew && !isOldSimCompatible" class="is-5 has-text-warning icon-message">
        <span class="icon is-small"><i class="tri-comment-exclamation-circle" aria-hidden='true'></i></span>
        <p>
            <strong>
                Attention, la sim en cours d'utilisation est incompatible avec le téléphone sélectionné.
            </strong>
        </p>
    </div>

    <div class="options is-centered" *ngIf="isQrEsimCompatible || isSimCompatible">
        <div class="field">
            <div class="control">
                <input type="radio" id="radio-sim" name="simType" (click)="changeSimChoice(typeSim.simPhysique)"
                       [disabled]="!isSimCompatible" [checked]="choosenSimType === typeSim.simPhysique">
                <label for="radio-sim" data-cy="choice-sim">SIM</label>
            </div>
        </div>
        <div class="field" *ngIf="isQrEsimCompatible">
            <div class="control">
                <input type="radio" id="radio-esim" name="simType" (click)="changeSimChoice(typeSim.qrEsim)"
                       [checked]="choosenSimType === typeSim.qrEsim">
                <label for="radio-esim" data-cy="choice-esim">eSIM</label>
            </div>
        </div>
    </div>

    <form class="form-sim is-centered" #simForm="ngForm" (ngSubmit)="scanSim()" *ngIf="choosenSimType === typeSim.simPhysique">
        <div [hidden]="!!simCode">
            <div class="control has-icons-right">
                <input data-cy="sim-plan" [(ngModel)]="scanCode"
                       id="example-input"
                       name="scanCode"
                       maxlength="13"
                       minlength="13"
                       placeholder="Numéro de SIM"
                       class="input"
                       type="text"
                       pattern="[0-9]{13,13}$"
                       required/>
                <span *ngIf="scanCode" class="icon" (click)="removeScanCode()" > <i class="tri-times-circle"></i></span>
            </div>
        </div>
        <div class="ccid-confirmed" [hidden]="!simCode">
            <span class="icon is-medium is-success"><i class="tri-check" aria-hidden='true'></i></span>

            <span class="ccid-value" >{{simCode}}</span>

            <button type="button" class="button is-warning is-icon-only" data-cy="sim-remove" (click)="removeSim()" [ngClass]="{'action-feedback': loading}">
                <span class="icon is-small"><i class="tri-trash" aria-hidden='true'></i></span>
            </button>
        </div>
        <button class="button is-info is-icon-only" [hidden]="simForm.valid || !onTablete || !!simCode" (click)="scanOpen($event)">
            <span class="icon is-small"><i class="tri-camera" aria-hidden='true'></i></span>
        </button>
        <button class="button is-info is-icon-only" data-cy="add-sim-plan" type="submit" [hidden]="!!simCode || (!simForm.valid && onTablete)" [disabled]="!simForm.valid || loading" [ngClass]="{'action-feedback': loading}">
            OK
        </button>
    </form>

    <div *ngIf="messageEsimAuto" class="is-5 has-text-warning icon-message">
        <span class="icon is-small"><i class="tri-comment-exclamation-circle" aria-hidden='true'></i></span>
        <p>
            <strong>
                Suite à l'incompatibilité de la sim en cours d'utilisation avec le téléphone sélectionné, une eSIM vient d'être ajoutée au panier.<br>
                Cliquer sur 'eSIM' pour la supprimer.
            </strong>
        </p>
    </div>
</div>

<div *ngIf="this.message" class="is-size-6 has-text-warning">
    <p>
        <strong>
            <span class="icon is-small"><i class="tri-comment-exclamation-circle" aria-hidden='true'></i></span>
            {{ this.message }}
        </strong>
    </p>
</div>
<div *ngIf="choosenSimType === typeSim.qrEsim && !loading && sim && isReplaceSimComponent">
    <div class="row-display">
        <div class="row-input">
            <button
                    data-cy="deleteReplaceSim" class="button is-warning is-icon-only" [disabled]="loading"
                    (click)="removeSim()">
                <span class="icon is-small"><i class="tri-trash" aria-hidden='true'></i></span>
            </button>
        </div>
    </div>
    <div class="row-display is-centered">
        <rcbt-price [product]="sim"></rcbt-price>
    </div>
</div>
