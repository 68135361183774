import { Component, OnInit } from '@angular/core';
import { RegularizationService } from '../../../promotions/regularizationService';
import { UserService } from '../../../user/user.service';

@Component({
  selector: 'rcbt-toggle-regularisation',
  templateUrl: './toggle-regularisation.component.html',
  styleUrls: ['./toggle-regularisation.component.scss'],
})
export class ToggleRegularisationComponent implements OnInit {
  public canRegularize: boolean;
  public canApplyDiscountCA: boolean;

  constructor(
    public regularisationService: RegularizationService,
    private userService: UserService,
  ) {}

  public ngOnInit(): void {
    const userContext = this.userService.user;
    this.canRegularize = userContext.canApplyRegularization;
    this.canApplyDiscountCA = userContext.canApplyDiscountCA;
  }

  public toggleModeRegularisation(): void {
    this.regularisationService.toggle();
  }
}
