<div class="dialog-modal-container no-gutters is-tri">
    <div class="close-modal" (click)="close()">
        <i class="icon-item">
            <span btlSvg file="rcbt-defs" title="close" name="shape-icon-close" class="icon-close"></span>
        </i>
    </div>
    <div class="modal-header is-centered">
        <h2 class="title is-level-1">Suppression assurance MEDI7</h2>
    </div>
    <div class="modal-text">
        Attention, la suppression de l'assurance MEDI7 sur TPV, entrainera l'invalidation de la dématérialisation, et impliquera le passage en mode papier.<br>
        Pour conserver la dématérialisation de votre contrat, vous devez supprimer l'assurance depuis une tablette.<br>
        Voulez-vous vraiment supprimer l'assurance MEDI7 et renoncer à la dématérialisation ?
    </div>
    <div class="modal-actions">
        <button data-cy="consent-close" class="button is-primary" (click)="close()">Annuler</button>
        <button data-cy="consent-valid" class="button is-info" (click)="continue()" [disabled]="loading">Confirmer</button>
    </div>
</div>
