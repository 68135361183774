import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'moment',
})
export class MomentPipe implements PipeTransform {
  public transform(value: string | Date | moment.Moment, ...args: string[]): string {
    const [format] = args;
    return moment(value).format(format);
  }
}
