import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { CustomCurrencyPipe } from './pipes/currency.pipe';
import { SvgDirective } from './directive/svg.directive';
import { PriceComponent } from './price/price.component';
import { TelephonePipe } from './pipes/telephone.pipe';
import { ConfirmationDialogModule } from './ui/confirmationDialog';
import { OrderObjPipe } from '../base/pipes/orderObj.pipe';
import { FilterObjPipe } from './pipes/filterObj.pipe';
import { FindObjPipe } from './pipes/findObj.pipe';
import { PageTitleComponent } from './components/page-title/page-title.component';
import { CustomMaskDirective } from './directive/custom-mask.directive';
import { FormFeedbackComponent } from './components/form-feedback/form-feedback.component';
import { StepperModule } from '../stepper/stepper.module';
import { KeysPipe } from './pipes/keys.pipe';
import { CirclesLoaderDirective } from './directive/circles-loader.directive';
import { UpperinputDirective } from './directive/upperinput.directive';
import { OrderObjByDatePipe } from './pipes/orderObjByDate.pipe';
import { AmountInputDirective } from './directive/amountinput.directive';
import { ContainerBootstrapComponent } from './container-bootstrap/container-bootstrap.component';
import { IbanInputDirective } from './directive/ibaninput.directive';
import { PricePipe } from './pipes/price.pipe';
import { MultipleMatchValueFilterPipe } from './pipes/multipleMatchValueFilter.pipe';
import { SearchMultipleFilterPipe } from './pipes/multipleSearchFilter.pipe';
import { TableScrollYDirective } from './directive/table-scroll-y.directive';
import { InterceptorsDirective } from './directive/interceptors.directive';
import { InputFeedbackComponent } from './components/input-feedback/input-feedback.component';
import { MomentPipe } from './pipes/moment.pipe';
import { InputCrossComponent } from './components/input-cross/input-cross.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MultiSelectComponent } from './components/multi-select/multi-select.component';
import { MediaPipe } from './pipes/media.pipe';
import { BonusRepriseComponent } from './components/bonus-reprise/bonus-reprise.component';
import { AsteriskDirective } from './directive/asterisk.directive';
import { FormatPhoneNumberPipe } from './pipes/format.phone.number.pipe';
import { FinancementProduitPipe } from './pipes/financement-produit.pipe';
import { AutoCompleteComponent } from './components/auto-complete/auto-complete.component';

@NgModule({
  imports: [CommonModule, RouterModule, ConfirmationDialogModule, StepperModule, FormsModule, ReactiveFormsModule],
  exports: [
    CustomCurrencyPipe,
    TelephonePipe,
    MediaPipe,
    OrderObjPipe,
    OrderObjByDatePipe,
    SvgDirective,
    PriceComponent,
    ConfirmationDialogModule,
    FilterObjPipe,
    FindObjPipe,
    PageTitleComponent,
    CustomMaskDirective,
    FormFeedbackComponent,
    InputFeedbackComponent,
    KeysPipe,
    CirclesLoaderDirective,
    UpperinputDirective,
    AmountInputDirective,
    InterceptorsDirective,
    ContainerBootstrapComponent,
    UpperinputDirective,
    IbanInputDirective,
    MultipleMatchValueFilterPipe,
    SearchMultipleFilterPipe,
    TableScrollYDirective,
    MomentPipe,
    MultiSelectComponent,
    InputCrossComponent,
    BonusRepriseComponent,
    AsteriskDirective,
    FormatPhoneNumberPipe,
    FinancementProduitPipe,
    AutoCompleteComponent,
  ],
  declarations: [
    CustomCurrencyPipe,
    SvgDirective,
    PriceComponent,
    TelephonePipe,
    MediaPipe,
    OrderObjPipe,
    OrderObjByDatePipe,
    FilterObjPipe,
    FindObjPipe,
    PageTitleComponent,
    CustomMaskDirective,
    FormFeedbackComponent,
    InputFeedbackComponent,
    MultiSelectComponent,
    KeysPipe,
    PricePipe,
    CirclesLoaderDirective,
    UpperinputDirective,
    AmountInputDirective,
    InterceptorsDirective,
    ContainerBootstrapComponent,
    UpperinputDirective,
    IbanInputDirective,
    MultipleMatchValueFilterPipe,
    SearchMultipleFilterPipe,
    TableScrollYDirective,
    InputCrossComponent,
    MomentPipe,
    BonusRepriseComponent,
    AsteriskDirective,
    FinancementProduitPipe,
    FormatPhoneNumberPipe,
    AutoCompleteComponent,
  ],
})
export class BaseModule {}

export { ConfirmationDialogService } from './ui/confirmationDialog';
