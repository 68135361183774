<!-- Steps -->
<div class="is-tri">
    <section class="section has-text-centered">
        <div class="stepper" aria-label="breadcrumb">
            <ng-container *ngFor="let step of steps">
                <div *ngIf="!step.isHidden && step.isAllowed" [attr.data-cy]="'step-'+step.code"
                    class="step" [attr.data-label]="step.label" (click)="selectStep(step)"
                    [ngClass]="{'loading': loading, 'is-current': step.isActive && step.isAllowed, 'is-done': step.isSubmitted}">
                </div>
            </ng-container>
        </div>
    </section>
</div>
