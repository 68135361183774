import { Component, OnInit, Input } from '@angular/core';
import { VirtualScheme } from '../../contextualized-catalog/models/virtual-scheme';
import { VirtualProduct } from '../../contextualized-catalog/models/virtual-product';

@Component({
  selector: 'rcbt-virtual-scheme',
  templateUrl: './virtual-scheme.component.html',
  styleUrls: ['./virtual-scheme.component.scss'],
})
export class VirtualSchemeComponent implements OnInit {
  @Input() public virtualScheme: { scheme: VirtualScheme; schemeEdp: VirtualScheme };
  public phone: VirtualProduct;
  public edpPhone: VirtualProduct;

  public ngOnInit(): void {
    this.phone = this.virtualScheme.scheme.equipement;
    this.edpPhone = this.virtualScheme.schemeEdp.equipement;
  }
}
