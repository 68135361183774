import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'rcbt-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent {
  @Input()
  public message: string;

  @Input()
  public onValidClick: () => void;

  @Input()
  public hideCancelButton = false;

  @Input()
  public hideValidButton = false;

  @Input()
  public validButtonText = 'Valider';

  @Input()
  public cancelButtonText = 'Annuler';

  constructor(private activeModal: NgbActiveModal) {
    if (!this.onValidClick) {
      this.onValidClick = this.closeModal;
    }
  }

  public closeModal(): void {
    this.activeModal.close();
  }
}
