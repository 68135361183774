import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DispatchComponent } from './dispatch.component';
import { ContainerBootstrapComponent } from '../base/container-bootstrap/container-bootstrap.component';
import { prismeLoggerResolver } from '../shared/prisme/prisme-logger.resolver';
import { contextResolver } from '../context/contextResolverFn';

const routes: Routes = [
  {
    path: '',
    component: ContainerBootstrapComponent,
    resolve: { context: contextResolver },
    children: [
      {
        path: 'dispatch',
        component: DispatchComponent,
        resolve: {
          prismeLoggerResolver,
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DispatchRoutingModule {}
