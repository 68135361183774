import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckoutStepperComponent } from './checkout-stepper.component';
import { BaseModule } from '../base/base.module';
import { RefcliModule } from '../external/refcli/refcli.module';

@NgModule({
  imports: [CommonModule, BaseModule, RefcliModule],
  declarations: [CheckoutStepperComponent],
  exports: [CheckoutStepperComponent],
})
export class CheckoutStepperModule {}
