<div class="columns newscheme">
    <div class="column is-12 btn-newscheme">
        <button class="button is-info is-inverted btn-reload-act" data-cy="new-scheme" (click)="newScheme()"
            [disabled]=" loading"  [ngClass]="{'action-feedback': loading}">
            <i class="icon-item" *ngIf="!loading">
                <span btlSvg file="rcbt-defs" name="shape-icon-reload" class="icon s-reload"></span>
            </i>
                <span class="btn-text">Nouvel acte</span>
        </button>
    </div>
</div>
