import { PromoActionTypes, PromotionTypes, PromotionTypologies, UpdatePromoParams } from '../promotions.interfaces';
import { Promotion } from '../promotion.class';

export class Agility extends Promotion {
  public type: PromotionTypes = PromotionTypes.agility;
  public typology: PromotionTypologies = PromotionTypologies.product;

  /**
   * @param {number} baseAmount
   */
  public calculateDiscount(baseAmount: number): void {
    if (this.application.actionPromotion.type === PromoActionTypes.byFixed) {
      // laisser le max de chiffres apres la virgule
      this.application.actionPromotion.percent = this.application.actionPromotion.amount / baseAmount;
    } else {
      this.application.actionPromotion.amount = Number(
        (this.application.actionPromotion.percent * baseAmount).toFixed(2),
      );
    }
  }

  public isApplied(): boolean {
    return this.application.actionPromotion.amount > 0;
  }

  public setParams(params: UpdatePromoParams): this {
    this.application.actionPromotion.amount = params.amount || 0;
    return this;
  }
}
