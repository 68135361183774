export enum EComponentSource {
  topBarFilter = 'topBarFilter',
  mobileTakeBack = 'mobileTakeBack',
}

export interface PartnerProductsQuestions {
  criteresRepriseProduit: CritereRepriseProduit[];
  id: string;
  nom: string;
  marque: string;
}

export interface CritereRepriseProduit {
  id: string;
  libelle: string;
  reponses: Response[];
}

export interface Response {
  id: string;
  description?: string[];
  libelle: string;
}

export interface IEquipmentsMobilesResponse {
  cartesSim?: {
    dateRemise?: string;
    iccid?: string;
  }[];
  conditionsRenouvellement?: {
    dateEligibilitePrevisionnelleRenouvellement?: string;
    dateTarifPreferentiel?: string;
    eligibleEtalementPaiement?: boolean;
    eligibleRenouvellement?: boolean;
    migrationObligatoire?: boolean;
    motifsNonEligibiliteEtalementPaiement?: string;
    motifsNonEligibiliteRenouvellement?: string;
    renouvellementAutorise?: boolean;
  };
  mobileUsage?: {
    codeTac?: string;
    genCode?: string;
    imei?: string;
  };
  mobilesContractuels?: {
    codeTac?: string;
    dateRemise?: string;
    genCode?: string;
    imei?: string;
    subvention?: {
      dateDebut?: string;
      dateFin?: string;
      montant?: number;
    };
  }[];
}

export interface IProduitsPartenaireResponse {
  items: IProduitPartenaire[];
}

export interface IProduitPartenaire {
  id: string;
  marque: string;
  montantMax: number;
  montantMin: number;
  nom: string;
}
