import { Component } from '@angular/core';
import { GlobalLoaderService } from '../../../base/services/global-loader.service';
import { CartService } from '../../cart/cart.service';
import { ConfigService } from '../../../config.service';

const LOADING_ACTIONS = {
  cancelCart: '[FraudModalComponent] cancelCart',
};

@Component({
  selector: 'rcbt-fraud-modal',
  templateUrl: './fraud-modal.component.html',
  styleUrls: ['./fraud-modal.component.scss'],
})
export class FraudModalComponent {
  constructor(
    private globalLoaderService: GlobalLoaderService,
    private cartService: CartService,
    private configService: ConfigService,
  ) {}

  public cancelCart(): void {
    this.globalLoaderService
      .showLoaderUntilFinalize(
        this.cartService.cancelBasket(this.configService.isAccapActive()),
        LOADING_ACTIONS.cancelCart,
      )
      .subscribe();
  }
}
