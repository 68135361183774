import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'findObj',
})
export class FindObjPipe implements PipeTransform {
  public transform(
    array: unknown[],
    key: string | string[],
    value: unknown | unknown[] | unknown[][],
    multipleKeyAndCondition: boolean = true,
    defaultIndex?: number,
  ): unknown {
    let result;
    if (!Array.isArray(array) || key === undefined) {
      return undefined;
    }
    if (typeof key === 'string') {
      result = this.findByUniqKey(array, key, value);
      return this.manageResult(result, array, defaultIndex);
    }
    if (
      !Array.isArray(key) ||
      !Array.isArray(value) ||
      key?.length === 0 ||
      value?.length === 0 ||
      key?.length !== value?.length
    ) {
      return undefined;
    }
    result = this.findByListKey(array, key, value, multipleKeyAndCondition);
    return this.manageResult(result, array, defaultIndex);
  }

  private findByUniqKey(array: unknown[], key: string, value: unknown | unknown[]): unknown {
    const listValue = Array.isArray(value) ? value : [value];
    return array.find(item => listValue.includes(item[key]));
  }

  private findByListKey(
    array: unknown[],
    key: string[],
    value: unknown[][] | unknown[][][],
    multipleKeyAndCondition: boolean,
  ): unknown {
    return array.find(item => {
      const keyOkTest = (k, index): boolean => {
        const listValue = Array.isArray(value[index]) ? value[index] : [value[index]];
        return listValue.includes(item[k]);
      };
      return multipleKeyAndCondition ? key.every(keyOkTest) : key.some(keyOkTest);
    });
  }

  private manageResult(result: unknown, array: unknown[], defaultIndex?: number): unknown {
    return result !== undefined || defaultIndex === undefined || defaultIndex > array?.length
      ? result
      : array[defaultIndex];
  }
}
