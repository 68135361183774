import { Injectable } from '@angular/core';
import { CartService } from '../checkout/cart/cart.service';
import { Scheme } from '../checkout/cart/scheme.class';
import { Subject } from 'rxjs';
import { ScanditService } from '../scandit/scandit.service';
import { CustomerCategory } from '../checkout/cart/customer/customer.interface';
import { CustomerService } from '../checkout/cart/customer/customer.service';
import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root',
})
export class ConsumerLoanSimulationService {
  public onAddCredit: Subject<string> = new Subject<string>();

  constructor(
    private readonly cartService: CartService,
    private readonly scanditService: ScanditService,
    private customerService: CustomerService,
    private userService: UserService,
  ) {}

  public canLoanAccess(currentScheme?: Scheme): boolean {
    const scheme: Scheme = currentScheme ?? this.cartService.getCurrentScheme();
    const customerContext = this.customerService.customer;
    return (
      this.scanditService.isOnTablet() &&
      this.userService.user.eligibleCredit &&
      !!scheme.isLoanEligible() &&
      customerContext.category === CustomerCategory.gp
    );
  }
}
