import { Mobile } from './mobile';

export class Premium extends Mobile {
  public static planType = 'plan_premium';

  // todo vérifier l'utilité de ce code
  // /**
  //  * Add plan gencode in context for EDP contextualisation
  //  * @param scheme
  //  * @returns {SchemeBase}
  //  */
  // public updateSchemeOnAdd(scheme: Scheme): Scheme {
  //   // todo vérifier l'utilité de ce code
  //   // scheme.productContext.plan = this.gencode;
  //   // const phone: Product = scheme.getProductByType(Phone);
  //   // if (phone) {
  //   //   phone.updateSchemeOnAdd(scheme);
  //   // }
  //   // scheme.updateContext(scheme.productContext);
  //   return scheme;
  // }

  // todo vérifier l'utilité de ce code
  /**
   * Remove plan gencode in context if you remove plan
   * @param scheme
   * @returns {SchemeBase}
   */
  // public updateSchemeOnRemove(scheme: Scheme): Scheme {
  //
  //   super.updateSchemeOnRemove(scheme);
  //   // delete scheme.productContext.plan;
  //   // const phone: Product = scheme.getProductByType(Phone);
  //   // if (phone) {
  //   //   phone.updateSchemeOnAdd(scheme);
  //   // }
  //   // scheme.updateContext(scheme.productContext);
  //   return scheme;
  // }
}
