import { Injectable } from '@angular/core';
import { ConfigService } from '../config.service';
import { SimulatorParams } from '../checkout/cart/customer/customer.interface';

@Injectable()
export class TopBarService {
  constructor(private configService: ConfigService) {}

  public getSimulatorUrl(queryParams?: SimulatorParams): string {
    const simulator = this.configService.getSimulatorUrl();
    if (!simulator) {
      return '';
    }

    let url = simulator.url;

    if (queryParams) {
      const filteredParams = {};
      Object.entries(queryParams).forEach(([key, value]) => {
        if (value !== null) {
          filteredParams[key] = value;
        }
      });

      const queryString = Object.keys(filteredParams)
        .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(filteredParams[key]))
        .join('&');

      if (queryString) {
        url += '?' + queryString;
      }
    }

    return url.replace('$env', this.configService.getEnv());
  }
}
