import { ICluster } from '../context/child/clusters.interface';
import { UserContextSerializedInterface } from '../context/child/user-context-serialized.interface';

export enum UserEnsType {
  ca = 499,
  cs = 993,
  cm = 400,
}

export class User {
  public keySerialize = 'utilisateur';
  public firstname: string;
  public lastname: string;
  public idPersonne: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public user_type: string;
  public roles: string[];
  public login: string;
  public codePdv: string;
  public codeEns: string;
  public nomBoutique = '';
  public isPBO = false;
  public canApplyRegularization = false;
  public userId: number;
  public registrationNumber: string;
  public typeEntiteReseauDistribution: string;
  public clusters: ICluster[] = [];
  public canApplyDiscountCA = false;
  public eligibleCredit = false;
  public deviceId: string;

  public isCS(): boolean {
    return Number(this.codeEns) === UserEnsType.cs;
  }

  public isCA(): boolean {
    return Number(this.codeEns) === UserEnsType.ca;
  }

  public unserialize(data: UserContextSerializedInterface): void {
    Object.keys(data).forEach(key => {
      this[key] = this[key] || data[key];
    });
  }

  public serialize(): UserContextSerializedInterface {
    return {
      firstname: this.firstname,
      lastname: this.lastname,
      idPersonne: this.idPersonne,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      user_type: this.user_type,
      roles: this.roles,
      codeEns: this.codeEns,
      codePdv: this.codePdv,
      login: this.login,
      nomBoutique: this.nomBoutique,
      isPBO: this.isPBO,
      registrationNumber: this.registrationNumber,
      typeEntiteReseauDistribution: this.typeEntiteReseauDistribution,
      clusters: this.clusters,
      canApplyRegularization: this.canApplyRegularization,
      canApplyDiscountCA: this.canApplyDiscountCA,
      eligibleCredit: this.eligibleCredit,
      deviceId: this.deviceId,
    };
  }
}
