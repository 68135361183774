import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'telephone' })
export class TelephonePipe implements PipeTransform {
  public transform(value: string, exponent?: string): string {
    if (!value) {
      return '';
    }
    return value.replace(new RegExp(/^((\+)33|0033)/g), '0').replace(new RegExp(/(\d{2})/g), '$1 ');
  }
}
