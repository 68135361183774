<section class="log-details">
    <h1 class="title">
        <span class="icon is-medium" aria-label="Icone de taille large">
            <i class="tri-alert" aria-hidden="true"></i>
        </span>
        <span> (Panier N°{{ cartService.cart.cartId }}) </span>
    </h1>
    <pre class="code code-log">
        <code>
            <span *ngIf="controlErrors.length === 0">Aucune erreur dans le parcours</span><br>
            <span *ngFor="let control of controlErrors">{{ control.time }} => Request: {{ control.request | json : nospacing}}   Reponse: {{ control.response | json : nospacing}} </span><br>
        </code>
    </pre>

    <div class="actions">
        <button class="button is-primary" (click)="close()">Fermer</button>
        <button class="button is-info" *ngIf="controlErrors.length > 0" (click)="copy()">Copier</button>
    </div>
</section>
