import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalLoaderService } from '../../../../../base/services/global-loader.service';
import { PromotionsService } from '../../../../../promotions/promotionsService';
import { CartService } from '../../../cart.service';
import { ICartTotal, PromoParams, PromotionTypes } from '../../../../../promotions/promotions.interfaces';
import { ToolsService } from '../../../../../base/services/tools.service';
import { Observable, of } from 'rxjs';
import { AgilityCa } from '../../../../../promotions/promotion/agilityCa.class';
import { catchError, mergeMap, finalize } from 'rxjs/operators';
import { ConfigService } from '../../../../../config.service';
import { FundingService } from '../../../../../fundings/services/funding.service';

const LOADING_ACTIONS = {
  processAgilityCa: '[AgilityCaComponent] add agility Ca',
};
@Component({
  selector: 'rcbt-agility-ca',
  templateUrl: './modal-agility-ca.component.html',
  styleUrls: ['./modal-agility-ca.component.scss'],
})
export class ModalAgilityCaComponent implements OnInit {
  @Output() public emitter = new EventEmitter<AgilityCa>();
  public agilityAmountCA: number;
  public percentAgilityCA: number;
  public error: string;
  public loading = false;
  public agilityCaPromoId: number;
  public caTotals: ICartTotal;

  constructor(
    private activeModal: NgbActiveModal,
    private promoService: PromotionsService,
    private cartService: CartService,
    private configService: ConfigService,
    private fundingService: FundingService,
    private globalLoaderService: GlobalLoaderService,
  ) {}

  public ngOnInit(): void {
    this.error = '';
    this.caTotals = this.cartService.cart.caTotals;
    this.agilityAmountCA = 0;
    this.agilityCaPromoId = +this.configService.data.promotions.rabaisPanierId;
  }

  public close(): void {
    this.activeModal.close();
  }

  public onDiscountAmountChanged(val: number, byPercent: boolean): void {
    if (byPercent) {
      this.agilityAmountCA = ToolsService.roundDigits(this.caTotals.today * (val / 100), 2);
      this.percentAgilityCA = val;
    } else {
      this.percentAgilityCA = ToolsService.roundDigits((val / this.caTotals.today) * 100, 2);
      this.agilityAmountCA = val;
    }
  }

  public onApplyAgilityCA(): void {
    this.globalLoaderService.dispatchLoadingStatus({ actionType: LOADING_ACTIONS.processAgilityCa, isLoading: true });
    this.fundingService.launchWarningModalWithCallBackObservable(this.cartService, this.applyAgilityCA.bind(this));
  }

  public applyAgilityCA(): Observable<void> {
    this.error = '';
    if (this.caTotals.today < this.agilityAmountCA) {
      this.error = 'Ce rabais ne peut etre appliqué, le prix doit etre positif';
      return of(null);
    }
    this.loading = true;
    return this.saveAgilityCA().pipe(
      mergeMap(() => this.cartService.deleteGrantedLoan()),
      mergeMap(() => this.cartService.refreshCart()),
      catchError(() => {
        this.loading = false;
        this.error = "Une erreur s'est produite, impossible d'appliquer le rabais";
        this.globalLoaderService.dispatchLoadingStatus({
          actionType: LOADING_ACTIONS.processAgilityCa,
          isLoading: false,
        });
        return of(null);
      }),
      finalize(() => {
        this.loading = false;
        this.globalLoaderService.dispatchLoadingStatus({
          actionType: LOADING_ACTIONS.processAgilityCa,
          isLoading: false,
        });

        this.close();
      }),
    );
  }

  private saveAgilityCA(): Observable<void> {
    const params: PromoParams = { amount: this.agilityAmountCA, type: PromotionTypes.agilityCa };
    return this.promoService.addPromoById(this.agilityCaPromoId, this.cartService.cart.cartId, params);
  }
}
