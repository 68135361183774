import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseModule } from '../base/base.module';
import { CheckoutRoutingModule } from './checkout-routing.module';
import { CheckoutComponent } from './checkout.component';
import { TicketModule } from './ticket/ticket.module';
import { CheckoutStepperModule } from './checkout-stepper.module';
import { ConsumerLoanModule } from '../consumer-loan/consumer-loan.module';
import { EquipmentsModule } from './cart/equipments/equipments.module';
import { CustomerModule } from './cart/customer/customer.module';

@NgModule({
  imports: [
    CommonModule,
    BaseModule,
    CheckoutRoutingModule,
    TicketModule,
    ConsumerLoanModule,
    CheckoutStepperModule,
    EquipmentsModule,
    CustomerModule,
  ],
  declarations: [CheckoutComponent],
})
export class CheckoutModule {}
