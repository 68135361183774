import { Step } from '../../stepper/step.abstract';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class OrderingStepService extends Step {
  public code = 'ordering';
  public path = '/panier/commander';
  public label = 'Commander';
  public isHidden = true;
}
