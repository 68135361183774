<div class="is-tri insurance-page" [ngClass]="{'margin': showHeader}">

    <div class="card insurance-title">
        <div class="card-content bottom">
            <p>Assurances déjà souscrites : <b *ngIf="!hasSubscribedInsurances">Aucune</b></p>
            <ul *ngIf="hasSubscribedInsurances">
                <li *ngIf="spb.subscribedGe24">- GE24</li>
                <li *ngIf="spb.subscribed">- SPB</li>
                <li *ngIf="medicis.subscribed">- MEDICIS</li>
                <li *ngIf="medi7.subscribed">- MEDI7</li>
            </ul>
        </div>
    </div>

    <div class="insurance-choices">
        <div class="card insurance-choice spb-card" *ngIf="!isFai" data-cy="insurance-spb">
            <div class="card-content">
                <div class="insurance-name">
                    <h2 class="title is-3">SPB</h2>
                    <span class="d-block ">L’assurance mobile {{ phonesName[scheme.uniqueId]? 'pour '+phonesName[scheme.uniqueId]: ''  }}</span>
                </div>
                <div class="insurance-display">
                    <form class="spb-data" #spbForm="ngForm" (ngSubmit)="onSubmit()">
                        <div class="control has-icons-right has-dynamic-placeholder">
                            <input scan type="text" #spbImeiRef="ngModel" data-cy="insurance-spb-input"
                                    id="input-imei"
                                    class="input"
                                    name="spbImei"
                                    placeholder="IMEI"
                                    minlength="15"
                                    maxlength="15"
                                    pattern="^(?:[0-9]{15})$"
                                    (keyup)="onImeiChanged()"
                                    [(ngModel)]="spb.imei"
                                    [disabled]="hasPhoneInScheme || spb.added"
                                    required ngModel
                                    [ngClass]="{'is-success' : spbForm.form.valid && spb?.imei?.length !== 0,
                                    'is-danger' : !spbForm.form.valid && spb?.imei && spb?.imei?.length !== 0}">
                            <label for="input-imei">IMEI</label>
                            <span class="icon">
                                <i class="tri-check-circle" *ngIf="spbForm.form.valid && spb?.imei?.length !== 0 && !hasPhoneInScheme && !spb.added"></i>
                                <i class="tri-exclamation-circle" *ngIf="!spbForm.form.valid && spb?.imei && spb?.imei?.length !== 0 && !hasPhoneInScheme && !spb.added"></i>
                            </span>
                        </div>
                        <button *ngIf="!hasPhoneInScheme && !spb.added"
                            type="submit" class="button is-info" data-cy="insurance-spb-ok"
                            [ngClass]="{'action-feedback' : loading}" [disabled]="!spbForm.form.valid">OK</button>
                    </form>

                    <div class="add-product" *ngIf="canShowSPB()">
                        <div class="options-feedback" [ngClass]="{'default': !spb.added, 'info': spb.added}">
                            <h1 class="title-details is-3">
                                <span class="icon is-medium" aria-label="Icone de taille small">
                                    <i class="tri-provident-health" aria-hidden="true"></i>
                                </span>
                            </h1>
                            <h4 class="feedback-title">SPB</h4>
                            <span *ngIf="spbInsuranceProduct" class="option-feedback-item price-insurance">
                                <span [innerHtml]="spbInsuranceProduct.price | customCurrency"></span>&nbsp;/Mois
                            </span>
                        </div>

                        <button type="button" class="button is-winherit"
                                [ngClass]="{'action-feedback' : loading, 'is-warning': spb.added, 'is-info': !spb.added}"
                                [disabled]="!hasPhoneInScheme && !spb.eligible"
                                (click)="toggleSpb()" [attr.data-cy]="'insurance-spb-'+(spb.added ? 'remove' : 'add')">
                            {{spb.added ? 'Supprimer' : 'Ajouter'}}
                        </button>
                    </div>

                </div>
            </div>
        </div>


        <div class="card insurance-choice" *ngIf="medi7VisibleOffers.length" data-cy="insurance-medi7">
            <div class="card-content">
                <div class="insurance-name">
                    <h2 class="title is-3">MEDI7</h2>
                    <span class="d-block ">L'assurance Produits Multimédias.</span>
                </div>

                <div class="insurance-display">
                    <div class="add-product" *ngFor="let offer of medi7VisibleOffers">
                        <div class="options-feedback" [ngClass]="{'default': !offer.added, 'info': offer.added}">
                            <h1 class="title-details is-3">
                                <span class="icon is-medium" aria-label="Icone de taille small">
                                    <i class="tri-provident-health" aria-hidden="true"></i>
                                </span>
                            </h1>
                            <h4 class="feedback-title">{{offer.product.partner_line.toUpperCase()}}</h4>
                            <span class="option-feedback-item">{{offer.product.price}}&nbsp;€/Mois</span>

                        </div>

                        <button type="button" class="button is-winherit"
                                [ngClass]="{'action-feedback' : loading, 'is-warning': offer.added, 'is-info': !offer.added}"
                                [disabled]="offer.disabled || (!onTablet && !offer.added) || (!offer.eligible && !offer.added)"
                                (click)="toggleMedi7(offer)" [attr.data-cy]="'insurance-medi7-'+offer.product.partner_line.toLowerCase()+(offer.added ? '-remove' : '-add')">
                            {{offer.added ? 'Supprimer' : 'Ajouter'}}
                        </button>
                    </div>
                </div>

            </div>
        </div>

</div>
