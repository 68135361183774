<div class="is-tri m-t-30">

    <div class="is-flex is-fullwidth is-justified-center m-t-10" *ngIf="error">
        <button class="button is-info" (click)="reload()" [disabled]="globalLoaderService.isAppOnLoadingStatus$ | async">
            <span class="icon is-small"><i class="tri-exclamation-redo" aria-hidden="true"></i></span>    Recharger
        </button>
    </div>

    <div class="columns" *ngIf="!error">

        <rcbt-side-bar-filter class="column sideBar m-b-0 p-b-0" (filtersChangedEmitter) = "onFiltersChanged()"></rcbt-side-bar-filter>

        <div class="is-flex is-vertical is-fullwidth m-r-5">
            <rcbt-comparator-parameters class="is-fullwidth m-t-5"></rcbt-comparator-parameters>

            <rcbt-top-bar-filter class="columns m-r-10"
                *ngIf="allPlans"
                [allPlans]="allPlans"
                (filtersChangedEmitter) = "onFiltersChanged()"
                [nbCurrentPhones]="(equipments$ | async).length"
                [nbAllPhones]="nbAllPhones">
            </rcbt-top-bar-filter>
    
            <div class="product-list">
                <rcbt-infinite-scroll (scrolled)="onScroll()" [hasFetchedAllEquipments]="hasFetchedAllEquipments" [scrollInProgress]="scrollInProgress">
                    <div class="is-flex is-multiline g-5" rcbtQview>
                        <div *ngFor="let equipment of equipments$ | async; let i = index;  trackBy: trackByFn"
                            [id]="equipment.gencode"
                            class="is-flex one-device is-vertical is-justified-end"
                            [ngClass]="{'isReco': equipment.recommande, 'selectedForCompare': isParentSelected(equipment)}"
                            (click)="productClick(equipment)">
                            <div *ngIf="equipment.recommande" class="isRecoTitle">Sélectionné pour votre client</div>
                            <rcbt-product-item
                                    class="component-device"
                                    [index]="i"
                                    [equipment]="equipment">
                            </rcbt-product-item>
                        </div>
                    </div>
                </rcbt-infinite-scroll>
                <div *ngIf="scrollInProgress" class="is-flex is-fullwidth is-justified-center is-aligned-center">
                    <div class="notification has-body has-background-warning-20 is-warning m-y-5">
                        <div class="icon">
                            <i class="tri-exclamation-circle" aria-hidden="true"></i>
                        </div>
                        <div class="body">
                            <div class="text is-size-3">Chargement en cours ...</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
