import { Component } from '@angular/core';
@Component({
  selector: 'app-cross-sell',
  templateUrl: './cross-sell.component.html',
  styleUrls: ['./cross-sell.component.scss'],
})
export class CrossSellComponent {
  public activeAccordions: { [key: string]: boolean } = { piecesJustif: true };

  public ngOnInit(): void {
    this.toggleAccordion('accessoires');
  }

  public toggleAccordion(id: string): void {
    if (!this.activeAccordions[id]) {
      this.activeAccordions = {};
      this.activeAccordions = { [id]: true };
    } else {
      this.activeAccordions[id] = !this.activeAccordions[id];
    }
  }
}
