import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PretGaletComponent } from './pret-galet.component';
import { PretGaletRoutingModule } from './pret-galet-routing.module';

@NgModule({
  declarations: [PretGaletComponent],
  imports: [CommonModule, PretGaletRoutingModule],
})
export class PretGaletModule {}
