<div class="is-tri modal-content">

  <div class="modal-text">
    <p class="text is-size-3">
      Les crédits au delà de 50€ ne seront pas repris lors de la migration.
    </p>
    <p class="text is-size-5">
      Le solde restant sur la carte est valide jusqu'au {{ dateValidite }}
    </p>
  </div>

  <div class="modal-buttons">
    <button class="button is-primary" (click)="closeModal()">Annuler</button>
    <button class="button is-info" (click)="goToNextStep()">Continuer</button>
  </div>
</div>