import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { GlobalLoaderService } from '../../../../base/services/global-loader.service';
import { CartService } from '../../cart.service';
import { Phone } from '../../../../catalog/products/equipement/complex/phone';
import { CheckoutStepperService } from '../../../checkout-stepper.service';
import { MobileTakeBack } from '../../../../catalog/products/mobileTakeBack';
import { forkJoin as observableForkJoin, Observable, of, Subscription } from 'rxjs';
import { Scheme } from '../../scheme.class';
import { mergeMap, tap } from 'rxjs/operators';
import { ConsumerLoanSimulationService } from '../../../../consumer-loan/consumer-loan-simulation.service';
import { CommercialProposal } from '../../../../fundings/interfaces/funding.interface';
import { ConfigService } from '../../../../config.service';
import { PromotedStatusEnum } from '../../../../catalog/products/interface/promoted-status.enum';

const LOADING_ACTIONS = {
  deletePhone: '[PhoneComponent] deletePhone',
};

@Component({
  selector: 'rcbt-phone',
  templateUrl: './phone.component.html',
  styleUrls: ['./phone.component.scss'],
})
export class PhoneComponent implements OnInit, OnDestroy {
  @Input()
  public product: Phone;
  public scheme: Scheme;
  public loadingItem: boolean;
  public paymentLoading = false;
  public isEligibleEdp = false;
  public subscription = new Subscription();
  public isReco = false;
  public canLoanAccess = false;
  public paymentError: string;
  public canAddEdp = false;
  public edpFundingData: CommercialProposal;
  public isMtbStandAlone = false;
  /**
   * [constructor description]
   * @param  {CartService} cart [description]
   * @return {[type]}           [description]
   */
  constructor(
    protected cartService: CartService,
    protected stepperService: CheckoutStepperService,
    private readonly consumerLoanSimulationService: ConsumerLoanSimulationService,
    private globalLoaderService: GlobalLoaderService,
    private checkoutStepperService: CheckoutStepperService,
    private configService: ConfigService,
  ) {
    this.isMtbStandAlone = this.configService.data.mtbStandAlone?.active;
  }

  get isMobileTakeBack(): boolean {
    const products = this.cartService.getCurrentScheme().getProductsByType(MobileTakeBack);
    return products.length > 0;
  }

  public ngOnInit(): void {
    this.scheme = this.cartService.getCurrentScheme();
    const phone: Phone = this.scheme.getProductByType(Phone);
    this.isReco = phone.promotedStatus === PromotedStatusEnum.promoted;
    this.canLoanAccess = this.consumerLoanSimulationService.canLoanAccess();
    this.subscription.add(
      this.consumerLoanSimulationService.onAddCredit
        .pipe(
          mergeMap((error: string) => {
            if (error) {
              this.showPaymentError(error);
              return of(null);
            }
          }),
        )
        .subscribe(),
    );
    this.cartService.afterRefresh.subscribe(() => {
      this.handleAfterRefreshCart();
    });
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  /**
   * [remove description]
   * @return {[type]} [description]
   */
  public remove(): void {
    this.loadingItem = true;
    const obs: Observable<boolean>[] = [this.cartService.remove(this.product.uniqueId, this.scheme.uniqueId)];

    const mobileTakeBack: MobileTakeBack = this.scheme.getProductByType(MobileTakeBack);
    if (mobileTakeBack && !this.isMtbStandAlone) {
      obs.push(this.cartService.remove(mobileTakeBack.uniqueId, this.scheme.uniqueId));
    }

    if (this.scheme.hasEdp) {
      this.cartService.setCashFundingMode(this.scheme.quoteId);
    }

    const removeRequests$ = observableForkJoin(obs);

    const finalize$ = removeRequests$.pipe(
      mergeMap(() => this.cartService.refreshCart()),
      tap(
        () => {
          this.loadingItem = false;
          const currentStep = this.stepperService.getCurrentStep();
          if (!currentStep.isAllowed) {
            const redirected = this.checkoutStepperService.redirectIfEmptyScheme(
              this.cartService.getCurrentScheme().isEmpty(),
            );
            if (!redirected) {
              this.stepperService.goToStep(this.stepperService.getNextStep());
            }
          }
        },
        () => (this.loadingItem = false),
      ),
    );

    this.globalLoaderService.showLoaderUntilFinalize(finalize$, LOADING_ACTIONS.deletePhone).subscribe();
  }

  private showPaymentError(error: string): void {
    this.paymentError = error;
    setTimeout(() => {
      this.paymentError = null;
    }, 3500);
  }

  private handleAfterRefreshCart(): void {
    this.scheme = this.cartService.getCurrentScheme();
  }
}
