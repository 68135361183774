import { Component, HostBinding, OnDestroy } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';

import { EMPTY, Subscription } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AlertService } from '../../common/alert/alert.service';

import { DynamicComponentLoaderService } from '../dynamic-component/dynamic-component-loader.service';
import { odrContactFormValidator } from '../odr-contact-form-control.validator';
import { SUCCESS_MESSAGE } from '../odr.config';
import { Odr } from '../odr.model';

import { OdrService } from '../odr.service';
import { formatPhoneNumber } from '../odr.utils';
import { CustomerService } from '../../checkout/cart/customer/customer.service';

@Component({
  selector: 'rcbt-odr-recap',
  templateUrl: './odr-recap.component.html',
  styleUrls: ['./odr-recap.component.scss'],
})
export class OdrRecapComponent implements OnDestroy {
  @HostBinding('class.is-tri') hasClass = true;
  data: Odr;
  contact: UntypedFormControl;

  private customerContextService = this.customerService.customer;
  private initContact = this.customerContextService.phoneNumber
    ? this.customerContextService.phoneNumber
    : this.customerContextService.email;
  private subscription = new Subscription();

  constructor(
    private alertService: AlertService,
    private odrService: OdrService,
    private dynamicComponentLoaderService: DynamicComponentLoaderService,
    private customerService: CustomerService,
  ) {
    this.contact = new UntypedFormControl(this.initContact, {
      validators: [Validators.required, odrContactFormValidator],
    });
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public onSubmit(): void {
    if (!this.contact.value) {
      return;
    }
    const value = formatPhoneNumber(this.contact.value);
    this.subscription.add(
      this.odrService
        .send(this.data, value)
        .pipe(
          catchError(error => {
            this.alertService.successEmitter.next(error.msgRetour);
            this.resetContact();
            return EMPTY;
          }),
        )
        .subscribe(() => {
          this.alertService.successEmitter.next(SUCCESS_MESSAGE);
          this.onClose();
        }),
    );
    this.resetContact();
  }

  public onClose(): void {
    this.dynamicComponentLoaderService.deleteComponent();
  }

  private resetContact(): void {
    this.contact.reset();
  }
}
