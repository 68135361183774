<div class="control">
  <div class="field autocomplete-input">
    <div class="control has-dynamic-placeholder has-icons-right">
      <input
        id="input-{{idName}}"
        role="textbox"
        type="text"
        class="input is-default"
        autocomplete="off"
        placeholder="{{label}}"
        [attr.data-cy]="'input-select-'+idName"
        [formControl]="inputControl"
        (focus)="showOptions = true"
        (blur)="onInputBlur()"
        (input)="onInputChange($event.target.value)"
        (keydown.ArrowDown)="onArrowDown()"
        (keydown.ArrowUp)="onArrowUp()"
        (keydown.Enter)="onEnter()"
        [attr.aria-expanded]="showOptions"
        aria-autocomplete="list"
        [ngClass]="{'is-success' : inputControl.valid,
        'is-danger' : inputControl.errors, 'disabled' : inputControl.disabled}"
         
      />
      <label for="input-{{label}}" rcbtAsterisk [formcontrol]="inputControl">{{label}}</label>

      <div>
        <span class="icon is-small">
          <i class="tri-check-circle has-background-success-60" *ngIf="inputControl.valid"></i>
          <i class="tri-exclamation-circle has-background-error-60" *ngIf="inputControl.errors"></i>
        </span>
      </div>
    </div>
    <div *ngIf="showOptions" class="autocomplete-options" id="autocomplete-options">
      <div
        *ngFor="let option of filteredOptions; let i = index"
        class="p-t-5 option"
        [class.active]="i === activeOptionIndex"
        (click)="selectOption(option)"
        (mouseenter)="setActiveOptionIndex(i)"
        role="option"
        [id]="getActiveDescendantId(i)"
      >
        {{ option.code }} - {{ option.name }}
      </div>
    </div>
  </div>
</div>
