import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class IntraCommunicationService {
  public emitMedi7Refresh: Subject<void> = new Subject<void>();
  public emitCancelScheme: Subject<void> = new Subject<void>();
  public emitCancelCart: Subject<void> = new Subject<void>();

  public refreshMedi7Components(): void {
    this.emitMedi7Refresh.next();
  }

  public doCancelScheme(): void {
    this.emitCancelScheme.next();
  }

  public doCancelCart(): void {
    this.emitCancelCart.next();
  }
}
