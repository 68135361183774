/**
 * Created by fruysshaert on 06/03/2017.
 */

import { Injectable } from '@angular/core';
import { StorageService } from '../base/services/storage.service';
import { StringObject } from '../base/base.interfaces';

@Injectable({
  providedIn: 'root',
})
export class ContextStorageService extends StorageService {
  public key: StringObject = {
    browseContext: 'browse_context',
    contexts: 'contexts',
    customer: 'customer',
    user: 'user',
    browseConfig: 'browseConfig',
  };

  protected prefix = 'context_';

  constructor() {
    super();
    for (const key of Object.keys(this.key)) {
      this.sessionStorageKeys.push(this.key[key]);
    }
  }
}
