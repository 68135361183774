import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpbComponent } from './spb/spb.component';
import { InsuranceListComponent } from './insuranceList/insurance-list.component';
import { BaseModule } from '../base/base.module';
import { FormsModule } from '@angular/forms';
import { ModalDeleteWarningComponent } from './insuranceList/modal-delete-warning/modal-delete-warning.component';
import { ModalMedi7WarningComponent } from './insuranceList/modal-medi7-warning/modal-medi7-warning.component';

@NgModule({
  imports: [CommonModule, BaseModule, FormsModule],
  declarations: [SpbComponent, InsuranceListComponent, ModalDeleteWarningComponent, ModalMedi7WarningComponent],
  exports: [InsuranceListComponent, SpbComponent],
})
export class PartnerModule {}
