import { CompatibilityMessages } from '../../product';
import { Sim } from '../sim';
import { BrowseType } from '../../../../checkout/cart/browse-type';
import { AddResult, ResultAddMessage, TypeResult } from '../../../../checkout/cart/cart.interfaces';

export class Esim extends Sim {
  public canAdd(browseType: string, isPrepaid: boolean, isFnb: boolean): AddResult {
    const result: AddResult = { status: true, productsConflict: [] };

    if (browseType === BrowseType.acquisitionFix && this.data.sim_model === 'bbox') {
      return result;
    }

    if (isFnb && this.data.sim_model === 'fnb') {
      return result;
    }

    result.status = false;
    result.addCheck = <ResultAddMessage>{ type: TypeResult.error, message: CompatibilityMessages.simTypeNotCompatible };

    return result;
  }
}
