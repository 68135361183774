<div class="is-tri modal-compatibility">
    <div class="bloc-row title is-3 is-centered">Incompatibilité SIM/eSIM</div>
    <div class="bloc-row body">
        <span class="icon is-medium"><i class="tri-comment-exclamation-circle" aria-hidden='true'></i></span>
        <div>
            Le téléphone et la SIM/eSIM ne sont pas compatibles.<br>
            Voulez-vous continuer ?
        </div>
    </div>
    <div class="bloc-row actions">
        <button class="button is-primary" (click)="cancel()">Annuler</button>
        <button class="button is-info"    (click)="confirm()">Continuer</button>
    </div>
</div>
