import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { JsonCatalog } from '../products/interface/context';
import { Category } from './category.model';

@Component({
  selector: 'rcbt-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss'],
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class CategoryComponent implements OnInit {
  /**
   * name of category
   * @type {string}
   */
  public category: Category;
  // all products in json catalog
  public products: JsonCatalog;
  // all productsKeys from json catalog, used for wall loop
  public productsKeys: string[];

  constructor(protected route: ActivatedRoute) {}

  public ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.category = params['category'];
    });
  }
}
