import { Pipe, PipeTransform } from '@angular/core';
import { ConfigService } from '../../config.service';

@Pipe({ name: 'media' })
export class MediaPipe implements PipeTransform {
  constructor(private readonly configService: ConfigService) {}

  public transform(value: string): string {
    value = value?.startsWith('/') ? value : '/' + value;
    return (this.configService.data.media?.origin || '') + value;
  }
}
