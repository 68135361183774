export enum ShippingMethodsLabelsFront {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  LIVRAISON_DIRECTE = 'livrés par la Poste',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  CHRONOPOST = 'LIVRAISON DIRECTE',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  LA_POSTE = 'LIVRAISON DIRECTE',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  CLICK_COLLECT = 'CLICK COLLECT',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  POINT_RELAIS = 'POINT RELAIS',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  INSTALLATEUR = "remis par l'installateur",
}
