<div class="is-tri discount-modal is-flex is-vertical is-spaced-between is-aligned-left is-fullwidth" *ngIf="product">
    <div class="header is-flex is-justified-end is-aligned-start is-paddingless is-fullwidth">
        <div class="title">Régularisation</div>
        <div (click)="cancel()">
            <span class="icon is-medium">
              <i class="tri-times" aria-hidden="true"></i>
            </span>
        </div>    
    </div>

    <form class="form is-flex is-vertical is-spaced-between is-aligned-left is-fullwidth" novalidate (submit)="onSubmit($event)">
        <div class="form-row is-fullwidth is-flex is-spaced-between is-aligned-center">
            <div class="label product">
                {{ product.name }}
            </div>
            <div class="data">
                <rcbt-price [price]="product.price" [fontLevel]="4"></rcbt-price>
            </div>
        </div>
        <hr>

        <div class="form-row is-fullwidth is-flex is-spaced-between is-aligned-center">
            <div class="label">
                Régularisation
            </div>
            <div class="data">
                <rcbt-price [price]="regularizationAmount" [fontLevel]="5"></rcbt-price>
            </div>
        </div>

        <div class="form-row is-fullwidth is-flex is-spaced-between is-aligned-center">
            <div class="label">
                Prix régularisé
            </div>
            <div class="input-data field">
                <div class="control is-fullwidth is-flex is-justified-start is-aligned-center">
                    <input type= "text" class="input is-fullwidth" [(ngModel)]="regularizedPrice" [disabled]="loading" name="regularized_price"
                        amountinput (amountChange)="updatePriceRegularized($event)"/>
                    <span class="currency-symbol">€</span>
                </div>
            </div>
        </div>

        <div class="form-row is-fullwidth is-flex is-spaced-between is-aligned-center">
            <div class="label">
                Motif
            </div>
           <div class="control large-data has-dynamic-placeholder">
                <select [(ngModel)]="reasonCode" name="reason_code" class="select has-text-tertiary is-fullwidth is-paddingless">
                    <option value="" [selected]> Selectionnez un motif</option>
                    <option *ngFor="let reason of listReasons" [value]="reason.id">{{ reason.libelle }}</option>
                </select>
            </div>
        </div>

        <div class="form-row is-fullwidth is-flex is-spaced-between is-aligned-center">
            <div class="label">
                Commentaire
            </div>
            <div class="large-data textarea-wrapper">
                <textarea [maxLength]="255" class="textarea" [(ngModel)]="comment" name="_comment"></textarea>
            </div>
        </div>

        <div class="form-row is-fullwidth is-flex is-aligned-center is-justified-center">
            <div class="label has-text-danger">
                {{ error }}
            </div>
        </div>

        <div class="form-row is-fullwidth is-flex is-aligned-center is-justified-center">
            <button type="submit" class="button is-info" [disabled]="loading">
                <span class="text">Appliquer</span>
            </button>
        </div>
    </form>
</div>
