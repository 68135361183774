import { Injectable } from '@angular/core';
import { Oauth2RessourceService } from '../oauth2/oauth2-resources.service';
import { ICheckAgility } from '../promotions/agility/interfaces/agility.interfaces';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AgilityEnvelopeService {
  constructor(private oauth2RessourceService: Oauth2RessourceService) {}

  public callCheckAgilityEnvelope(amount: number): Observable<ICheckAgility> {
    return this.oauth2RessourceService
      .setLocalService()
      .ventes()
      .useSalesApi()
      .verifierAgilite()
      .setParams({ amount })
      .get<ICheckAgility>();
  }
}
