import { Component } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { Observable } from 'rxjs';
import { GlobalLoaderService } from './base/services/global-loader.service';

/**
 * This class represents the main application component.
 */
@Component({
  selector: 'rcbt-app',
  templateUrl: 'app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  // Sets initial value to true to show loading spinner on first load
  public loading = true;

  public isAppOnLoadingStatus$: Observable<boolean>;
  public forceLoadingStatusOff$: Observable<boolean>;

  constructor(
    private router: Router,
    private globalLoaderService: GlobalLoaderService,
  ) {
    this.router.events.subscribe((event: NavigationStart | NavigationEnd | NavigationCancel | NavigationError) => {
      this.navigationInterceptor(event);
    });
    this.isAppOnLoadingStatus$ = this.globalLoaderService.isAppOnLoadingStatus$;
    this.forceLoadingStatusOff$ = this.globalLoaderService.forceLoadingStatusOff$;
  }

  // loader show
  public navigationInterceptor(event: NavigationStart | NavigationEnd | NavigationCancel | NavigationError): void {
    if (this.router.url !== '/') {
      this.loading = false;
      return;
    }

    if (event instanceof NavigationStart) {
      this.loading = true;
    }

    if (event instanceof NavigationEnd) {
      this.loading = false;
    }

    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      this.loading = false;
    }

    if (event instanceof NavigationError) {
      this.loading = false;
    }
  }
}
