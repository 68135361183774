import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, of, Subscription } from 'rxjs';

import { CommercialProposal } from '../../../fundings/interfaces/funding.interface';
import { FundingService } from '../../../fundings/services/funding.service';
import { CartService } from '../../cart/cart.service';

@Component({
  selector: 'rcbt-edp',
  templateUrl: './edp.component.html',
  styleUrls: ['./edp.component.scss'],
})
export class EdpComponent implements OnInit, OnDestroy {
  public hasEdp = false;
  public edpData: Observable<CommercialProposal>;

  private subscription = new Subscription();

  constructor(
    private fundingService: FundingService,
    private cartService: CartService,
  ) {}

  public ngOnInit(): void {
    this.setEdpData();

    this.subscription.add(this.fundingService.stateEligibleFunding.subscribe(() => this.setEdpData()));

    this.subscription.add(
      this.cartService.stateSelectedFundingMode.subscribe(
        () => (this.hasEdp = this.cartService.getCurrentScheme().hasEdp),
      ),
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public toggleEdp(hasActivatedEdp: boolean): void {
    if (hasActivatedEdp) {
      return this.cartService.setEdpFundingMode();
    }
    this.cartService.setCashFundingMode();
  }

  private setEdpData(): void {
    this.hasEdp = this.cartService.getCurrentScheme().hasEdp;
    this.edpData = of(this.fundingService.getEdpFundingData(this.cartService.getCurrentScheme()));
  }
}
