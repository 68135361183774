import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbDate, NgbDatepicker, NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { CustomDatepickerI18n } from './calendar.service';

@Component({
  selector: 'rcbt-input-date',
  templateUrl: './input-date.component.html',
  styleUrls: ['./input-date.component.scss'],
  providers: [
    {
      provide: NgbDatepickerI18n,
      useClass: CustomDatepickerI18n,
    },
  ],
})
export class InputDateComponent {
  @Input() public placeholder = '';
  @Input() displayCalendar = false;
  @Input() disableAutoClose = false;
  @Input() hideInput = false;
  @Input() min: NgbDateStruct = undefined;
  @Input() max: NgbDateStruct = undefined;
  @Input() currentDate: NgbDateStruct = undefined;
  @Output() public onDateChange = new EventEmitter();
  public dateLabel: string;

  @Input() set defaultDate(date) {
    if (date) {
      this.dateLabel = this.formatDate(date);
      this.currentDate = date;
    }
  }

  @Input() disabledDates = (date: NgbDate, current: { month: number }): unknown => void 0;

  public toggleCalendar(d: NgbDatepicker): void {
    d.navigateTo(this.currentDate);
    this.displayCalendar = !this.displayCalendar;
  }

  public onDateSelect(d: NgbDateStruct): void {
    this.onDateChange.emit(d);
    this.dateLabel = this.formatDate(d);
    if (!this.disableAutoClose) {
      this.displayCalendar = false;
    }
  }

  public formatDate(d: NgbDateStruct): string {
    const day = `${d.day < 10 ? '0' : ''}${d.day}`;
    const month = `${d.month < 10 ? '0' : ''}${d.month}`;
    return `${day}/${month}/${d.year}`;
  }
}
