import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { GlobalLoaderService } from '../../base/services/global-loader.service';
import { ToolsService } from '../../base/services/tools.service';
import { Product } from '../../catalog/products/product';
import { JsonCatalog } from '../../catalog/products/interface/context';
import { Oauth2RessourceService } from '../../oauth2/oauth2-resources.service';
import { PromotionsService } from '../../promotions/promotionsService';
import { CartService } from '../cart/cart.service';
import { PromotionalEligibilityResultDto } from '../cart/dto/promotional-eligibility-output.dto';
import { Scheme } from '../cart/scheme.class';
import { CheckoutStepperService } from '../checkout-stepper.service';
import { Step } from '../../stepper/step.abstract';
import { AlertService } from '../../common/alert/alert.service';
import { combineLatest, EMPTY, Observable, Subscription } from 'rxjs';
import { PromotionComponent } from '../promotion/promotion.component';
import { AgilityComponent } from '../agility/agility.component';
import { ICheckAgility, Responses } from '../../promotions/agility/interfaces/agility.interfaces';
import { AgilityEnvelopeService } from '../AgilityEnvelopeService';
import { InsuranceConfig, InsurancePartnerCodeEnum } from '../../partner/partner.dto';
import { Accessory } from '../../catalog/products/equipement/accessory';
import { Crv } from '../../catalog/products/crv';
import { Service } from '../../catalog/products/service';
import { AccessOnlineOrder } from '../../catalog/products/equipement/accessory/accessOnlineOrder';
import { ModalAgilityCaComponent } from '../cart/summary/modals/modal-agility-ca/modal-agility-ca.component';
import { CaProduct } from '../../catalog/products/caProduct/caProduct';
import { catchError, filter, finalize, switchMap, tap } from 'rxjs/operators';
import { ConsumerLoanService } from '../../consumer-loan/consumer-loan.service';
import { ScanditService } from '../../scandit/scandit.service';
import { FundingComponent } from './funding/funding.component';
import { DocumentData, PreContractualDocument, PreContractualDownloadData } from './ticket.interface';
import { FundingService } from '../../fundings/services/funding.service';
import { Sensation } from '../../catalog/products/subscription/plan/premium/sensation';
import { Ideo } from '../../catalog/products/subscription/plan/premium/ideo';
import { Plan } from '../../catalog/products/subscription/plan';
import { CustomerService } from '../cart/customer/customer.service';
import { CustomerCategory, CustomerStep, CustomerType } from '../cart/customer/customer.interface';
import { PriceTypes } from '../../catalog/products/interface/price-type.enum';
import { UserService } from '../../user/user.service';
import { SummaryStepService } from '../steps/summary-step.service';

const LOADING_ACTIONS = {
  stepToNextStep: '[TicketComponent] step to next step',
};

const SUMMARY_PAGE_PATH = 'panier/recapitulatif';

@Component({
  selector: 'rcbt-ticket',
  templateUrl: './ticket.component.html',
  styleUrls: ['./ticket.component.scss'],
})
export class TicketComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(FundingComponent) fundingComponent: FundingComponent;
  public everyMonthIncluded = PriceTypes.everyMonthIncluded;
  public loading = false;
  public isSummaryStep: boolean;
  public isJustificatoryStep: boolean;
  public isOrderingStep: boolean;
  public isCa: boolean;
  public canApplyDiscountCA: boolean;
  public showDetail = true;
  public disabled: boolean;
  public showDiscountButton: boolean;
  public showAgilityButton = false;
  public disableAgilityButton = true;
  public nextStep: Step;
  public summaryStep: Step;
  public customerStep: CustomerStep;
  public canLoanAccess: boolean;
  public collapseHide: boolean;
  public isAppOnLoadingStatus$: Observable<boolean>;

  /**
   * [todayTotal description]
   * @type {number}
   */
  public todayTotal = 0;
  /**
   * [todayTotal description]
   * @type {number}
   */
  public todayCaTotal = 0;
  /**
   * [todayTotal description]
   * @type {number}
   */
  public todayBytelTotal = 0;
  /**
   * [monthlyTotal description]
   * @type {number}
   */
  public monthlyTotal = 0;
  public reportedTotal = 0;
  /**
   * [monthlyBillProducts description]
   * @type {Product[]}
   */
  public monthlyBillProducts: Product[] = [];
  /**
   * [monthlyBillProducts description]
   * @type {Product[]}
   */
  public reportedBillProducts: Product[] = [];
  /**
   * [todayBillProducts description]
   * @type {Product[]}
   */
  public todayBillProducts: Product[] = [];
  /**
   * [products description]
   * @type {JsonCatalog}
   */
  public products: JsonCatalog;
  public insuranceProduct: JsonCatalog;
  public spbConfig: InsuranceConfig;
  public isOntablet = false;
  public cartTotals = 0;
  public nbPromo: number;
  public nbPromoVisibility: 'visible' | 'hidden' = 'hidden';
  public canAddEdp = false;
  public expandDailyPrices = false;
  public subscriptions: Subscription = new Subscription();
  public expandMonthlyPrices = false;
  public expandInvoicePrices = false;
  public applyWrapClass = this.router.url.includes(SUMMARY_PAGE_PATH);
  private tarifAndConditionsLink = 'https://www.bouyguestelecom.fr/tarifs-conditions';
  private initPromo$ = this.setNbPromoBadge();
  private updatePromo$ = this.cartService.afterRefresh.pipe(
    switchMap(() => {
      this.canAddEdp = this.isEdpAvailable();
      return this.setNbPromoBadge();
    }),
  );

  constructor(
    private cartService: CartService,
    private stepperService: CheckoutStepperService,
    private agilityEnvelopeService: AgilityEnvelopeService,
    private modalService: NgbModal,
    private alertService: AlertService,
    private readonly scanditService: ScanditService,
    private readonly consumerLoanService: ConsumerLoanService,
    private oauth2RessourceService: Oauth2RessourceService,
    private fundingService: FundingService,
    private promotionService: PromotionsService,
    private globalLoaderService: GlobalLoaderService,
    public customerService: CustomerService,
    private router: Router,
    private userService: UserService,
  ) {
    this.subscriptions.add(this.alertService.isLoading.subscribe((isLoading: boolean) => (this.loading = isLoading)));
    this.isAppOnLoadingStatus$ = this.globalLoaderService.isAppOnLoadingStatus$;
  }

  /**
   * [ngOnInit description]
   * @return {[type]} [description]
   */
  public ngOnInit(): void {
    this.canApplyDiscountCA = this.userService.user.canApplyDiscountCA;
    this.isCa = this.userService.user.isCA();
    this.isOntablet = this.scanditService.isOnTablet();
    this.isSummaryStep = this.stepperService.getCurrentStep().code === 'summary';
    this.isOrderingStep = this.stepperService.getCurrentStep().code === 'ordering';
    this.isJustificatoryStep =
      ['justificatory', 'justificatory-v2'].indexOf(this.stepperService.getCurrentStep().code) !== -1;
    this.expandDailyPrices = this.isSummaryStep || this.isJustificatoryStep;
    this.canLoanAccess = this.consumerLoanService.canLoanAccess();
    this.canAddEdp = this.isEdpAvailable();
    this.handleInsurances();
    this.handleCartTotals();
    if (this.isSummaryStep && !this.isCa) {
      this.updateAgilityAccessibility();
    }
    this.nextStep = this.stepperService.getNextStep();
    this.showDiscountButton =
      ['justificatory', 'ordering', 'justificatory-v2'].indexOf(this.stepperService.getCurrentStep().code) === -1;

    this.subscriptions.add(this.subscribeRouter());
    this.subscriptions.add(this.subscribeCartService());
    this.subscriptions.add(this.subscribeStepperServiceChangesCurrentStep());
    this.subscriptions.add(
      this.stepperService.valideSubmit.subscribe(() => {
        this.disabled = !this.stepperService.currentStepComponent.valideSubmit();
      }),
    );
    this.subscriptions.add(combineLatest([this.initPromo$, this.updatePromo$]).subscribe());
    this.subscriptions.add(this.cartService.afterRefresh.subscribe(() => this.handleCartTotals()));
    this.subscriptions.add(this.customerService.changeStepNotif.subscribe(customerStep => this.setCustomerStep()));
    this.subscriptions.add(
      this.customerService.valideStep.subscribe(val => {
        this.disabled = !this.stepperService.currentStepComponent.valideSubmit();
        if (!!this.customerStep) {
          this.customerStep.disabled = !val;
        }
      }),
    );
  }

  public ngAfterViewInit(): void {
    this.collapseHide = this.initCollapseHide();
    this.setCustomerStep();
    this.disabled = !this.stepperService.currentStepComponent.valideSubmit();
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public toggleCollapseHide(): void {
    this.collapseHide = !this.collapseHide;
  }

  public submitStep(): void {
    this.loading = true;
    this.globalLoaderService.dispatchLoadingStatus({ actionType: LOADING_ACTIONS.stepToNextStep, isLoading: true });

    this.stepperService.currentStepComponent
      .submit()
      .pipe(
        finalize(() => {
          this.globalLoaderService.dispatchLoadingStatus({
            actionType: LOADING_ACTIONS.stepToNextStep,
            isLoading: false,
          });
        }),
        catchError(async () => (this.loading = false)),
      )
      .subscribe(result => {
        if (result) {
          this.scrollToTop();
          this.stepperService.getCurrentStep().isSubmitted = result;
          this.stepperService.goToStep(this.stepperService.getNextStep());
        } else {
          this.loading = false;
        }
      });
  }

  public passToSummaryStep(): void {
    this.loading = true;
    this.stepperService.currentStepComponent.submit().subscribe(result => {
      if (result) {
        this.stepperService.goToStep(this.stepperService.getSummaryStep());
      } else {
        this.loading = false;
      }
    });
  }

  public openPromoPopin(): void {
    const options: NgbModalOptions = <NgbModalOptions>{
      backdrop: 'static',
      size: 'lg',
      windowClass: 'promos-ticket-modal',
      keyboard: false,
    };
    this.modalService.open(PromotionComponent, options);
  }

  public openAgilityPopin(): void {
    const options: NgbModalOptions = <NgbModalOptions>{
      backdrop: 'static',
      size: 'lg',
      windowClass: 'scoring-details-modal',
      keyboard: false,
    };
    this.modalService.open(AgilityComponent, options);
  }

  public openAgilityCaModal(): void {
    const options: NgbModalOptions = <NgbModalOptions>{
      backdrop: 'static',
      size: 'lg',
      windowClass: 'modal-agility-ca',
      keyboard: false,
    };
    this.modalService.open(ModalAgilityCaComponent, options);
  }

  public goToTarifsAndConditions(): void {
    this.subscriptions.add(
      this.oauth2RessourceService
        .preContractual(this.cartService.cart.cartId)
        .get<PreContractualDocument>()
        .pipe(
          switchMap(({ documents: documentsData }) => {
            const documents = ToolsService.getElementsByPropertyValue<DocumentData>(
              documentsData,
              'typeDocument',
              'RECAP_CONTRACTUEL',
            );
            // Redirect to web page if MEDOC returns more than one document
            if (documents.length !== 1) {
              this.redirectToTarifAndConditionPage();
            }
            const path = documents[0]._links.recupererLienTelechargementUniversel.href;
            return this.oauth2RessourceService.downloadPreContractual(path).get<PreContractualDownloadData>();
          }),
          catchError(() => this.redirectToTarifAndConditionPage()),
        )
        .subscribe(({ _links }) => {
          const url = this.oauth2RessourceService.getUrl() + _links.telechargerDocumentLienEphemere.href;
          ToolsService.openNewTab(url);
        }),
    );
  }

  public submitCustomerStep(): void {
    this.customerService.changeCustomerStep({ step: this.customerService.getNextStep().value, submit: true });
  }

  protected schemeTotals(scheme: Scheme): void {
    if (scheme) {
      for (const product of scheme.products) {
        this.productsListPush(product);
        this.totalSum(product);
      }
    }
  }

  private subscribeRouter(): Subscription {
    return this.router.events.subscribe(routerEvent => {
      if (!(routerEvent instanceof NavigationStart)) {
        return;
      }
      if (routerEvent.url.includes(SUMMARY_PAGE_PATH)) {
        this.applyWrapClass = true;
      }
    });
  }

  private subscribeCartService(): Subscription {
    return this.cartService.onChangesSchemes
      .pipe(
        tap(() => {
          this.handleInsurances();
          this.handleCartTotals();
        }),
        filter(() => this.stepperService.currentStepComponent != null),
      )
      .subscribe(() => {
        this.nextStep = this.stepperService.getNextStep();
        this.disabled = !this.stepperService.currentStepComponent.valideSubmit();
      });
  }

  private subscribeStepperServiceChangesCurrentStep(): Subscription {
    return this.stepperService.onChangesCurrentStep
      .pipe(filter(() => this.stepperService.currentStepComponent != null))
      .subscribe(() => {
        this.showDiscountButton =
          ['justificatory', 'ordering', 'justificatory-v2'].indexOf(this.stepperService.getCurrentStep().code) === -1;
        this.nextStep = this.stepperService.getNextStep();
        this.disabled = !this.stepperService.currentStepComponent.valideSubmit();
        const currentScheme2 = this.cartService.getCurrentScheme();
        if (!!currentScheme2) {
          if (
            !(this.stepperService.getNextStep() instanceof SummaryStepService) &&
            this.customerService.customer.type === CustomerType.prospect &&
            this.customerService.customer.category === CustomerCategory.gp &&
            ['accessories', 'services', 'crv', 'ventes-complementaires'].includes(
              this.stepperService.getCurrentStep().code,
            ) &&
            currentScheme2.products.every(
              product =>
                (product instanceof Accessory && !(product instanceof AccessOnlineOrder)) ||
                product instanceof Crv ||
                product instanceof Service ||
                product instanceof CaProduct,
            )
          ) {
            this.summaryStep = this.stepperService.getSummaryStep();
          } else {
            this.summaryStep = undefined;
          }
        }
      });
  }

  private productsListPush(product: Product): void {
    switch (product.priceType ?? PriceTypes.today) {
      case PriceTypes.today:
        this.todayBillProducts.push(product);
        return;
      case PriceTypes.everyMonth:
      case PriceTypes.everyMonthIncluded:
        this.monthlyBillProducts.push(product);
        return;
      case PriceTypes.reported:
        this.reportedBillProducts.push(product);
        return;
    }
  }

  private totalSum(product: Product): void | number {
    switch (product.priceType ?? PriceTypes.today) {
      case PriceTypes.today:
        this.todayTotal += product.price;
        return;
      case PriceTypes.everyMonth:
        this.monthlyTotal += product.price;
        return;
      case PriceTypes.reported:
        this.reportedTotal += product.price;
        return;
    }
  }

  private isEdpAvailable(): boolean {
    const plan: Plan =
      this.cartService.getCurrentScheme().getProductByType(Sensation) ||
      this.cartService.getCurrentScheme().getProductByType(Ideo);
    return (
      this.cartService.getCurrentScheme().isEligibleEDP(this.cartService.isEligibleEdp, plan?.gencode || '') &&
      !this.isSummaryStep &&
      !this.isJustificatoryStep &&
      !this.isOrderingStep
    );
  }

  private handleInsurances(): void {
    const currentScheme: Scheme = this.cartService.getCurrentScheme();
    if (currentScheme) {
      this.spbConfig = currentScheme.addedInsurances.find(
        (insuranceConfig: InsuranceConfig) => insuranceConfig.code === InsurancePartnerCodeEnum.spb,
      );
    }
  }

  private updateAgilityAccessibility(): void {
    this.agilityEnvelopeService.callCheckAgilityEnvelope(null).subscribe((checkAgilityResponse: ICheckAgility) => {
      if (checkAgilityResponse && checkAgilityResponse.response === Responses.koCa) {
        this.showAgilityButton = false;
      } else {
        this.showAgilityButton = true;
        this.disableAgilityButton = !(checkAgilityResponse && checkAgilityResponse.response === Responses.ok);
      }
    });
  }

  private handleCartTotals(): void {
    this.todayBillProducts = [];
    this.monthlyBillProducts = [];
    this.reportedBillProducts = [];
    this.monthlyTotal = 0;
    this.todayTotal = 0;
    this.todayCaTotal = 0;
    this.todayBytelTotal = 0;
    this.reportedTotal = 0;

    this.showDetail =
      ['summary', 'justificatory', 'ordering', 'justificatory-v2'].indexOf(this.stepperService.getCurrentStep().code) <
      0;
    if (this.showDetail) {
      this.schemeTotals(this.cartService.getCurrentScheme());
    } else {
      this.todayCaTotal = this.cartService.cart.caTotals.today;
      this.todayBytelTotal = this.cartService.cart.bytelTotals.today;
      this.monthlyTotal = this.cartService.cart.totals.everyMonth;
      this.reportedTotal = this.cartService.cart.totals.reported;
      this.todayTotal = this.todayBytelTotal + this.todayCaTotal;
      this.updateTotalWithCredit();
      this.subscriptions.add(
        this.fundingService.stateCredit.subscribe(isOnCredit => {
          if (!isOnCredit) {
            this.monthlyTotal = this.cartService.cart.totals.everyMonth;
            this.todayTotal = this.todayBytelTotal + this.todayCaTotal;
            this.cartTotals = this.todayTotal;
          }
        }),
      );
      this.subscriptions.add(
        this.fundingService.selectedProposal$$.subscribe(commercialProposal => {
          if (commercialProposal && !!this.cartService.cart.creditData && !!this.fundingService.bestProposal) {
            this.cartTotals = commercialProposal.initialAmount;
            this.monthlyTotal = this.cartService.cart.totals.everyMonth + Number(commercialProposal.monthlyAmount);
          }
        }),
      );
    }
    this.cartTotals = this.todayTotal;
  }

  private updateTotalWithCredit(): void {
    if (
      !!this.cartService.cart.creditData &&
      this.fundingService.selectedProposal$$.getValue() &&
      !!this.fundingService.bestProposal
    ) {
      this.todayTotal = this.fundingService.selectedProposal$$.getValue().initialAmount;
      this.monthlyTotal =
        this.cartService.cart.totals.everyMonth +
        Number(this.fundingService.selectedProposal$$.getValue().monthlyAmount);
    }
  }

  private redirectToTarifAndConditionPage(): Observable<never> {
    ToolsService.openNewTab(this.tarifAndConditionsLink);
    return EMPTY;
  }

  private initCollapseHide(): boolean {
    return !!this.fundingComponent && this.isOntablet;
  }

  private setNbPromoBadge(): Observable<PromotionalEligibilityResultDto> {
    return this.promotionService.cartEligPromoResult$.pipe(
      tap(promotionalEligibilityInputDto => {
        if (!promotionalEligibilityInputDto) {
          return;
        }
        this.nbPromo = this.getAllManuellePromotions(promotionalEligibilityInputDto).length;
        this.nbPromoVisibility = this.nbPromo > 0 ? 'visible' : 'hidden';
      }),
    );
  }

  private getAllManuellePromotions({ promotions, parcours }: PromotionalEligibilityResultDto): number[] {
    const basketPromotionIds: number[] = promotions.manuelles.reduce((acc, { id }) => {
      acc.push(id);
      return acc;
    }, []);

    const productPromotionIds: number[] = [];
    parcours.forEach(({ produits }) =>
      produits.forEach(({ promotions: productPromotions }) =>
        productPromotions?.manuelles?.forEach(({ id }) => productPromotionIds.push(id)),
      ),
    );

    return Array.from(new Set([...basketPromotionIds, ...productPromotionIds]));
  }

  private scrollToTop(): void {
    setTimeout(() => {
      window.scroll({ left: 0, top: 0, behavior: 'smooth' });
    }, 0);
  }

  private setCustomerStep(): void {
    this.customerStep =
      this.stepperService.getCurrentStep().code === 'customer' ? this.customerService.getNextStep() : null;
    if (!!this.customerStep) {
      this.customerStep.disabled = this.customerService.currentStep.disabled;
    }
  }
}
