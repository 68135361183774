import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'rcbt-duplicate-command',
  templateUrl: './duplicate-command.component.html',
  styleUrls: ['./duplicate-command.component.scss'],
})
export class DuplicateCommandComponent {
  public idCommand: string;

  constructor(protected activeModal: NgbActiveModal) {}

  public cancel(): void {
    this.activeModal.dismiss();
  }

  public change(): void {
    this.activeModal.close();
  }
}
