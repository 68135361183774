<div class="box" [ngClass]="{'sticky': isSummaryStep || isJustificatoryStep || isOrderingStep}">
    <div class="box-content">
        <p class="title has-text-left is-level-1">
            {{ showDetail ? 'Acte en cours' :  'Panier en cours' }}
        </p>
        <div class="is-divider is-unboxed"></div>
        <div class="equipment-prices" data-cy="ticket-section-today">
            <div class="columns is-vcentered is-mobile">
                <span (click)="expandDailyPrices = !expandDailyPrices"
                      class="icon-and-text has-text-left has-cursor column is-flex">
                    <span [ngClass]="{'up': !expandDailyPrices, 'down': expandDailyPrices}"
                          class="icon is-small icon-rotate" *ngIf="!isSummaryStep && !isJustificatoryStep && !isOrderingStep">
                        <span class="tri-arrow-down"></span>
                    </span>
                    <span class="is-size-5 has-text-weight-semibold">Aujourd'hui</span>
                </span>
                <div class="is-narrow column has-text-right">
                    <rcbt-price [price]="cartTotals" [fontLevel]="5" data-cy="ticket-section-today-total"></rcbt-price>
                </div>
            </div>
            <div [ngClass]="{'is-collapsed': !expandDailyPrices }" class="price-details" data-cy="ticket-section-today-details">
                <ng-container *ngIf="!showDetail && isCa">
                    <div class="columns">
                        <div class="column is-8">
                            Dont total
                        </div>

                        <div class="column is-4 box-prices">
                            <rcbt-price [price]="todayBytelTotal" [fontLevel]="6"></rcbt-price>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column is-8">
                            * Dont total distributeur
                        </div>

                        <div class="column is-4 box-prices">
                            <rcbt-price [price]="todayCaTotal" [fontLevel]="6"></rcbt-price>
                        </div>
                    </div>
                </ng-container>
                <div class="column is-12"  *ngIf="!isSummaryStep && !isJustificatoryStep && !isOrderingStep">
                    <div class="product" *ngFor="let product of todayBillProducts">
                        <div class="columns" data-cy="ticket-section-today-detail">
                            <div class="column is-8">
                                <span *ngIf="product.type_id === 'produit_ca'" data-cy="ticket-section-today-detail-ca">*&nbsp;</span>
                                <span data-cy="ticket-section-today-detail-name">{{['box', 'DEVICE_FAI_GENERIC'].includes(product.type_id) && product.deviceElement?.equipementTypeMIM ? product.deviceElement.equipementTypeMIM : product.name}}</span>
                            </div>
                            <div class="column is-4 box-prices">
                                <rcbt-price [price]="(!product.deviceElement || product.data?.to_scan) ? product.oldPrice : product.price"
                                    [fontLevel]="6" data-cy="ticket-section-today-detail-price"></rcbt-price>
                            </div>
                        </div>
                        <ng-container *ngIf="product.oldPrice > product.price && product.priceType == 0" >
                            <div class="columns" data-cy="ticket-section-today-detail" *ngFor="let promotion of product.promotions" >
                                <div *ngIf="promotion.isApplied() && !promotion.isDeferred()" class="column is-8">
                                    <span *ngIf="product.type_id === 'produit_ca'" data-cy="ticket-section-today-detail-ca">*&nbsp;</span>
                                    <span data-cy="ticket-section-today-detail-name">{{ promotion.label || promotion.name || 'Remise' }}</span>
                                </div>
                                <div *ngIf="promotion.isApplied() && !promotion.isDeferred()" class="column is-4 box-prices">
                                    <rcbt-price [price]="-promotion.application.actionPromotion.amount" [fontLevel]="6" data-cy="ticket-section-today-detail-price"></rcbt-price>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
        <div class="is-divider"></div>
        <div class="subscription-prices" data-cy="ticket-section-monthly">
            <div class="columns is-vcentered is-mobile">
                <span (click)="expandMonthlyPrices = !expandMonthlyPrices"
                      class="icon-and-text has-text-left has-cursor column is-flex">
                    <span [ngClass]="{'up': !expandMonthlyPrices, 'down': expandMonthlyPrices}"
                          class="icon is-small icon-rotate" *ngIf="!isSummaryStep && !isJustificatoryStep && !isOrderingStep">
                        <span class="tri-arrow-down"></span>
                    </span>
                    <span class="is-size-5 has-text-weight-semibold">Par mois</span>
                </span>
                <div class="is-narrow column has-text-right">
                    <rcbt-price [price]="monthlyTotal" [fontLevel]="5" data-cy="ticket-section-monthly-total"></rcbt-price>
                </div>
            </div>
            <div [ngClass]="{'is-collapsed': !expandMonthlyPrices}" class="price-details" *ngIf="showDetail" data-cy="ticket-section-monthly-details">
                <div class="columns toggle-content">
                    <div class="column is-12">
                        <div class="product" *ngFor="let product of monthlyBillProducts">
                            <div class="columns" data-cy="ticket-section-monthly-detail">
                                <div class="column is-9">
                                    <span data-cy="ticket-section-monthly-detail-name"><strong *ngIf="product.priceType === everyMonthIncluded">dont </strong>{{product.name}}</span>
                                </div>
                                <div class="column is-3 box-prices">
                                    <rcbt-price [price]="product.oldPrice" [fontLevel]="6" data-cy="ticket-section-monthly-detail-price"></rcbt-price>
                                </div>
                            </div>
                            <ng-container *ngIf="product.oldPrice > product.price && product.priceType == 1">
                                <div class="columns" *ngFor="let promotion of product.promotions" data-cy="ticket-section-monthly-detail">
                                    <div *ngIf="promotion.isApplied() && !promotion.isDeferred()" class="column is-8 small">
                                        <span data-cy="ticket-section-monthly-detail-name">{{ promotion.label || promotion.name || 'Remise' }}</span>
                                    </div>
                                    <div *ngIf="promotion.isApplied() && !promotion.isDeferred()" class="column is-4 box-prices small">
                                        <rcbt-price [price]="-promotion.application.actionPromotion.amount" [fontLevel]="6" data-cy="ticket-section-monthly-detail-price"></rcbt-price>
                                    </div>
                                </div>
                            </ng-container>
                            <p class="price-duration" *ngIf="product.promotion">{{ product.promotion.description }}</p>
                            <rcbt-ticket-renew-promotions [product]="product" class="columns" data-cy="ticket-section-monthly-detail"></rcbt-ticket-renew-promotions>
                        </div>
                        <!-- Assurances -->
                        <div class="columns product" *ngIf="spbConfig" data-cy="ticket-section-monthly-detail">
                            <div class="column is-8">
                                <span data-cy="ticket-section-monthly-detail-name">Assurance SPB</span>
                            </div>
                            <div class="column is-4 box-prices">
                                <rcbt-price *ngIf="spbConfig?.price" [price]="spbConfig.price" [fontLevel]="6" data-cy="ticket-section-monthly-detail-price"></rcbt-price>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="is-divider" *ngIf="reportedBillProducts.length"></div>
        <div class="" *ngIf="reportedBillProducts.length" data-cy="ticket-section-onInvoice">
            <div class="columns is-vcentered is-mobile">
                <span (click)="expandInvoicePrices = !expandInvoicePrices"
                      class="icon-and-text has-text-left has-cursor column is-flex">
                    <span [ngClass]="{'up': !expandInvoicePrices, 'down': expandInvoicePrices}"
                          class="icon is-small icon-rotate" *ngIf="!isSummaryStep && !isJustificatoryStep && !isOrderingStep">
                        <span class="tri-arrow-down"></span>
                    </span>
                    <span class="is-size-5 has-text-weight-semibold">Sur facture</span>
                </span>
                <div class="is-narrow column has-text-right">
                    <rcbt-price [price]="reportedTotal" [fontLevel]="5" data-cy="ticket-section-onInvoice-total"></rcbt-price>
                </div>
            </div>
            <div class="price-details" [ngClass]="{'is-collapsed': !expandInvoicePrices}" *ngIf="showDetail" data-cy="ticket-section-onInvoice-details">
                <div class="columns toggle-content" data-cy="ticket-section-onInvoice-detail">
                    <div class="column is-12">
                        <div class="product" *ngFor="let product of reportedBillProducts">
                            <div class="columns">
                                <div class="column is-8">
                                    <span data-cy="ticket-section-onInvoice-detail-name">{{product.name}}</span>
                                </div>
                                <div class="column is-4 box-prices">
                                    <rcbt-price [price]="product.price" [fontLevel]="6" data-cy="ticket-section-onInvoice-detail-price"></rcbt-price>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="is-divider"></div>
        <div class="buttons actions is-centered">
            <div class="ticket-left">
                <button *ngIf="showDiscountButton" class="button is-info btn-discount" data-cy="btn-discount" (click)="openPromoPopin()">
                    <span class="text-discount" [attr.data-nbPromo]="nbPromo" [style]="{'--show-nb-promo': nbPromoVisibility }" data-cy="nb-discount">Remises</span>
                </button>
            </div>
            <div class="ticket-right" [ngClass]="{ 'summary-page': applyWrapClass }">
                <button *ngIf="nextStep && !customerStep" (click)="submitStep()"
                        id="btn-next" class="button is-primary is-icon-only" data-cy="btn-next"
                        [disabled]="loading || disabled || (isAppOnLoadingStatus$ | async)"
                        [ngClass]="{
                        'action-feedback' : loading,
                        'is-fullwidth': showDiscountButton}">
                    <span class="icon is-small" aria-label="Icone de taille small">
                         <i class="tri-arrow-right" aria-hidden="true"></i>
                    </span>
                    <span class="next-step-label"> {{ nextStep.labelButton ? nextStep.labelButton : nextStep.label }} </span>
                </button>

                <button *ngIf="!!customerStep"
                    id="btn-next-customer" class="button is-primary" data-cy="btn-next-customer"
                    [disabled]="customerStep.disabled || loading || (isAppOnLoadingStatus$ | async)"
                    [ngClass]="{'action-feedback' : loading, 'is-fullwidth': showDiscountButton, 'tablet': isOntablet}"
                    (click)="submitCustomerStep()"
                >
                    <span class="next-step-label"> {{ customerStep?.label }} </span>
                </button>

                <button *ngIf="summaryStep" data-cy="btn-next-summary" id="btn-recap" class="button is-primary" (click)="passToSummaryStep()" [disabled]="loading || disabled">
                    <span class="icon is-small" aria-label="Icone de taille small">
                         <i class="tri-arrow-right" aria-hidden="true"></i>
                    </span>
                    <span >Récap.</span>
                </button>
            </div>
        </div>
        <ng-container *ngIf="canAddEdp">
            <div class="is-divider"></div>
            <rcbt-edp></rcbt-edp>
        </ng-container>
        <div class="is-divider" *ngIf="showAgilityButton || (isSummaryStep && todayCaTotal > 0 && canApplyDiscountCA)"></div>
        <div class="agility-summary" *ngIf="showAgilityButton || (isSummaryStep && canLoanAccess)">
            <button *ngIf="showAgilityButton" data-cy="button-agility-co" class="button is-info" (click)="openAgilityPopin()" [disabled]="disableAgilityButton">
                <span>Agilité Co</span>
            </button>
        </div>
        <div class="agility-summary" *ngIf="isSummaryStep && todayCaTotal > 0 && canApplyDiscountCA">
            <button class="button is-info" (click)="openAgilityCaModal()" [disabled]="loading"
                [ngClass]="{'action-feedback': loading}" data-cy="button-discount">
                <span>Rabais panier</span>
            </button>
        </div>
        <rcbt-toggle-regularisation *ngIf="isSummaryStep"></rcbt-toggle-regularisation>
        <div class="is-divider" *ngIf="isSummaryStep"></div>
        <rcbt-newscheme *ngIf="isSummaryStep" class="wrapper-newschemet"> </rcbt-newscheme>
        <rcbt-cggc *ngIf="isSummaryStep"></rcbt-cggc>
        <div class="tarifs-conditions-button" *ngIf="!isOntablet && isJustificatoryStep">
            <button class="button is-info" (click)="goToTarifsAndConditions()" data-cy="button-tarifs">Tarifs et conditions</button>
        </div>
    </div>
</div>

<rcbt-funding #fundingComponent *ngIf="!isSummaryStep && !isJustificatoryStep && !isOrderingStep"></rcbt-funding>
