export interface ICheckAgility {
  response: Responses;
  message: Messages;
  currentAmountEnvelope?: number;
}

export interface IAgilityEnvelope {
  id: number;
  codeEns: string;
  codePdv: string;
  name?: string;
  initialAmount: number;
  currentAmount: number;
}

export enum Responses {
  koCa = 'KO_CA',
  koEnveloppeInexistante = 'KO_ENVELOPPE_INEXISTANTE',
  koEnveloppeVide = 'KO_ENVELOPPE_VIDE',
  koAgilitecoNegative = 'KO_AGILITECO_NEGATIVE',
  koMontantExcedeEnveloppe = 'KO_MONTANT_EXCEDE_ENVELOPPE',
  ok = 'OK',
}

export enum Messages {
  koCa = "Les boutiques CA n'ont pas accès à l'agilité commerciale",
  koEnveloppeInexistante = 'Aucune enveloppe paramétrée pour cette boutique',
  koEnveloppeVide = "Enveloppe d'agilité commerciale vide",
  koAgilitecoNegative = "Le montant de l'agilité commerciale en entrée doit être positif",
  koMontantExcedeEnveloppe = "Le montant de l'agilité commerciale excède l'enveloppe disponible :",
  ok = 'Succès',
}
