import { Component, AfterViewInit } from '@angular/core';
import { ScanService } from './scan.service';

@Component({
  selector: 'rcbt-scan',
  templateUrl: './scan.component.html',
  styleUrls: ['./scan.component.scss'],
})
export class ScanComponent implements AfterViewInit {
  /**
   * [constructor description]
   * @param  {ScanService} privatescanService [description]
   * @return {[type]}                         [description]
   */
  constructor(private scanService: ScanService) {}
  /**
   * [ngOnInit description]
   * @return {[type]} [description]
   */
  public ngAfterViewInit(): void {
    this.scanService.onBeginScan.next(true);
  }
}
