import { Component, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'rcbt-modal-update-warning',
  templateUrl: './modal-update-warning.component.html',
  styleUrls: ['./modal-update-warning.component.scss'],
})
export class ModalUpdateWarningComponent {
  public loading = false;
  public continueEvent: EventEmitter<void> = new EventEmitter();
  public cancelEvent: EventEmitter<void> = new EventEmitter();

  constructor(private activeModal: NgbActiveModal) {}

  public close(): void {
    this.activeModal.close();
    this.cancelEvent.emit();
  }

  public continue(): void {
    this.continueEvent.emit();
  }
}
