import { DateUniversal } from '../base/class/date-universal.class';

export class ContextType {
  public date: DateUniversal;
  public isAvailable = true;
  public display = true;
  public loading = false;
  protected key: string;
  protected label: string;

  constructor(key: string, label: string) {
    this.key = key;
    this.label = label;
  }
}
