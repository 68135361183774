<section class="accordions">
    <article class="accordion" [ngClass]="{'is-active': displayPanel}">
        <div class="accordion-header is-flex">
            <h4 class="is-marginless">
                <div *ngIf="!isRenew">ACQUISITION {{ plan }} </div>
                <div *ngIf="isRenew">RENOUVELLEMENT {{ plan }} </div>
            </h4>
            <div class="actions">
                <button class="button link action" [disabled]="loading || loadingUpdate" (click)="onUpdateScheme()">Modifier</button>
                <button class="button link action" [disabled]="loading || loadingDelete" (click)="onDeleteScheme()">Supprimer</button>
            </div>
            <button class="toggle is-marginless" aria-label="toggle" (click)="displayPanel = !displayPanel"></button>
        </div>
        <div class="accordion-body accordion-body-modif has-background-white">
            <div class="accordion-content">
                <table class="table is-fullwidth is-marginless" *ngIf="scheme.products.length || scheme.addedInsurances.length">
                    <thead>
                        <tr>
                            <th class="has-background-white"><p class="is-bold">PRODUIT</p></th>
                            <th class="has-background-white"><p class="is-bold is-flex is-justified-center">AUJOURD'HUI</p></th>
                            <th class="has-background-white"><p class="is-bold is-flex is-justified-center">PAR MOIS</p></th>
                            <th class="has-background-white"><p class="is-bold is-flex is-justified-center">SUR FACTURE</p></th>
                            <th *ngIf="oneMoreColumn" class="has-background-white"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <!--  Produits -->
                        <ng-container *ngFor="let product of scheme.products">
                            <ng-container *ngIf="product.aoData?.innerResponse?.commandeAccessOnLine; else elseBlock">
                                <tr class="AccessOnlineHeader">
                                    <td><span *ngIf="product.type_id === 'produit_ca'">*&nbsp;</span>{{ product.name }}</td>
                                    <td><rcbt-price *ngIf="product.priceType === 0 || product.priceType === undefined" [price]="product.oldPrice" [fontLevel]="6" [isSecondaryNegatif]="true"></rcbt-price></td>
                                    <td><rcbt-price *ngIf="product.priceType === 1" [price]="product.oldPrice" [fontLevel]="6" [isSecondaryNegatif]="true"></rcbt-price></td>
                                    <td><rcbt-price *ngIf="product.priceType === 3" [price]="product.oldPrice" [fontLevel]="6" [isSecondaryNegatif]="true"></rcbt-price></td>
                                    <td class="has-text-centered" *ngIf="oneMoreColumn"></td>
                                </tr>
                                <ng-container *ngFor="let accessory of product.aoData.innerResponse.commandeAccessOnLine.detailProduitsAOL.produitsAOL">
                                    <tr class="disableTopBorder">
                                        <td class="has-text-tertiary is-italic">{{ accessory.libelleCommercial }}</td>
                                        <td><rcbt-price [price]="accessory.tarifCommercial.montantTTC" [fontLevel]="6" [isSecondaryNegatif]="true"></rcbt-price></td>
                                        <td></td>
                                        <td></td>
                                        <td class="has-text-centered" *ngIf="oneMoreColumn"></td>
                                    </tr>
                                </ng-container>
                                <tr class="disableTopBorder">
                                    <td class="has-text-tertiary is-italic">{{(product.aoData.innerResponse.commandeAccessOnLine.modeLivraison === 'CLUB') ? 'LIVRAISON CLUB BOUYGUES TELECOM' : 'CHEZ LE CLIENT'}}</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td *ngIf="oneMoreColumn"></td>
                                </tr>
                            </ng-container>
                            <ng-template #elseBlock>
                                <tr *ngIf="product.type_id !== 'edp'">
                                    <td *ngIf="product.type_id !== 'credit'">
                                        <span *ngIf="product.type_id === 'produit_ca'">*&nbsp;</span>
                                        {{['box', 'DEVICE_FAI_GENERIC'].includes(product.type_id) && product.deviceElement?.equipementTypeMIM ? product.deviceElement.equipementTypeMIM : product.name}}
                                    </td>
                                    <td *ngIf="product.type_id === 'credit'">
                                        <div [innerHTML]="'Financement à crédit ' + (product.creditData.final ? '' : 'à partir de ') + (product.creditData.oneOff | customCurrency)+' + '+(product.creditData.monthlyPrice | customCurrency)+' * '+(product.creditData.mensualities)+' mois, TAEG '+(product.creditData.taeg)+'% (soit au total '+(product.creditData.totalPrice | customCurrency)+' dont '+(product.creditData.interests | customCurrency)+' d\'intérêts)'"></div>
                                    </td>
                                    <td *ngIf="!product.deviceElement && product.type_id !== 'credit'"><rcbt-price *ngIf="product.priceType === 0 || product.priceType === undefined" [price]="product.oldPrice" [fontLevel]="6" [isSecondaryNegatif]="true"></rcbt-price></td>
                                    <td *ngIf="product.deviceElement && !product.data?.to_scan"><rcbt-price *ngIf="product.priceType === 0 || product.priceType === undefined" [price]="product.price" [fontLevel]="6" [isSecondaryNegatif]="true"></rcbt-price></td>
                                    <td *ngIf="product.deviceElement && product.data?.to_scan"><rcbt-price *ngIf="product.priceType === 0 || product.priceType === undefined" [price]="product.baseCost" [fontLevel]="6" [isSecondaryNegatif]="true"></rcbt-price></td>
                                    <td *ngIf="product.type_id === 'credit'"><rcbt-price  [price]="-product.creditData.price" [fontLevel]="6" [isSecondaryNegatif]="true"></rcbt-price></td>
                                    <td><rcbt-price  *ngIf="product.priceType === 1" [price]="product.oldPrice" [fontLevel]="6" [isSecondaryNegatif]="true"></rcbt-price></td>
                                    <td><rcbt-price  *ngIf="product.priceType === 3" [price]="product.price" [fontLevel]="6" [isSecondaryNegatif]="true"></rcbt-price></td>
                                    <td class="has-text-centered is-cell-button regul-button" *ngIf="oneMoreColumn">
                                        <rcbt-btn-regularization *ngIf="canBeRegularized(product)" [product]="product" [scheme]="scheme" [loading]="loading"></rcbt-btn-regularization>
                                        <button class="button is-info button-odr" [ngClass]="{'button-margin-top': canBeRegularized(product)}" *ngIf="product.getData('odr')" (click)="downloadFileODR(product)">
                                            ODR&nbsp;<rcbt-price [price]="product.getData('odr').amount" [fontLevel]="7"></rcbt-price>
                                        </button>
                                        <button class="button" *ngIf="isMobileTakeBack(product)" [disabled]="loading || hasGrantedLoan"
                                                [ngClass]="{'is-info': (product.isCessionCoupon() || product.isMtbCessionCouponPrinted), 'is-primary': (!product.isCessionCoupon() && !product.isMtbCessionCouponPrinted),
                                                'is-disabled': loading || hasGrantedLoan, 'button-margin-top': canBeRegularized(product) || product.getData('odr')}"
                                                data-cy="print-transfer-voucher"
                                                (click)="printMobileTakeBackCoupon(product)">
                                            Imprimer bon de cession
                                        </button>
                                        <ng-template dynamicComponentHost ></ng-template>
                                    </td>
                                </tr>
                            </ng-template>

                            <!--  Promotions -->
                            <ng-container *ngFor="let promotion of product.promotions">
                                <tr *ngIf="promotion.isApplied() && !promotion.isDeferred() && !!promotion.application.actionPromotion.amount">
                                    <td class="has-text-info is-italic" [attr.data-cy]="'promo-'+promotion.id">
                                        <span *ngIf="product.type_id === 'produit_ca'">*&nbsp;</span>
                                        {{ promotion.label || promotion.name || 'Remise' }}
                                    </td>
                                    <td><rcbt-price *ngIf="product.priceType === 0 || product.priceType === undefined" [price]="0 - promotion.application.actionPromotion.amount" [fontLevel]="6" [isSecondaryNegatif]="true"></rcbt-price></td>
                                    <td><rcbt-price *ngIf="product.priceType === 1" [price]="0 - promotion.application.actionPromotion.amount" [fontLevel]="6" [isSecondaryNegatif]="true"></rcbt-price></td>
                                    <td><rcbt-price *ngIf="product.priceType === 3" [price]="0 - promotion.application.actionPromotion.amount" [fontLevel]="6" [isSecondaryNegatif]="true"></rcbt-price></td>
                                    <td class="has-text-centered" *ngIf="oneMoreColumn">
                                        <button class="button is-icon-only is-warning" *ngIf="regularizationService.inModeRegularisation && isRegularization(promotion) && canDeleteRegularization(promotion)"
                                            [disabled]="loading" (click)="onDeleteRegularization(product)">
                                            <span class="icon is-small"><i class="tri-trash" aria-hidden='true'></i></span>
                                        </button>
                                    </td>
                                </tr>
                            </ng-container>
                            <rcbt-scheme-renew-promotions *ngIf="isRenew" [product]="product"></rcbt-scheme-renew-promotions>
                        </ng-container>

                        <!--  EDP  -->
                        <tr *ngIf="scheme.hasEdp && !loading">
                            <td>Etalement de paiement</td>
                            <td></td>
                            <td> <rcbt-price  [price]="edpCommercialProposal?.monthlyAmount" [fontLevel]="6" [isSecondaryNegatif]="true"></rcbt-price></td>
                            <td></td>
                            <td *ngIf="oneMoreColumn"></td>
                        </tr>

                        <!--  Assurances SPB  -->
                        <tr *ngIf="spbConfig">
                            <td>Assurance SPB</td>
                            <td></td>
                            <td><rcbt-price *ngIf="spbConfig.price" [price]="spbConfig.price" [fontLevel]="6" [isSecondaryNegatif]="true"></rcbt-price></td>
                            <td></td>
                            <td class="has-text-centered is-cell-button" *ngIf="oneMoreColumn">
                                <rcbt-partner-spb [scheme]="scheme" [gencode]="spbConfig.gencode" [imei]="spbConfig.imei" [ibanBic]="spbConfig.customerIbanBic">Souscrire</rcbt-partner-spb>
                            </td>
                        </tr>
                    </tbody>
                    <tfoot class="has-background-secondary-10">
                        <tr>
                            <td><p class="title is-level-3">TOTAL DE L'ACTE</p></td>
                            <td><rcbt-price *ngIf="updateTotal().today !== 0"[price]="updateTotal().today" [fontLevel]="5" [isSecondaryNegatif]="true"></rcbt-price></td>
                            <td><rcbt-price *ngIf="scheme.totals.everyMonth !== 0" [price]="scheme.totals.everyMonth" [fontLevel]="5" [isSecondaryNegatif]="true"></rcbt-price></td>
                            <td><rcbt-price *ngIf="updateTotal().reported !== 0" [price]="updateTotal().reported" [fontLevel]="5" [isSecondaryNegatif]="true"></rcbt-price></td>
                            <td *ngIf="oneMoreColumn"></td>
                        </tr>
                        <tr class="border-solid-blue" *ngIf="canSwitchFundingMode">
                            <td class="title is-level-3" colspan="2"> PAIEMENT DU TERMINAL </td>
                            <td class="has-text-centered"
                                (click) = "activeComptant()">
                                <button class="button is-info"
                                        [ngClass]="{'is-outlined' : scheme.hasEdp || cartService.cart.creditData}"
                                        [disabled]="loading"> Comptant </button>
                            </td>
                            <td class="has-text-centered"
                                (click) = "activeEdp()">
                                <button class="button is-info"
                                        [ngClass]="{'is-outlined' : !scheme.hasEdp}"
                                        [disabled]="loading"> EDP </button>
                            </td>
                            <td *ngIf="oneMoreColumn"></td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    </article>
</section>

<div class="is-flex is-justified-center" *ngIf="error">
    <div class="alert has-body is-error">
        <span class="icon"><i class="tri-exclamation-circle" aria-hidden="true"></i></span>
        <div class="body">
            <p class="text is-loaded">{{error}}</p>
        </div>
    </div>
</div>
