<div class="modal is-tri" *ngIf="isOpened">
    <div class="modal-notification">
        <div class="modal-content">
            <div class="modal-head">
                <div class="title" data-cy="notif-title">Messages d'alerte</div>
                
                <div (click)="doClose()" data-cy="notif-close">
                    <span class="icon is-medium"><i class="tri-times-circle" aria-hidden="true"></i></span>
                </div>
            </div>
            <div class="modal-body">
                <div class="box" *ngFor="let notification of notificationList; let i=index"
                    [attr.data-cy]="'notif-cadre-'+(i+1)"
                    [ngClass]="{'is-danger' : notification.changeStatus === changeStatus.new,
                                'is-warning' : notification.changeStatus === changeStatus.updated, 
                                'is-info' : notification.changeStatus === changeStatus.unchanged,
                                'is-success' : notification.changeStatus === changeStatus.resolved,
                                'closed' : !notification.isOpened,
                                }">
                    <header class="box-header">
                        <p class="text is-marginless"><strong>
                            <span *ngIf="notification.changeStatus === changeStatus.resolved">[RÉSOLU] </span>
                            <span *ngIf="notification.changeStatus === changeStatus.new">[NOUVEAU] </span>
                            <span *ngIf="notification.changeStatus === changeStatus.updated">[MODIFIÉ] </span>
                            {{notification.title}}
                        </strong></p>
                        <div class="notif-deploy" (click)="notification.isOpened = !notification.isOpened">
                            <span class="icon is-medium"><i [class]="notification.isOpened ? 'tri-arrow-up' : 'tri-arrow-down'" aria-hidden="true"></i></span>
                        </div>
                    </header>
                    <div class="box-content" *ngIf="notification.isOpened">
                        <p class="text" [innerHTML]="notification.descriptionHtml"></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>