export enum EquipmentErrorType {
  global = 'GLOBAL',
  warn = 'WARN',
  specific = 'SPECIFIC',
}

export enum EquipmentErrorCode {
  listEquipment = 'ListEquipmentError',
  stockError = 'StockError',
  checkEquipment = 'CheckEquipment',
  scanError = 'ScanError',
  simListError = 'SimListError',
}

export class EquipmentError extends Error {
  public type: EquipmentErrorType;
  public code: EquipmentErrorCode;

  constructor(type: EquipmentErrorType, code: EquipmentErrorCode, message: string) {
    super(message);
    this.type = type;
    this.code = code;
  }
}
