import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ConfigService } from '../../config.service';
import { CartService } from '../../checkout/cart/cart.service';
import { User } from '../../user/user';
import { BrowseConfigService } from '../../context/browse-config.service';
import { UserService } from '../../user/user.service';

@Injectable({
  providedIn: 'root',
})
export class TagService {
  private currentUrl: string;
  private cookies: string[] = [
    '_cs_c=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=.bouyguestelecom.fr',
    '_cs_c=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=.nbyt.fr',
    '_cs_id=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=.bouyguestelecom.fr',
    '_cs_id=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=.nbyt.fr',
    '_cs_s=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=.bouyguestelecom.fr',
    '_cs_s=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=.nbyt.fr',
  ];

  constructor(
    private configService: ConfigService,
    @Inject(DOCUMENT) private document: Document,
    private cartService: CartService,
    private browseConfigService: BrowseConfigService,
    private userService: UserService,
  ) {}

  public pushVirtualPage(): void {
    const win: Window & { _uxa?: unknown[] } = this.document.defaultView;
    win._uxa = win._uxa || [];
    const url = win.location.pathname + win.location.hash.replace('#', '?__');
    if (this.currentUrl !== url) {
      this.currentUrl = url;
      win._uxa.push(['setQuery', location.search + (location.search ? '&' : '?') + 'ANONIMYZED_QUERY_STRING']);
      win._uxa.push(['trackPageview', this.currentUrl]);
    }
  }

  public addContentSquareScript(): void {
    if (this.configService.data.contentSquare?.active === '1') {
      window['_uxa'] = window['_uxa'] || [];
      if (typeof window['CS_CONF'] === 'undefined') {
        window['_uxa'].push(['setPath', window.location.pathname + window.location.hash.replace('#', '?__')]);
        const mt = document.createElement('script');
        mt.type = 'text/javascript';
        mt.async = true;
        mt.src = '//t.contentsquare.net/uxa/bdc70e9aff7a5.js';
        document.getElementsByTagName('head')[0].appendChild(mt);
      }
    }
  }

  public deleteCookieCS(): void {
    this.cookies.forEach(cookie => (document.cookie = cookie));
  }

  public sentCustomVarsForDispatch(): void {
    const win: Window & { _uxa?: unknown[] } = this.document.defaultView;
    win._uxa = win._uxa || [];
    const userCtxService: User = this.userService.user;
    win._uxa.push(['setCustomVariable', 1, 'ID Device', this.browseConfigService.browseConfig?.deviceId]);
    win._uxa.push(['setCustomVariable', 2, 'ID Point de Vente', userCtxService.codeEns + userCtxService.codePdv]);
    win._uxa.push(['setCustomVariable', 3, 'ID Conseiller de Vente', userCtxService.registrationNumber]);
  }

  public sentCustomVarsForSummary(): void {
    const win: Window & { _uxa?: unknown[] } = this.document.defaultView;
    win._uxa = win._uxa || [];
    win._uxa.push(['setCustomVariable', 4, 'Panier', this.cartService.cart.cartId]);
  }
}
