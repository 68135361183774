import { Injectable } from '@angular/core';
import { StorageService } from '../base/services/storage.service';
import { StringObject } from '../base/base.interfaces';

@Injectable({
  providedIn: 'root',
})
export class Oauth2StorageService extends StorageService {
  public key: StringObject = {
    state: 'state',
    nonce: 'nonce',
    accessToken: 'access_token',
    idToken: 'id_token',
    params: 'params',
    trackerId: 'trackerId',
  };

  protected prefix = 'oauth2_';

  constructor() {
    super();
    Object.keys(this.key).forEach(key => this.sessionStorageKeys.push(this.key[key]));
  }
}
