import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { APP_BASE_HREF } from '@angular/common';

import { AccapAdapterModule } from './web-component/accap-adapter/accap-adapter.module';
// OLD
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
// New
import { TopBarModule } from './topBar/topBar.module';
import { CatalogModule } from './catalog/catalog.module';
import { CheckoutModule } from './checkout/checkout.module';
import { ScanModule } from './scan/scan.module';
import { StepperModule } from './stepper/stepper.module';
import { StorageService } from './base/services/storage.service';
import { ToolsService } from './base/services/tools.service';
import { AppStorageService } from './app-storage.service';
import { Oauth2Module } from './oauth2/oauth2.module';
import { DispatchModule } from './dispatch/dispatch.module';
import { ContextModule } from './context/context.module';
import { LoaderModule } from './loader/loader.module';
import { BaseModule } from './base/base.module';
import { ContainerModule } from './container/container.module';
import { ConfigService } from './config.service';
import { ErrorService } from './error/error.service';
import { LoaderBarModule } from './loaderBar/loaderBar.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { I18n } from './checkout/cart/cart-items/msisdn/input-date/i18n.service';
import { PricingModule } from './pricing/pricing.module';
import { HttpCustomInterceptor } from './base/interceptor/httpCustom.interceptor';
import { HTTP_INTERCEPTORS, HttpClientJsonpModule } from '@angular/common/http';
import { LogService } from './base/services/log.service';
import { ForbiddenComponent } from './pages/403/403.component';
import { TimeoutService } from './base/services/timeout.service';
import { ScanditModule } from './scandit/scandit.module';
import { DebugTabService } from './debug-tab/debug-tab.service';
import { APP_INITIALIZER_PROVIDER } from './base/providers/app-initializer';
import { PretGaletModule } from './pret-galet/pret-galet.module';
import { GmapsService } from './address/gmaps.service';
import { AngularPrismeLogger } from '@bytel/prisme-logger/lib/angular';
import { PrismeInterceptor } from './base/interceptor/prisme.interceptor';
import { GLOBAL_ERROR_HANDLER_PROVIDER } from './shared/global-error-handle/global-error-handle.provider';
import { TopBarService } from './topBar/topBar.service';
import { FormsModule } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';

@NgModule({
  imports: [
    NgbModule,
    BrowserModule,
    FormsModule,
    ContextModule,
    AppRoutingModule,
    LoaderBarModule,
    TopBarModule,
    CatalogModule,
    CheckoutModule,
    ScanModule,
    StepperModule,
    Oauth2Module,
    BaseModule,
    LoaderModule,
    ContainerModule,
    DispatchModule,
    HttpClientJsonpModule,
    PricingModule,
    ScanditModule.forRoot(),
    PretGaletModule,
    AccapAdapterModule,
  ],
  declarations: [AppComponent, ForbiddenComponent],
  providers: [
    {
      provide: APP_BASE_HREF,
      useValue: '',
    },
    APP_INITIALIZER_PROVIDER,
    ConfigService,
    StorageService,
    ToolsService,
    AppStorageService,
    ErrorService,
    LogService,
    TopBarService,
    TimeoutService,
    CookieService,
    I18n,
    { provide: HTTP_INTERCEPTORS, useClass: HttpCustomInterceptor, multi: true },
    DebugTabService,
    GmapsService,
    {
      provide: AngularPrismeLogger,
      useFactory: (config: ConfigService) =>
        new AngularPrismeLogger({
          st: 'portailvente_rcbt',
          environnement: config.getEnv(),
          urlEndpoint: config.getResourceUrl() + '/traces-techniques',
          tailleMaxEnvoiQueueMessages: 5,
        }),
      deps: [ConfigService],
    },
    GLOBAL_ERROR_HANDLER_PROVIDER,
    { provide: HTTP_INTERCEPTORS, useClass: PrismeInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
  exports: [],
})
export class AppModule {}
