<div class="container" *ngIf="showConvergence || showScoring">
    <ng-container *ngIf=" isScoringLoading || isConvergenceLoading; else elseBlock">
        <div *ngIf="isScoringLoading">
            Chargement du scoring...
        </div>
        <div *ngIf="isConvergenceLoading">
            Chargement éligibilité convergence...
        </div>
    </ng-container>
    <ng-template #elseBlock>
        <section>
            <ul *ngFor="let key of messages | keys" class="is-paddingless">
                <li *ngIf="messages[key].length" class="message-block is-flex">
                    <div class="sticker is-stretched is-flex is-justified-center is-aligned-center sticker-modif"
                         [ngClass]="{'has-background-primary': key === 'avantages', 'has-background-success': key === 'credit',
                    'has-background-secondary': (key !== 'avantages' && key !== 'credit') }"><strong>{{key}}</strong></div>
                    <div class="items is-paddingless is-fullwidth">
                        <p *ngFor="let message of messages[key]" class="item has-background-white is-marginless is-fullwidth is-bordered">
                            <span class="content is-flex is-aligned-center" [innerHTML]="message"></span>
                        </p>
                    </div>
                </li>
            </ul>
        </section>
    </ng-template>
</div>
<hr *ngIf="hasScoringMsg || hasConvergenceMsg">