<div  #dropdownContainer *ngIf="links.length > 0" class="dropdown" (click)="showDropdownMenu = !showDropdownMenu"
  [ngClass]="{'is-active': showDropdownMenu}">

  <div class="dropdown-trigger">
    <span class="icon is-medium" aria-hidden="true" aria-haspopup="true" aria-controls="dropdown-menu">
      <i class="tri-tools" aria-hidden="true"></i>
    </span>
  </div>
  <div class="dropdown-menu no-double">
    <div class="dropdown-menu-width">
      <div class="dropdown-content">
        <div class="dropdown-item" *ngFor="let link of links">
          <div class="icon-and-text" (click)="navigateTo(link)">
            <span class="icon is-small" aria-label="Icone avec texte">
              <i class="{{ link.icon }}" aria-hidden="true"></i>
            </span>
            <span class="title is-size-6 is-6">{{ link.title }}</span>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>
