export enum MzrValidationCode {
  ko = 'KO',
  ok = 'OK',
  nf = 'NF',
  ns = 'NS',
  nc = 'NC',
}

export enum ScanStateCode {
  ok = 1,
  errorA = 2,
  errorB = 3,
  errorC = 4,
  errorD = 5,
}

export enum UploadStateCode {
  ok = 2,
  timeout = 3,
  error = 4,
}

export enum DocTypeBTCode {
  cniGerantRecto = 'CNI_GERANT_RECTO',
  cniSignataireRecto = 'CNI_SIGNATAIRE_RECTO',
  cniGerantVerso = 'CNI_GERANT_VERSO',
  cniSignataireVerso = 'CNI_SIGNATAIRE_VERSO',
  passeportGerant = 'PASSEPORT_GERANT',
  passeportSignataire = 'PASSEPORT_SIGNATAIRE',
  csGerantRecto = 'CS_GERANT_RECTO',
  csSignataireRecto = 'CS_SIGNATAIRE_RECTO',
  csGerantVerso = 'CS_GERANT_VERSO',
  csSignataireVerso = 'CS_SIGNATAIRE_VERSO',
  attestationAssurance = 'ATTESTATION_ASSURANCE',
  avisImposition = 'AVIS_IMPOSITION',
  factureEdfGdf = 'FACTURE_EDF_GDF',
  factureTelephone = 'FACTURE_TELEPHONE',
  pouvoir = 'POUVOIR',
  quittanceLoyer = 'QUITTANCE_LOYER',
  carteGrise = 'CARTE_GRISE',
  carteMilitaireRecto = 'CARTE_MILITAIRE_RECTO',
  carteMilitaireVerso = 'CARTE_MILITAIRE_VERSO',
  carteRefugieRecto = 'CARTE_REFUGIE_RECTO',
  carteRefugieVerso = 'CARTE_REFUGIE_VERSO',
  chequeAnnule = 'CHEQUE_ANNULE',
  permisConduire = 'PERMIS_CONDUIRE',
  rib = 'RIB',
  autre = 'AUTRE',
}
