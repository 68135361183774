import { Component, OnInit } from '@angular/core';
import { ConfigService } from '../../../config.service';

@Component({
  selector: 'rcbt-cggc',
  templateUrl: './cggc.component.html',
  styleUrls: ['./cggc.component.scss'],
})
export class CggcComponent implements OnInit {
  public cggcUrl: string;

  constructor(protected configService: ConfigService) {}

  public ngOnInit(): void {
    this.cggcUrl = this.configService.data.cggc.url.value;
  }

  public print(): void {
    window.open(this.cggcUrl, 'theFrame', '_blank');
  }
}
