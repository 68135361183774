import { CommercialProposal, FundingEnum } from '../interfaces/funding.interface';
import { PaymentMode } from './../interfaces/funding.interface';
import { Model } from './model';

export class FundingMethod extends Model {
  public label: string;
  public labelModal?: string;
  public paymentModes: PaymentMode[];
  public commercialProposal: CommercialProposal[];
  public bestCommercialProposal: CommercialProposal;
  public type: FundingEnum;

  constructor(data: Partial<FundingMethod>) {
    super(data);
  }
}
