import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseModule } from '../../base/base.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TakebackButtonComponent } from './takeback-button.component';
import { TakebackModalComponent } from './takeback-modal/takeback-modal.component';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { TakebackService } from './takeback.service';

@NgModule({
  imports: [CommonModule, BaseModule, FormsModule, ReactiveFormsModule, NgbPopoverModule],
  declarations: [TakebackModalComponent, TakebackButtonComponent],
  exports: [TakebackModalComponent, TakebackButtonComponent],
  providers: [TakebackService],
})
export class TakebackModule {}
