<header class="is-tri">
    <nav class="is-flex is-vcentered has-background-white has-max-height">
        <div class="column is-narrow">
            <img src="/assets/images/logo_venteRCBT.png" class="logo-shop" alt="shop" (click)="openCategories()" data-cy="go-home"/>
        </div>
        <div class="column is-narrow verticalDivider"></div>

        <div class="column" *ngIf="customerHeader && (customerHeader.firstname || customerHeader.company.raisonSociale) && !isEligStandalonePage">
          <span class="icon-and-text">
              <span class="icon is-medium" aria-label="">
                  <i class="tri-user" aria-hidden="true"></i>
              </span>
              <span class="text-top-bar">
                  <span class="is-uppercase has-text-weight-bold" *ngIf="!customerHeader.company?.raisonSociale">
                      {{customerHeader.firstname.substring(0,1).toUpperCase()}}. {{customerHeader.lastname}}
                  </span>
                  <span class="is-uppercase has-text-weight-bold" *ngIf="!!customerHeader.company?.raisonSociale">
                      {{ customerHeader.company.raisonSociale }}
                  </span>
                  <span class="is-block" *ngIf="!!customerHeader.company?.raisonSociale && customerHeader.company?.noSiren">
                      Siren : {{customerHeader.company.noSiren}}
                  </span>
                  <span class="is-block" *ngIf="!contract">{{ customerHeader.phoneNumber | telephone }}</span>
                  <span class="is-block" *ngIf="contract">{{ contract.numeroTel | telephone}}</span>
                  <span class="is-block" *ngIf="!!customerHeader.company?.raisonSociale"></span>
              </span>
          </span>
        </div>

        <div class="column">
            <div *ngIf="!isDispatchPage && (!!cartService.currentSchemeUniqueId || isConsultAccessoryPricePage) && !isEligStandalonePage && !isCustomWelcomePage">
                <button data-cy="open-scan-code" class="button is-secondary"  *ngIf="!scanOpened && scanEnable" (click)="scanOpened = true">
                    <span class="icon is-medium">
                        <i class="tri-camera" aria-hidden='true'></i>
                    </span>
                    &nbsp;Scanner
                </button>
                <button data-cy="close-scan-code" class="button is-secondary" *ngIf="scanOpened" (click)="scanOpened = false">
                    <span class="icon is-medium">
                        <i class="tri-times-circle" aria-hidden='true'></i>
                    </span>
                    &nbsp;Fermer
                </button>
            </div>
        </div>

        <div class="column" *ngIf="user">
          <span class="icon-and-text">
              <span *ngIf="showSimulatorLink" class="icon is-large simulator" aria-label="" (click)="navigateToSimulator()">
                  <i class="tri-hands-helping" aria-hidden="true"></i>
              </span>
              <span class="icon is-medium" aria-label="">
                  <i class="tri-store" aria-hidden="true"></i>
              </span>
              <span class="text-top-bar">
                  <span class="is-block is-uppercase has-text-weight-bold">{{user.nomBoutique}}</span>
                  <span class="is-block">{{user.codeEns}} - {{user.codePdv}}</span>
              </span>
          </span>
        </div>

        <div class="column">
            <button *ngIf="!hideAccessoryPriceCancelButton && !isCustomWelcomePage && !onCustomRecapPage"
                    class="button has-icon"
                    data-cy="cancel"
                    [ngClass]="{'is-danger': cancelType === 'cart', 'is-warning': (cancelType !== 'cart') || isEligStandalonePage}"
                    [disabled]="loading || isNotificationBoardDisplay"
                    (click)="cancelAction()"
            >
                <span class="icon is-medium">
                    <i class="tri-times" aria-hidden='true'></i>
                </span>
                &nbsp;{{isEligStandalonePage?'Fermer':'Annuler'}}
            </button>
        </div>

        <div class="column" *ngIf="isEligStandalonePage && !hideAccessoryPriceCancelButton">
            <button
                  class="button is-info has-icon"
                  data-cy="new-stand-alone"
                  (click)="gotoElig()"
                  [disabled]="loading"
            >
                <span class="icon is-medium"><i class="tri-exclamation-redo" aria-hidden="true"></i></span>
                &nbsp;Faire un nouveau test
            </button>
        </div>

        <div class="column is-flex">
            <ng-containner *ngIf="isNotificationBoardButton && nbNotificationBoardMessages > 0">
              <span
                  class="icon is-medium"
                  data-cy="notif-btn"
                  [ngClass]="{'action-feedback' : loading, 'has-status': nbNotificationBoardMessages > 0}"
                  (click)="changeNotificationBoardVisibility()">
                  <i class="tri-exclamation-circle" data-cy="notif-icon" [ngClass]="{'is-shadow' : isNotificationBoardUpdated}"></i>
                  <span *ngIf="nbNotificationBoardMessages > 0" class="rcbt-badge" data-cy="notif-badge">{{ nbNotificationBoardMessages }}</span>
              </span>

            </ng-containner>
            <ng-container *ngIf="isCartdisplay && cartService.cart.schemes.length > 0 && !isCustomWelcomePage">
                <div class="column is-narrow"></div>

                <span
                      class="icon is-medium"
                      data-cy="cart-link"
                      (click)="openCart()"
                      [ngClass]="{'has-status': currentSchemeProductsCount > 0}"
                >
                <i class="tri-shopping-cart" aria-hidden='true'></i>
                <span *ngIf="currentSchemeProductsCount > 0" class="rcbt-badge" data-cy="cart-pastil">{{ currentSchemeProductsCount }}</span>
            </span>
            </ng-container>
            <ng-container *ngIf="isCustomWelcomePage || onCustomRecapPage">
                <div class="column is-narrow"></div>

                <rcbt-tool-links></rcbt-tool-links>
            </ng-container>
            <ng-container
                *ngIf="shouldShowLogButton">
                <div class="column is-narrow"></div>
                  <span
                    class="icon is-medium"
                    data-cy="btn-log"
                    [ngClass]="{'action-feedback' : loading}"
                    (click)="openFrontLog()"
                >
                    <i class="tri-file-list" aria-hidden='true'></i>
                </span>
                <div class="column is-narrow"></div>
            </ng-container>
        </div>

        <div class="column is-narrow has-background-success has-text-success-invert has-text-centered"
             *ngIf="user" [ngClass]="{'is-flex customlogin': !isCustomWelcomePage}">
            <span class="icon is-medium" *ngIf="isCustomWelcomePage || onCustomRecapPage" (click)="logout()">
                <i class="tri-power" aria-hidden='true'></i>
            </span>
            <span *ngIf="user.isCS()" class="is-block is-uppercase text-top-bar">{{user.firstname}} {{user.lastname.substring(0,1)}}</span>
        </div>

    </nav>

</header>
<rcbt-scan *ngIf="scanOpened"></rcbt-scan>
<rcbt-alert-cart></rcbt-alert-cart>

<rcbt-notification-board [isOpened]="isNotificationBoardDisplay"
                         [login]="user?.login"
                         (emitActive)="recupNotificationBoardActive($event)"
                         (emitOpen)="isNotificationBoardDisplay=true;"
                         (emitNbMsg)="recupNotificationBoardNbMsg($event)"
                         (emitUpdated)="recupNotificationBoardUpdated($event)"
                         (emitDoClose)="changeNotificationBoardVisibility()"></rcbt-notification-board>

<div class="modal" *ngIf="cancelConfirmDisplay" >
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body">
                <h2 class="modal-title">Annuler la vente ?</h2>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-lg btn-warning col-auto action"
                        (click)="cancelCart()" [disabled]="loading"
                        [ngClass]="{'action-feedback' : loading}">Oui</button>
                <button type="button" class="btn btn-lg btn-info" [disabled]="loading"
                        (click)="cancelConfirmDisplay = !cancelConfirmDisplay">
                    Non, continuer</button>
            </div>
        </div>
    </div>
</div>
