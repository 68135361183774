<div class="fai-box-component">

    <div class="fai-box-header">
        <div class="header">
        <span class="icon is-large has-text-tertiary" aria-label="Icone de Bbox">
            <i class="tri-box" aria-hidden="true"></i>
        </span>
            <h2 class="title is-size-3 center-title">{{product.name}}</h2>
        </div>

        <div class="fai-box-infos">
            <div class="data-cell-details-image">
                <img *ngIf="imageSrc" class="visual" [src]="imageSrc | media" alt="" />
                <div class="fai-box-description">
                    <h3>{{equipmentLabels[this.faiPlan.getData('equipment')]}}</h3>
                    <rcbt-price class="price" [price]="product?.price" [oldPrice]="product?.oldPrice" [fontLevel]="3" [priceType]="1"></rcbt-price>
                </div>
            </div>
        </div>
    </div>



</div>
