import { Component, Input } from '@angular/core';

import { EquipmentItem } from '../product-list/product-list.component';

@Component({
  selector: 'rcbt-product-item',
  templateUrl: './product-item.component.html',
  styleUrls: ['./product-item.component.scss'],
})
export class ProductItemComponent {
  // @Input() public readonly code: string;
  // @Input() public readonly minPrices: ObjectOfType<MinPrice>;
  // @Input() public readonly products: JsonCatalog;
  @Input() public readonly index: number;
  @Input() readonly equipment: EquipmentItem;

  public connectivityIconMap: Map<string, string> = new Map<string, string>()
    .set('5g', 'tri-4g-5g')
    .set('4g+', 'tri-4g-plus')
    .set('4g', 'tri-4g');
}
