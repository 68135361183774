import { NgModule } from '@angular/core';
import { ModalConsentComponent } from './modal-consent/modal-consent.component';
import { FormsModule } from '@angular/forms';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { ModalConfirmRemoveEdpComponent } from './modal-confirm-remove-edp/modal-confirm-remove-edp.component';

@NgModule({
  imports: [FormsModule, NgbModalModule],
  declarations: [ModalConsentComponent, ModalConfirmRemoveEdpComponent],
})
export class ConsumerLoanModule {}
