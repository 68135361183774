<div class="box funding" *ngIf="onTablet && !isPro" id="funding-box">
    <div class="box-content" [ngClass]="{'inactive' : !addedCredit}">
        <div class="control switch" *ngIf="!loading && fundingMethods.length > 0">
            <input type="checkbox" id="switch-credit" data-cy="switch-credit" [(ngModel)]="addedCredit" (ngModelChange)="toggleCredit($event)">
            <label for="switch-credit" class="title is-size-4">Financement Panier</label>
        </div>
        <div class="box-content has-text-centered" *ngIf="!loading && !fundingMethods.length">
            <h3 class="title is-size-4">Financement Panier</h3>
        </div>
        <div class="is-divider"></div>
        <div class="field">
        </div>
        <div class="funding-content" *ngIf="!loading && fundingMethods.length > 0">
            <div class="columns is-vcentered total">
                <div class="column">
                    <span class="is-size-6 has-text-weight-semibold">Total panier</span>
                </div>
                <div class="column has-text-right">
                    <span class="is-size-6 has-text-weight-bold"
                          [ngClass]="{'credit-active': addedCredit}"
                          [innerHTML]="cartTotal | customCurrency"></span>
                </div>
            </div>
            <div class="is-divider"></div>
            <div class="columns is-vcentered">
                <div class="column is-narrow">
                    <span class="is-size-7">A payer aujourd'hui<br/>(à partir de)</span>
                </div>
                <div class="column has-text-right">
                    <span class="is-size-7 has-text-weight-bold"
                          [ngClass]="{'credit-active': addedCredit}"
                          [innerHTML]="bestProposal?.initialAmount | customCurrency"></span>
                </div>
            </div>
            <ng-container *ngFor="let method of fundingMethods; let i = index">
                <div class="columns deadlines">
                    <div class="column is-narrow">
                        <span *ngIf="i === 0" class="is-size-7">Puis :</span>

                    </div>
                    <div class="column has-text-right">
                        <span class="is-size-8 has-text-weight-bold"
                              [ngClass]="{'credit-active': addedCredit}"
                              [innerHTML]="method.bestCommercialProposal.monthlyAmount | customCurrency"></span>
                        <span class="is-size-8">/mois sur {{method.bestCommercialProposal.nbLoan}} mois</span>
                    </div>
                </div>
            </ng-container>
            <div class="container-amount">
                <div class="columns">
                    <div class="column is-narrow">
                        <span class="is-size-7">Montant Financé</span>
                    </div>
                    <div class="column has-text-right">
                        <span class="is-size-7 has-text-weight-bold"
                              [ngClass]="{'credit-active': addedCredit}"
                              [innerHTML]="bestProposal?.amountToFund | customCurrency"></span>
                    </div>
                </div>
                <br>
                <div class="columns">
                    <div class="column is-narrow">
                        <span class="is-size-7">TAEG à partir de</span>
                    </div>
                    <div class="column has-text-right">
                        <span class="is-size-7 has-text-weight-bold">{{bestProposal?.tAEG}}%</span>
                    </div>
                </div>
                <br>
                <div class="columns">
                    <div class="column is-narrow">
                        <span class="is-size-7">Coût du crédit <br>
                            sur 24 mois</span>
                    </div>
                    <div class="column has-text-right">
                        <span class="is-size-7 has-text-weight-bold active-font-color"
                              [ngClass]="{'credit-active': addedCredit}"
                              [innerHTML]="'<br>' + (bestProposal?.fundingFinalCost | customCurrency)">
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="funding-content" *ngIf="!loading && !fundingMethods.length">
            <div class="columns is-vcentered">
                <div class="column">
                    <span class="is-size-6 has-text-weight-semibold">Panier non finançable à crédit</span>
                </div>
            </div>
        </div>
    </div>
</div>
