import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FraudComponent } from './fraud/fraud.component';
import { FraudModalComponent } from './fraud-modal/fraud-modal.component';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [FraudComponent, FraudModalComponent],
  imports: [CommonModule, ReactiveFormsModule],
  exports: [FraudComponent],
})
export class FraudModule {}
