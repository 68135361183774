import { Phone } from '../../catalog/products/equipement/complex/phone';
import { Scheme } from './scheme.class';
import { BrowseType } from './browse-type';
import { SchemeSerialized } from './scheme.interface';

export class RenewScheme extends Scheme {
  public static prevDateErrorMessagPrefix = 'Date prévisionnelle : ';
  public keySerialize = 'renouvellement';
  public static renewTypes = {
    month12: { label: 'month12', diff: 0 },
    month18: { label: 'month18', diff: 6 },
    month24: { label: 'month24', diff: 12 },
  };
  public idCt: number;
  public contractId: number;
  public planType: string;
  public migrateTo: string;
  public renewType: string;
  public gencodeOffre: string;
  public codeTac: string;
  public collaborator: boolean;
  public multiLigne: boolean;
  public amountPaid: number;
  public insurance: boolean;
  /** seniority of the phone in months */
  public phoneSeniority: number;
  public hasRobbLossRestriction: boolean;
  public eligibiliteCreditRenouvellement = false;
  public promoType = 'renouvellement';
  public idOffers: string[];
  public status: boolean;
  public browseType = BrowseType.renew;

  public getClientType(): string {
    return this.collaborator ? 'collaborator' : 'client';
  }

  public isEligibleEDP(isEligibleEDP: boolean, plan?: string): boolean {
    const phone: Phone = this.getProductByType(Phone);
    if (!phone) {
      return false;
    }
    const appliedPromotionsAmount: number = phone.oldPrice - phone.price;
    return (
      isEligibleEDP && phone.getOneOffWithPlan(plan, this.collaborator, this.renewType) - appliedPromotionsAmount >= 1
    );
  }

  public unserializeSpecificData(schemeSerialized: SchemeSerialized): void {
    this.status = schemeSerialized.contextProduit.contextService.renouvellement.status;
    this.contractId = schemeSerialized.contextProduit.contextService.renouvellement.contractId;
    this.idCt = schemeSerialized.contextProduit.contextService.renouvellement.idCt;
    this.planType = schemeSerialized.contextProduit.contextService.renouvellement.planType;
    this.migrateTo = schemeSerialized.contextProduit.contextService.renouvellement.migrateTo;
    this.renewType = schemeSerialized.contextProduit.contextService.renouvellement.type;
    this.gencodeOffre = schemeSerialized.contextProduit.contextService.renouvellement.gencodeOffre;
    this.multiLigne = schemeSerialized.contextProduit.contextService.renouvellement.multiLigne;
    this.collaborator = schemeSerialized.contextProduit.contextService.renouvellement.collaborator;
    this.amountPaid = schemeSerialized.contextProduit.contextService.renouvellement.amountPaid;
    this.insurance = schemeSerialized.contextProduit.contextService.renouvellement.insurance;
    this.phoneSeniority = schemeSerialized.contextProduit.contextService.renouvellement.phoneSeniority;
    this.idOffers = schemeSerialized.contextProduit.contextService.renouvellement.idOffers;
  }
}
