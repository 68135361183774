import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { saveAs } from 'file-saver';
import { Observable, of, Subscription, throwError } from 'rxjs';
import { catchError, concatMap, finalize, map, mergeMap, tap } from 'rxjs/operators';
import { GlobalLoaderService } from '../../../../base/services/global-loader.service';
import { Avc } from '../../../../catalog/products/avc';
import { MobileTakeBack } from '../../../../catalog/products/mobileTakeBack';
import { Product } from '../../../../catalog/products/product';
import { Plan } from '../../../../catalog/products/subscription/plan';
import { AlertService } from '../../../../common/alert/alert.service';
import { ConsumerLoanService } from '../../../../consumer-loan/consumer-loan.service';
import { FaiStepperService } from '../../../../fai-widget/fai-stepper/fai-stepper.service';
import { FaiStorageService } from '../../../../fai-widget/fai-storage.service';
import { CommercialProposal } from '../../../../fundings/interfaces/funding.interface';
import { FundingService } from '../../../../fundings/services/funding.service';
import { Oauth2RessourceService } from '../../../../oauth2/oauth2-resources.service';
import { DynamicComponentHostDirective } from '../../../../odr/dynamic-component/dynamic-component-host.directive';
import { DynamicComponentLoaderService } from '../../../../odr/dynamic-component/dynamic-component-loader.service';
import { OdrRecapComponent } from '../../../../odr/odr-recap/odr-recap.component';
import { InsuranceConfig, InsurancePartnerCodeEnum } from '../../../../partner/partner.dto';
import { Promotion } from '../../../../promotions/promotion.class';
import { Regularization } from '../../../../promotions/promotion/regularization.class';
import { PromotionTypes } from '../../../../promotions/promotions.interfaces';
import { PromotionsService } from '../../../../promotions/promotionsService';
import { RegularizationService } from '../../../../promotions/regularizationService';
import { MobileTakeBackService } from '../../../../scan/mobileTakeBackService';
import { ScanditService } from '../../../../scandit/scandit.service';
import { Step } from '../../../../stepper/step.abstract';
import { CheckoutStepperService } from '../../../checkout-stepper.service';
import { CheckoutStorageService } from '../../../checkout-storage.service';
import { CartService } from '../../cart.service';
import { Scheme } from '../../scheme.class';
import { ModalScoringDetailedComponent } from '../modals/modal-scoring/modal-scoring-detailed.component';
import { User } from '../../../../user/user';
import { ScoringService } from '../../../../scoring/scoring.service';
import { CustomerService } from '../../customer/customer.service';
import { UserService } from '../../../../user/user.service';

interface Prices {
  today: number;
  month: number;
  reported: number;
}

const LOADING_ACTIONS = {
  postFundingMode: '[SchemeComponent] postFundingMode',
  modifyScheme: '[SchemeComponent] modifyScheme',
  deleteRabais: '[SummaryComponent] delete rabais',
};

@Component({
  selector: 'rcbt-summary-scheme',
  templateUrl: './scheme.component.html',
  styleUrls: ['./scheme.component.scss'],
})
export class SchemeComponent implements OnInit, OnDestroy {
  @Input()
  public scheme: Scheme;

  @Input()
  public showControls = true;

  @ViewChild(DynamicComponentHostDirective) host: DynamicComponentHostDirective;

  public total: Prices;

  public isSummaryCollapsed: boolean;

  public loadingDelete: boolean;
  public loadingUpdate: boolean;
  public loading: boolean;
  public error: string;
  public isRenew: boolean;
  public approuvedConsentement = false;
  public canActivateLoan = false;
  public activeModal: boolean;
  public canLoanAccess: boolean;
  public canSwitchFundingMode = false;
  public user: User;
  public edpCommercialProposal: CommercialProposal;
  public msisdn: string;
  public plan: string;
  public displayPanel = true;
  public hasGrantedLoan: boolean;
  public spbConfig: InsuranceConfig;
  public oneMoreColumn: boolean;

  private subscription = new Subscription();
  constructor(
    protected router: Router,
    protected cartService: CartService,
    protected oauth2Ressource: Oauth2RessourceService,
    protected stepper: CheckoutStepperService,
    protected checkoutStorageService: CheckoutStorageService,
    protected faiStorageService: FaiStorageService,
    protected faiStepperService: FaiStepperService,
    protected alertService: AlertService,
    protected regularizationService: RegularizationService,
    protected promotionService: PromotionsService,
    protected consumerLoanService: ConsumerLoanService,
    private readonly modalService: NgbModal,
    private scanditService: ScanditService,
    private mtbService: MobileTakeBackService,
    private dynamicComponentLoaderService: DynamicComponentLoaderService,
    private fundingService: FundingService,
    private globalLoaderService: GlobalLoaderService,
    private scoringService: ScoringService,
    private customerService: CustomerService,
    private userService: UserService,
  ) {}

  public ngOnInit(): void {
    this.updateTotal();
    this.subscription.add(
      this.cartService.onChangesSchemes.subscribe(() => {
        this.updateLoanData();
      }),
    );
    this.subscription.add(
      this.stepper.onChangesCurrentStep.subscribe(() => {
        this.updateLoanData();
      }),
    );
    this.setEdpCommercialProposal();
    this.subscription.add(this.fundingService.stateEligibleFunding.subscribe(() => this.setEdpCommercialProposal()));

    this.subscription.add(
      this.cartService.afterRefresh.subscribe(
        () => (this.scheme = this.cartService.getSchemeByQuoteId(this.scheme.quoteId)),
      ),
    );

    this.isRenew = this.isRenewScheme();

    this.canSwitchFundingMode =
      this.fundingService.isSchemeEligibleEdp(this.scheme) && !this.fundingService.hasGrantedCredit();
    this.fundingService.stateEligibleFunding.subscribe(() => {
      this.canSwitchFundingMode =
        this.fundingService.isSchemeEligibleEdp(this.scheme) && !this.fundingService.hasGrantedCredit();
    });

    this.subscription.add(
      this.consumerLoanService.onSaveConsentment.subscribe(
        () => (this.approuvedConsentement = !this.consumerLoanService.needConsent()),
      ),
    );

    this.approuvedConsentement = !this.consumerLoanService.needConsent();
    this.canLoanAccess = this.consumerLoanService.canLoanAccess();
    if (!!this.cartService.cart.creditData) {
      this.canActivateLoan = this.scanditService.isOnTablet();
    }
    this.canActivateLoan = this.scanditService.isOnTablet() && !this.consumerLoanService.hasGrantedCredit();
    this.subscription.add(this.alertService.isLoading.subscribe(loading => (this.loading = loading)));
    this.user = this.userService.user;

    this.plan = this.getPlan();
    this.setMsisdn();
    this.hasGrantedLoan = this.consumerLoanService.hasGrantedCredit();
    this.spbConfig = this.getSpbConfig();

    this.oneMoreColumn = this.displayOneMoreColumn();

    this.subscription.add(
      this.regularizationService.onModeRegularisationChanges.subscribe(() => {
        this.oneMoreColumn = this.displayOneMoreColumn();
      }),
    );
  }

  public displayOneMoreColumn(): boolean {
    return (
      this.regularizationService.inModeRegularisation ||
      !!this.spbConfig ||
      this.scheme.products.some(
        p => p.type_id !== 'edp' && (p.getData('odr') || this.isMobileTakeBack(p) || this.canBeRegularized(p)),
      )
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.globalLoaderService.deleteStatusByActionTypes(LOADING_ACTIONS.postFundingMode);
  }

  public isRenewScheme(): boolean {
    return this.scheme.isRenew();
  }

  public updateTotal(): Prices {
    const total: Prices = { today: 0, month: 0, reported: 0 };
    for (const product of this.scheme.products) {
      if (product.priceType === 0 || product.priceType === undefined) {
        total.today += product.price;
      }

      if (product.priceType === 1) {
        total.month += product.price;
      }

      if (product.priceType === 3) {
        total.reported += product.price;
      }
    }
    this.total = total;
    return this.total;
  }

  public deleteScheme(): Observable<boolean | void> {
    this.loadingDelete = true;
    this.loading = true;
    this.alertService.emitBeenLoading(this.loading);
    return this.cartService
      .addToObsQueue(
        this.cartService.deleteScheme(this.scheme).pipe(
          mergeMap(() => this.cartService.deleteGrantedLoan()),
          mergeMap(() => this.cartService.refreshCart()),
          tap(
            () => null,
            () => this.alertService.errorEmitter.next("Erreur serveur, impossible de supprimer l'acte !"),
          ),
          map(() => true),
        ),
      )
      .pipe(
        tap(() => {
          if (this.cartService.cart.schemes.length === 0) {
            this.faiStepperService.reset();
            this.faiStorageService.clear();
            this.stepper.reset();
            this.checkoutStorageService.clear();
            this.router.navigate(['/dispatch']);
          }
        }),
        finalize(() => {
          this.loadingDelete = false;
          this.loading = false;
          this.alertService.emitBeenLoading(this.loading);
        }),
      );
  }

  public updateScheme(): void {
    // eslint-disable-next-line max-len
    // TODO: Add remove granted credit if credit is active (Maybe create the delete method in fundingService to share with delete in funding component)

    this.loadingUpdate = true;
    this.loading = true;
    this.alertService.emitBeenLoading(this.loading);
    this.cartService.unlockSchemeById(this.scheme.uniqueId);
    let obs = of(null);
    const postCash: boolean = this.cartService.cart.schemes.some(s => s.hasEdp);
    if (this.fundingService.hasGrantedCredit()) {
      obs = this.cartService.deleteGrantedLoan().pipe(
        catchError(error => {
          this.alertService.errorEmitter.next('Erreur serveur, impossible de modifier le parcours !');
          return error;
        }),
      );
    } else if (postCash) {
      obs = this.fundingService.postFundingMode(this.cartService, true).pipe(
        concatMap(() => this.cartService.refreshCart(null, false)),
        catchError(error => {
          this.alertService.errorEmitter.next('Erreur serveur, impossible de modifier le parcours !');
          return error;
        }),
      );
    }
    this.cartService
      .addToObsQueue(
        obs.pipe(
          tap(() =>
            this.globalLoaderService.dispatchLoadingStatus({
              actionType: LOADING_ACTIONS.modifyScheme,
              isLoading: true,
            }),
          ),
          tap(() => this.cartService.selectCurrentScheme(this.scheme.uniqueId)),
          concatMap(() =>
            this.removeAvc(this.cartService.getCurrentScheme()).pipe(
              tap(() => {
                this.cartService.unlockSchemeById(this.scheme.uniqueId);
                const step: Step = this.stepper.getStepByCode('cart');
                if (step && !step.isAllowed) {
                  this.stepper.goToStep(this.stepper.findFirstStep());
                } else if (step) {
                  this.stepper.goToStep(step);
                }
                this.cartService.save();
              }),
            ),
          ),
          concatMap(() => this.cartService.unlockScheme(this.scheme.quoteId)),
        ),
      )
      .pipe(
        finalize(() => {
          this.loadingUpdate = false;
          this.loading = false;
          this.alertService.emitBeenLoading(this.loading);
          this.globalLoaderService.dispatchLoadingStatus({
            actionType: LOADING_ACTIONS.modifyScheme,
            isLoading: false,
          });
        }),
      )
      .subscribe();
  }

  public removeAvc(currentScheme: Scheme): Observable<void> {
    const avcProduct = currentScheme.getProductByType(Avc);
    if (!!avcProduct) {
      return this.cartService.remove(avcProduct.uniqueId, currentScheme.uniqueId).pipe(
        concatMap(() => this.cartService.refreshCart(true, false)),
        tap(() => this.cartService.selectCurrentScheme(this.scheme.uniqueId)),
      );
    }
    return of(null);
  }

  public downloadFileODR({ data }: Product): void {
    const odrData = {
      data: {
        file: data.odr.file,
        amount: data.odr.amount,
        type: data.type_id === 'phone_simple' ? 'phone_simple' : 'accessory',
      },
    };
    this.dynamicComponentLoaderService.setViewContainerRef(this.host.viewContainerRef);
    this.dynamicComponentLoaderService.createComponent(OdrRecapComponent, odrData);
  }

  public downloadFile(filename): Observable<void> {
    return this.oauth2Ressource
      .setLocalService(true)
      .useSalesApi()
      .ventes()
      .fichiersSecurise()
      .setParams({ file: filename, delete: 1 })
      .setResponseType('blob')
      .get()
      .pipe(
        mergeMap((content: object) => of(saveAs(content, filename))),
        catchError(err => throwError('error secure download', err)),
      );
  }

  public collapseSumary(): void {
    this.isSummaryCollapsed = !this.isSummaryCollapsed;
  }

  public canBeRegularized(product: Product): boolean {
    return (
      [
        'phone_simple',
        'sim',
        'crv',
        'service_accompaniment',
        'service_assistance',
        'sim_bbox',
        'sim_cbt',
        'sim_fnb',
        'sim_replace',
        'sim_sav',
        'box',
        'accessory',
        'produit_ca',
        'esim',
        'qr_esim',
        'esim_replace',
        'qr_esim_replace',
      ].includes(product.type_id) && !product.promotions.some(p => p instanceof Regularization)
    );
  }

  public onDeleteRegularization(product: Product): void {
    this.globalLoaderService.dispatchLoadingStatus({
      actionType: LOADING_ACTIONS.deleteRabais,
      isLoading: true,
    });
    this.fundingService.launchWarningModalWithCallBackObservable(
      this.cartService,
      this.deleteRegularization.bind(this),
      product,
    );
  }

  public deleteRegularization(product: Product): Observable<void | string> {
    this.loading = true;
    return this.promotionService
      .deletePromo(product.getRegularization().id, this.cartService.cart.cartId, product.gencode)
      .pipe(
        mergeMap(() => this.cartService.deleteGrantedLoan()),
        mergeMap(() => this.cartService.refreshCart()),
        catchError(() => {
          this.error = "Une erreur s'est produite, impossible de retirer la régularisation";
          this.globalLoaderService.dispatchLoadingStatus({
            actionType: LOADING_ACTIONS.deleteRabais,
            isLoading: false,
          });
          return of(null);
        }),
        finalize(() => {
          this.loading = false;
          this.globalLoaderService.dispatchLoadingStatus({
            actionType: LOADING_ACTIONS.deleteRabais,
            isLoading: false,
          });
        }),
      );
  }

  public isRegularization(promotion: Promotion): boolean {
    return promotion.type === PromotionTypes.regularization || promotion.type === PromotionTypes.regularizationCa;
  }

  public canDeleteRegularization(promotion: Promotion): boolean {
    return promotion.type === PromotionTypes.regularization
      ? this.user.canApplyRegularization
      : this.user.canApplyDiscountCA;
  }

  public isMobileTakeBack(product: Product): boolean {
    return product instanceof MobileTakeBack;
  }

  public printMobileTakeBackCoupon(product: Product): void {
    const mtb: MobileTakeBack = <MobileTakeBack>product;
    this.loading = true;
    let obsPrint: Observable<void>;

    try {
      if (mtb.isCessionCoupon()) {
        obsPrint = this.mtbService.printDisposalTicket(mtb.mtbCessionCouponLink).pipe(
          mergeMap((content: object) => of(saveAs(content, 'bonDeCession.pdf'))),
          catchError(err => throwError("Erreur pour l'impression du bon de cession", err)),
        );
      } else {
        obsPrint = this.downloadFile(mtb.mtbCessionCouponLink);
      }
      mtb.isMtbCessionCouponPrinted = true;
      obsPrint
        .pipe(
          mergeMap(() => this.cartService.update(mtb, this.scheme.uniqueId)),
          mergeMap(() => this.fundingService.postFundingMode(this.cartService)),
        )
        .subscribe(
          () => {
            this.loading = false;
            this.cartService.onChangesSchemes.next(this.cartService.cart.schemes);
          },
          () => {
            mtb.isMtbCessionCouponPrinted = false;
            this.loading = false;
            this.alertService.errorEmitter.next("Erreur serveur, impossible d'enregistrer le bon de cession !");
          },
        );
    } catch (e) {
      mtb.isMtbCessionCouponPrinted = false;
      this.loading = false;
      this.alertService.errorEmitter.next("Erreur serveur, impossible d'imprimer le bon de cession !");
    }
  }

  public activeComptant(): void {
    if (!this.scheme.hasEdp && !this.cartService.cart.creditData) {
      return;
    }
    const hasEdp: boolean = this.scheme.hasEdp;
    this.updateFundingState();
    if (hasEdp) {
      this.loading = true;
      this.globalLoaderService
        .showLoaderUntilFinalize(
          this.fundingService.postFundingMode(this.cartService).pipe(
            concatMap(() => this.cartService.refreshCart(null, false)),
            finalize(() => (this.loading = false)),
          ),
          LOADING_ACTIONS.postFundingMode,
        )
        .subscribe();
    }
  }

  public activeEdp(): void {
    if (this.scheme.hasEdp) {
      return;
    }
    this.updateFundingState(true);
    this.loading = true;
    this.globalLoaderService
      .showLoaderUntilFinalize(
        this.fundingService.postFundingMode(this.cartService).pipe(
          concatMap(() => this.cartService.refreshCart(null, false)),
          concatMap(() =>
            this.scoringService.getScoring(this.customerService.customer.personId, this.cartService.cart.cartId),
          ),
          finalize(() => {
            this.loading = false;
          }),
        ),
        LOADING_ACTIONS.postFundingMode,
      )
      .subscribe(() => this.checkScoringResult());
  }

  public onUpdateScheme(): void {
    this.fundingService.launchWarningModal(this.updateScheme.bind(this));
  }

  public onDeleteScheme(): void {
    if (this.cartService.cart.schemes.length === 1) {
      this.deleteScheme().subscribe();
    } else {
      this.fundingService.launchWarningModalWithCallBackObservable(this.cartService, this.deleteScheme.bind(this));
    }
  }

  private getSpbConfig(): InsuranceConfig {
    return this.scheme.addedInsurances.find(
      (insuranceConfig: InsuranceConfig) => insuranceConfig.code === InsurancePartnerCodeEnum.spb,
    );
  }

  private updateFundingState(activeEdp: boolean = false): void {
    this.fundingService.stateCredit.next(false);
    if (activeEdp) {
      this.cartService.setEdpFundingMode(this.scheme.quoteId);
    } else {
      this.cartService.removeCreditFundingMode();
      this.cartService.setCashFundingMode(this.scheme.quoteId);
    }
  }

  private checkScoringResult(): void {
    if (this.scoringService.scoringResult) {
      if (!this.scoringService.isScoringValid()) {
        const options: NgbModalOptions = <NgbModalOptions>{
          backdrop: 'static',
          size: 'lg',
          windowClass: 'scoring-details-modal',
          keyboard: false,
        };
        const component: ModalScoringDetailedComponent = this.modalService.open(
          ModalScoringDetailedComponent,
          options,
        ).componentInstance;
        component.schemeId = this.cartService.currentSchemeUniqueId;
      }
    }
  }

  private getPlan(): string {
    const plan: Product[] = this.scheme.getProductsByType(Plan);
    if (plan.length > 0) {
      return plan[0].name;
    }
    return '';
  }

  private setMsisdn(): void {
    if (this.isRenew) {
      const contractId = this.scheme.contractId;
      this.msisdn = this.customerService.customer.contracts.find(c => Number(c.id) === Number(contractId))?.numeroTel;
    }
  }

  private updateLoanData(): void {
    this.updateTotal();
  }

  private setEdpCommercialProposal(): void {
    this.edpCommercialProposal = this.fundingService.getEdpFundingData(this.getCurrentScheme());
  }

  private getCurrentScheme(): Scheme {
    return this.cartService.cart.schemes.find(({ quoteId }) => quoteId === this.scheme.quoteId);
  }
}
