import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TopBarComponent } from './topBar.component';
import { TopBarRoutingModule } from './topBar-routing.module';
import { ScanModule } from '../scan/scan.module';
import { BaseModule } from '../base/base.module';
import { Oauth2Module } from '../oauth2/oauth2.module';
import { CartModule } from '../checkout/cart/cart.module';
import { LogComponent } from '../log/log.component';
import { ReprisePanierComponent } from '../reprise-panier/reprise-panier.component';
import { DialogComponent } from './dialog/dialog.component';
import { NgbProgressbarModule } from '@ng-bootstrap/ng-bootstrap';
import { NotificationBoardComponent } from './notification-board/notification-board.component';
import { ToolLinksComponent } from './tool-links/tool-links.component';
import { TopBarService } from './topBar.service';

@NgModule({
  imports: [CommonModule, TopBarRoutingModule, ScanModule, BaseModule, Oauth2Module, CartModule, NgbProgressbarModule],
  declarations: [
    TopBarComponent,
    LogComponent,
    ReprisePanierComponent,
    DialogComponent,
    NotificationBoardComponent,
    ToolLinksComponent,
  ],
  exports: [TopBarComponent],
  providers: [TopBarService],
})
export class TopBarModule {}
