import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DispatchComponent } from '../dispatch/dispatch.component';
import { DispatchRoutingModule } from '../dispatch/dispatch-routing.module';
import { BaseModule } from '../base/base.module';
import { TopBarModule } from '../topBar/topBar.module';
import { CheckoutStepperModule } from '../checkout/checkout-stepper.module';
import { ModalContinuityComponent } from './modal-continuity/modal-continuity.component';
import { ScoringComponent } from './scoring/scoring.component';

@NgModule({
  imports: [CommonModule, TopBarModule, BaseModule, CheckoutStepperModule, DispatchRoutingModule],
  declarations: [DispatchComponent, ModalContinuityComponent, ScoringComponent],
})
export class DispatchModule {}
