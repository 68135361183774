import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, of } from 'rxjs';
import { catchError, finalize, map, mergeMap, tap } from 'rxjs/operators';
import { GlobalLoaderService } from '../../base/services/global-loader.service';
import { CartService } from '../../checkout/cart/cart.service';
import { Scheme } from '../../checkout/cart/scheme.class';
import { FundingService } from '../../fundings/services/funding.service';
import { Oauth2RessourceService } from '../../oauth2/oauth2-resources.service';
import { PromotionTypes } from '../../promotions/promotions.interfaces';
import { PromotionsService } from '../../promotions/promotionsService';
import { InputForScan, ScanditFieldInterface } from '../../scandit/scandit.interface';
import { ScanditService } from '../../scandit/scandit.service';
import { PromotionalEligibilityPromotionExtV2Dto } from '../cart/dto/promotional-eligibility-output.dto';
import { CheckoutStepperService } from '../checkout-stepper.service';

const LOADING_ACTIONS = {
  managePromo: '[PromotionComponent] managePromo',
  refreshEligPromo: '[PromotionComponent] refreshEligPromo',
};

@Component({
  selector: 'rcbt-promotion',
  templateUrl: './promotion.component.html',
  styleUrls: ['./promotion.component.scss'],
})
export class PromotionComponent implements OnInit, OnDestroy {
  public promotions: PromotionalEligibilityPromotionExtV2Dto[] = [];
  public error: string;
  public msg: string;
  public loading = false;
  public promoCode: string;
  public eligPromoLoading$: Observable<boolean>;
  public onTablete = false;
  public isSummaryStep = false;

  constructor(
    private activeModal: NgbActiveModal,
    public cartService: CartService,
    public oauth2RessourceService: Oauth2RessourceService,
    public promotionService: PromotionsService,
    private globalLoaderService: GlobalLoaderService,
    private fundingService: FundingService,
    private scanditService: ScanditService,
    private stepperService: CheckoutStepperService,
  ) {}

  public ngOnInit(): void {
    // Note: Do NOT dispatch 'managePromo' here
    this.globalLoaderService.dispatchLoadingStatus({ actionType: LOADING_ACTIONS.refreshEligPromo, isLoading: true });
    this.refreshEligPromo()
      .pipe(tap(() => this.updatePromotionList()))
      .subscribe();
    this.isSummaryStep = this.stepperService.getCurrentStep().code === 'summary';
    this.onTablete = this.scanditService.isOnTablet();
    if (this.onTablete) {
      this.scanditService.scanForField.subscribe((data: ScanditFieldInterface) => {
        if (data.field === InputForScan.cpu) {
          if (data.scanCode && data.scanCode.length > 0) {
            this.loading = true;
          }
          this.promoCode = data.scanCode;
          this.onAddPromoCode();
        }
      });
    }
  }

  public ngOnDestroy(): void {
    this.globalLoaderService.deleteStatusByActionTypes([LOADING_ACTIONS.refreshEligPromo, LOADING_ACTIONS.managePromo]);
  }

  public updatePromotionList(): void {
    this.promotions = [];
    const { coupons, couponsUniques, manuelles } = this.promotionService.getLastEligPromoResult().promotions;
    this.appendPromotions(manuelles);
    let schemeIndex = 0;
    const promotionsToBasket: PromotionalEligibilityPromotionExtV2Dto[] = [];
    if (coupons?.length > 0) {
      promotionsToBasket.push(...coupons);
    }
    if (couponsUniques?.length > 0) {
      promotionsToBasket.push(...couponsUniques);
    }
    if (promotionsToBasket.length) {
      this.appendPromotions(promotionsToBasket);
    }
    this.cartService.cart.schemes.forEach((scheme: Scheme) => {
      this.appendPromotions(this.promotionService.getLastEligPromoResult().parcours[schemeIndex].promotions.manuelles);
      let productIndex = 0;
      scheme.products.forEach(() => {
        this.appendPromotions(
          this.promotionService.getLastEligPromoResult().parcours[schemeIndex].produits[productIndex].promotions
            .manuelles,
        );
        // TODO demander à hamza de remonter un tableau vide "coupons" pour éviter le "if (coupons.length)" chez nous
        const couponsProduct: PromotionalEligibilityPromotionExtV2Dto[] =
          this.promotionService.getLastEligPromoResult().parcours[schemeIndex].produits[productIndex].promotions
            .coupons || [];
        if (couponsProduct.length) {
          this.appendPromotions(couponsProduct);
        }
        const couponsUniquesProduct: PromotionalEligibilityPromotionExtV2Dto[] =
          this.promotionService.getLastEligPromoResult().parcours[schemeIndex].produits[productIndex].promotions
            .couponsUniques || [];
        if (couponsUniquesProduct.length) {
          this.appendPromotions(couponsUniquesProduct);
        }
        productIndex++;
      });
      schemeIndex++;
    });
  }

  /**
   * On affiche les promotions manuel et les promotions de type coupon si elle sont appliqué
   * TODO refacto les méthode isCoupon et isManuel pour leur passer une promo de type PromotionalEligibilityPromotionExtV2Dto
   * et pouvoir les utiliser
   * @param {Promotion[]} applicablePromotions
   */
  public appendPromotions(applicablePromotions: PromotionalEligibilityPromotionExtV2Dto[]): void {
    applicablePromotions
      .filter(
        (applicablePromotion: PromotionalEligibilityPromotionExtV2Dto) =>
          applicablePromotion.type === PromotionTypes.manual ||
          (this.isCoupon(applicablePromotion) && this.isApplied(applicablePromotion)),
      )
      .forEach((applicablePromotion: PromotionalEligibilityPromotionExtV2Dto) => {
        if (!this.promotions.find((p: PromotionalEligibilityPromotionExtV2Dto) => p.id === applicablePromotion.id)) {
          this.promotions.push(applicablePromotion);
        }
      });
  }

  /**
   * @param promo
   */
  public getDiscount(promo: PromotionalEligibilityPromotionExtV2Dto): string {
    if (this.isPercentPromo(promo)) {
      return `-${promo.valeurRemise}%`;
    }
    return `-${promo.valeurRemise}€`;
  }

  public onAddPromo(promo: PromotionalEligibilityPromotionExtV2Dto): void {
    this.fundingService.launchWarningModalWithCallBackObservable(this.cartService, this.addPromo.bind(this), promo);
  }

  public onDeletePromo(promoId: number): void {
    this.fundingService.launchWarningModalWithCallBackObservable(
      this.cartService,
      this.deletePromo.bind(this),
      promoId,
    );
  }

  public addPromo(promo: PromotionalEligibilityPromotionExtV2Dto): Observable<void> {
    const obs = this.promotionService.addPromoById(promo.id, this.cartService.cart.cartId, { type: promo.type });
    return this.changePromo(obs);
  }

  public deletePromo(promoId: number): Observable<void> {
    const obs = this.promotionService.deletePromo(promoId, this.cartService.cart.cartId);
    return this.changePromo(obs);
  }

  public onAddPromoCode(): void {
    this.fundingService.launchWarningModalWithCallBackObservable(this.cartService, this.addPromoCode.bind(this));
  }

  public addPromoCode(): Observable<void> {
    if (this.promotions.some((promo: PromotionalEligibilityPromotionExtV2Dto) => promo.coupon === this.promoCode)) {
      this.showError(`Le code promo ${this.promoCode} est déjà appliqué sur ce panier`);
      this.promoCode = null;
      return of(null);
    }
    this.loading = true;
    return this.globalLoaderService
      .showLoaderUntilFinalize(
        this.promotionService.applyPromoCode(this.promoCode, this.cartService.cart.cartId).pipe(
          mergeMap(() => this.cartService.refreshCart()),
          tap(() => {
            this.showSuccess(`Le coupon ${this.promoCode} a été appliqué`);
            this.updatePromotionList();
          }),
          catchError(e =>
            this.oauth2RessourceService
              .useSalesApi()
              .setLocalService()
              .ventes()
              .promotions()
              .cpu(this.promoCode)
              .get()
              .pipe(
                tap(
                  (response: { status: string }) => {
                    this.showError(response.status);
                  },
                  () => {
                    if (e.error.message) {
                      this.showError(e.error.message);
                    }
                  },
                ),
              ),
          ),
          finalize(() => {
            this.promoCode = null;
            this.loading = false;
          }),
        ),
        LOADING_ACTIONS.managePromo,
      )
      .pipe(map(() => null));
  }

  public close(): void {
    this.activeModal.close();
  }

  public isPercentPromo(promotion: PromotionalEligibilityPromotionExtV2Dto): boolean {
    return promotion.type === 'POURCENTAGE' || promotion.typeRemise === 'POURCENTAGE';
  }

  public getCartTotalWithAgility(): number {
    let total: number = this.cartService.cart.totals.today;
    const agility = this.cartService.getAgility();
    if (agility) {
      total += agility.application.actionPromotion.amount;
    }
    const agilityCa = this.cartService.getAgilityCa();
    if (agilityCa) {
      total += agilityCa.application.actionPromotion.amount;
    }
    return total;
  }

  public scanOpen(): void {
    if (this.onTablete) {
      this.scanditService.openScanForField(InputForScan.cpu);
    }
  }

  protected showError(error: string): void {
    const errorDuration = 8000;
    this.error = error;
    setTimeout(() => {
      this.error = null;
    }, errorDuration);
  }

  protected showSuccess(msg: string): void {
    const msgDuration = 8000;
    this.msg = msg;
    setTimeout(() => {
      this.msg = null;
    }, msgDuration);
  }

  private isCoupon(p: PromotionalEligibilityPromotionExtV2Dto): boolean {
    return p.type === PromotionTypes.coupon || p.type === PromotionTypes.cpu;
  }

  private isApplied(p: PromotionalEligibilityPromotionExtV2Dto): boolean {
    return this.cartService.isPromoAlreadyApplied(p.id);
  }

  private refreshEligPromo(): Observable<unknown> {
    return this.globalLoaderService.showLoaderUntilFinalize(
      this.promotionService.eligibilitePromotionnelle(this.cartService.getCartAsEligPromoBody()),
      LOADING_ACTIONS.refreshEligPromo,
    );
  }

  private changePromo(inObs: Observable<void>): Observable<void> {
    this.loading = true;
    return this.globalLoaderService.showLoaderUntilFinalize(
      inObs.pipe(
        mergeMap(() => this.cartService.deleteGrantedLoan()),
        mergeMap(() => this.cartService.refreshCart()),
        mergeMap(() => this.refreshEligPromo()),
        tap(() => this.updatePromotionList()),
        catchError(() => {
          this.showError("Une erreur s'est produite, impossible d'appliquer le changement de promotion");
          return of(null);
        }),
        finalize(() => (this.loading = false)),
      ),
      LOADING_ACTIONS.managePromo,
    );
  }
}
