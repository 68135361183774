<!-- Steps -->
<div class="col-12">

    <div class="order-steps row">

        <a class="step btn btn-default col-auto" *ngFor="let step of steps; let i=index" (click)="selectStep(step)" [ngClass]="{active:step.isActive}" [hidden]="step.isHidden || !step.isAllowed">
            <!--<span class="number">{{ i + 1 }}.</span>-->
            <span class="name">{{ step.label }}</span>
        </a>

    </div>

    <div class="row">

    	<h1 class="step-title col-12">{{ getCurrentStep().label }}</h1>

    </div>

</div>
