import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OdrModule } from '../../../odr/odr.module';
import { ViewComponent } from './view.component';
import { PlansRenewComponent } from './renew/plans.component';
import { PlansAcquiseComponent } from './acquise/plans.component';
import { BaseModule } from '../../../base/base.module';
import { ViewDirective } from './view.directive';
import { FormsModule } from '@angular/forms';
import { PlansComponent } from './plans.component';
import { ModalsModule } from './modals/modals.module';
import { LoaderModule } from '../../../loader/loader.module';

@NgModule({
  imports: [CommonModule, BaseModule, FormsModule, ModalsModule, LoaderModule, OdrModule],
  declarations: [ViewComponent, PlansComponent, PlansRenewComponent, PlansAcquiseComponent, ViewDirective],
  exports: [ViewComponent, ViewDirective],
})
export class ViewModule {}
