import { EventEmitter, Injectable } from '@angular/core';
import { JsonProduct } from '../catalog/products/interface/context';
import * as html2pdf from 'html2pdf.js';
import { CartService, PaymentMode } from '../checkout/cart/cart.service';
import { Sowo } from '../catalog/products/subscription/plan/sowo';
import { forkJoin, from, Observable } from 'rxjs';
import { RenewScheme } from '../checkout/cart/renew-scheme';
import { CustomerService } from '../checkout/cart/customer/customer.service';
import { VirtualScheme } from '../contextualized-catalog/models/virtual-scheme';
import {
  IPostCatalogResponse,
  IVirtualProductCtx,
} from '../contextualized-catalog/dtos/contextualized-product-output.dto';
import { CatalogCtxService } from '../contextualized-catalog/services/catalog-ctx.service';
import {
  ConsulterCatalogueContextualiseAvecPanierQuery,
  ProductsContextRequestProductDto,
} from '@bytel/pt-ihm-api-portailvente-sapic-catalogue';
import { CatalogInputHelperDto } from '../contextualized-catalog/dtos/catalog-input-helper.dto';
import { CatalogOutputHelperDto } from '../contextualized-catalog/dtos/catalog-output-helper.dto';
import { map } from 'rxjs/operators';
import { VirtualProduct } from '../contextualized-catalog/models/virtual-product';
import { GlobalLoaderService } from '../base/services/global-loader.service';

const LOADING_ACTIONS = {
  loadingProducts: '[ProductListComponent] loadingProducts',
  pdfGenerating: '[ProductListComponent] pdfGenerating',
};

@Injectable({
  providedIn: 'root',
})
export class ComparatorService {
  public static phoneAlone = 'Téléphone seul';
  public availablePlans: JsonProduct[] = [];
  public selectedPhones: IPostCatalogResponse[] = [];
  public active = false;
  public selectedPlans: JsonProduct[] = [];
  public generatingPdf: EventEmitter<boolean> = new EventEmitter<boolean>();
  public isPhoneAloneSelected = false;
  public nbPlansSelected = 0;
  public act: string;
  public virtualSchemes: {
    [planGencode: string]: { [phoneGencode: string]: { scheme: VirtualScheme; schemeEdp: VirtualScheme } };
  } = {};

  constructor(
    private readonly cartService: CartService,
    private customerService: CustomerService,
    private catalogCtxService: CatalogCtxService,
    private globalLoaderService: GlobalLoaderService,
  ) {}

  public selectProduct(ctxPhone: IPostCatalogResponse): void {
    const phoneExists: boolean = this.selectedPhones.some(tmpPhone => tmpPhone.gencode === ctxPhone.gencode);

    if (phoneExists) {
      this.selectedPhones = this.selectedPhones.filter(tmpPhone => tmpPhone.gencode !== ctxPhone.gencode);
    } else if (this.selectedPhones.length < 3) {
      this.selectedPhones.push(ctxPhone);
    }
  }

  public removeProduct(gencode: string): void {
    this.selectedPhones = this.selectedPhones.filter(tmpPhone => tmpPhone.gencode !== gencode);
  }

  public empty(): void {
    this.selectedPhones = [];
    this.selectedPlans = [];
  }

  public compare(): void {
    this.generatingPdf.emit(true);
    const browseTypeSpaceLower = this.cartService.getCurrentScheme().browseType.replace(/_/i, ' ').toLowerCase();
    this.act = browseTypeSpaceLower.replace(/^\w/, c => c.toUpperCase());
    this.globalLoaderService
      .showLoaderUntilFinalize(forkJoin(this.loadContextualizedProductsData()), LOADING_ACTIONS.loadingProducts)
      .subscribe(
        (contextualizedProducts: IPostCatalogResponse[][]) => {
          let phoneOnlyContextualizedProducts: IPostCatalogResponse[];
          if (this.isPhoneAloneSelected) {
            phoneOnlyContextualizedProducts = contextualizedProducts.pop();
          }
          if (phoneOnlyContextualizedProducts) {
            this.parsePhoneOnlyResult(phoneOnlyContextualizedProducts);
          }
          this.parsePlansResult(contextualizedProducts);
          this.generatePdf('rcbt-comparator-result');
        },
        () => {
          this.generatingPdf.emit(false);
        },
      );
  }

  public onPlanChecked(jsonPlans: JsonProduct[]): void {
    this.selectedPlans = [];
    this.nbPlansSelected = 0;
    this.isPhoneAloneSelected = false;
    jsonPlans.forEach((jsonProduct: JsonProduct) => {
      this.nbPlansSelected++;
      if (jsonProduct.name === ComparatorService.phoneAlone) {
        this.isPhoneAloneSelected = true;
      } else {
        this.selectedPlans.push(jsonProduct);
      }
    });
  }

  public reset(): void {
    this.active = false;
    this.empty();
  }

  public isCompareButtonEnabled(): boolean {
    return this.selectedPhones.length && !!(this.selectedPlans.length || this.isPhoneAloneSelected);
  }

  public getPlan(plan: JsonProduct): VirtualProduct {
    if (this.selectedPhones?.length && !!this.virtualSchemes[plan.gencode]) {
      return this.virtualSchemes[plan.gencode][this.selectedPhones[0].gencode].scheme.plan;
    }
    return null;
  }

  /**
   * get All Avaiblable Plans for the comparator
   */
  public processPlansForCompare(planList: JsonProduct[]): void {
    const currentScheme = this.cartService.getCurrentScheme();
    if (currentScheme instanceof RenewScheme) {
      if (currentScheme.planType === Sowo.planType) {
        planList = planList.filter(p => p.gencode !== currentScheme.gencodeOffre);
      }
    } else {
      planList.unshift({ name: ComparatorService.phoneAlone } as JsonProduct);
    }
    this.availablePlans = planList;
    this.highlightRenewPlan();
  }

  private highlightRenewPlan(): void {
    this.availablePlans.forEach((plan: JsonProduct) => {
      if (
        plan.name !== ComparatorService.phoneAlone &&
        plan.gencode === (this.cartService.getCurrentScheme() as RenewScheme).gencodeOffre
      ) {
        plan['highlighted'] = true;
      }
    });
  }

  private parsePhoneOnlyResult(phoneOnlyContextualizedProducts: IPostCatalogResponse[]): void {
    phoneOnlyContextualizedProducts.forEach(phonesOnlyContextualizedProduct => {
      if (!this.virtualSchemes[ComparatorService.phoneAlone]) {
        this.virtualSchemes[ComparatorService.phoneAlone] = {};
      }
      this.virtualSchemes[ComparatorService.phoneAlone][phonesOnlyContextualizedProduct.gencode] = {
        scheme: this.createVirtualSchemeInstance(
          phonesOnlyContextualizedProduct,
          PaymentMode.cash,
          phonesOnlyContextualizedProduct.gencode,
        ),
        schemeEdp: this.createVirtualSchemeInstance(
          phonesOnlyContextualizedProduct,
          PaymentMode.edp,
          phonesOnlyContextualizedProduct.gencode,
        ),
      };
    });
  }

  private createVirtualSchemeInstance(
    phoneOnlyContextualizedProducts: IPostCatalogResponse,
    fundingMode: PaymentMode,
    gencode: string,
  ): VirtualScheme {
    const sapicScheme = new VirtualScheme();
    sapicScheme.setEquipmentData(phoneOnlyContextualizedProducts, fundingMode, gencode);
    return sapicScheme;
  }

  private parsePlansResult(plansContextualizedProducts: IPostCatalogResponse[][]): void {
    this.selectedPlans.forEach(currentPlan => {
      const currentPlanContextualizedProduct = plansContextualizedProducts[this.selectedPlans.indexOf(currentPlan)];
      currentPlanContextualizedProduct.forEach(phoneWithPlanContextualizedProduct => {
        const currentSapicScheme = phoneWithPlanContextualizedProduct.panierSimule.parcours.find(
          parcours => parcours.estCourant,
        );
        const sapicPlan: IVirtualProductCtx = currentSapicScheme.produits.find(
          planContextualised => planContextualised.gencode === currentPlan.gencode,
        );
        if (!this.virtualSchemes[currentPlan.gencode]) {
          this.virtualSchemes[currentPlan.gencode] = {};
        }
        this.virtualSchemes[currentPlan.gencode][phoneWithPlanContextualizedProduct.gencode] = {
          scheme: this.createVirtualSchemeInstance(
            phoneWithPlanContextualizedProduct,
            PaymentMode.cash,
            phoneWithPlanContextualizedProduct.gencode,
          ).setPlanData(sapicPlan),
          schemeEdp: this.createVirtualSchemeInstance(
            phoneWithPlanContextualizedProduct,
            PaymentMode.edp,
            phoneWithPlanContextualizedProduct.gencode,
          ).setPlanData(sapicPlan),
        };
      });
    });
  }

  /**
   * Préparer les appels à sapic : 1 pour chaque plan selectionné + 1 appel pour tél seul
   * @private
   */
  private loadContextualizedProductsData(): Observable<IPostCatalogResponse[]>[] {
    const contextualizedDataObs: Observable<IPostCatalogResponse[]>[] = [];
    const queryParamCtx: ConsulterCatalogueContextualiseAvecPanierQuery = {
      modePourFinancement: 'min-one-off',
      limite: 20,
      detail: 'true',
      gencodes: this.selectedPhones.map(phone => phone.gencode).join(','),
      forceEdp: 'true',
    };

    this.selectedPlans.forEach((plan: JsonProduct) => {
      const catalogBodywithCart = CatalogInputHelperDto.buildBodyCtxWithCart(
        this.customerService.customer,
        this.cartService.cart,
        this.getPayloadProductForPlan(plan.gencode),
        this.cartService.getCurrentScheme()?.uniqueId,
      );

      contextualizedDataObs.push(
        this.catalogCtxService
          .postCatalogCtx(catalogBodywithCart, queryParamCtx)
          .pipe(map(catalogResult => CatalogOutputHelperDto.convertCatalogResponse(catalogResult))),
      );
    });
    if (this.isPhoneAloneSelected) {
      const catalogBodywithCart = CatalogInputHelperDto.buildBodyCtxWithCart(
        this.customerService.customer,
        this.cartService.cart,
        null,
        this.cartService.getCurrentScheme()?.uniqueId,
      );
      const queryParamCtxForPhones: ConsulterCatalogueContextualiseAvecPanierQuery = {
        modePourFinancement: 'min-one-off',
        limite: 20,
        detail: 'true',
        gencodes: this.selectedPhones.map(phone => phone.gencode).join(','),
        forceEdp: 'true',
      };
      contextualizedDataObs.push(
        this.catalogCtxService
          .postCatalogCtx(catalogBodywithCart, queryParamCtxForPhones)
          .pipe(map(catalogResult => CatalogOutputHelperDto.convertCatalogResponse(catalogResult))),
      );
    }
    return contextualizedDataObs;
  }

  private getPayloadProductForPlan(planGencode: string): ProductsContextRequestProductDto {
    return {
      gencode: planGencode,
      catalogue: 'BYTEL',
    };
  }

  private generatePdf(selector: string): void {
    const element: HTMLDivElement = document.getElementsByTagName(selector)[0] as HTMLDivElement;
    const opt = {
      margin: 10,
      filename: 'ComparateurProduits.pdf',
      // image:        { type: 'jpeg', quality: 0.98 },
      // html2canvas:  { scale: 1 },
      // jsPDF:        { unit: 'mm', format: 'a4', orientation: 'portrait' }
    };
    from(html2pdf().set(opt).from(element).save()).subscribe(
      () => {
        this.generatingPdf.emit(false);
      },
      () => {
        this.generatingPdf.emit(false);
      },
    );
  }
}
