import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ScanService {
  private static instance: ScanService;
  /**
   * [onChangesParent description]
   * @type {EventEmitter}
   */
  public onBeginScan = new Subject<boolean>();
  /**
   * [onEndScan description]
   * @type {EventEmitter}
   */
  public onEndScan = new Subject<boolean>();
  /**
   * [constructor description]
   * @return {[type]} [description]
   */
  constructor() {
    ScanService.instance = ScanService.instance || this;
  }
}
