import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { inject } from '@angular/core';
import { PrismeLoggerService } from './prisme-logger.service';
import { PrismeLogType } from './prisme-log-type.enum';

export const prismeLoggerResolver: ResolveFn<void> = (
  _route: ActivatedRouteSnapshot,
  _state: RouterStateSnapshot,
  primseService: PrismeLoggerService = inject(PrismeLoggerService),
) => {
  // Sending an empty object because info of routing is already in log prisme context
  primseService.sendDataToPrisme(PrismeLogType.urlChange, {});
};
