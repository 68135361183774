import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ValuesPipe } from '../../../../base/pipes/values.pipe';

import { FormsModule } from '@angular/forms';
import { BaseModule } from '../../../../base/base.module';
import { ComparatorModule } from '../../../../comparator/comparator.module';
import { TopBarRoutingModule } from '../../../../topBar/topBar-routing.module';
import { TopBarFilterComponent as TopBarFilterComponent } from './top-bar-filter/top-bar-filter.component';
import { SideBarFilterComponent } from './side-bar-filter/side-bar-filter.component';
import { FilterService } from './filter.service';
import { TakebackModule } from '../../../../checkout/takeback/takeback.module';

@NgModule({
  imports: [FormsModule, ComparatorModule, TopBarRoutingModule, CommonModule, BaseModule, TakebackModule],
  declarations: [TopBarFilterComponent, SideBarFilterComponent, ValuesPipe],
  exports: [TopBarFilterComponent, SideBarFilterComponent],
  providers: [FilterService],
})
export class FilterModule {}
