<div class="box edp" *ngIf="(edpData | async) as edp">
    <div class="header">
        <h3 class="title is-size-4">EDP</h3>
        <div class="control switch">
            <input type="checkbox" id="switch-edp" data-cy="switch-edp" [(ngModel)]="hasEdp" (ngModelChange)="toggleEdp($event)">
            <label for="switch-edp" class="title is-size-4"></label>
        </div>
    </div>
    <div [ngClass]="{'inactive' : !hasEdp}">
        <div class="edp-content">
            <div class="columns">
                <div class="column is-narrow">
                    <span class="is-size-7">Prix téléphone</span>
                </div>
                <div class="column has-text-right">
                    <div class="old-price price-bold">
                        <div [innerHTML]=" edp.fundingFinalCost | customCurrency"></div>
                    </div>

                    <div class="regular-price price-bold" [innerHTML]="edp.initialAmount | customCurrency"></div>

                    <div class="edp-price price-bold">
                        +<span [innerHTML]="edp.monthlyAmount | customCurrency"></span>/mois
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
