import { EventEmitter, Injectable, Output } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  @Output() public isLoading = new EventEmitter<boolean>();
  public errorEmitter: Subject<string> = new Subject<string>();
  public successEmitter: Subject<string> = new Subject<string>();
  public errors: string[] = [];
  public messages: string[] = [];
  public loading = false;

  constructor() {
    this._init();
  }

  public emitBeenLoading(loading: boolean): void {
    this.loading = loading;
    this.isLoading.emit(loading);
  }

  protected _init(): void {
    const ALERT_DURATION = 2500;
    this.successEmitter.subscribe((message: string) => {
      this.messages.push(message);
      setTimeout(() => {
        this.messages.splice(0, 1);
      }, ALERT_DURATION);
    });

    this.errorEmitter.subscribe((error: string) => {
      this.errors.push(error);
      setTimeout(() => {
        this.errors.splice(0, 1);
      }, ALERT_DURATION);
      window.dispatchEvent(new CustomEvent('alertServiceError', { detail: error }));
    });
  }
}
