import { HttpResponse as Response } from '@angular/common/http';
import { ProductSerialized } from '../../interface/configurable';
import { ScanConfigInterface } from '../../../../scan/interfaces/types';
import { ScanConfig } from '../../../../scan/scan-config.class';
import { StockService } from '../../../stock/stock.service';
import { Observable } from 'rxjs';
import { IProductConfiguration } from '../../IProductConfiguration';
import { Complex } from '../complex';
import { JsonPhoneProduct, JsonProduct } from '../../interface/context';
import { PromotedStatusEnum } from '../../interface/promoted-status.enum';
import { BasicObject } from '../../../../base/base.interfaces';

export class Phone extends Complex {
  public static readonly phoneLabel = 'Téléphone';
  public static readonly minPriceOneOff = 1;
  /**
   * The price with the current context
   */
  public price: number;

  /**
   *
   * @type {ScanConfigInterface}
   */
  public scanConfig: ScanConfigInterface = ScanConfig.imei;

  /**
   *
   * @type {PromotedStatusEnum}
   */
  public promotedStatus: PromotedStatusEnum;

  public eligibleCredit: boolean;
  public fundableAmount: number;

  public parent: Phone;

  /**
   * @param stockService
   * @param schemeUniqueId
   * @returns {Observable<Response>}
   */
  public reserve(stockService: StockService, schemeUniqueId: string): Observable<Response<BasicObject>> {
    return stockService.reserve(this.getScanCode(), this.scanConfig.code, schemeUniqueId);
  }

  /**
   *
   * @param stockService
   * @param schemeUniqueId
   * @returns {Observable<Response>}
   */
  public cancelReservation(stockService: StockService, schemeUniqueId: string): Observable<Response<BasicObject>> {
    return stockService.cancel(this.getScanCode(), this.scanConfig.code, schemeUniqueId);
  }

  public setConfiguration(data: IProductConfiguration | JsonProduct): void {
    this.setScanCode(data['scanCode'] || null);
    this.setPromotedStatus(data['promotedStatus'] || null);
  }

  public getConfiguration(): BasicObject {
    const config: BasicObject = super.getConfiguration();
    if (this.data['odr']) {
      Object.assign(config, { odr: this.data['odr'] });
    }
    if (this.promotedStatus) {
      Object.assign(config, { promotedStatus: this.promotedStatus });
    }
    return config;
  }

  public getOneOffWithPlan(plan: string, isCollaborator?: boolean, renewType?: string): number {
    if (!this.checkPrices(plan)) {
      return 0;
    }
    if (renewType) {
      const canGetCollabPrices: boolean =
        !!this.data.prices.plans[plan].renew['collaborator'] &&
        !!this.data.prices.plans[plan].renew['collaborator'][renewType];
      const prices =
        isCollaborator && canGetCollabPrices
          ? this.data.prices.plans[plan].renew['collaborator']
          : this.data.prices.plans[plan].renew['client'];
      return prices ? prices[renewType].edp_price : 0;
    } else {
      return this.prices.plans[plan].acquisition.edp_price;
    }
  }

  public setPromotedStatus(status: string): void {
    if (status) {
      this.promotedStatus = PromotedStatusEnum[status];
    }
  }

  public serialize(): ProductSerialized {
    return {
      idParcours: this.quoteId,
      idProduit: this.itemId,
      gencode: this.gencode,
      idUnique: this._uniqueId,
      prix: this.price,
      sku: this.sku,
      promotions: this.promotions,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      type_id: this.type_id || this.data?.type_id,
      secondOffersIds: this.secondOffersIds,
      scanCode: this.getScanCode(),
      promotedStatus: this.promotedStatus,
    };
  }

  public setJsonData(data?: JsonPhoneProduct): this {
    this.eligibleCredit = data.eligible_credit;
    return super.setJsonData(data);
  }

  private checkPrices(plan: string): boolean {
    return !!this.prices && !!this.prices.plans && !!this.prices.plans[plan];
  }
}
