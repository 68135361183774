import { StringObject } from '../base/base.interfaces';
import { StorageService } from '../base/services/storage.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class RenewStorageService extends StorageService {
  public key: StringObject = {
    promotions: 'promotions',
  };
  protected prefix = 'renouvellement_';

  constructor() {
    super();
  }
}
