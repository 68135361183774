import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { forkJoin, Observable, of, Subject, Subscription, throwError } from 'rxjs';
import { IPostCatalogResponse } from '../../../contextualized-catalog/dtos/contextualized-product-output.dto';
import { Odr } from '../../../odr/odr.model';
import { ComparatorService } from '../../../comparator/comparator.service';
import { Configuration, Configurations } from '../../products/interface/configurable';
import { StockService } from '../../stock/stock.service';
import { OnCatalogCtxLoadedConfig, ViewService } from './view.service';
import { PlansComponent } from './plans.component';
import { CartService } from '../../../checkout/cart/cart.service';
import { BasicObject } from '../../../base/base.interfaces';
import { catchError, map, tap } from 'rxjs/operators';
import {
  ConsulterCatalogueContextualiseAvecPanierQuery,
  ProductsContextRequestProductDto,
} from '@bytel/pt-ihm-api-portailvente-sapic-catalogue';
import { CatalogInputHelperDto } from '../../../contextualized-catalog/dtos/catalog-input-helper.dto';
import { CatalogOutputHelperDto } from '../../../contextualized-catalog/dtos/catalog-output-helper.dto';
import { GlobalLoaderService } from '../../../base/services/global-loader.service';
import { CustomerService } from '../../../checkout/cart/customer/customer.service';
import { CatalogCtxService } from '../../../contextualized-catalog/services/catalog-ctx.service';
import { PromoRenewService } from '../../../checkout/cart/promo-renew.service';

const LOADING_ACTIONS = {
  getAllContextualisedProducts: '[ViewComponent] getAllContextualisedProducts',
  getContextualizedCatalogForPhonesAlone: '[ViewComponent] getContextualizedCatalogForPhonesAlone',
  getContextualizedCatalogForPlans: '[ViewComponent] getContextualizedCatalogForPlans',
};

@Component({
  selector: 'rcbt-catalog-category-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss'],
  providers: [StockService],
})
export class ViewComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('plansComponent') plansComponent: PlansComponent;
  public equipmentType: string;
  /**
   * [parentCode description]
   * @type {string}
   */
  public parentCode: string;
  /**
   * [childrenCodes description]
   * @type {string}
   */
  public childrenCodes: string[] = [];

  public bestChild: string;
  /**
   * [scanCode description]
   * @type {string}
   */
  public scanCode: string;

  // configuration structure, to switch child
  public configuration: Configurations = { colors: [], capacitys: [] };

  // selected configuration, or défault displayed configuration
  public selectedConfiguration: Configuration = { color: '', capacity: '' };

  public currentPlansCategory: string;
  public isRenew = false;
  public close = new Subject<boolean>();
  public onComparator = false;
  public mobileTakebackBonusAmount: number;
  public ctxPhonesOnly: IPostCatalogResponse[];
  public currentEquipment: IPostCatalogResponse;
  public loadingCtxCatalogError = false;
  public odr: Odr;
  public contextualizedPlans = new Map<string, IPostCatalogResponse[]>();
  public produitsContextualises: IPostCatalogResponse[];
  public enlargedImage = false;
  private subscriptions: Subscription = new Subscription();
  private phoneOnlyKey = 'Téléphones seuls';

  constructor(
    private viewService: ViewService,
    private readonly comparatorService: ComparatorService,
    private cartService: CartService,
    private globalLoaderService: GlobalLoaderService,
    private customerService: CustomerService,
    private catalogCtxService: CatalogCtxService,
    protected promoRenewService: PromoRenewService,
  ) {
    this.onComparator = this.comparatorService.active;
    this.subscriptions.add(
      this.viewService.onChangesParent.subscribe((data: BasicObject) => {
        this.parentCode = data.parentCode;
        this.childrenCodes = data.childrenCodes;
        this.bestChild = data.bestChild;
        this.scanCode = data.scanCode;
        this.equipmentType = data.equipmentType;
        this.isRenew = this.cartService.getCurrentScheme().isRenew();
      }),
    );
  }

  public ngOnInit(): void {
    this.reset();
    if (this.onComparator) {
      this.initQvForComparator();
    } else {
      this.subscriptions.add(
        this.viewService.getAllContextualisedProducts$.subscribe((category: string) => {
          this.currentPlansCategory = category;
          this.getAllContextualisedProducts().subscribe(() => {
            this.initQV();
            this.viewService.onCatalogCtxLoaded$.emit({
              produitsContextualises: this.produitsContextualises,
              currentEquipment: this.currentEquipment,
            } as OnCatalogCtxLoadedConfig);
          });
        }),
      );

      this.subscriptions.add(
        this.viewService.getContextualizedCatalogForPlans$.subscribe((category: string) => {
          this.currentPlansCategory = category;
          this.getContextualizedCatalogForPlans().subscribe(() => {
            this.viewService.onCatalogCtxLoaded$.emit({
              produitsContextualises: this.produitsContextualises,
              currentEquipment: this.currentEquipment,
            } as OnCatalogCtxLoadedConfig);
          });
        }),
      );
    }
  }

  public ngAfterViewInit(): void {
    if (!this.onComparator) {
      this.plansComponent.getAllContextualisedProducts();
    }
  }

  public initQvForComparator(): void {
    this.loadingCtxCatalogError = false;
    this.subscriptions.add(
      this.globalLoaderService
        .showLoaderUntilFinalize(
          this.getContextualizedCatalogForPhonesAlone().pipe(
            tap(
              () => {
                this.addAllConfigurations();
                this.updateProduct();
              },
              () => (this.loadingCtxCatalogError = true),
            ),
          ),
          LOADING_ACTIONS.getContextualizedCatalogForPhonesAlone,
        )
        .subscribe(),
    );
  }

  public initQV(): void {
    this.currentEquipment = this.getDefaultChild();
    if (this.scanCode) {
      this.addConfigrations(this.currentEquipment);
      this.updateProduct();
      return;
    }
    this.addAllConfigurations();
    this.updateProduct();
    this.defaultSelectProductForComparator();
    this.buildOdr();
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.viewService.qview = false;
  }

  /**
   *@function when new configuration selected in quickview
   *@return void
   */
  public updateConfiguration(key: string, value: string): void {
    if (this.scanCode) {
      return;
    }
    if (key === 'color') {
      this.selectedConfiguration.color = value;
    }

    if (key === 'capacity') {
      this.selectedConfiguration.capacity = value;
    }
    let selectedProduct: IPostCatalogResponse = this.ctxPhonesOnly.find(
      child =>
        child.couleurs[0] === this.selectedConfiguration.color &&
        child.capacite === this.selectedConfiguration.capacity &&
        child.quantite > 0,
    );
    if (!selectedProduct) {
      selectedProduct = this.ctxPhonesOnly.find(
        child =>
          child.couleurs[0] === this.selectedConfiguration.color &&
          child.capacite === this.selectedConfiguration.capacity,
      );
    }
    // filtrer uniquement avec la couleur OU la capacité pour trouver le premier tél disponible
    if (!selectedProduct) {
      selectedProduct = this.ctxPhonesOnly.find(child => {
        const filterValue = key === 'color' ? child.couleurs[0] : child.capacite;
        return filterValue === value;
      });
    }
    this.updateProduct(selectedProduct);
    if (!this.onComparator) {
      this.getContextualizedCatalogForPlans().subscribe(() => {
        this.viewService.onCatalogCtxLoaded$.emit({
          produitsContextualises: this.produitsContextualises,
          currentEquipment: this.currentEquipment,
        } as OnCatalogCtxLoadedConfig);
      });
    }
  }

  /**
   * [close description]
   * @return {[type]} [description]
   */
  public closeClicked(b?: boolean): void {
    this.close.next(b);
  }

  public selectProductToCompare(equipmentItem: IPostCatalogResponse): void {
    this.comparatorService.selectProduct(equipmentItem);
    this.close.next(true);
  }

  public getAllContextualisedProducts(): Observable<void[]> {
    this.loadingCtxCatalogError = false;
    const observables: Observable<void>[] = [
      this.getContextualizedCatalogForPhonesAlone(),
      this.getContextualizedCatalogForPlans(),
    ];
    return this.globalLoaderService.showLoaderUntilFinalize(
      forkJoin(observables).pipe(
        catchError(error => {
          this.loadingCtxCatalogError = true;
          return throwError(error);
        }),
      ),
      LOADING_ACTIONS.getAllContextualisedProducts,
    );
  }

  public getContextualizedCatalogForPhonesAlone(): Observable<void> {
    this.loadingCtxCatalogError = false;
    const mapKey = `${this.phoneOnlyKey}_${this.childrenCodes.join(',')}`;
    if (this.contextualizedPlans.get(mapKey)) {
      this.ctxPhonesOnly = this.contextualizedPlans.get(mapKey);
      this.currentEquipment = this.ctxPhonesOnly?.find(
        equipementCourant => equipementCourant.gencode === this.bestChild,
      );
      return of(null);
    } else {
      const queryParamCtx: ConsulterCatalogueContextualiseAvecPanierQuery = this.initializeContextParams();
      queryParamCtx.gencodes = this.childrenCodes.join(',');

      const catalogBodywithCart = CatalogInputHelperDto.buildBodyCtxWithCart(
        this.customerService.customer,
        this.cartService.cart,
        null,
        this.cartService.getCurrentScheme()?.uniqueId,
      );

      return this.catalogCtxService.postCatalogCtx(catalogBodywithCart, queryParamCtx).pipe(
        catchError(error => {
          this.loadingCtxCatalogError = true;
          return throwError(error);
        }),
        map(catalogResult => CatalogOutputHelperDto.convertCatalogResponse(catalogResult)),
        map(phoneOnlyResult => {
          this.ctxPhonesOnly = phoneOnlyResult;
          const currentEquipGencode = this.currentEquipment?.gencode || this.bestChild;
          this.currentEquipment = this.ctxPhonesOnly.find(phoneAlone => currentEquipGencode === phoneAlone.gencode);
          this.contextualizedPlans.set(mapKey, phoneOnlyResult);
        }),
      );
    }
  }

  public getContextualizedCatalogForPlans(): Observable<void> {
    this.loadingCtxCatalogError = false;
    const prefixCacheKey = `${this.isRenew ? 'renew' : this.currentPlansCategory}`;
    const mapKey = `${prefixCacheKey}_${this.currentEquipment?.gencode || this.bestChild}`;
    if (this.contextualizedPlans.get(mapKey)) {
      this.produitsContextualises = this.contextualizedPlans.get(mapKey);
      return of(null);
    } else {
      const queryParamCtx: ConsulterCatalogueContextualiseAvecPanierQuery = this.initializeContextParams();

      if (this.isRenew) {
        queryParamCtx.gencodes = this.promoRenewService.idOffers.join(',');
      } else {
        queryParamCtx.categorie = this.currentPlansCategory;
      }

      const catalogBodywithCart = CatalogInputHelperDto.buildBodyCtxWithCart(
        this.customerService.customer,
        this.cartService.cart,
        this.getPayloadProduct(),
        this.cartService.getCurrentScheme()?.uniqueId,
      );

      return this.globalLoaderService.showLoaderUntilFinalize(
        this.catalogCtxService.postCatalogCtx(catalogBodywithCart, queryParamCtx).pipe(
          catchError(error => {
            this.loadingCtxCatalogError = true;
            return throwError(error);
          }),
          map(catalogResult => CatalogOutputHelperDto.convertCatalogResponse(catalogResult)),
          map(plansResult => {
            this.produitsContextualises = this.sortPlans(plansResult);
            this.contextualizedPlans.set(mapKey, this.produitsContextualises);
          }),
        ),
        LOADING_ACTIONS.getContextualizedCatalogForPlans,
      );
    }
  }

  public reload(): void {
    if (this.onComparator) {
      this.initQvForComparator();
    } else {
      this.viewService.getAllContextualisedProducts$.emit(this.currentPlansCategory);
    }
  }

  public updateMainImage(imageUrl: string): void {
    this.currentEquipment.image = imageUrl;
  }

  private sortPlans(plansResult: IPostCatalogResponse[]): IPostCatalogResponse[] {
    return plansResult.sort((a, b) => {
      if (a.prix instanceof Number || b.prix instanceof Number) {
        return;
      }
      return b.prix.initial - a.prix.initial;
    });
  }

  private getPayloadProduct(): ProductsContextRequestProductDto {
    return {
      gencode: this.currentEquipment?.gencode || this.bestChild,
      catalogue: 'BYTEL',
    };
  }

  private buildOdr(): void {
    this.odr = undefined;
    const odrPromo = this.currentEquipment.promotions.find(p => p.type === 'ODR');
    if (odrPromo) {
      this.odr = {
        file: this.currentEquipment.urlDocumentOdr,
        amount: odrPromo.reduction + '',
        type: 'phone_simple',
      };
    }
  }

  /**
   *@function called when new child is selected
   *@return void
   */
  private updateProduct(product?: IPostCatalogResponse): void {
    this.cartService.modifiedOffer.emit(true);
    this.currentEquipment = product || this.currentEquipment;
    this.selectedConfiguration = {
      color: this.currentEquipment.couleurs[0],
      capacity: this.currentEquipment.capacite,
    };
    this.buildOdr();
  }

  private addConfigrations(child: IPostCatalogResponse): void {
    const color = child.couleurs[0];
    const capacity = child.capacite;
    if (color && !this.configuration.colors.includes(color)) {
      this.configuration.colors.push(color);
    }
    if (capacity && !this.configuration.capacitys.includes(capacity)) {
      this.configuration.capacitys.push(capacity);
    }
  }

  /**
   *@function used to clear all data when switch quickview
   *@return void
   */
  private reset(): void {
    this.selectedConfiguration = { color: '', capacity: '' };
    this.configuration = { colors: [], capacitys: [] };
    this.mobileTakebackBonusAmount = 0;
  }

  private defaultSelectProductForComparator(): void {
    if (this.onComparator && this.ctxPhonesOnly.length === 1) {
      this.selectProductToCompare(this.ctxPhonesOnly[0]);
    }
  }

  private getDefaultChild(): IPostCatalogResponse {
    return this.currentEquipment || this.ctxPhonesOnly.find(phoneAlone => phoneAlone.gencode === this.bestChild);
  }

  private addAllConfigurations(): void {
    this.addConfigrations(this.currentEquipment);
    for (const equipmentItem of this.ctxPhonesOnly) {
      if (equipmentItem.gencode == this.currentEquipment.gencode) {
        continue;
      }
      this.addConfigrations(equipmentItem);
    }
  }

  private initializeContextParams(): ConsulterCatalogueContextualiseAvecPanierQuery {
    const queryParamCtx: ConsulterCatalogueContextualiseAvecPanierQuery = {
      modePourFinancement: 'min-one-off',
      limite: 20,
      detail: 'true',
      gencodes: undefined,
      categorie: undefined,
      forceEdp: 'true',
    };
    return queryParamCtx;
  }
}
