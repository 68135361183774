import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { BasicObject } from '../../base.interfaces';

@Component({
  selector: 'rcbt-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss'],
})
export class MultiSelectComponent implements OnInit, OnChanges {
  @Input() options: {}[];
  @Input() title: string;
  @Input() keyLabel: string;
  @Input() maxSelection = -1;
  @Output() checked: EventEmitter<BasicObject[]> = new EventEmitter<BasicObject[]>();

  public name: string;
  public show = false;
  public selection: { selected: boolean; value: {} }[] = [];
  private debouncer;

  constructor() {
    this.name = 'multiselect' + new Date().getTime();
  }

  public ngOnInit(): void {
    this.reset();
  }

  public ngOnChanges(): void {
    this.reset();
  }

  public toggleDropdown(): void {
    this.show = !this.show;
  }

  public debounceCloseDropdown(): void {
    this.debouncer = setTimeout(() => {
      this.show = false;
    }, 1000);
  }

  public undebounceCloseDropdown(): void {
    clearTimeout(this.debouncer);
  }

  public select(selection: { selected: boolean; value: {} }): void {
    if (selection.selected) {
      selection.selected = false;
      this.emit();
      return;
    }

    if (this.maxSelection >= 0 && this.selection.filter(s => s.selected).length >= this.maxSelection) {
      return;
    }
    selection.selected = true;
    this.emit();
  }

  public checkMaxSelected(isSelected: boolean): boolean {
    return !isSelected && this.maxSelection >= 0 && this.selection.filter(s => s.selected).length >= this.maxSelection;
  }

  public reset(): void {
    this.selection = this.options.map(option => ({
      value: option,
      selected: false,
    }));
  }

  private emit(): void {
    const values = this.selection.filter(opt => opt.selected).map(opt => opt.value);
    this.checked.next(values);
  }
}
