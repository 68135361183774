import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TopBarComponent } from './topBar.component';
import { prismeLoggerResolver } from '../shared/prisme/prisme-logger.resolver';

@NgModule({
  imports: [RouterModule.forChild([{ path: 'topbar', component: TopBarComponent, resolve: { prismeLoggerResolver } }])],
  exports: [RouterModule],
})
export class TopBarRoutingModule {}
