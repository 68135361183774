import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Notification, ChangeStatus, SimpleNotification } from './notification-board.interface';
import { ConfigService } from '../../config.service';
import { DomSanitizer } from '@angular/platform-browser';
import { finalize, takeUntil, tap } from 'rxjs/operators';
import { Oauth2RessourceService } from '../../oauth2/oauth2-resources.service';
import { NotificationStorageService } from './notification-storage.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'rcbt-notification-board',
  templateUrl: './notification-board.component.html',
  styleUrls: ['./notification-board.component.scss'],
})
export class NotificationBoardComponent implements OnInit, OnChanges, OnDestroy {
  @Output()
  public emitActive: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  public emitOpen: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  public emitNbMsg: EventEmitter<number> = new EventEmitter<number>();

  @Output()
  public emitUpdated: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  public emitDoClose: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() public isOpened = false;
  @Input() public login: string;
  public notificationList: SimpleNotification[] = [];
  public notificationListLastViewed: SimpleNotification[];
  public readonly changeStatus = ChangeStatus;
  private unsubscribe$ = new Subject();
  private clearInterval;

  constructor(
    private readonly notificationStorage: NotificationStorageService,
    private readonly configService: ConfigService,
    private readonly oauth2ressourceService: Oauth2RessourceService,
    private readonly sanitizer: DomSanitizer,
  ) {}

  public ngOnInit(): void {
    if (this.getActive()) {
      this.intervalFetchActiveNotifications();
      this.emitActive.emit(true);
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.isOpened.previousValue === true && changes.isOpened.currentValue === false) {
      this.saveNotificationListLastViewed();
      this.emitUpdated.emit(false);
    }
    if (changes.isOpened.previousValue === false && changes.isOpened.currentValue === true) {
      this.fetchActiveNotifications(true);
    }
  }

  public ngOnDestroy(): void {
    clearInterval(this.clearInterval);
  }

  public doClose(): void {
    this.emitDoClose.emit(true);
  }

  private getActive(): boolean {
    if (!this.configService.data.notificationBoard?.active) {
      return false;
    }
    const param: string = this.configService.data.notificationBoard.active;
    return param === '1' || param === 'true';
  }

  private getAutoPopin(): boolean {
    if (!this.configService.data.notificationBoard?.autoPopin) {
      return false;
    }
    const param: string = this.configService.data.notificationBoard.autoPopin;
    return param === '1' || param === 'true';
  }

  private getRefreshInterval(): number {
    return this.configService.data.notificationBoard?.interval || 5;
  }

  private intervalFetchActiveNotifications(): void {
    this.fetchActiveNotifications();

    this.clearInterval = setInterval(() => {
      this.fetchActiveNotifications();
    }, this.getRefreshInterval() * 60000);
  }

  private fetchActiveNotifications(openAll = false): void {
    this.oauth2ressourceService
      .activeNotifications()
      .get()
      .pipe(
        tap((notifications: Notification[]) => {
          this.integrateNotificationList(notifications);
          if (openAll) {
            this.notificationList.forEach(n => (n.isOpened = true));
          }
        }),
      )
      .pipe(
        takeUntil(this.unsubscribe$),
        finalize(() => {
          this.unsubscribe$.next();
          this.unsubscribe$.complete();
        }),
      )
      .subscribe();
  }

  private integrateNotificationList(msgList: Notification[]): void {
    const closedNotificationIds: string[] = this.notificationList.reduce<string[]>((acc, current) => {
      if (!current.isOpened) {
        acc.push(current.id);
      }
      return acc;
    }, []);
    this.notificationList = [];
    this.restoreNotificationListLastViewed();
    msgList.sort((a, b) => a.priorite - b.priorite);
    for (const msg of msgList) {
      const simpleMsg: SimpleNotification = {} as SimpleNotification;
      simpleMsg.id = msg.id;
      simpleMsg.title = msg.titre;
      simpleMsg.description = msg.description;
      simpleMsg.descriptionHtml = this.sanitizer.bypassSecurityTrustHtml(msg.description) as string;
      simpleMsg.isResolved = msg.estResolu;
      simpleMsg.isOpened = !closedNotificationIds.some(id => msg.id === id);
      this.setStatusMsg(simpleMsg, this.notificationListLastViewed);
      this.notificationList.push(simpleMsg);
    }

    this.emitNbMsg.emit(this.notificationList.length);
    if (this.notificationList.some(msg => msg.changeStatus !== ChangeStatus.unchanged)) {
      this.emitUpdated.emit(true);
      if (this.getAutoPopin()) {
        this.emitOpen.emit(true);
      }
    }
    this.notificationList.filter(m => m.isResolved).forEach(m => (m.changeStatus = ChangeStatus.resolved));
  }

  private setStatusMsg(msg: SimpleNotification, msgList: SimpleNotification[]): void {
    msg.changeStatus = ChangeStatus.new;
    if (!msgList?.length) {
      return;
    } else {
      for (const msgItem of msgList) {
        if (msgItem.id === msg.id) {
          msg.changeStatus = this.getStatusFromMsgComparison(msg, msgItem);
          break;
        }
      }
    }
  }

  private getStatusFromMsgComparison(msgNew: SimpleNotification, msgOld: SimpleNotification): ChangeStatus {
    if (
      msgOld.title === msgNew.title &&
      msgOld.description === msgNew.description &&
      msgOld.isResolved === msgNew.isResolved
    ) {
      return ChangeStatus.unchanged;
    } else if (msgOld.title === msgNew.title && msgOld.description === msgNew.description) {
      return ChangeStatus.resolved;
    } else {
      return ChangeStatus.updated;
    }
  }

  private saveNotificationListLastViewed(): void {
    this.notificationListLastViewed = [];
    for (const msg of this.notificationList) {
      const simpleMsg: SimpleNotification = msg;
      simpleMsg.changeStatus = ChangeStatus.unchanged;
      this.notificationListLastViewed.push(simpleMsg);
    }
    // push this.notificationListLastViewed to local storage of navigator for this login
    this.notificationStorage.setItem(this.login, this.notificationListLastViewed);
  }

  private restoreNotificationListLastViewed(): void {
    // if local list defined, we already restored it or save it
    // it's already up to date, no need to restore it from local storage
    if (this.notificationListLastViewed === undefined) {
      // restore from local storage of navigator for this login
      this.notificationListLastViewed = this.notificationStorage.getItem(this.login);
    }
  }
}
