import { Observable, of } from 'rxjs';

import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Oauth2RessourceService } from '../oauth2/oauth2-resources.service';
import { JsonCatalog, JsonProduct } from './products/interface/context';
import { Produits } from '@bytel/pt-ihm-api-portailvente-sapi-catalogue/dist/models/components/schemas/getProductsWithFilter200Response/produits';
import { HttpClientSwaggerService } from '../oauth2/http-client-swagger.service';
import {
  GetProductsWithFilter200Response,
  SwaggerSpec,
  GetProductsWithFilterQuery,
} from '@bytel/pt-ihm-api-portailvente-sapi-catalogue';
import { HttpParams } from '@angular/common/http';

/**
 * This class represents the produts Service used to get catalog.
 */
@Injectable({
  providedIn: 'root',
})
export class ProductsService {
  private cache = new Map<string, JsonCatalog>();
  constructor(
    private oauth2Resource: Oauth2RessourceService,
    private httpService: HttpClientSwaggerService,
  ) {}

  /**
   * todo avoir un swagger pour l'appel de /categories et utiliser swagger-http-client
   * remplacer les JsonCatalog et JsonProduct par le bon typage conformément au swagger
   * @param gencodes
   */
  public getProductsByCategories(categories: string[]): Observable<JsonCatalog> {
    const cacheKey = categories.join(',');
    const data = this.cache.get(cacheKey);
    if (data) {
      return of(data);
    }
    return this.oauth2Resource
      .setLocalService(true)
      .ventes()
      .categories(categories.join(','), true)
      .useSalesApi()
      .get()
      .pipe(
        map(result => {
          const jsonCatalog: JsonCatalog = {};
          if (result.categories?.length) {
            result.categories.forEach(category => {
              category.products.forEach(jsonProduct => {
                jsonCatalog[jsonProduct.gencode] = jsonProduct;
              });
            });
          }
          this.cache.set(cacheKey, jsonCatalog);
          return jsonCatalog;
        }),
      );
  }

  public getJsonCaProductsByFilter(queryParam: GetProductsWithFilterQuery): Observable<JsonCatalog> {
    const cacheKey = this.getCacheKey(queryParam, true);
    const data = this.cache.get(this.getCacheKey(queryParam));
    if (data) {
      return of(data);
    }
    return this.oauth2Resource
      .setLocalService(true)
      .ventes()
      .produitsCA({ listGencode: queryParam.listGencode })
      .useSalesApi()
      .get()
      .pipe(
        map(result => {
          this.cache.set(cacheKey, result.produits);
          return result.produits;
        }),
      );
  }

  /**
   * todo remplacer les jsonCatalog et les jsonProduct par le vrai retour de SAPI (ProductDto)
   * Ensuite supprimer cette méthode et utiliser directement loadProductDetailsByGencode
   * @param gencodes
   */
  public getJsonProductsByFilter(queryParam: GetProductsWithFilterQuery): Observable<JsonCatalog> {
    const cacheKey = this.getCacheKey(queryParam);
    const data = this.cache.get(this.getCacheKey(queryParam));
    if (data) {
      return of(data);
    }
    return this.getProductsByFilter(queryParam).pipe(
      map((result: Produits) => {
        const jsonCatalog: JsonCatalog = {};
        Object.keys(result).forEach(key => {
          jsonCatalog[key] = result[key] as JsonProduct;
        });
        this.cache.set(cacheKey, jsonCatalog);
        return jsonCatalog;
      }),
    );
  }

  public getProductsByFilter(queryParam: GetProductsWithFilterQuery): Observable<Produits> {
    return this.httpService
      .getClient(SwaggerSpec, 'ventes')
      .call(
        'GetProductsWithFilter',
        {
          queryParam,
          pathParam: {},
        },
        {
          headers: {
            'x-version': '4',
          },
        },
      )
      .pipe(map((result: GetProductsWithFilter200Response) => result.produits));
  }

  private getCacheKey(queryParam: GetProductsWithFilterQuery, isCatalogCA?: boolean): string {
    let params = new HttpParams();
    for (const key in queryParam) {
      if (queryParam.hasOwnProperty(key)) {
        params = params.set(key, queryParam[key]);
      }
    }
    return `${params.toString()}${isCatalogCA ? '_CA' : ''}`;
  }
}
