import { CommercialProposal, FundingEnum, FundingKOReasonStatus, FundingStatus } from '../interfaces/funding.interface';
import { Model } from './model';

export class FundingCurrent extends Model {
  public id: string;
  public status: FundingStatus;
  public raison: FundingKOReasonStatus;
  public modeDeFinancement: FundingEnum;
  public commercialProposal: CommercialProposal;

  constructor(data: Partial<FundingCurrent>) {
    super(data);
  }
}
