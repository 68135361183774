import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SummaryComponent } from './summary.component';
import { SummaryResolverService } from './summay-resolver.service';
import { prismeLoggerResolver } from '../../../shared/prisme/prisme-logger.resolver';

const routes: Routes = [
  {
    path: '',
    component: SummaryComponent,
    resolve: {
      scoring: SummaryResolverService,
      prismeLoggerResolver,
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class SummaryRoutingModule {}
