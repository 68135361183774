import { Inject, Injectable, EventEmitter } from '@angular/core';
import { Observable, of } from 'rxjs';
import {
  DematStatusEnums,
  IDDocTypeEnums,
  IDematResponse,
  IIdentityData,
  IScannedDematData,
} from './justificatory.interface';
import { catchError, finalize, map, mergeMap, retryWhen, tap } from 'rxjs/operators';
import { CartService } from '../cart.service';
import { DOCUMENT } from '@angular/common';
import { genericRetryStrategy } from '../../../base/class/generic-retry-strategy';
import { ConfigService } from '../../../config.service';
import { Customer } from '../customer/customer';
import { CustomerService } from '../customer/customer.service';
import { PandoraDocument } from '../../documents/PandoraDocument.abstract';
import { GlobalLoaderService } from '../../../base/services/global-loader.service';
import { PrismeLoggerService } from '../../../shared/prisme/prisme-logger.service';
import { PrismeLogType } from '../../../shared/prisme/prisme-log-type.enum';

@Injectable({ providedIn: 'root' })
export class DematService {
  public mapScannedDataEvent: EventEmitter<Customer> = new EventEmitter<Customer>(null);
  constructor(
    public cartService: CartService,
    private configService: ConfigService,
    protected customerService: CustomerService,
    protected globalLoaderService: GlobalLoaderService,
    private prismeLogger: PrismeLoggerService,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  public launchScanApp(cartId: number): Observable<IDematResponse> {
    this.prismeLogger.sendDataToPrisme(PrismeLogType.customLog, { message: 'Debut auto-remplissage' });
    return this.exectuteDematStep('OPEN_AUTO_COMPLETE').pipe(
      catchError(() => of(this.erroDematMessage('technic', 'message'))),
      tap(res2 => {
        this.document.defaultView.window.open(res2.data?.sdkWebUrl, '_self');
      }),
    );
  }

  public resetDemat(): void {
    this.exectuteDematStep('TOGGLE_PAPER_MODE')
      .pipe(mergeMap(() => this.voidDossierData()))
      .subscribe();
  }

  public launchAutoCompleteScan(): void {
    this.globalLoaderService.dispatchLoadingStatus({ actionType: 'globalLoading', isLoading: true });
    const cartId: number = this.cartService.cart.cartId;
    this.loopUntilExectuteDematStep('INIT_AUTO_COMPLETE', cartId)
      .pipe(mergeMap(x => this.launchScanApp(cartId)))
      .subscribe(() =>
        this.globalLoaderService.dispatchLoadingStatus({ actionType: 'globalLoading', isLoading: false }),
      );
  }

  private exectuteDematStep(action: string): Observable<IDematResponse> {
    return this.cartService
      .demat(action, true)
      .pipe(catchError(err => of(this.erroDematMessage('technic', 'message'))));
  }

  public getScannedData(): Observable<void> {
    this.globalLoaderService.dispatchLoadingStatus({ actionType: 'globalLoading', isLoading: true });
    return this.exectuteDematStep('GET_SCANNED_DATA').pipe(
      map(res => this.mappingScannedDataToCustomer(res.data.scannedData)),
      tap(() =>
        this.prismeLogger.sendDataToPrisme(PrismeLogType.customLog, {
          message: 'Fin récuperation des données auto-remplissage',
        }),
      ),
      finalize(() => this.globalLoaderService.dispatchLoadingStatus({ actionType: 'globalLoading', isLoading: false })),
    );
  }

  private mappingScannedDataToCustomer(scannedData: IScannedDematData): void {
    const customer = this.customerService.customer;
    let identityData: IIdentityData;
    let addressData: IIdentityData;
    let financeData: IIdentityData;

    if (!scannedData) {
      return;
    }

    if (scannedData.customerIdentities?.length && scannedData.customerIdentities[0]) {
      identityData = scannedData.customerIdentities[0].identityData;
      addressData = scannedData.customerIdentities[0].addressData;
      financeData = scannedData.customerIdentities[0].financeData;
    }

    customer.firstname = identityData?.firstNames?.values[0] || customer.firstname;
    customer.lastname = identityData?.lastName?.value || customer.lastname;
    customer.birthplace = identityData?.birthDepartment?.value || customer.birthplace;
    if (identityData?.gender?.value === 'F') {
      customer.civility = 'Mme';
    } else {
      customer.civility = 'M';
    }

    customer.dob = identityData?.birthDate?.value || customer.dob;
    this.setPaymentData(customer, financeData);
    customer.identityDocumentType = customer.identityDocumentType || new PandoraDocument();
    customer.identityDocumentType.name =
      this.mappingDocumentTypeToIdentity(scannedData?.info?.documentType) || customer.identityDocumentType.name;
    customer.identityDocumentNumber = scannedData?.info?.documentNumber || customer.identityDocumentNumber;
    this.setadressData(customer, addressData);
  }

  private setPaymentData(customer: Customer, financeData: IIdentityData): void {
    customer.paymentAccount = customer.paymentAccount || { iban: '' };
    customer.paymentAccount.iban = financeData?.iban?.value || customer.paymentAccount.iban;
  }

  public setadressData(customer: Customer, addressData: IIdentityData): void {
    const first = addressData?.fullAddress?.values[0]?.split(' ') || [];
    const second = addressData?.fullAddress?.values[1]?.split(' ') || [];
    customer.streetNumber = first[0] || customer.streetNumber;
    customer.street =
      addressData?.fullAddress?.values[0]?.substring(addressData.fullAddress.values[0].indexOf(' ') + 1) ||
      customer.street;
    customer.city =
      addressData?.fullAddress?.values[1]?.substring(addressData.fullAddress.values[1].indexOf(' ') + 1) ||
      customer.city;
    customer.postcode = second[0] || customer.postcode;
  }

  private mappingDocumentTypeToIdentity(documentType: IDDocTypeEnums): string {
    switch (documentType) {
      case IDDocTypeEnums.ID:
        return "Carte d'identité";
      case IDDocTypeEnums.PASSPORT:
        return 'Passeport';
      case IDDocTypeEnums.DRIVING_LICENSE:
        return 'Permis de conduire';
      case IDDocTypeEnums.RESIDENCE_PERMIT:
        return 'Carte de séjour';
    }
  }

  private loopUntilExectuteDematStep(action: string, cartId: number): Observable<IDematResponse> {
    return of(null)
      .pipe(mergeMap(() => this.exectuteDematStep(action)))
      .pipe(
        tap((response: IDematResponse) => {
          if (
            response.status !== DematStatusEnums.scanDesPieces &&
            response.status !== DematStatusEnums.scanTermine &&
            response.status !== DematStatusEnums.erreur
          ) {
            throw new Error('Traitement en cours, veuillez patienter');
          }
        }),
        map((response: IDematResponse) => response),
        retryWhen(
          genericRetryStrategy({
            durationBeforeRetry: this.configService.data.demat.interval,
            maxRetryAttempts: this.configService.data.demat.attempts,
            excludedStatusCodes: [1],
          }),
        ),
        catchError(() => of(this.erroDematMessage('technic', 'message'))),
      );
  }

  private erroDematMessage(type: string, message: string): IDematResponse {
    return {
      status: DematStatusEnums.erreur,
      data: { errorDetail: { type: type, blocking: true, errorMessages: [message] } },
    };
  }

  private voidDossierData(): Observable<boolean> {
    this.cartService.cart.dematId = null;
    this.cartService.cart.encryptionKey = null;
    this.cartService.cart.contractSigned = false;
    this.cartService.cart.tokenFolder = null;
    this.cartService.cart.contractPrinted = false;
    return this.cartService.updateCart();
  }
}
