import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[amountinput]',
})
export class AmountInputDirective {
  @Output() amountChange = new EventEmitter<number>();
  @Input() integerOnly = false;
  @Input() splitThousands = false;
  @Input() customRegex: RegExp;
  @HostListener('input', ['$event']) public onInput(event): void {
    const initValue = event.target.value as string;
    let value: string = event.target.value.replace(/ /g, '');
    let newCursorPos: number =
      event.target.selectionStart - initValue.substring(0, event.target.selectionStart - 1).replace(/[^ ]/g, '').length;
    const integerOnly = /^[0-9]+$/; // NOSONAR
    const withComma = /^[0-9]+([\.,]{0,1}[0-9]{0,2})$/; // NOSONAR
    const regularRegex: RegExp = this.integerOnly ? integerOnly : withComma;
    const regex: RegExp = this.customRegex ? this.customRegex : regularRegex;
    if (value.match(regex)) {
      value = value.replace(',', '.');
      if (value.match(/^0+[1-9]/)) {
        const nbZero = value.search(/[1-9]/);
        value = value.substring(nbZero);
        newCursorPos -= nbZero;
      }
    } else if ((value[0] === '.' || value[0] === ',') && !this.integerOnly) {
      value = value.replace(/^[.,]/, '0.');
      newCursorPos++;
    } else {
      const nbSpaceBefore = initValue.substring(0, event.target.selectionStart - 1).replace(/[^ ]/g, '').length;
      const nbSpaceBetween = initValue
        .substring(event.target.selectionStart, event.target.selectionEnd - 1)
        .replace(/[^ ]/g, '').length;
      value = value
        .substring(0, event.target.selectionStart - 1 - nbSpaceBefore)
        .concat(value.substring(event.target.selectionEnd - nbSpaceBefore - nbSpaceBetween));
    }
    this.amountChange.emit(Number(value));
    if (this.splitThousands) {
      const newValue = value.replace(/\B(?=(\d{3})+(?!\d))/g, ' '); // NOSONAR
      if (value !== newValue) {
        const ref = newCursorPos;
        while (newValue.substring(0, newCursorPos - 1).replace(/ /g, '').length < ref) {
          newCursorPos++;
        }
        value = newValue;
      }
    }
    event.target.value = value;
    event.target.setSelectionRange(newCursorPos, newCursorPos);
  }
}
