import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Step } from '../../stepper/step.abstract';
import { EligibilityStepService } from './steps/eligibility-step.service';
import { TechsStepService } from './steps/techs-step.service';
import { FaiStorageService } from '../fai-storage.service';
import { StepperService } from '../../stepper/stepper.service';
import { Location } from '@angular/common';
import { FaiEligService } from '../fai-elig.service';
import { PtoStepService } from './steps/pto-step.service';
import { HousingStepService } from './steps/housing-step.service';
import { BrowseConfigService } from '../../context/browse-config.service';

@Injectable({
  providedIn: 'root',
})
export class FaiStepperService extends StepperService {
  public steps: Step[] = [];
  public back = new EventEmitter<void>();

  constructor(
    protected stepperStorageService: FaiStorageService,
    protected router: Router,
    protected eligibilityStepService: EligibilityStepService,
    protected techsStepService: TechsStepService,
    protected ptoStepService: PtoStepService,
    protected housingStepService: HousingStepService,
    protected location: Location,
    private faiEligService: FaiEligService,
    private browseConfigService: BrowseConfigService,
  ) {
    super(stepperStorageService, router, location);
    this.ptoStepService.isAllowed = false;
    this.housingStepService.isAllowed = false;
    if (this.browseConfigService.browseConfig.browseActionType === 'ELIG') {
      this.faiEligService.isStandalone = true;
      this.faiEligService.serialize();
    }
    this.steps = [this.eligibilityStepService, this.ptoStepService, this.housingStepService, this.techsStepService];
    for (const step of this.steps) {
      this.stepsOrder.push(step.code);
    }
    this.unserialize();
    for (const step of this.steps) {
      if (this.router.isActive(step.path, true)) {
        step.isActive = true;
      } else {
        step.isActive = false;
      }
    }
  }

  public goToStep(step: Step): void {
    super.goToStep(step);
  }

  public enablePtoStep(): void {
    this.ptoStepService.isAllowed = true;
    this.housingStepService.isAllowed = false;
  }

  public enableHousingStep(): void {
    this.housingStepService.isAllowed = true;
    this.ptoStepService.isAllowed = false;
  }

  protected changeStep(targetStep: Step, currentStepCode: string, params): void {
    if (targetStep instanceof PtoStepService || targetStep instanceof HousingStepService) {
      this.location.go(targetStep.path);
      return;
    }
    if (targetStep instanceof EligibilityStepService) {
      if (currentStepCode === 'techs') {
        this.router.navigate([targetStep.path], { queryParams: params });
      } else {
        this.back.emit();
        this.location.go(targetStep.path);
      }
      return;
    }
    this.router.navigate([targetStep.path], { queryParams: params });
  }
}
