import { Component, OnInit } from '@angular/core';
import { Resultat } from '@bytel/pt-ihm-api-egide-controle-risque-vente-demander-autorisation-vente';
import { ParLigneMarcheMetierParCategorie as FinancementsParLigneMarcheMetierParCategorie } from '@bytel/pt-ihm-api-egide-controle-risque-vente-demander-financements-autorises/dist/models/components/schemas/ParLigneMarcheMetierParCategorie';
import { ScoringService } from '../../../../../../scoring/scoring.service';
import { ResultatEnum } from '../../../../../../scoring/interfaces';
import { StringTabObject } from '../../../../../../base/base.interfaces';

@Component({
  selector: 'rcbt-scoring-partial',
  templateUrl: './scoring-partial.component.html',
  styleUrls: ['./scoring-partial.component.scss'],
})
export class ScoringPartialComponent implements OnInit {
  public messages: StringTabObject = { fai: [], mobile: [] };
  public showScoring = false;

  constructor(private scoringService: ScoringService) {}

  public ngOnInit(): void {
    if (this.scoringService.scoringResult) {
      this.showScoring = true;
      this.initScoringMessages(this.scoringService.scoringResult.resultats);
    }
  }

  protected initScoringMessages(results: Resultat[]): void {
    results = results.filter(
      result =>
        result.type === 'ResultatGlobal' &&
        result.champApplication.type === 'ParLigneMarcheMetierParCategorie' &&
        [ResultatEnum.KO, ResultatEnum.KOSAUFACTIONREALISEE].includes(result.resultat as ResultatEnum),
    );
    results.forEach(result => {
      if ((result.champApplication as FinancementsParLigneMarcheMetierParCategorie).ligneMarcheMetier === 'FAI') {
        this.messages.fai.push(result.message);
      } else if (
        (result.champApplication as FinancementsParLigneMarcheMetierParCategorie).ligneMarcheMetier === 'FNB'
      ) {
        this.messages.mobile.push(result.message);
      }
    });
  }
}
