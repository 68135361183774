<div class="is-tri services-page" [ngClass]="{'margin': showHeader}" *ngIf="!loadServicesError">
  <div id="gencode_{{service.code}}" class="card" *ngFor="let service of servicesList" [ngClass]="{'added': service.state === serviceStates.added}">
    <div class="card-content">
      <div class="service-name">
        <label>{{service.name}}</label>
      </div>
      <div class="service-price">
        <rcbt-price [price]="service.price" [fontLevel]="5"></rcbt-price>
      </div>
      <div class="service-button">
        <button *ngIf="service.state === serviceStates.available" class="button is-info" [attr.data-cy]="'srv_add_'+service.code"
          (click)="addService(service.code)">
          <span class="icon is-small" aria-label="Ajout au panier">
            <i class="tri-shopping-cart" aria-hidden="true"></i>
          </span>
        </button>
        <button *ngIf="service.state === serviceStates.added" class="button is-warning" [attr.data-cy]="'srv_remove_'+service.code"
          (click)="removeService(service.code)">
          <span class="icon is-small" aria-label="Suppression du panier">
            <i class="tri-trash" aria-hidden="true"></i>
          </span>
        </button>
        <button *ngIf="service.state === serviceStates.incompatible" class="button is-info" [attr.data-cy]="'srv_add_'+service.code" [disabled]="true">
          <span class="icon is-small" aria-label="Ajout au panier">
            <i class="tri-shopping-cart" aria-hidden="true"></i>
          </span>
        </button>
      </div>
    </div>
  </div>
</div>
<div class="is-tri row reload-button is-justified-center p-20" *ngIf="loadServicesError">
  <button class="button is-info" (click)="loadServices()" [disabled]="globalLoaderService.isAppOnLoadingStatus$ | async">
    <span class="icon is-small"><i class="tri-exclamation-redo" aria-hidden="true"></i></span> Recharger
  </button>
</div>

