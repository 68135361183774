import { DOCUMENT } from '@angular/common';
import { APP_INITIALIZER, FactoryProvider } from '@angular/core';
import { HttpBackend } from '@angular/common/http';

import { appInitializerFactory } from './app-initializer.factory';

export const APP_INITIALIZER_PROVIDER: FactoryProvider = {
  provide: APP_INITIALIZER,
  useFactory: appInitializerFactory,
  deps: [HttpBackend, DOCUMENT],
  multi: true,
};
