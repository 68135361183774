import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, of } from 'rxjs';
import { catchError, finalize, mergeMap, tap } from 'rxjs/operators';
import { GlobalLoaderService } from '../../../../../../base/services/global-loader.service';
import { ToolsService } from '../../../../../../base/services/tools.service';
import { Phone } from '../../../../../../catalog/products/equipement/complex/phone';
import { Product } from '../../../../../../catalog/products/product';
import { ConfigService } from '../../../../../../config.service';
import { FundingService } from '../../../../../../fundings/services/funding.service';
import { PromoActionTypes, PromoParams, PromotionTypes } from '../../../../../../promotions/promotions.interfaces';
import { PromotionsService } from '../../../../../../promotions/promotionsService';
import { CartService } from '../../../../cart.service';
import { Scheme } from '../../../../scheme.class';

const LOADING_ACTIONS = {
  processRabais: '[ModalDiscountCaComponent] add rabais',
};
@Component({
  selector: 'rcbt-modal-discount-ca',
  templateUrl: './modal-discount-ca.component.html',
  styleUrls: ['./modal-discount-ca.component.scss'],
})
export class ModalDiscountCaComponent implements OnInit {
  public product: Product;
  public scheme: Scheme;
  public oldPrice: number;
  public price: number;
  public discountAmountCA = 0;
  public percentDiscountCA: number;
  public error: string;
  public isOneOffprice: boolean;
  public regularizationPromoId: number;
  public loading = false;
  public type: PromoActionTypes;

  constructor(
    private activeModal: NgbActiveModal,
    private promoService: PromotionsService,
    private cartService: CartService,
    private configService: ConfigService,
    private fundingService: FundingService,
    private globalLoaderService: GlobalLoaderService,
  ) {}

  public ngOnInit(): void {
    this.error = '';
    this.oldPrice = this.product.oldPrice;
    this.price = this.product.price;
    this.isOneOffprice = this.product instanceof Phone && this.scheme.hasEdp;
    this.regularizationPromoId = +this.configService.data.promotions.rabaisProduitId;
  }

  public close(): void {
    this.activeModal.close();
  }

  public onDiscountAmountChanged(val: number, byPercent: boolean): void {
    if (byPercent) {
      this.discountAmountCA = ToolsService.roundDigits(this.price * (val / 100), 2);
      this.percentDiscountCA = val;
      this.type = PromoActionTypes.byPercent;
    } else {
      this.percentDiscountCA = ToolsService.roundDigits((val / this.price) * 100, 2);
      this.discountAmountCA = val;
      this.type = PromoActionTypes.byFixed;
    }
    this.updateProductFrontPrice();
  }

  public onSubmit(event: Event): void {
    if (event && event.preventDefault) {
      this.globalLoaderService.dispatchLoadingStatus({ actionType: LOADING_ACTIONS.processRabais, isLoading: true });
      event.preventDefault();
      this.fundingService.launchWarningModalWithCallBackObservable(this.cartService, this.applyDiscountCA.bind(this));
    }
  }

  private applyDiscountCA(): Observable<void> {
    this.error = '';
    if (!this.canApplyDiscount()) {
      this.error = this.isOneOffprice
        ? 'Ce rabais ne peut etre appliqué : EDP, le prix doit etre supérieur a 1€'
        : 'Ce rabais ne peut etre appliqué, le prix doit etre positif';
      return of(null);
    }
    this.loading = true;
    return this.applyDiscountToProduct().pipe(
      mergeMap(() => this.cartService.refreshCart()),
      catchError(() => {
        this.error = "Une erreur s'est produite, impossible d'appliquer le rabais";
        this.globalLoaderService.dispatchLoadingStatus({
          actionType: LOADING_ACTIONS.processRabais,
          isLoading: false,
        });
        return of(null);
      }),
      tap(() => this.close()),
      finalize(() => {
        this.loading = false;
        this.globalLoaderService.dispatchLoadingStatus({
          actionType: LOADING_ACTIONS.processRabais,
          isLoading: false,
        });
      }),
    );
  }

  private updateProductFrontPrice(): void {
    this.product.price = this.product.oldPrice - this.discountAmountCA;
  }

  private canApplyDiscount(): boolean {
    const minPrice: number = this.isOneOffprice ? Phone.minPriceOneOff : 0;
    return this.product.oldPrice - this.discountAmountCA >= minPrice;
  }

  private applyDiscountToProduct(): Observable<void> {
    const params: PromoParams = {
      amount: this.discountAmountCA,
      gencode: this.product.gencode,
      type: PromotionTypes.regularizationCa,
    };
    return this.promoService.addPromoById(this.regularizationPromoId, this.cartService.cart.cartId, params);
  }
}
