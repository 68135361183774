import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgilityComponent } from './agility.component';
import { BaseModule } from '../../base/base.module';

@NgModule({
  imports: [CommonModule, BaseModule],
  declarations: [AgilityComponent],
  exports: [AgilityComponent],
})
export class AgilityModule {}
