import { ILogin, IMigration, IMsisdn, IPlanConfiguration, IRio, TypesPortability } from './IPlanConfiguration';
import { webShopEligibility } from '../../../fai-widget/fai-webshop.interface';
import { ProductSerialized } from '../interface/configurable';
import { Subscription } from '../subscription';

export enum PlanComponentEnum {
  newPhoneNumber = 'NEW_PHONE_NUMBER',
  portabilityPhoneNumber = 'PORTABILITY_PHONE_NUMBER',
  newLogin = 'NEW_LOGIN',
}

export class Plan extends Subscription {
  public price: number;
  public msisdns: IMsisdn[];
  public logins: ILogin[];
  public rio: IRio;
  public ptoRef: string;
  public equipedPto: boolean;
  public portabilityType: TypesPortability;
  public isVoipReserved: boolean;
  public isLoginReserved: boolean;
  public shippingMethode?: string;
  public eligTech: webShopEligibility.EligeTech;
  public eligTechTokyo: webShopEligibility.EligTokyo;
  public structureVerticale: webShopEligibility.StructureVerticale;
  public libelleSI: string;
  public typePortabilite: string;
  public migrationFluide: IMigration;
  public bankOffer: boolean;

  public setConfiguration(data: IPlanConfiguration): void {
    super.setConfiguration(data);

    this.initRioMsisdns(data);

    if (this.rio !== undefined) {
      this.typePortabilite = data.typePortabilite || this.typePortabilite;
      this.migrationFluide = data.migrationFluide || this.migrationFluide;
      if (data.typePortabilite && data.migrationFluide === undefined) {
        // car on peut vouloir supprimer la migration fluide. Ce qui veut dire qu'on a set forcement le typePortabilite
        // et qu'on a unset migrationFluide
        this.migrationFluide = undefined;
      }
    }
    if (data.secondOffersIds) {
      this.secondOffersIds = data.secondOffersIds;
    }
    if (data.banking_offer) {
      this.bankOffer = data.banking_offer;
    }
  }

  public getConfiguration(): IPlanConfiguration {
    return <IPlanConfiguration>{
      msisdns: this.msisdns,
      rio: this.rio,
      logins: this.logins,
      isLoginReserved: this.isLoginReserved,
      isVoipReserved: this.isVoipReserved,
      eligTech: this.eligTech,
      eligTechTokyo: this.eligTechTokyo,
      structureVerticale: this.structureVerticale,
      ptoRef: this.ptoRef,
      equipedPto: this.equipedPto,
      typePortabilite: this.typePortabilite,
      migrationFluide: this.migrationFluide,
      secondOffersIds: this.secondOffersIds,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      banking_offer: this.bankOffer,
      ...super.getConfiguration(),
    };
  }

  public serialize(): ProductSerialized {
    return Object.assign(super.serialize(), this.getConfiguration());
  }

  public setMigration(typePortabilite: string, migration?: IMigration): void {
    this.migrationFluide = migration;
    this.typePortabilite = typePortabilite;
  }

  protected applyAml(): boolean {
    this.price = this.price - this.price / 10;
    return true;
  }

  private initRioMsisdns(data: IPlanConfiguration): void {
    // reset data.rio if no codeRio or phoneNumber
    if (!data.rio?.codeRio && !data.rio?.phoneNumber) {
      data.rio = undefined;
    }
    if (data.rio !== undefined && data.msisdns === undefined) {
      this.msisdns = undefined;
    }
    this.msisdns = data.msisdns || this.msisdns;

    if (data.msisdns !== undefined && data.rio === undefined) {
      this.rio = undefined;
    }
    this.rio = data.rio || this.rio;
    if (this.rio?.codeRio) {
      this.rio.codeRio = this.rio.codeRio.toUpperCase();
    }
  }
}
