<ng-container *ngIf="product.type_id === 'produit_ca' && canApplyDiscountCA;then discountCA"></ng-container>
<ng-container *ngIf="product.type_id !== 'produit_ca' && canApplyRegularization;then regularization"></ng-container>
<ng-template #discountCA>
    <button class="button is-success is-regul" *ngIf="regularizationService.inModeRegularisation"
            [ngClass]="{'action-feedback' : loading}" (click)="showProductRegularization()" [disabled]="loading">
        Rabais
    </button>
</ng-template>
<ng-template #regularization>
    <button class="button is-success is-regul" *ngIf="regularizationService.inModeRegularisation"
            [ngClass]="{'action-feedback' : loading}" (click)="showProductRegularization()" [disabled]="loading">
        Régularisation
    </button>
</ng-template>
