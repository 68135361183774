import { Scheme } from './scheme.class';
import { Plan } from '../../catalog/products/subscription/plan';
import { Fai } from '../../catalog/products/subscription/plan/fai/fai';
import { Faim } from '../../catalog/products/subscription/plan/fai/faim';
import { Sensation } from '../../catalog/products/subscription/plan/premium/sensation';
import { Ideo } from '../../catalog/products/subscription/plan/premium/ideo';
import { Simo } from '../../catalog/products/subscription/plan/simo';
import { Sim } from '../../catalog/products/equipement/sim';
import { Box } from '../../catalog/products/equipement/complex/phone/box';
import { Crv } from '../../catalog/products/crv';
import { Service } from '../../catalog/products/service';
import { FaimSensation } from '../../catalog/products/subscription/plan/premium/faim_sensation';
import { Phone } from '../../catalog/products/equipement/complex/phone';
import { Sowo } from '../../catalog/products/subscription/plan/sowo';
import { AccessOnlineOrder } from '../../catalog/products/equipement/accessory/accessOnlineOrder';
import { Accessory } from '../../catalog/products/equipement/accessory';
import { DataSim } from '../../catalog/products/subscription/plan/dataSim';
import { FaimUnlimited } from '../../catalog/products/subscription/plan/fai/faim-unlimited';
import { Prepaid } from '../../catalog/products/subscription/plan/prepaid';
import { Subscription } from '../../catalog/products/subscription';
import { SimSav } from '../../catalog/products/equipement/sim/sim-sav';

export class SchemeHelper {
  public static isPremium(scheme: Scheme): boolean {
    return this.hasPlanPremium(scheme) && (this.hasPhone(scheme) || this.hasBox(scheme));
  }

  public static isSowo(scheme: Scheme): boolean {
    return (
      (this.hasPlanSowo(scheme) || this.hasPlanPremium(scheme) || this.hasPlanSimo(scheme)) && !this.hasPhone(scheme)
    );
  }

  public static isSimOnly(scheme): boolean {
    return this.hasSim(scheme) && scheme.products.length === 1;
  }

  public static isFaim(scheme: Scheme): boolean {
    return this.hasFaim(scheme) || this.hasFaimPremium(scheme);
  }

  public static isFai(scheme): boolean {
    return this.hasPlanFai(scheme);
  }

  public static hasPlan(scheme: Scheme): boolean {
    return scheme.getProductsByType(Plan).length > 0;
  }

  public static hasPlanFai(scheme: Scheme): boolean {
    return scheme.getProductsByType(Fai).length > 0;
  }

  public static hasFaim(scheme: Scheme): boolean {
    return scheme.getProductsByType(Faim).length > 0;
  }

  public static hasPlanPremium(scheme: Scheme): boolean {
    return scheme.getProductsByType(Sensation).length > 0 || scheme.getProductsByType(Ideo).length > 0;
  }

  public static hasPlanSimo(scheme: Scheme): boolean {
    return scheme.getProductsByType(Simo).length > 0;
  }

  public static hasSim(scheme: Scheme): boolean {
    return scheme.getProductsByType(Sim).length > 0;
  }

  public static hasSimSav(scheme: Scheme): boolean {
    return scheme.getProductsByType(SimSav).length > 0;
  }

  public static hasBox(scheme: Scheme): boolean {
    return scheme.getProductsByType(Box).length > 0;
  }

  public static hasCrv(scheme: Scheme): boolean {
    return scheme.getProductsByType(Crv).length > 0;
  }

  public static hasService(scheme: Scheme): boolean {
    return scheme.getProductsByType(Service).length > 0;
  }

  public static hasFaimPremium(scheme: Scheme): boolean {
    return scheme.getProductsByType(FaimSensation).length > 0;
  }
  public static hasPremium(scheme: Scheme): boolean {
    return scheme.getProductsByType(Sensation).length > 0;
  }

  public static hasPhone(scheme: Scheme): boolean {
    return scheme.getProductsByType(Phone).length > 0;
  }

  public static hasPlanSowo(scheme: Scheme): boolean {
    return scheme.getProductsByType(Sowo).length > 0;
  }

  public static hasAccessOnline(scheme: Scheme): boolean {
    return scheme.getProductsByType(AccessOnlineOrder).length > 0;
  }

  public static hasAccessory(scheme: Scheme): boolean {
    return scheme.getProductsByType(Accessory).length > 0;
  }

  public static hasPlanData(scheme: Scheme): boolean {
    return scheme.getProductsByType(DataSim).length > 0;
  }

  public static hasSimo(scheme: Scheme): boolean {
    return scheme.getProductsByType(Simo).length > 0;
  }

  public static hasFaimUnlimited(scheme: Scheme): boolean {
    return scheme.getProductsByType(FaimUnlimited).length > 0;
  }

  public static isFaimUnlimited(scheme: Scheme): boolean {
    return this.hasFaimUnlimited(scheme);
  }

  public static isFaimPremium(scheme: Scheme): boolean {
    return this.hasFaimPremium(scheme);
  }

  public static isSimo(scheme: Scheme): boolean {
    return this.hasSimo(scheme);
  }

  public static isSimData(scheme: Scheme): boolean {
    return this.hasFaim(scheme);
  }

  public static isPrepaid(scheme: Scheme): boolean {
    return scheme.getProductsByType(Prepaid).length > 0;
  }

  public static hasSubscription(scheme: Scheme): boolean {
    return scheme.getProductsByType(Subscription).length > 0;
  }

  public static isEligibleEdp(scheme: Scheme, isEligible?: boolean): boolean {
    if (scheme.totals && scheme.totals.today < 1) {
      return false;
    }

    if (!this.hasPlanPremium(scheme) && this.hasPhone(scheme)) {
      return isEligible;
    }

    if (this.hasPlanPremium(scheme) && this.hasPhone(scheme)) {
      if (scheme.isRenew()) {
        return isEligible;
      }

      if (scheme.isAcquisitionMobile()) {
        return true;
      }
    }
    return false;
  }

  public static isFnb(scheme: Scheme): boolean {
    return (
      scheme.isAcquisitionMobile() &&
      (this.hasPlanSowo(scheme) || this.hasPremium(scheme) || this.hasFaim(scheme) || this.hasFaimPremium(scheme))
    );
  }
}
