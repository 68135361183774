import { Component, OnInit, Input, OnDestroy, OnChanges } from '@angular/core';
import { Product } from '../../../../../catalog/products/product';
import { CartService } from '../../../cart.service';
import {
  PromotionalEligibilityPromotionExtV2Dto,
  PromotionalEligibilityPromotionsDto,
} from '../../../dto/promotional-eligibility-output.dto';
import { MarketingTypes, PromotionTypes } from '../../../../../promotions/promotions.interfaces';
import { Subscription } from 'rxjs';
import { OffresSecondaires } from '../../../../../context/child/renew-promo-context-serialized.interface';

interface IPromoToDisplay {
  name: string;
  price?: string;
  strAppliedDate?: string;
  strTimeLength?: string;
}

@Component({
  selector: 'rcbt-plan-promotions',
  templateUrl: './plan-promotions.component.html',
  styleUrls: ['./plan-promotions.component.scss'],
})
export class PlanPromotionsComponent implements OnInit, OnDestroy, OnChanges {
  @Input()
  public product: Product;

  public osSecond: OffresSecondaires[] = [];
  public appliedPromotions: PromotionalEligibilityPromotionExtV2Dto[] = [];
  public isRenew: boolean;
  public listener: Subscription = new Subscription();

  public promosToDisplay: IPromoToDisplay[];

  constructor(private cartService: CartService) {}

  public ngOnInit(): void {
    this.isRenew = this.cartService.getCurrentScheme().isRenew();
    this.initPromotionOnPlan();
    this.setPromosToDisplay();
  }

  public ngOnChanges(): void {
    this.initPromotionOnPlan();
    this.setPromosToDisplay();
  }

  public ngOnDestroy(): void {
    this.listener.unsubscribe();
  }

  private setPromotions(): void {
    this.appliedPromotions = [];
    const productPromos: PromotionalEligibilityPromotionsDto = this.cartService.getProductPromotions(this.product);

    if (productPromos?.automatiques.length && !this.isRenew) {
      productPromos.automatiques.forEach((promo: PromotionalEligibilityPromotionExtV2Dto) => {
        if (this.promoIsNotOdrAndRegularization(promo)) {
          this.appliedPromotions.push(promo);
        }
      });
    }
    if (productPromos?.incitations.length && !this.isRenew) {
      productPromos.incitations.forEach((promo: PromotionalEligibilityPromotionExtV2Dto) => {
        if (this.canAddIncitations(promo)) {
          this.appliedPromotions.push(promo);
        }
      });
    }
  }

  private promoIsNotOdrAndRegularization(promo: PromotionalEligibilityPromotionExtV2Dto): boolean {
    return promo.type !== PromotionTypes.regularization && !promo.odr;
  }

  private canAddIncitations(promo: PromotionalEligibilityPromotionExtV2Dto): boolean {
    return (
      !this.appliedPromotions.some(
        (applyedPromotion: PromotionalEligibilityPromotionExtV2Dto) => applyedPromotion.id === promo.id,
      ) && promo.proprietesSupplementaires?.typesMarketing?.includes(MarketingTypes.portabilite)
    );
  }

  private setRenewPromotions(): void {
    if (this.isRenew && this.product.renewPromotion) {
      this.osSecond = this.product.renewPromotion?.offresSecondairesCibles;
    }
  }

  private initPromotionOnPlan(): void {
    if (this.isRenew) {
      this.setRenewPromotions();
    } else {
      this.setPromotions();
    }
  }

  private setPromosToDisplay(): void {
    this.promosToDisplay = [];

    this.osSecond.forEach(os => {
      const promo: IPromoToDisplay = {
        name: os.libelleCommercial,
        price: os.tarifTTC.toString(),
      };
      this.promosToDisplay.push(promo);
    });

    if (this.product.opSource && this.product.opSource.osDetenues) {
      this.product.opSource.osDetenues.forEach(os => {
        const promo: IPromoToDisplay = {
          name: os.libelleCommercial,
          price: os.tarifTTC.toString(),
        };
        if (os.dateEffet) {
          promo.strAppliedDate = "jusqu'au " + os.dateEffet;
        }
        this.promosToDisplay.push(promo);
      });
    }

    this.appliedPromotions.forEach(appliedPromo => {
      const promo: IPromoToDisplay = {
        name: appliedPromo.nom,
        price: '-' + appliedPromo.valeurRemiseReelle,
      };
      if (appliedPromo.duree) {
        promo.strTimeLength = 'pendant ' + appliedPromo.duree + ' mois';
      }
      this.promosToDisplay.push(promo);
    });
  }
}
