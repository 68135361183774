import { Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { TakebackModalComponent } from './takeback-modal/takeback-modal.component';
import { Oauth2RessourceService } from '../../oauth2/oauth2-resources.service';
import { CartService } from '../cart/cart.service';
import { IEquipmentsMobilesResponse, IProduitsPartenaireResponse } from './takeback.interface';

@Injectable()
export class TakebackService {
  private contractId: number;
  public takebackPriceEstimation: number;
  public takebackNameMobile: string;

  constructor(
    private cartService: CartService,
    private oauth2RessourceService: Oauth2RessourceService,
    private modalService: NgbModal,
  ) {}

  public openModal(): void {
    const options: NgbModalOptions = <NgbModalOptions>{
      backdrop: 'static',
      size: 'lg',
      windowClass: 'takeback-modal',
      keyboard: false,
    };
    this.modalService.open(TakebackModalComponent, options);
  }

  public setTakebackPriceEstimation(): void {
    // Retour :
    //     undefined => pas d'estimation (ex: prospect)
    //     0 => erreur ou estimation max à 0
    //     n => estimation OK avec prix >0

    const contractId = this.cartService.getCurrentScheme()?.contractId;

    if (!contractId) {
      this.setTakebackData();
      return;
    }

    if (this.contractId === contractId && this.takebackNameMobile) {
      return;
    }
    this.contractId = contractId;

    this.oauth2RessourceService
      .contracts(contractId)
      .equipementsMobiles()
      .get()
      .subscribe(
        (equipmentData: IEquipmentsMobilesResponse) => {
          const imei = this.getImeiFromEquipData(equipmentData);
          this.getEstimationPartenaire(imei);
        },
        err => {
          this.setTakebackData(0);
        },
      );
  }

  private getEstimationPartenaire(imei: string): void {
    if (!imei) {
      this.setTakebackData();
      return;
    }
    this.oauth2RessourceService
      .produitsPartenaires(imei)
      .get()
      .subscribe(
        (data: IProduitsPartenaireResponse) => {
          if (data.items.length === 0) {
            this.setTakebackData(0);
            return;
          }
          const minAmount = Math.min(...data.items.map(mobile => mobile.montantMax));
          const mobileData = data.items.find(mobile => mobile.montantMax === minAmount);
          this.setTakebackData(minAmount, mobileData.marque + ' ' + mobileData.nom);
        },
        err => {
          this.setTakebackData(0);
        },
      );
  }

  private getImeiFromEquipData(equipmentData: IEquipmentsMobilesResponse): string {
    if (equipmentData?.mobileUsage?.imei) {
      return equipmentData.mobileUsage.imei;
    }
    if (equipmentData?.mobilesContractuels) {
      return equipmentData.mobilesContractuels[0].imei;
    }
    return undefined;
  }

  private setTakebackData(amount?: number, name?: string): void {
    this.takebackPriceEstimation = amount;
    this.takebackNameMobile = name;
  }
}
