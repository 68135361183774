import * as moment from 'moment/moment';

import { Component, Input, OnInit } from '@angular/core';
import { Scheme } from '../../checkout/cart/scheme.class';
import { CartService } from '../../checkout/cart/cart.service';
import { Equipement } from '../../catalog/products/equipement';
import { ConfigService } from '../../config.service';
import * as SHA256 from 'crypto-js/sha256';
import { InsuranceConfig, InsurancePartnerCodeEnum } from '../partner.dto';
import { CheckoutStepperService } from '../../checkout/checkout-stepper.service';
import { BrowseType } from '../../checkout/cart/browse-type';
import { CustomerService } from '../../checkout/cart/customer/customer.service';
import { UserService } from '../../user/user.service';

interface InputValueKey {
  value: string | number;
  key: string;
}

@Component({
  selector: 'rcbt-partner-spb',
  templateUrl: './spb.component.html',
  styleUrls: ['./spb.component.scss'],
})
export class SpbComponent implements OnInit {
  @Input() public ibanBic: { iban: string; bic: string };
  @Input() public scheme: Scheme;
  @Input() public gencode: string;
  @Input() public imei: string;
  public inputs: InputValueKey[] = [];
  public url = '';
  public insuranceConfig: InsuranceConfig;

  constructor(
    private cartService: CartService,
    private configService: ConfigService,
    private stepperService: CheckoutStepperService,
    private customerService: CustomerService,
    private userService: UserService,
  ) {
    this.url = '/assets/post.html';
  }

  public ngOnInit(): void {
    this.insuranceConfig = this.getConcernedSpbInsurance();
    const customer = this.customerService.customer;
    const scheme = this.cartService.getCurrentScheme();
    const user = this.userService.user;
    const equipement: Equipement = this.scheme.getProductByType<Equipement>(Equipement);

    /** -------- **/
    /**   PROB   **/
    let prob: string;
    switch (scheme.browseType) {
      case BrowseType.renew:
        prob = 'RENOUV';
        break;
      case BrowseType.acquisition:
        prob = 'ACQUIS';
        break;
      default:
        prob = '30JOURS';
    }
    /** -------- **/
    const inputs: { key: string; value: string }[] = [];
    const iban = scheme.paymentAccount && scheme.paymentAccount.iban ? scheme.paymentAccount.iban : this.ibanBic.iban;
    const bic = scheme.paymentAccount && scheme.paymentAccount.bic ? scheme.paymentAccount.bic : this.ibanBic.bic;

    inputs.push({ key: 'url', value: this.configService.data.spb.create.replace('https://', '') });
    inputs.push({ key: 'typeClt', value: customer.company.noSiren ? '1' : '2' });
    inputs.push({ key: 'siren', value: customer.company.noSiren });
    inputs.push({ key: 'rSoc', value: customer.company.raisonSociale });
    inputs.push({ key: 'banq', value: iban ? iban.substring(4, 9) : '' });
    inputs.push({ key: 'ag', value: iban ? iban.substring(9, 14) : '' });
    inputs.push({ key: 'compte', value: iban ? iban.substring(14, 25) : '' });
    inputs.push({ key: 'cle', value: iban ? iban.substring(25, 27) : '' });
    inputs.push({ key: 'civilite', value: customer.civility ? customer.civility.replace('.', '') : '' });
    inputs.push({ key: 'prenomClt', value: customer.firstname });
    inputs.push({ key: 'nomClt', value: customer.lastname });
    inputs.push({ key: 'datenais', value: moment(customer.dob).format('DD/MM/YYYY') });
    inputs.push({ key: 'tel', value: customer.phoneNumber });
    inputs.push({ key: 'mail', value: customer.email });
    inputs.push({ key: 'adresse1', value: customer.streetNumber + ' ' + customer.street });
    inputs.push({ key: 'adresse2', value: customer.street2 });
    inputs.push({ key: 'adresse3', value: customer.postcode });
    inputs.push({ key: 'adresse4', value: customer.city });
    inputs.push({ key: 'type', value: equipement ? '1' : '2' });
    inputs.push({ key: 'imei', value: this.imei });
    inputs.push({ key: 'genCod', value: this.gencode });
    inputs.push({ key: 'canal', value: 'RCBT' });
    inputs.push({ key: 'login', value: user.login });
    inputs.push({ key: 'matricule', value: '0000' });
    inputs.push({ key: 'codeEnsPDV', value: user.codeEns + user.codePdv });
    inputs.push({ key: 'idp', value: String(this.cartService.cart.cartId) });
    inputs.push({
      key: 'idb',
      value: this.cartService.getCurrentScheme().isRenew()
        ? String(this.cartService.getCurrentScheme().contractId)
        : String(this.cartService.cart.cartId),
    });
    inputs.push({ key: 'prob', value: prob });
    const spbChaine: string = 'RCBT' + user.login + '0000' + user.codeEns + user.codePdv;
    const spbHash: string = SHA256(
      spbChaine + this.configService.data.spb.secret + moment().utcOffset(0).format('YYYY-MM-DD:HH'),
    ).toString();
    inputs.push({ key: 'SPB_CHAINE', value: spbChaine });
    inputs.push({ key: 'SPB_HASH', value: spbHash.toString() });
    inputs.push({ key: 'iban', value: iban });
    inputs.push({ key: 'bic', value: bic });

    this.inputs = inputs;
  }

  private getConcernedSpbInsurance(): InsuranceConfig {
    const schemeInstance = this.cartService.cart.schemes.find(x => x.quoteId === this.scheme.quoteId);

    return schemeInstance.addedInsurances.find(
      (insuranceConfig: InsuranceConfig) => insuranceConfig.code === InsurancePartnerCodeEnum.spb,
    );
  }

  public onSubmit(): void {
    this.insuranceConfig = this.getConcernedSpbInsurance();
    this.insuranceConfig.subscribeClicked = true;
    this.cartService.save();
    this.stepperService.changesCurrentStep.next(null);
  }
}
