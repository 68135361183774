import { Scheme } from './scheme.class';
import { Promotion } from '../../promotions/promotion.class';
import { ICartTotal } from '../../promotions/promotions.interfaces';
import { Consent, CreditData } from './cart.interface';
import { IPlanConfiguration } from '../../catalog/products/subscription/IPlanConfiguration';
import { InsuranceConfig } from '../../partner/partner.dto';

export class Cart {
  public contractPrinted: boolean;
  public schemes: Scheme[] = [];
  public cartId: number;
  public cartEncryptedId: string;
  public channel: string;
  public orcomId: string;
  public cartOrderId: string;
  public dematId: string;
  public tokenFolder: string;
  public encryptionKey: string;
  public contractSigned: boolean;
  public promotions: Promotion[] = []; // La liste des promos applicable
  public archivedPromotions: Promotion[] = [];
  public isEligibleEdp = true;
  public totals: ICartTotal = { today: 0, everyMonth: 0, reported: 0 };
  public caTotals: ICartTotal = { today: 0, everyMonth: 0, reported: 0 };
  public bytelTotals: ICartTotal = { today: 0, everyMonth: 0, reported: 0 };
  public consent: Consent;
  public plansConfig: { [productId: number]: IPlanConfiguration } = {};
  public schemeInsurances: { [quoteId: number]: InsuranceConfig[] } = {};
  public creditData: CreditData;
  public personId: number; // todo prévoir une tâche technique pour le changer en string
}
