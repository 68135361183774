<div class="is-tri agility-modal">
    <div class="close-modal" (click)="close()">
        <i class="icon-item">
            <span btlSvg file="rcbt-defs" title="close" name="shape-icon-close" class="icon-close"></span>
        </i>
    </div>

    <div class="title">Agilité Co</div>

    <form class="form" novalidate (submit)="onSubmit($event)">
        <div class="form-row">
            <div class="label">
                <label for="agility_amount">Montant TTC agilité commerciale </label>
            </div>
            <div class="data">
                <input type="text" name="agility_amount" class="input" id="agility_amount" [value]="agilityAmount || null"
                        amountinput (amountChange)="onAgilityAmountChanged($event)">
                <span class="currency-symbol">€</span>
            </div>
            <label class="label has-text-danger">
                {{error}}
            </label>
        </div>
        <div class="form-row">
            <div class="label">
                Total Panier remisé TTC :
            </div>
            <div class="data">
                <rcbt-price [price]="cartAmount - agilityAmount"  [oldPrice]="cartAmount - agilityAmount" [fontLevel]="4"></rcbt-price>
            </div>
        </div>
        <div class="form-row reverse">
            <button type="button" class="button is-info" (click)="onSaveAgility(agilityAmount)">
                <span class="text">Appliquer</span>
            </button>
        </div>
    </form>
</div>
