import { Observable, of as observableOf, throwError } from 'rxjs';
import { finalize, catchError, mergeMap, tap, concatMap, map } from 'rxjs/operators';
import { Component, Input, OnInit } from '@angular/core';
import { CartService } from '../../../cart.service';
import { ProductsService } from '../../../../../catalog/products.service';
import { Avc } from '../../../../../catalog/products/avc';
import { Catalog } from '../../../../../catalog/products/catalog';
import { CheckoutStepperService } from '../../../../checkout-stepper.service';
import { IProductConfiguration } from '../../../../../catalog/products/IProductConfiguration';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CanalService } from '../../../../../partner/canal/canal.service';
import { FundingService } from '../../../../../fundings/services/funding.service';
import { ScoringService } from '../../../../../scoring/scoring.service';
import { GetProductsWithFilterQuery } from '@bytel/pt-ihm-api-portailvente-sapi-catalogue';

interface ControlError {
  key?: string;
  message?: string;
  unlockable?: boolean;
}

@Component({
  selector: 'rcbt-scoring-detailed',
  templateUrl: './modal-scoring-detailed.component.html',
  styleUrls: ['./modal-scoring-detailed.component.scss'],
})
export class ModalScoringDetailedComponent implements OnInit {
  @Input()
  public schemeId: string;
  public controlErrors: ControlError[] = [];
  public loading = false;
  public showRetry = true;
  public avcAmount = 0;
  public error: string;
  public showAddAdvanceButton: boolean;

  constructor(
    public readonly cartService: CartService,
    private readonly productService: ProductsService,
    private readonly checkoutStepperService: CheckoutStepperService,
    private readonly activeModal: NgbActiveModal,
    private readonly canalService: CanalService,
    private readonly fundingService: FundingService,
    private readonly scoringService: ScoringService,
  ) {}

  public ngOnInit(): void {
    this.initScoringMessages();
  }

  public updateScheme(): void {
    this.scoringService.retryAfterSD = true;
    this.cartService
      .unlockScheme(this.cartService.getSchemeById(this.schemeId).quoteId)
      .pipe(tap(() => this.cartService.selectCurrentScheme(this.schemeId)))
      .subscribe(() => {
        this.checkoutStepperService.goToStep(this.checkoutStepperService.findFirstStep());
        this.closeModal();
      });
  }

  public addAvc(): void {
    this.loading = true;
    this.productService
      .getJsonProductsByFilter({ type: 'avc' } as GetProductsWithFilterQuery)
      .pipe(
        mergeMap(jsonCatalog => {
          const avcJsonProduct = Object.values(jsonCatalog)[0];
          const avc: Avc = <Avc>Catalog.getInstance(avcJsonProduct);
          avc.setConfiguration(<IProductConfiguration>{ prix: this.avcAmount });
          return this.cartService.add(avc, 1, false, this.schemeId);
        }),
        concatMap(() => this.cartService.refreshCart()),
        mergeMap(() => this.fundingService.postFundingMode(this.cartService)),
        mergeMap(() => this.retryScoringInternal()),
        mergeMap(() => {
          if (this.scoringService.isScoringValid()) {
            return this.canalService.getScoring(this.schemeId).pipe(
              catchError(() => observableOf({ data: -1 })),
              finalize(() => {
                this.cartService.save();
                this.closeModal();
              }),
            );
          } else {
            return observableOf(null);
          }
        }),
        finalize(() => (this.loading = false)),
        catchError(error => {
          this.showError("Une erreur s'est produite, impossible d'ajouter l'AVC");
          return throwError(error);
        }),
      )
      .subscribe();
  }

  public retryScoring(): void {
    // nya maybe add scoringCanal
    this.retryScoringInternal().subscribe();
  }

  public getControlMessage(control: ControlError): string {
    if (
      ['controleAchatsRapproches', 'controlePreventel', 'controleEffectifEntreprise', 'controleNeoBanque'].indexOf(
        control.key,
      ) !== -1
    ) {
      control.message = control.message.replace('#param1', this.avcAmount.toString());
    }
    return control.message;
  }

  private initScoringMessages(): void {
    this.reset();
    const scoringDetailed = this.scoringService.scoringResult?.resultats || [];

    scoringDetailed.forEach((resultat, index) => {
      if (
        resultat.type === 'ResultatControleUnitaire' &&
        ['KO_SAUF_ACTION_REALISEE', 'KO'].includes(resultat.resultat)
      ) {
        this.controlErrors.push({
          key: resultat[index],
          message: resultat.message,
          unlockable: resultat.caracteristiqueDeblocage?.deblocable,
        });
        if (!resultat.caracteristiqueDeblocage?.deblocable) {
          this.showRetry = false;
        }
      }
      if (
        resultat.type === 'ResultatGlobal' &&
        resultat.champApplication?.type === 'ParActeDemande' &&
        resultat.actions
      ) {
        resultat.actions.forEach(action => {
          if (action.action === 'AVANCE_SUR_CONSOMMATION') {
            this.avcAmount += action.montant || 0;
            this.showAddAdvanceButton = true;
          }
        });
      }
    });
  }

  private showError(error: string): void {
    const errorDuration = 4000;
    this.error = error;
    setTimeout(() => {
      this.error = null;
    }, errorDuration);
  }

  private closeModal(): void {
    this.activeModal.close();
  }

  private reset(): void {
    this.controlErrors = [];
    this.showRetry = true;
    this.avcAmount = 0;
  }

  private retryScoringInternal(): Observable<void> {
    this.loading = true;
    return this.scoringService.forceUpdate().pipe(
      tap(
        () => {
          this.initScoringMessages();
          if (this.scoringService.isScoringValid()) {
            this.closeModal();
          } else {
            this.initScoringMessages();
          }
        },
        () => {
          this.showError("Une erreur s'est produite, impossible de relancer le scoring");
        },
      ),
      map(() => null),
      finalize(() => {
        this.loading = false;
      }),
    );
  }
}
