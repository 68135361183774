import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CategoryModule } from './category/category.module';
import { ContextModule } from '../context/context.module';
import { CheckoutModule } from '../checkout/checkout.module';

@NgModule({
  imports: [CommonModule, CategoryModule, HttpClientModule, ContextModule, CheckoutModule],
})
export class CatalogModule {}
