import { IServiceCost } from './IServiceCost';
import { ProductSerialized } from '../../interface/configurable';
import { Equipement } from '../../equipement';

export class FaiService extends Equipement {
  public setConfiguration(data: IServiceCost): void {
    super.setConfiguration(data);
    this.priceType = Number(data.priceType);
  }

  public getConfiguration(): IServiceCost {
    return <IServiceCost>{
      priceType: this.priceType,
      ...super.getConfiguration(),
    };
  }

  public serialize(): ProductSerialized {
    return Object.assign(super.serialize(), this.getConfiguration());
  }
}
