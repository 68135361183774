<div class="is-tri modal-content">
    <div class="close-modal">
        <span class="icon is-medium" aria-label="Icône de taille moyenne" (click)="close()">
            <i class="tri-times-circle" aria-hidden="true"></i>
        </span>    
    </div>

    <div class="title">Reprise mobile</div>

    <div class="data-mobile-takeback">
        <div class="part-left">

            <div class="full-width" [formGroup]="imeiForm">
                <div class="data-row">
                    <label>Entrer le numéro IMEI ou un bon de cession</label>
                    <div class="full-width input-button field">
                        <div class="full-width control has-dynamic-placeholder">
                            <input role="textbox" type="tel"
                                class="input is-default"
                                placeholder="IMEI ou bon de cession"
                                (input)="search($event)" maxlength="17" formControlName="imei"
                                data-cy="mobileTakeBackImei">
                            <label>IMEI ou bon de cession</label>
                        </div>
                        <button class="button is-info is-icon-only" data-cy="scan-reprise" *ngIf="onTablet" (click)="scanImei()">
                            <span class="icon is-small"><i class="tri-camera" aria-hidden='true'></i></span>
                        </button>
                    </div>
                    <label *ngIf="!isValidImei()" class="has-text-danger">
                        Le format est incorrect ! Veuillez saisir un IMEI ou un bon de cession valide.
                    </label>
                </div>

                <div class="data-row" *ngIf="productlist?.length">
                    <label>Veuillez sélectionner votre modèle de téléphone:</label>
                    <div class="control has-dynamic-placeholder">
                        <select id="select-terminal" formControlName="phoneModel" (change)="getFormQuestion()" class="has-text-tertiary" data-cy="mobileTakeBackSelect">1
                            <option [value]="null" [disabled]="isLoading">Sélectionner votre terminal</option>
                            <option *ngFor="let product of productlist" [ngValue]="product"
                                [disabled]="isLoading">{{product.nom}}
                            </option>
                        </select>
                        <label for="select-terminal">Sélectionner votre terminal</label>
                    </div>
                </div>
            </div>

            <div class="data-row">
                <label class="has-text-sucess" *ngIf="success">
                    Veuillez vérifier qu'il s'agit bien du même mobile, du même IMEI et que l'état correspond à la déclaration initiale.
                    Nous vous rappelons que l'identité du bon de cession doit correspondre à celle de l'acte de vente.
                </label>
            </div>

            <div class="data-row" [formGroup]="questionForm"  *ngIf='questionsList && questionsList.length > 0' data-cy="mobileTakeBackList">

                <div class="qa" *ngFor="let question of questionsList; index as i;">
                    <div class="question">

                        <ng-template #popContent>
                            <div class="popover-response" *ngFor="let response of question.reponses">
                                <div class="popover-label">
                                    <b>{{response.libelle}}</b>
                                </div>
                                <div class="popover-details">
                                    <ul class="">
                                        <li *ngFor="let d of response.description">● <span [innerHTML]="d"></span></li>
                                    </ul>
                                </div>
                            </div>
                        </ng-template>

                        <label>{{question.libelle}}
                            <span class="icon is-small" #p2="ngbPopover" [ngbPopover]="popContent" placement="right" container="body" (click)="togglePopover(p2)">
                                <i class="tri-infos-circle" aria-hidden='true'></i>
                            </span>
                        </label>
                        <input type="text" formControlName="{{question.id}}" [value]="question.id" [hidden]='true'>
                    </div>

                    <div class="qrCode" *ngIf="question.qrCode">
                        <div class="qr-code-bloc is-flex is-aligned-center">
                            <img src="/assets/images/QR_Code_WebApp_test_DeadPixel.png" class="qrCodeDeadPixel" alt="qrcode"/>
                            <span class="has-text-primary m-l-5">Testez l'écran<br>en ligne</span>
                        </div>
                    </div>
        
                    <div class="answer options">
                        <div class="field" *ngFor="let response of question.reponses">
                            <div class="control">
                                <input type="radio" id="{{question.id}}-{{response.id}}" value="{{response.id}}" [checked]="questionForm.get('response-'+question.id).value === response.id" formControlName="response-{{question.id}}">
                                <label for="{{question.id}}-{{response.id}}" data-cy="btn-response">{{response.libelle}}</label>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="data-row" *ngIf="geolocalisationError">
                    <p class="text has-text-danger">
                        {{geolocalisationError}}
                    </p>
                </div>
            </div>

            <div class="data-row" *ngIf="inventoryWarning">
                <p class="text has-text-warning">
                    {{inventoryWarning}}
                </p>
            </div>
            <div class="data-row" *ngIf="inventoryError">
                <p class="text has-text-danger">
                    {{inventoryError}}
                </p>
            </div>
        </div>

        <div class="part-right">
            <div class="phone-name">
                <p class="title is-level-3 has-text-centered">{{ phone?.marque }}</p>
                <p class="title is-level-3 has-text-centered">{{ phone?.nom }}</p>
            </div>
            <div class="price-infos">
                <h2 class="text margin">Montant de la reprise</h2>
                <rcbt-price class="margin" [price]="mobileTakeBackAmount" *ngIf="mobileTakeBackAmount !== undefined"></rcbt-price>
                <button class="button is-primary margin" (click)="onValid()"
                    [disabled]="!isValid() || isLoading || !mobileTakeBackAmount"
                    [ngClass]="{'action-feedback': isLoading}" data-cy="mobileTakeBackOk">Appliquer</button>
                <p *ngIf="isValid() && !isLoading && mobileTakeBackAmount" class="text is-3 has-text-centered margin">
                    <span class="icon is-small"><i class="tri-comment-info" aria-hidden='true'></i></span>
                    En cas de reprise d'un terminal eSim, pensez à supprimer le profil eSim du terminal
                </p>
            </div>
        </div>
    </div>

    <div class="text has-text-danger" *ngIf="error"> {{error}} </div>
</div>
