import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Product } from '../../../../../catalog/products/product';
import { OffresSecondaires } from '../../../../../context/child/renew-promo-context-serialized.interface';
import { RegularizationService } from '../../../../../promotions/regularizationService';

@Component({
  selector: 'rcbt-scheme-renew-promotions',
  templateUrl: './scheme-renew-promotions.component.html',
  styleUrls: ['./scheme-renew-promotions.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SchemeRenewPromotionsComponent implements OnInit {
  @Input() public product: Product;
  public osList: OffresSecondaires[] = [];

  constructor(public regularizationService: RegularizationService) {}

  public ngOnInit(): void {
    if (this.product.renewPromotion && this.product.renewPromotion.offresSecondairesCibles) {
      this.osList = this.product.renewPromotion.offresSecondairesCibles;
    }
    if (this.product.opSource && this.product.opSource.osDetenues) {
      this.osList = this.osList.concat(this.product.opSource.osDetenues);
    }
  }
}
