import { Injectable } from '@angular/core';
import { mergeMap } from 'rxjs/operators';
import { Observable, of as observableOf, throwError } from 'rxjs';
import { Oauth2RessourceService } from '../oauth2/oauth2-resources.service';
import { User } from './user';
import { DateUniversal } from '../base/class/date-universal.class';
import {
  ConfigBOEnum,
  ICluster,
  IClusters,
  PointsDeVente,
  PointsDeVenteInterface,
  StatutCluster,
  TypeCluster,
} from '../context/child/clusters.interface';
import {
  IRequestRegularizationAdminRights,
  IResponseRegularizationAdminRights,
} from '../promotions/promotions.interfaces';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  public readonly user: User = new User();
  constructor(private oauth2Ressource: Oauth2RessourceService) {}

  public initUserData(): Observable<User> {
    const data = this.oauth2Ressource.getUserInfo();
    const errors: string[] = [];
    if (data) {
      if (!data['point_vente']) {
        errors.push('point_vente');
      }
      if (!data['enseigne']) {
        errors.push('enseigne');
      }
      if (data['user_type'] !== 'CDV') {
        errors.push('user_type : ' + data['user_type']);
      }
      if (!data['roles']) {
        errors.push('roles');
      }
      this.user.firstname = data['given_name'];
      this.user.lastname = data['family_name'];
      this.user.idPersonne = data['id_personne'];
      this.user.user_type = data['user_type'];
      this.user.codeEns = data['enseigne'];
      this.user.codePdv = data['point_vente'];
      this.user.roles = data['roles'];
      this.user.login = data['login'];
      this.user.registrationNumber = data['matricule'];
      this.user.deviceId = data['deviceId'];
    } else {
      errors.push('Info token vide');
    }
    if (errors.length) {
      return throwError(errors);
    }
    return this.setStoreData(this.user);
  }

  private setStoreData(user: User): Observable<User> {
    const dateNow: DateUniversal = new DateUniversal('now');
    const object = { pointDeVentes: [{ codePdv: `${this.user.codeEns}${this.user.codePdv}` }] };
    return this.oauth2Ressource
      .pointsDeVentes()
      .post(object)
      .pipe(
        mergeMap((pointsDeVentes: PointsDeVenteInterface) => {
          const point: PointsDeVente = pointsDeVentes.pointsDeVente.find(
            (pdV: PointsDeVente) => pdV.codePdv === `${this.user.codeEns}${this.user.codePdv}`,
          );
          const idPdv: string = point.idPdv;
          this.user.nomBoutique = point.libelle;
          return this.oauth2Ressource
            .pointsDeVentes(idPdv)
            .addQueryParams({ domaines: ['CLUSTER', 'LOGISTIQUE', 'IDENTIFICATION'] })
            .get();
        }),
        mergeMap((res: IClusters) => {
          // todo comparer avec l'ancien
          this.user.isPBO = res.pointsDeVente.logistique?.configurationBackOffice === ConfigBOEnum.pbo;
          this.user.typeEntiteReseauDistribution = res.pointsDeVente.type;
          if (res && res.pointsDeVente && res.pointsDeVente.clusters) {
            const clusters: ICluster[] = res.pointsDeVente.clusters.filter((cluster: ICluster) => {
              const dateBegin: DateUniversal = new DateUniversal(cluster.dateDebut);
              const dateEnd: DateUniversal = new DateUniversal(cluster.dateFin);
              return (
                cluster.type === TypeCluster.promo &&
                cluster.statut === StatutCluster.actif &&
                DateUniversal.dateBetween(dateBegin, dateEnd, dateNow)
              );
            });
            this.user.clusters = clusters;
          }
          this.user.canApplyDiscountCA =
            this.user.codeEns === '499' &&
            this.user.user_type === 'CDV' &&
            this.user.roles &&
            this.user.roles.some((role: string) => ['RCBT_MANAGER_SIEGE_CA_SP', 'RCBT_MANAGER_CA_SP'].includes(role));
          const bodyCanApplyRegularization: IRequestRegularizationAdminRights = {
            logonManager: this.user.login,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            Fonctionnality: 'Regularisation',
          };
          return this.oauth2Ressource
            .ventes()
            .setLocalService()
            .useSalesApi()
            .canRegularize()
            .post(bodyCanApplyRegularization)
            .pipe(
              mergeMap((result: IResponseRegularizationAdminRights) => {
                this.user.canApplyRegularization = result.Granted === 'true';
                return observableOf(user);
              }),
            );
        }),
      );
  }
}
