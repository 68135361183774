import { CompatibilityMessages } from '../../product';
import { Sim } from '../sim';
import { BrowseType } from '../../../../checkout/cart/browse-type';
import { AddResult, ResultAddMessage, TypeResult } from '../../../../checkout/cart/cart.interfaces';

export class SimReplace extends Sim {
  public canAdd(browseType: BrowseType, isPrepaid: boolean, isFnb: boolean): AddResult {
    const result: AddResult = { status: true, productsConflict: [] };

    if (browseType === BrowseType.acquisitionFix || isFnb || isPrepaid) {
      result.status = false;
      result.addCheck = <ResultAddMessage>{
        type: TypeResult.error,
        message: CompatibilityMessages.simTypeNotCompatible,
      };
    }

    return result;
  }
}
