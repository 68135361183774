import { Component, NgZone, ViewChild } from '@angular/core';
import { CartService } from '../../cart.service';
import { Observable, of, ReplaySubject } from 'rxjs';
import { Fai } from '../../../../catalog/products/subscription/plan/fai/fai';
import { Plan } from '../../../../catalog/products/subscription/plan';
import { IPlanConfiguration } from '../../../../catalog/products/subscription/IPlanConfiguration';
import { FaiService } from '../../../../fai-widget/fai.service';
import { GlobalLoaderService } from '../../../../base/services/global-loader.service';
import { CheckoutStepperService } from '../../../checkout-stepper.service';
import { FaiScheme } from '../../fai-scheme';
import { CustomerCategory, CustomerType } from '../../customer/customer.interface';
import { CustomerService } from '../../customer/customer.service';

const LOADING_ACTIONS = {
  login: '[WetoLoginComponent] voip',
};

@Component({
  selector: 'rcbt-weto-login',
  templateUrl: './weto-login.component.html',
  styleUrls: ['./weto-login.component.scss'],
})
export class WetoLoginComponent {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @ViewChild('widgetWetoLogin') widgetWetoLogin?: { nativeElement: any };
  public isEmailValid = false;
  public errors: string[] = [];
  private plan: Plan = this.cartService.getCurrentScheme().getProductByType<Fai>(Fai);
  private submit$: ReplaySubject<boolean>;

  constructor(
    public faiService: FaiService,
    private _ngZone: NgZone,
    private cartService: CartService,
    private globalLoaderService: GlobalLoaderService,
    private stepperService: CheckoutStepperService,
    private customerService: CustomerService,
  ) {}

  public ngAfterViewInit(): void {
    if (this.faiService.reservedLogin) {
      this.isEmailValid = true;
    } else {
      this.render();
    }
  }

  public render(): void {
    const customerContextService = this.customerService.customer;
    this.widgetWetoLogin?.nativeElement.render(
      this.faiService.createWetoContext(),
      (this.cartService.getCurrentScheme() as FaiScheme).faiEligId,
      this.getNbLogin(),
      customerContextService.firstname,
      customerContextService.lastname,
      [],
      this.getSelectedLogin(),
    );
    this._ngZone.runOutsideAngular(() => this.widgetWetoLogin?.nativeElement.onEvent(this.onEvent.bind(this)));
  }

  public onEvent(e: Event): void {
    this._ngZone.run(() => {
      if (e.type === 'END') {
        this.onEndEvent(e);
      }
      if (e.type === 'STEP_STATUS') {
        this.onStatusEvent(e);
      }
      if (e.type === 'ERROR') {
        this.onErrorEvent(e);
      }
    });
  }

  public submit(): Observable<boolean> {
    if (this.faiService.reservedLogin) {
      return of(true);
    }
    this.errors = [];
    this.submit$ = new ReplaySubject<boolean>(1);
    this.widgetWetoLogin?.nativeElement?.next();
    return this.submit$;
  }

  public isValid(): boolean {
    return this.isEmailValid;
  }

  private onEndEvent(e): void {
    const faiPlan = this.cartService.getCurrentScheme().getProductByType(Fai);
    if (!e.event.result.selected) {
      faiPlan.setConfiguration(<IPlanConfiguration>{ logins: [{ login: e.event.result.login, selected: false }] });
    }
    this.faiService.reservedLogin = e.event.result.login;
    this.submit$.next(true);
    this.submit$.complete();
  }

  private onErrorEvent(e): void {
    this.globalLoaderService.dispatchLoadingStatus({ actionType: LOADING_ACTIONS.login, isLoading: false });
    if (this.submit$) {
      this.errors.push(e.event?.description ?? "Impossible d'enregistrer le login choisi.");
      this.submit$.error(null);
    } else {
      this.errors.push(e.event?.description ?? 'Impossible de charger les logins.');
      this.stepperService.changesCurrentStep.next(null);
    }
  }

  private onStatusEvent(e): void {
    this.errors = [];
    if (e.event.status === 'LOADING') {
      this.globalLoaderService.dispatchLoadingStatus({ actionType: LOADING_ACTIONS.login, isLoading: true });
    } else {
      this.globalLoaderService.dispatchLoadingStatus({ actionType: LOADING_ACTIONS.login, isLoading: false });
      if (e.event.status === 'VALID') {
        this.isEmailValid = true;
      } else if (e.event.status === 'INVALID') {
        this.isEmailValid = false;
      }
      this.stepperService.changesCurrentStep.next(null);
    }
  }

  private getSelectedLogin(): string {
    return this.plan.logins?.length ? this.plan.logins[0].login : (this.faiService.reservedLogin ?? undefined);
  }

  private getNbLogin(): number {
    const customerContextService = this.customerService.customer;
    return customerContextService.category === CustomerCategory.pro &&
      customerContextService.type === CustomerType.prospect &&
      (!customerContextService.firstname || !customerContextService.lastname)
      ? 0
      : 3;
  }
}
