<div *ngIf="comparatorService.active" class="comparator-parameters">
    <div class="columns">
        <div class="column is-9">
            <div class="columns">
                <div *ngFor="let index of [0, 1, 2]" class="column is-4">
                    <div class="card" *ngIf="comparatorService.selectedPhones[index]">
                        <div class="is-flex is-fullwidth">
                            <div class="phone-image is-flex is-justified-center">
                                <img
                                    defaultImage="/assets/images/placeholder.png"
                                    alt="{{comparatorService.selectedPhones[index].marque}} {{comparatorService.selectedPhones[index].nom}} {{comparatorService.selectedPhones[index].capacite}} {{comparatorService.selectedPhones[index].couleurLibelle}}"
                                    [src]="comparatorService.selectedPhones[index].image | media"
                                >
                            </div>
                            <div class="phone-text is-flex is-vertical is-aligned-center is-justified-center has-text-weight-semibold">
                                <p>{{comparatorService.selectedPhones[index].marque}} {{comparatorService.selectedPhones[index].nom}}</p>
                                <p>{{comparatorService.selectedPhones[index].capacite}} {{comparatorService.selectedPhones[index].couleurLibelle}}</p>
                            </div>
                        </div>
                        <div class="phone-close">
                            <span class="icon is-small" aria-label="Icone de taille small" (click)="comparatorService.removeProduct(comparatorService.selectedPhones[index].gencode)">
                                <i class="tri-times-circle" aria-hidden="true"></i>
                            </span>
                        </div>
                    </div>
                    <div class="card empty-card" *ngIf="!comparatorService.selectedPhones[index]">
                        Sélectionner un téléphone ci-dessous
                    </div>
                </div>
            </div>
        </div>
        <div class="column is-3 is-flex is-justified-end is-aligned-center">
            <button type="button" class="button is-secondary" [disabled]="!comparatorService.isCompareButtonEnabled()"
                    (click)="comparatorService.compare()">Comparer</button>
        </div>
    </div>
    <div class="columns">
        <div class="column is-4">
            <rcbt-multi-select
                    [title]="'Choisir 1 à 3 offres'"
                    [maxSelection]="3"
                    [keyLabel]="'name'"
                    [options]="comparatorService.availablePlans"
                    (checked)="comparatorService.onPlanChecked($event)"
                    #multiSelect
            >
            </rcbt-multi-select>
        </div>
        <div class="column is-4 nb-plans">
            {{comparatorService.nbPlansSelected}}&nbsp;
            <span [ngPlural]="comparatorService.nbPlansSelected">
                <ng-template ngPluralCase="=0">offre sélectionnée</ng-template>
                <ng-template ngPluralCase="=1">offre sélectionnée</ng-template>
                <ng-template ngPluralCase="other">offres sélectionnées</ng-template>
            </span>
        </div>
        <div class="column is-4 is-flex is-justified-end">
            <button type="button" class="button is-info" (click)="resetComparator()">
                <span class="icon is-small has-text-white"><i class="tri-trash" aria-hidden="true"></i></span>
                        Tout supprimer
            </button>
        </div>
    </div>
</div>
<div style="display: none">
    <rcbt-comparator-result></rcbt-comparator-result>
</div>
