export interface ICluster {
  dateDebut: string;
  dateFin: string;
  idCluster: string;
  libelle: string;
  noVersion: number;
  statut: StatutCluster;
  type: TypeCluster;
}
export enum StatutCluster {
  actif = 'ACTIF',
  enCoursDeCreation = 'EN_COURS_DE_CREATION',
  inactif = 'INACTIF',
}
export enum TypeCluster {
  assortiment = 'ASSORTIMENT',
  livraison = 'LIVRAISON',
  plv = 'PLV',
  promo = 'PROMO',
}
export interface IClusters {
  pointsDeVente: {
    clusters: ICluster[];
    libelle: string;
    logistique: {
      configurationBackOffice: ConfigBOEnum;
    };
    type: string;
  };
}

export interface PointsDeVenteInterface {
  resultCounts: number;
  pointsDeVente: PointsDeVente[];
}
export interface CoordonneesGps {
  latitude: number;
  longitude: number;
}

export interface Adresse {
  codePays: number;
  complementAdr1: string;
  coordonneesGps: CoordonneesGps;
  cp: string;
  num: string;
  rue: string;
  ville: string;
}

export interface CoordonneesContact {
  coordonnee: string;
  type: string;
}

export interface Etablissement {
  adresse: Adresse;
  coordonneesContact: CoordonneesContact[];
}

export interface VieDuReseau {
  dateFermetureTravaux: string;
  dateOuvertureCommerciale: string;
  dateReouvertureTravaux: string;
}

export interface ConsulterInfosCommerciales {
  href: string;
  type: string;
}

export interface ConsulterUrls {
  href: string;
  type: string;
}

export interface PriseRdv {
  href: string;
  type: string;
}

export interface ConsulterPointDeVente {
  href: string;
  type: string;
}

export interface Links {
  consulterInfosCommerciales: ConsulterInfosCommerciales;
  consulterUrls: ConsulterUrls;
  priseRdv: PriseRdv;
  consulterPointDeVente: ConsulterPointDeVente;
}

export interface PointsDeVente {
  codePdv: string;
  etablissement: Etablissement;
  idPdv: string;
  libelle: string;
  statut: string;
  type: string;
  vieDuReseau: VieDuReseau;
  _links: Links;
}

export enum ConfigBOEnum {
  gbo = 'GRAND_BO',
  pbo = 'PETIT_BO',
}
