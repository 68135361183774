import { Injectable } from '@angular/core';
import { forkJoin, MonoTypeOperatorFunction, Observable, of, OperatorFunction, throwError } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { Equipment } from './equipment.class';
import { CartService } from '../cart.service';
import { Fai } from '../../../catalog/products/subscription/plan/fai/fai';
import { Shipping } from '../../../catalog/products/shipping';
import { ShippingMethodsLabelsFront } from '../../../catalog/products/interface/shipping';
import { IGenericDeviceConfiguration } from '../../../catalog/products/equipement/IGenericDeviceConfiguration';
import { Product } from '../../../catalog/products/product';
import { Oauth2RessourceService } from '../../../oauth2/oauth2-resources.service';
import { Scheme } from '../scheme.class';
import { Option } from '../../../catalog/products/subscription/option';
import { Device } from '../../../catalog/products/equipement/device';
import { SimSav } from '../../../catalog/products/equipement/sim/sim-sav';
import { Sim } from '../../../catalog/products/equipement/sim';
import { Accessory } from '../../../catalog/products/equipement/accessory';
import { EquipmentError, EquipmentErrorCode, EquipmentErrorType } from './equipment.error';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Catalog } from '../../../catalog/products/catalog';
import { JsonCatalog, JsonProduct } from '../../../catalog/products/interface/context';
import { ProductsService } from '../../../catalog/products.service';
import { StockType } from '../../../scan/interfaces/types';
import { StockTypeDto } from './equipment.dto';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap/modal/modal-ref';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { EquipmentModalComponent } from './modal/equipment-modal.component';
import { ScanConfig } from '../../../scan/scan-config.class';
import { FaimUnlimited } from '../../../catalog/products/subscription/plan/fai/faim-unlimited';
import {
  ContraintesRemise,
  DeviceLstElement,
  DeviceLstResult,
  EquipementFixe,
  EquipementPhysique,
  EquipementsFixesMouvementsResponse,
} from './equipments.intefaces';
import { ConfigService } from '../../../config.service';
import { FaiScheme } from '../fai-scheme';
import { UserService } from '../../../user/user.service';
import { SimService } from '../../../sim/sim.service';
import { SimsContextualisesResponse } from '../../../sim/sim.interfaces';

interface IStock {
  stockType: StockTypeDto;
  quantity: number;
  gencode: string;
}

@Injectable({
  providedIn: 'root',
})
export class EquipmentsService {
  private jsonEquipments: JsonCatalog;
  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly cartService: CartService,
    private readonly oauth2RessourceService: Oauth2RessourceService,
    private readonly productsService: ProductsService,
    private readonly modalService: NgbModal,
    protected configService: ConfigService,
    private userService: UserService,
    private simService: SimService,
  ) {}

  public getShippingMethodMessage(): string {
    const faiProduct = this.cartService.getCurrentScheme().getProductByType<Fai>(Fai);
    const options = this.cartService.getCurrentScheme().getProductsByType(Option);

    const listGencodeBigTrust = this.configService.data?.listGencodeBigTrust || [];
    const hasBigTrust = !!options?.find(option => listGencodeBigTrust.find(x => x === option.data.gencode));

    if (faiProduct && !hasBigTrust) {
      const shippingProduct = this.cartService.getCurrentScheme().getProductByType(Shipping);
      if (shippingProduct) {
        return `Equipements ${ShippingMethodsLabelsFront[shippingProduct.gencode]}`;
      }
    }
    return '';
  }

  public buildForm(): UntypedFormGroup {
    const controlsConfig: { [key: string]: UntypedFormControl } = {};
    controlsConfig.scanCode = this.formBuilder.control('', []);
    return this.formBuilder.group(controlsConfig);
  }

  public buildEquipments(simList: string[]): Observable<Equipment[]> {
    const equipments: Equipment[] = [];
    const devices: Product[] = this.getDevicesToScan();
    for (const device of devices) {
      const configuration: IGenericDeviceConfiguration = device.getConfiguration() as IGenericDeviceConfiguration;
      configuration.element.productId = device.uniqueId;

      equipments.push(new Equipment(configuration.element));
    }
    return this.loadEquipments(simList).pipe(
      mergeMap((data: DeviceLstResult) => this.processCompleteLstEquipement(equipments, data)),
    );
  }

  public selectEquipment(scanCode: string, equipments: Equipment[]): Observable<void> {
    const foundEquipment: Equipment = equipments.find(eq => eq.getScanCode() === scanCode);
    if (foundEquipment) {
      // erreur non-bloquante
      return throwError(
        new EquipmentError(EquipmentErrorType.warn, EquipmentErrorCode.scanError, 'Equipement déjà scanné'),
      );
    }
    return this.getStocks(scanCode).pipe(
      mergeMap((stocks: { stockType: StockTypeDto; quantity: number; gencode: string }[]) => {
        if (stocks.length === 0) {
          // erreur, pas de gencode trouvé en stock
          throw new EquipmentError(EquipmentErrorType.warn, EquipmentErrorCode.stockError, 'Produit pas en stock.');
        } else if (stocks.length > 1) {
          // deux gencodes trouvés en stock pour divers types
          let selectedStock: { stockType: StockTypeDto; quantity: number; gencode: string } = null;
          const eligibleEquipments: Equipment[] = [];
          for (const stock of stocks) {
            for (const equipment of equipments) {
              if (equipment.isEligibleForGencode(stock.gencode, stock.stockType)) {
                eligibleEquipments.push(equipment);
                selectedStock = stock;
              }
            }
          }

          if (eligibleEquipments.length === 1) {
            eligibleEquipments[0].select(selectedStock.gencode, scanCode);
          } else if (eligibleEquipments.length > 0) {
            // cas stock sil dispo en mode imei + bbox et que le scanCode ne correspond qu'à une meme typologie d'equipement
            // ex: 2 decodeur_tv
            const typologies: string[] = [
              ...Array.from(new Set(eligibleEquipments.map(eq => eq.element.equipementTypeMIM))),
            ]; // array unique
            if (typologies.length === 1) {
              eligibleEquipments[0].select(selectedStock.gencode, scanCode);
            } else {
              // cas du scan de la SIM ICCID de 13 caracteres qui serait un gencode présent
              this.displayChoices(eligibleEquipments, scanCode, stocks);
            }
          } else {
            throw new EquipmentError(
              EquipmentErrorType.warn,
              EquipmentErrorCode.checkEquipment,
              'Equipement incompatible',
            );
          }
        } else {
          const eligibleEquipments: Equipment[] = [];
          for (const equipment of equipments) {
            if (equipment.isEligibleForGencode(stocks[0].gencode, stocks[0].stockType)) {
              eligibleEquipments.push(equipment);
            }
          }

          if (eligibleEquipments.length === 0) {
            // pas d'equipement éligible au gencode
            const wrongScannedBarcodeEquipment: Equipment = equipments.find(
              eq => !eq.isFilled() && eq.hasGencode(stocks[0].gencode),
            );
            if (wrongScannedBarcodeEquipment) {
              wrongScannedBarcodeEquipment.error = 'Mauvais codebarre scanné.';
            } else {
              throw new EquipmentError(
                EquipmentErrorType.warn,
                EquipmentErrorCode.stockError,
                'Equipement incompatible',
              );
            }
          } else if (eligibleEquipments.length > 1) {
            // faire le choix intelligent !
            return this.smartSelectEquipment(stocks[0].gencode, scanCode, eligibleEquipments, stocks[0].stockType);
          } else {
            eligibleEquipments[0].select(stocks[0].gencode, scanCode);
          }
        }
        return of(void 0);
      }),
    );
  }

  public saveDevices(equipments: Equipment[]): Observable<boolean> {
    const sonarFix = (equipment: Equipment): OperatorFunction<boolean, boolean> =>
      mergeMap((lastStatus: boolean) => this.saveDevice(equipment, lastStatus));
    let chain: Observable<boolean> = of(true);
    for (const equipment of equipments) {
      if (equipment.isFilled()) {
        chain = chain.pipe(sonarFix(equipment));
      }
    }
    return chain;
  }

  public getDevicesToScan(): Product[] {
    const currentScheme: Scheme = this.cartService.getCurrentScheme();
    const devices: Product[] = currentScheme.getProductsByType(Device);
    const simSav: SimSav = currentScheme.getProductByType(SimSav);
    const sim: Sim = currentScheme.getProductByType(Sim);
    const accessories: Accessory[] = currentScheme.getProductsByType(Accessory);

    if (simSav) {
      devices.push(simSav);
    } else if (sim) {
      devices.push(sim);
    }
    for (const accessory of accessories) {
      if (accessory && ((accessory.data && accessory.data.element) || accessory.deviceElement)) {
        devices.push(accessory);
      }
    }

    return devices;
  }

  public loadContextualizedSim(): Observable<string[]> {
    return this.simService.simsContextualises().pipe(
      map((res: SimsContextualisesResponse) => {
        const parcours = res.parcours.find(p => p.estCourant);
        return parcours.simsCompatibles.map(sim => sim.gencode);
      }),
      catchError(e => {
        throw new EquipmentError(
          EquipmentErrorType.global,
          EquipmentErrorCode.simListError,
          'Une erreur technique est survenue',
        );
      }),
    );
  }

  private smartSelectEquipment(
    gencode: string,
    scanCode: string,
    equipments: Equipment[],
    stockType: StockTypeDto,
  ): Observable<void> {
    // cas du decodeur_tv (gencodes en commun)

    // un equipement est dispo
    let availableEquipment: Equipment = this.getFirstAvailableEquipment(equipments);
    if (availableEquipment) {
      availableEquipment.select(gencode, scanCode);
      return of(void 0);
    }

    // l'equipement est déjà rempli, on va faire des switch
    const x: { gencode: string; scanCode: string; nb: number }[] = [{ gencode, scanCode, nb: equipments.length }];
    for (const equipment of equipments) {
      x.push({
        gencode: equipment.getGencode(),
        scanCode: equipment.getScanCode(),
        nb: equipments.filter(eq => eq.isEligibleForGencode(equipment.getGencode(), stockType)).length,
      });
    }
    x.sort((a, b) => {
      if (a.nb === b.nb) {
        return 0;
      } else {
        return a.nb > b.nb ? 1 : -1;
      }
    });
    equipments.forEach((equipment: Equipment) => equipment.free());
    for (const y of x) {
      availableEquipment = this.getFirstAvailableEquipment(equipments);
      if (availableEquipment) {
        availableEquipment.select(y.gencode, y.scanCode);
      }
    }
    return of(void 0);
  }

  private displayChoices(equipments: Equipment[], scanCode: string, stocks: IStock[]): void {
    const modalOptions: NgbModalOptions = {
      backdrop: 'static',
      backdropClass: 'cancel-confirm',
      windowClass: 'modal-size-xs',
      keyboard: false,
    };

    const modal: NgbModalRef = this.modalService.open(EquipmentModalComponent, modalOptions);
    const instance: EquipmentModalComponent = modal.componentInstance;
    instance.scanCode = scanCode;
    instance.types = equipments.map(equipment => ({
      internal: equipment.element.equipementTypeMIM,
      display: equipment.element.equipementTypeMIM === 'ACCESSOIRE' ? 'CPL' : equipment.element.equipementTypeMIM,
    }));
    instance.onTypeClick = (equipmentTypeMIM: { internal: string; display: string }): void => {
      modal.close();

      const selectedEquipment: Equipment = equipments.find(
        equipment => equipment.element.equipementTypeMIM === equipmentTypeMIM.internal,
      );

      for (const stock of stocks) {
        if (selectedEquipment.isEligibleForGencode(stock.gencode, stock.stockType)) {
          selectedEquipment.select(stock.gencode, scanCode);
          return;
        }
      }
    };
  }

  private getFirstAvailableEquipment(equipments: Equipment[]): Equipment {
    for (const equipment of equipments) {
      if (equipment.isAvailable()) {
        return equipment;
      }
    }
    return null;
  }

  private getStocks(scanCode: string): Observable<{ stockType: StockTypeDto; quantity: number; gencode: string }[]> {
    const stockTypes: StockTypeDto[] = [
      StockTypeDto.imei,
      StockTypeDto.bbox,
      StockTypeDto.sim,
      StockTypeDto.gencode,
    ].filter((v: StockTypeDto) => (scanCode.length > ScanConfig.gencode.size ? v !== StockTypeDto.gencode : true));
    const stockCalls: Observable<{ stockType: StockTypeDto; quantity: number; gencode: string }>[] = stockTypes.map(
      (stockType: StockTypeDto) =>
        this.cartService.getItemFromStockOrReservedProducts(scanCode, stockType, false).pipe(
          map((result: StockType[]) => {
            if (result.length === 0) {
              throw new EquipmentError(
                EquipmentErrorType.specific,
                EquipmentErrorCode.stockError,
                'erreur de stock pour ' + scanCode,
              );
            } else if (result[0].error) {
              throw new EquipmentError(EquipmentErrorType.specific, EquipmentErrorCode.stockError, result[0].error);
            }
            return {
              stockType,
              quantity: result[0].quantity,
              gencode: result[0].gencode,
            };
          }),
          catchError(() =>
            of({
              stockType: StockTypeDto.undefined,
              quantity: 0,
              gencode: '',
            }),
          ),
        ),
    );

    return forkJoin(stockCalls).pipe(
      map((stockResults: { stockType: StockTypeDto; quantity: number; gencode: string }[]) =>
        stockResults.filter(
          stockResult => stockResult.stockType !== StockTypeDto.undefined && stockResult.quantity > 0,
        ),
      ),
    );
  }

  private processCompleteLstEquipement(equipments: Equipment[], data: DeviceLstResult): Observable<Equipment[]> {
    return this.addAutoAddFrontToScanProducts(data).pipe(
      mergeMap(() => {
        const equipmentsToDelete: Equipment[] = [];
        if (!data.return?.length) {
          return this.getParsedEquipments(equipmentsToDelete, equipments);
        }

        const isConstraintDiscountEmpty = data.return.some(elementItem => !elementItem.contraintesRemises.length);
        if (isConstraintDiscountEmpty) {
          throw new EquipmentError(
            EquipmentErrorType.global,
            EquipmentErrorCode.listEquipment,
            'Impossible de consulter la liste des équipements à scanner!',
          );
        }
        let newEquipments: Equipment[] = data.return.map(elementItem => new Equipment(elementItem));

        for (const eq of equipments) {
          const newEquipFound = newEquipments.find(
            newEquip => eq.element.equipementTypeMIM === newEquip.element.equipementTypeMIM,
          );
          if (newEquipFound) {
            newEquipments = newEquipments.filter(e => e !== newEquipFound);
          } else {
            equipmentsToDelete.push(eq);
          }
        }
        equipments = equipments.concat(newEquipments);
        return this.getParsedEquipments(equipmentsToDelete, equipments);
      }),
    );
  }

  private getParsedEquipments(equipmentsToDelete: Equipment[], equipments: Equipment[]): Observable<Equipment[]> {
    if (equipmentsToDelete.length > 0) {
      const sonarFix: (equipment: Equipment) => OperatorFunction<boolean, boolean> = (equipment: Equipment) =>
        mergeMap(() => this.removeEquipment(equipment));
      const sonarFix2: (equipment: Equipment) => MonoTypeOperatorFunction<boolean> = (equipment: Equipment) =>
        tap(() => (equipments = equipments.filter((eq: Equipment) => eq !== equipment)));
      let chain: Observable<boolean> = of(true);
      for (const equipmentToDelete of equipmentsToDelete) {
        chain = chain.pipe(sonarFix(equipmentToDelete), sonarFix2(equipmentToDelete));
      }
      return chain.pipe(map(() => equipments));
    } else {
      return of(equipments);
    }
  }

  private saveDevice(equipment: Equipment, lastStatus: boolean): Observable<boolean> {
    let chain: Observable<boolean> = of(lastStatus);
    if (equipment.initialScanCode !== '') {
      if (equipment.hasChanged()) {
        chain = chain.pipe(mergeMap(() => this.removeEquipment(equipment)));
      } else if (this.hasEquipmentInCart(equipment)) {
        return of(true);
      }
    }
    chain = chain.pipe(
      mergeMap(() => {
        equipment.markAsSaved();
        return this.addEquipment(equipment);
      }),
      map((status: boolean) => lastStatus && status),
      catchError(e => {
        if (e.message) {
          equipment.error = e.message;
        } else if (e && e.error && e.error.message) {
          equipment.error = e.error.message;
        } else {
          equipment.error = 'Erreur technique non-attendue!';
        }
        return of(false);
      }),
    );
    return chain;
  }

  private hasEquipmentInCart(equipment: Equipment): boolean {
    const products: Product[] = this.cartService.getCurrentScheme().products;
    const gencode: string = ['SIM', 'ACCESSOIRE'].includes(equipment.element.typeEquipement)
      ? equipment.getGencode()
      : 'DEVICE_FAI_GENERIC';
    for (const product of products) {
      if (product.gencode === gencode && product.getScanCode() === equipment.getScanCode()) {
        return true;
      }
    }
    return false;
  }

  private addEquipment(equipment: Equipment): Observable<boolean> {
    let productInstance: Product;
    let jsonProduct: JsonProduct;
    if (['SIM', 'ACCESSOIRE'].includes(equipment.element.typeEquipement)) {
      jsonProduct = this.jsonEquipments?.[equipment.getGencode()] ?? null;
      productInstance = Catalog.getInstance(jsonProduct);
    } else {
      productInstance = Catalog.getInstance(this.jsonEquipments?.['DEVICE_FAI_GENERIC'] ?? null);
    }
    if (!productInstance.data?.to_scan) {
      productInstance.price = 0;
    }
    productInstance.setConfiguration({ element: equipment.element } as IGenericDeviceConfiguration);
    productInstance.setScanCode(equipment.getScanCode());
    return this.cartService.add(productInstance, 1, true).pipe(
      mergeMap((status: boolean) => this.cartService.refreshCart().pipe(map(() => status))),
      tap((status: boolean) => {
        if (status) {
          equipment.error = '';
        } else {
          equipment.error = 'Erreur technique non-attendue!';
        }
      }),
    );
  }

  private removeEquipment(equipment: Equipment): Observable<boolean> {
    return this.cartService.remove(equipment.element.productId, this.cartService.getCurrentScheme().uniqueId).pipe(
      mergeMap((status: boolean) => this.cartService.refreshCart().pipe(map(() => status))),
      tap((status: boolean) => {
        if (status) {
          equipment.error = '';
        } else {
          equipment.error = 'Erreur technique non-attendue !';
        }
      }),
    );
  }

  private getEquipments(
    offerId: string,
    offersIds: string[],
    complementarySim?: DeviceLstElement,
    complementaryBox?: DeviceLstElement,
    isDirect?: boolean,
  ): Observable<DeviceLstResult> {
    if (isDirect) {
      const result: DeviceLstResult = { return: [] } as DeviceLstResult;
      result.return.push(complementarySim);
      result.return.push(complementaryBox);
      return of(result);
    } else {
      return this.oauth2RessourceService
        .equipementsFixesMouvements((this.cartService.getCurrentScheme() as FaiScheme).faiEligId, offerId, offersIds)
        .setCartIdFromCorbis()
        .get()
        .pipe(
          catchError(err =>
            of({
              codeRetour: '-1',
              message:
                'Impossible de recuperer la liste des equipements : ' +
                `[${err?.response?.data?.error || err?.response?.data?.codeRetour}] => ` +
                `${err?.response?.data?.error_description || err?.response?.data?.msgRetour}`,
            }),
          ),
          map((resultTmp: EquipementsFixesMouvementsResponse) => {
            const result: DeviceLstResult = this.mappingEquipToDeviceLst(resultTmp);
            if (complementarySim && complementaryBox) {
              result.return.push(complementarySim);
              result.return.push(complementaryBox);
            }
            return result;
          }),
        );
    }
  }

  private buildBoxElement(boxesGencode: string[]): DeviceLstElement {
    const deviceElement = {
      equipementTypeMIM: 'TERMINAL',
      typeEquipement: 'TERMINAL',
      quantite: 1,
      contraintesRemises: [],
    } as DeviceLstElement;
    boxesGencode.forEach((gencode, index) => {
      deviceElement.contraintesRemises.push({
        gencode: gencode,
        priorite: index,
        selected: false,
      } as ContraintesRemise);
    });
    return deviceElement;
  }

  private buildTerrebiElement(terebi: JsonProduct): DeviceLstElement {
    return {
      contraintesRemises: [{ selected: false, gencode: terebi.gencode, priorite: 0 }],
      typeEquipement: 'ACCESSOIRE',
      equipementTypeMIM: 'ACCESSOIRE',
      name: terebi.name,
    } as DeviceLstElement;
  }

  private buildSimElement(simList: string[]): DeviceLstElement {
    const deviceElement = {
      equipementTypeMIM: 'SIM',
      typeEquipement: 'SIM',
      quantite: 1,
      contraintesRemises: [],
    } as DeviceLstElement;
    simList.forEach((sim, index) => {
      deviceElement.contraintesRemises.push({
        gencode: sim,
        priorite: index,
        selected: false,
      } as ContraintesRemise);
    });
    return deviceElement;
  }

  private loadEquipments(simList: string[]): Observable<DeviceLstResult> {
    const scheme = this.cartService.getCurrentScheme();
    const fai = scheme.getProductByType(Fai);
    const faimUnlimited = scheme.getProductByType(FaimUnlimited);
    let options = scheme.getProductsByType(Option);
    options = options.filter(x => !['option_partner', 'offer_partner', 'grouped_offer'].includes(x.data.type_id));
    const offersIds: string[] = [];
    options.forEach(element => {
      if (element.data.option_category !== 'big-trust') {
        offersIds.push(element.gencode);
      }
    });
    const offreId = fai ? fai.gencode : undefined;
    const listGencodeBigTrust = this.configService.data?.listGencodeBigTrust || [];
    const optionBigtrust = options.find(option => listGencodeBigTrust.find(x => x === option.data.gencode));
    const isSav = !!scheme.isBigtrustSav();
    const boxesGencode: string[] = [];

    if (optionBigtrust || faimUnlimited || isSav) {
      // xgbox parcours sav / fai + bigtrust
      if (optionBigtrust || isSav) {
        return this.loadEquipmentsForSavAndBigTrust(offreId, offersIds, simList);
      } else {
        if (faimUnlimited.data.box?.length) {
          faimUnlimited.data.box.forEach(boxGencode => boxesGencode.push(boxGencode));
        }
        return of({
          return: [this.buildSimElement(simList), this.buildBoxElement(boxesGencode)],
        } as DeviceLstResult);
      }
    }
    return this.getEquipments(offreId, offersIds);
  }

  private loadEquipmentsForSavAndBigTrust(
    offreId: string,
    offersIds: string[],
    simList: string[],
  ): Observable<DeviceLstResult> {
    const options = this.cartService.getCurrentScheme().getProductsByType(Option);
    const hasSao = options.some(
      option => option.type_id === 'option_salable' && option.getData('compatible_device_required') === 'box_sao',
    );
    return this.filterBoxes(hasSao).pipe(
      mergeMap((boxGencode: string[]) =>
        this.getEquipments(
          offreId,
          offersIds,
          this.buildSimElement(simList),
          this.buildBoxElement(boxGencode),
          this.userService.user.isPBO || this.cartService.getCurrentScheme().isBigtrustSav(),
        ),
      ),
    );
  }

  private filterBoxes(hasSao: boolean): Observable<string[]> {
    return this.loadAllBoxes().pipe(
      map(result => {
        const allBoxes = Object.values(result);
        const boxesGencode: string[] = [];
        const compatibilityOptions = ['sao_only', 'sao'];
        for (const box of allBoxes) {
          if (!box.priorite_sav || box.priorite_sav === '') {
            continue;
          }
          const isCompatible = compatibilityOptions.includes(box.compatibility);
          if (hasSao ? isCompatible : box.compatibility !== 'sao_only') {
            boxesGencode.push(box.gencode);
          }
        }
        return boxesGencode;
      }),
    );
  }

  private loadAllBoxes(): Observable<JsonCatalog> {
    return this.productsService.getJsonProductsByFilter({ type: 'box' });
  }

  public loadJsonEquipments(gencodes: string[]): Observable<JsonCatalog> {
    return this.productsService
      .getJsonProductsByFilter({ listGencode: gencodes.join(',') })
      .pipe(tap(result => (this.jsonEquipments = result)));
  }

  private mappingEquipToDeviceLst(param: EquipementsFixesMouvementsResponse): DeviceLstResult {
    const result: DeviceLstResult = {
      return: [],
    };

    // List of type of device to give
    param.equipementsAFournir.forEach((equipementAFournir: EquipementFixe) => {
      const lstEquip: ContraintesRemise[] = [];
      let prio = 0;
      // List of device
      equipementAFournir.equipementsPhysiques.forEach((equipementPhysique: EquipementPhysique) => {
        const equip: ContraintesRemise = {
          gencode: equipementPhysique.gencode,
          priorite: ++prio,
          selected: false,
        };
        lstEquip.push(equip);
      });
      const deviceLstElement: DeviceLstElement = {
        contraintesRemises: lstEquip,
        typeEquipement: equipementAFournir.type,
        equipementTypeMIM: equipementAFournir.type,
        quantite: 1,
      };
      result.return.push(deviceLstElement);
    });

    return result;
  }

  private addAutoAddFrontToScanProducts(deviceLstResult: DeviceLstResult): Observable<void> {
    const autoAddFrontGenCodes = this.getAllAutoAddFrontGencodes();
    if (!autoAddFrontGenCodes) {
      return of(null);
    }
    return this.getAutoAddFrontToScanProducts(autoAddFrontGenCodes).pipe(
      map(autoAddFrontToScanProducts => {
        if (!autoAddFrontToScanProducts) {
          return;
        }
        autoAddFrontToScanProducts.forEach(product => deviceLstResult?.return?.push(this.buildTerrebiElement(product)));
      }),
    );
  }

  private getAllAutoAddFrontGencodes(): string[] | null {
    const autoAddFrontGencodes = this.cartService
      .getCurrentScheme()
      .products.reduce((genCodesAcc: string[], product) => {
        const genCodes = product?.data?.auto_add_front?.split(',');
        if (genCodes) {
          genCodesAcc = [...genCodesAcc, ...genCodes];
        }
        return genCodesAcc;
      }, []);

    return autoAddFrontGencodes.length > 0 ? autoAddFrontGencodes : null;
  }

  private getAutoAddFrontToScanProducts(autoAddFrontGenCodes: string[]): Observable<JsonProduct[] | null> {
    return this.loadJsonCatalog(autoAddFrontGenCodes).pipe(
      map(jsonCatalog => {
        const autoAddFrontToScanProducts = autoAddFrontGenCodes.reduce((productAcc: JsonProduct[], gencode) => {
          if (jsonCatalog[gencode]?.to_scan) {
            productAcc.push(jsonCatalog[gencode]);
          }
          return productAcc;
        }, []);
        return autoAddFrontToScanProducts.length > 0 ? autoAddFrontToScanProducts : null;
      }),
    );
  }

  private loadJsonCatalog(gencodes: string[]): Observable<JsonCatalog> {
    return this.productsService.getJsonProductsByFilter({
      listGencode: gencodes.join(','),
    });
  }
}
