export interface ScanditFieldInterface {
  scanCode?: string;
  scanCodes?: string[];
  openingScan: boolean;
  field: InputForScan;
  multiScan?: boolean;
}

export enum InputForScan {
  simPlanCheckout = 'simPlanCheckout',
  scanTopBar = 'scanTopBar',
  scanEquipment = 'imei-',
  simReplace = 'simReplace',
  mobiletakeback = 'mobiletakeback',
  cpu = 'cpu',
}
