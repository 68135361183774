import { Step } from '../../stepper/step.abstract';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SummaryStepService extends Step {
  public code = 'summary';
  public path = '/panier/recapitulatif';
  public label = 'Récapitulatif';
}
