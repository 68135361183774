/* eslint-disable */
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ContainerBootstrapComponent } from '../../base/container-bootstrap/container-bootstrap.component';
import { ContainerComponent } from '../../container/container.component';

import { AccapAdpterComponent } from './accap-adpter.component';
import { AccapAdapterDirective } from './accap-adapter.directive';
import { contextResolver } from '../../context/contextResolverFn';

const AUTH_TEMP_URL = 'AUTH_TEMP_URL';

const routes: Routes = [
    {
        path: '',
        component: ContainerComponent,
        resolve: { context: contextResolver },
        children: [
            {
                path: AUTH_TEMP_URL,
                component: ContainerBootstrapComponent,
            },
            {
                path: '',
                component: ContainerBootstrapComponent,
                children: [{
                    path: '**',
                    component: AccapAdpterComponent,
                }]
            },

        ]
    },
];

@NgModule({
    declarations: [AccapAdpterComponent, AccapAdapterDirective],
    imports: [RouterModule.forChild(routes)],
    exports: [AccapAdpterComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AccapAdapterModule {}
