<div class="is-tri close-qview">
    <span class="icon is-large" btl-tag="QV_ALL_PLAN_CLOSE" btl-tag-params="[child]" (click)="closeClicked()">
        <i class="icon tri-times" aria-hidden="true"></i>
    </span>
</div>

<div class="is-tri image-zoom" *ngIf="enlargedImage && currentEquipment.medias[0]" (click)="enlargedImage=false">
    <div class="background-zoom is-flex is-justified-center is-aligned-center">
        <img class="enlarged-image" [src]="currentEquipment?.image | media" alt="{{currentEquipment?.nom}}">
        <span class="icon is-large">
            <i class="tri-zoom-out" aria-hidden='true'></i>
        </span>
    </div>
</div>

<div class="is-tri rcbtQview">
    <div class="is-fullwidth is-fullheight p-20">
        <div class="is-fullwidth is-fullheight is-flex" *ngIf="!loadingCtxCatalogError">
            <section class="device-section" [ngClass]="{'isReco': currentEquipment.recommande}" *ngIf="currentEquipment">
                <div *ngIf="currentEquipment.recommande" class="isRecoTitle">Sélectionné pour votre client</div>
                <div class="row">
                    <div class="column is-5 device-visual">
                        <div *ngIf="currentEquipment?.medias">

                                <div *ngIf="!onComparator">
                                    <span class="tags is-centered p-l-10" *ngIf="!!currentEquipment.etiquetteAnimCo|| odr">
                                        <span class="tag has-text-weight-semibold m-b-5" *ngIf="!!currentEquipment.etiquetteAnimCo" data-cy="tag-mobileTakeBackBonus">{{currentEquipment.etiquetteAnimCo}}</span>
                                        <span class="tag has-text-weight-semibold has-background-info has-text-white m-b-5" *ngIf="odr" data-cy="tag-odr">ODR -{{odr.amount}}€</span>
                                    </span>
                                </div> 
                            
                            <div class="selected-image-frame" (click)="enlargedImage=true">
                                <img *ngIf="currentEquipment.medias[0]" class="selected-image" [src]="currentEquipment?.image | media" alt="{{currentEquipment?.nom}}">
                                <span class="icon is-medium">
                                    <i class="tri-zoom-in" aria-hidden='true'></i>
                                </span>
                            </div>

                            <ul class="phone-thumbnail">
                                <li class="thumb" *ngFor="let image of currentEquipment.medias" (click)="updateMainImage(image)">
                                    <img src="{{image | media}}" class="thumb-image" alt="">
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="column is-7 device-data">
                        <div class="name-brand">
                            <h3 class="title is-size-3">
                                <span class="title is-size-5">{{currentEquipment?.marque}}</span><br>
                                {{currentEquipment?.nom}}
                            </h3>
                        </div>
                        <div class="divider m-0"></div>
                        <div class="p-10">
                            <div class="row">
                                <span class="icon is-small is-success iconStyle">
                                    <i class="tri-check-circle" aria-hidden='true'></i>
                                </span>
                                <span class="text is-level-1 has-text-weight-semibold disponibilityMargin">Disponibilité : {{ currentEquipment.quantite }}</span>
                            </div>
                        </div>
                        <div class="divider m-0"></div>
                        <div class="p-0">
                            <div class="p-y-10" *ngIf="configuration.colors.length">
                                <span class="text is-level-2 has-text-weight-semibold">Couleur</span>
                                <div class="columns is-multiline is-variable is-1 product-colors">
                                    <div class="item-color highlighted"
                                        *ngFor="let color of this.configuration.colors"
                                        (click)="updateConfiguration('color', color);"
                                        [ngStyle]="{'background-color': color}"
                                        [ngClass]="{'selected': selectedConfiguration.color === color}">
                                    </div>
                                </div>
                            </div>
                            <div class="p-y-10" *ngIf="configuration.capacitys.length">
                                <span class="text is-level-2 has-text-weight-semibold">Mémoire</span>
                                <div class="radio-list">
                                    <div class="radio-tile is-horizontal m-t-0 m-b-10 m-r-10" *ngFor="let capacity of configuration.capacitys">
                                        <input type="radio" name="capacity"
                                            [(ngModel)]="selectedConfiguration.capacity"
                                            value="{{capacity}}"
                                            id="{{capacity.split(' ').join('')}}"
                                            (ngModelChange)="updateConfiguration('capacity', capacity)">
                                        <label class="radio-label has-text-weight-semibold is-nowrap"
                                            for="{{capacity.split(' ').join('')}}">
                                            {{capacity}}
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="divider m-0"></div>
                            <div class="product-features column p-y-5" *ngIf="!onComparator">
                                <div class="row">
                                    <ul class="column p-y-0">
                                        <li class="feature row m-0" *ngIf="currentEquipment?.resolutionPhoto">
                                            <span class="icon is-small">
                                                <i class="tri-camera" aria-hidden="true"></i>
                                            </span>
                                            <span> Photo &nbsp;&nbsp;&nbsp;&nbsp; <span
                                                    class="has-text-weight-semibold">{{currentEquipment.resolutionPhoto}}
                                                    Mpx</span></span>
                                        </li>

                                        <li class="feature row m-0" *ngIf="currentEquipment?.tailleEcran">
                                            <span class="icon is-small">
                                                <i class="tri-mobile" aria-hidden="true"></i>
                                            </span>
                                            <span> Écran &nbsp;&nbsp;&nbsp;&nbsp; <span
                                                    class="has-text-weight-semibold">{{currentEquipment?.tailleEcran
                                                    }} pouces</span></span>
                                        </li>

                                        <li class="feature row m-0" *ngIf="currentEquipment?.autonomie">
                                            <span class="icon is-small">
                                                <i class="tri-battery" aria-hidden="true"></i>
                                            </span>
                                            <span>Autonomie &nbsp;&nbsp;&nbsp;&nbsp; <span
                                                    class="has-text-weight-semibold">{{currentEquipment.autonomie}}
                                                    h</span></span>
                                        </li>
                                        <li *ngIf="currentEquipment?.typeSim  === 'esim' || currentEquipment?.typeSim === 'hybride'"
                                            class="feature row m-0">
                                            <span class="icon is-small">
                                                <i class="tri-check-circle" aria-hidden="true"></i>
                                            </span>
                                            <span>Compatible eSIM</span>
                                            <span *ngIf="currentEquipment?.esimTa" class="exponent-text">activ. one click</span>
                                        </li>
                                        <li class="feature row m-0">
                                            <span class="icon is-small">
                                                <i class="tri-wifi" aria-hidden="true"></i>
                                            </span>
                                            <span class="d-block"> DAS Tête &nbsp;&nbsp;&nbsp;&nbsp; <span
                                                    class="has-text-weight-semibold">{{ currentEquipment?.dasTete
                                                    ? currentEquipment.dasTete+ 'W/Kg' : 'N.C' }}</span></span>
                                        </li>
                                        <li class="feature row m-0">
                                            <span class="icon is-small">
                                                <i class="tri-wifi" aria-hidden="true"></i>
                                            </span>
                                            <span> DAS Tronc &nbsp;&nbsp;&nbsp;&nbsp; <span
                                                    class="has-text-weight-semibold">{{ currentEquipment?.dasTronc
                                                    ? currentEquipment.dasTronc + 'W/Kg' : 'N.C' }}</span></span>
                                        </li>
                                        <li class="feature row m-0">
                                            <span class="icon is-small">
                                                <i class="tri-wifi" aria-hidden="true"></i>
                                            </span>
                                            <span>
                                                DAS Membre &nbsp;&nbsp;&nbsp;&nbsp; <span
                                                    class="has-text-weight-semibold">{{
                                                    currentEquipment?.dasMembre ? currentEquipment.dasMembre +
                                                    'W/Kg' : 'N.C' }}</span>
                                            </span>
                                        </li>
                                        <li class="feature row m-0">
                                            <span class="icon is-small">
                                                <i class="tri-mobile-tools" aria-hidden="true"></i>
                                            </span>
                                            <span>Indice de réparabilité &nbsp;&nbsp;&nbsp;&nbsp; <span
                                                    class="has-text-weight-semibold">{{
                                                    currentEquipment?.indiceReparabilite ?
                                                    currentEquipment.indiceReparabilite + '/10' : 'N.C'
                                                    }}</span></span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="p-t-35">
                    <rcbt-odr-quick-view *ngIf=" (odr?.amount && !onComparator)" [odr]="odr"></rcbt-odr-quick-view>
                </div>
            </section>
            <section class="plan-section">
                <rcbt-catalog-category-view-renew class="plans" (onClose)="closeClicked($event)"
                                                  [equipmentType]="equipmentType"
                    [odr]="odr?.amount && !onComparator" [scanCode]="scanCode"
                                                  *ngIf="isRenew && !onComparator" #plansComponent>
                </rcbt-catalog-category-view-renew>
                <rcbt-catalog-category-view-acquise class="plans" (onClose)="closeClicked($event)"
                                                    [equipmentType]="equipmentType"
                    [odr]="odr?.amount && !onComparator" [scanCode]="scanCode"
                                                    *ngIf="!isRenew && !onComparator" #plansComponent>
                </rcbt-catalog-category-view-acquise>
                <div class="comparator-info is-flex is-justified-center" *ngIf="onComparator && currentEquipment">
                    <p>Veuillez sélectionner une couleur et une capacité puis cliquez sur OK.</p>
                    <button class="button is-info" (click)="selectProductToCompare(currentEquipment)">
                        OK
                    </button>
                </div>
            </section>
        </div>
        <div class="row reload-button is-justified-center" *ngIf="loadingCtxCatalogError">
            <button class="button is-info" (click)="reload()" [disabled]="globalLoaderService.isAppOnLoadingStatus$ | async">
                <span class="icon is-small"><i class="tri-exclamation-redo" aria-hidden="true"></i></span> Recharger
            </button>
        </div>
    </div>

</div>