import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable, of } from 'rxjs';

import { catchError, concatMap, finalize, mergeMap } from 'rxjs/operators';
import { GlobalLoaderService } from '../../../base/services/global-loader.service';
import { AlertService } from '../../../common/alert/alert.service';
import { FundingService } from '../../../fundings/services/funding.service';
import { CanalService } from '../../../partner/canal/canal.service';
import { CartService } from '../cart.service';
import { ScoringService } from '../../../scoring/scoring.service';
import { CustomerService } from '../customer/customer.service';
import { ControleRisqueVente as DemanderAutorisationVenteControleRisqueVente } from '@bytel/pt-ihm-api-egide-controle-risque-vente-demander-autorisation-vente';
const LOADING_ACTIONS = {
  postFundingModeOrScoring: '[SummaryResolverService] postFundingModeOrScoring',
};

@Injectable({
  providedIn: 'root',
})
export class SummaryResolverService implements Resolve<void> {
  constructor(
    protected cartService: CartService,
    protected canalService: CanalService,
    protected alertService: AlertService,
    private fundingService: FundingService,
    private globalLoaderService: GlobalLoaderService,
    private scoringService: ScoringService,
    private customerService: CustomerService,
  ) {}

  public resolve(): Observable<void> {
    this.globalLoaderService.dispatchLoadingStatus({
      actionType: LOADING_ACTIONS.postFundingModeOrScoring,
      isLoading: true,
    });
    if (!this.fundingService.hasGrantedCredit()) {
      return this.fundingService.postFundingMode(this.cartService, !!this.cartService.cart.creditData, true).pipe(
        concatMap(() => this.cartService.refreshCart(false, true)),
        concatMap(() => this.launchScoring()),
        finalize(() =>
          this.globalLoaderService.dispatchLoadingStatus({
            actionType: LOADING_ACTIONS.postFundingModeOrScoring,
            isLoading: false,
          }),
        ),
      );
    }
    return this.launchScoring().pipe(
      finalize(() =>
        this.globalLoaderService.dispatchLoadingStatus({
          actionType: LOADING_ACTIONS.postFundingModeOrScoring,
          isLoading: false,
        }),
      ),
    );
  }

  private launchScoring(): Observable<void> {
    let scoringObs: Observable<DemanderAutorisationVenteControleRisqueVente>;
    if (!this.scoringService.scoringResult) {
      scoringObs = this.scoringService.getScoring(this.customerService.customer.personId, this.cartService.cart.cartId);
    } else {
      this.scoringService.cartId = this.cartService.cart.cartId;
      this.scoringService.personId = this.customerService.customer.personId;
      scoringObs = this.scoringService.forceUpdate();
    }
    this.alertService.emitBeenLoading(true);
    return scoringObs.pipe(
      catchError((httpErrorResponse: HttpErrorResponse) => {
        this.alertService.emitBeenLoading(false);
        return of(null);
      }),
      finalize(() => this.cartService.save()),
      mergeMap(res => {
        if (!res || !this.scoringService.isScoringValid()) {
          this.alertService.emitBeenLoading(false);
          return of(null);
        }
        return this.canalService.getScoring().pipe(
          catchError(() => of(null)),
          mergeMap(() => of(null)),
          finalize(() => {
            this.alertService.emitBeenLoading(false);
            this.cartService.save();
          }),
        );
      }),
    );
  }
}
