import { Component, EventEmitter, Input } from '@angular/core';
import { StepperService } from './stepper.service';
import { OnInit } from '../types/angular/core/onInit';
import { Step } from './step.abstract';

@Component({
  selector: 'rcbt-base-stepper',
  templateUrl: 'stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
})
export class StepperComponent implements OnInit {
  @Input() checkoutOnSubmitStep: EventEmitter<boolean>;

  public steps: Step[];
  currentStep: Step;
  nextStep: Step;

  constructor(protected stepperService: StepperService) {}

  /**
   * Load checkout steps, navigate to route when step changed
   */
  public ngOnInit(): void {
    this.steps = this.stepperService.steps;
    this.selectStep(this.stepperService.getCurrentStep());
  }

  /**
   * Return activated step
   * @returns {Step[]}
   */
  public getCurrentStep(): Step {
    return this.stepperService.getCurrentStep();
  }

  public getNextStep(): Step {
    return this.stepperService.getNextStep();
  }

  public goToPreviousStep(): void {
    this.stepperService.goToStep(this.stepperService.getPreviousStep());
  }

  public previousStepAviable(): boolean {
    return this.stepperService.getPreviousStep() != null;
  }

  /**
   * can go directly only to previous
   * @param index
   * @returns {boolean}
   */
  public canSelectStep(step: Step): boolean {
    return this.stepperService.canSelectStep(step);
  }

  /**
   * Navigate to specific step
   * @param step
   */
  public selectStep(step: Step): void {
    if (this.canSelectStep(step)) {
      this.stepperService.goToStep(step);
    }
  }

  public submit(): void {
    const result = this.stepperService.currentStepComponent.valideSubmit();
    if (result) {
      this.stepperService.goToStep(this.stepperService.getNextStep());
    }
  }
}
