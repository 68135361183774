import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { FundingService } from '../../../../../fundings/services/funding.service';
import { CartService } from '../../../cart.service';
import { AlertService } from '../../../../../common/alert/alert.service';
import { finalize } from 'rxjs/operators';
import { CommercialProposal } from '../../../../../fundings/interfaces/funding.interface';

@Component({
  selector: 'rcbt-validated-funding',
  templateUrl: './validated-funding.component.html',
  styleUrls: ['./validated-funding.component.scss'],
})
export class ValidatedFundingComponent implements OnInit, OnDestroy {
  public hasGrantedLoan: boolean;
  public loading: boolean;
  public proposal: CommercialProposal;
  private subscription = new Subscription();

  constructor(
    private fundingService: FundingService,
    private cartService: CartService,
    private alertService: AlertService,
  ) {}

  public ngOnInit(): void {
    this.hasGrantedLoan = this.fundingService.hasGrantedCredit(); // en cas de reprise
    this.setLoadDatasString();
    this.subscription.add(
      this.fundingService.stateEligibleFunding.subscribe(() => {
        this.hasGrantedLoan = this.fundingService.hasGrantedCredit();
        this.setLoadDatasString();
      }),
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public deleteGrantedLoan(): void {
    this.loading = true;
    this.alertService.emitBeenLoading(this.loading);
    this.subscription.add(
      this.cartService
        .deleteGrantedLoan()
        .pipe(
          finalize(() => {
            this.loading = false;
            this.alertService.emitBeenLoading(this.loading);
          }),
        )
        .subscribe(),
    );
  }

  private setLoadDatasString(): void {
    this.proposal = this.fundingService.fundingCurrent.commercialProposal;
  }
}
