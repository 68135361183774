export const departments = [
  { code: '01', name: 'Ain' },
  { code: '02', name: 'Aisne' },
  { code: '03', name: 'Allier' },
  { code: '04', name: 'Alpes de Haute Provence' },
  { code: '05', name: 'Hautes Alpes' },
  { code: '06', name: 'Alpes Maritimes' },
  { code: '07', name: 'Ardèche' },
  { code: '08', name: 'Ardennes' },
  { code: '09', name: 'Ariège' },
  { code: '10', name: 'Aube' },
  { code: '11', name: 'Aude' },
  { code: '12', name: 'Aveyron' },
  { code: '13', name: 'Bouches du Rhône' },
  { code: '14', name: 'Calvados' },
  { code: '15', name: 'Cantal' },
  { code: '16', name: 'Charente' },
  { code: '17', name: 'Charente Maritime' },
  { code: '18', name: 'Cher' },
  { code: '19', name: 'Corrèze' },
  { code: '2A', name: 'Corse du Sud' },
  { code: '2B', name: 'Haute-Corse' },
  { code: '21', name: "Côte d'Or" },
  { code: '22', name: "Côtes d'Armor" },
  { code: '23', name: 'Creuse' },
  { code: '24', name: 'Dordogne' },
  { code: '25', name: 'Doubs' },
  { code: '26', name: 'Drôme' },
  { code: '27', name: 'Eure' },
  { code: '28', name: 'Eure et Loir' },
  { code: '29', name: 'Finistère' },
  { code: '30', name: 'Gard' },
  { code: '31', name: 'Haute Garonne' },
  { code: '32', name: 'Gers' },
  { code: '33', name: 'Gironde' },
  { code: '34', name: 'Hérault' },
  { code: '35', name: 'Ille et Vilaine' },
  { code: '36', name: 'Indre' },
  { code: '37', name: 'Indre et Loire' },
  { code: '38', name: 'Isère' },
  { code: '39', name: 'Jura' },
  { code: '40', name: 'Landes' },
  { code: '41', name: 'Loir et Cher' },
  { code: '42', name: 'Loire' },
  { code: '43', name: 'Haute Loire' },
  { code: '44', name: 'Loire Atlantique' },
  { code: '45', name: 'Loiret' },
  { code: '46', name: 'Lot' },
  { code: '47', name: 'Lot et Garonne' },
  { code: '48', name: 'Lozère' },
  { code: '49', name: 'Maine et Loire' },
  { code: '50', name: 'Manche' },
  { code: '51', name: 'Marne' },
  { code: '52', name: 'Haute Marne' },
  { code: '53', name: 'Mayenne' },
  { code: '54', name: 'Meurthe et Moselle' },
  { code: '55', name: 'Meuse' },
  { code: '56', name: 'Morbihan' },
  { code: '57', name: 'Moselle' },
  { code: '58', name: 'Nièvre' },
  { code: '59', name: 'Nord' },
  { code: '60', name: 'Oise' },
  { code: '61', name: 'Orne' },
  { code: '62', name: 'Pas de Calais' },
  { code: '63', name: 'Puy de Dôme' },
  { code: '64', name: 'Pyrénées Atlantiques' },
  { code: '65', name: 'Hautes Pyrénées' },
  { code: '66', name: 'Pyrénées Orientales' },
  { code: '67', name: 'Bas Rhin' },
  { code: '68', name: 'Haut Rhin' },
  { code: '69', name: 'Rhône' },
  { code: '70', name: 'Haute Saône' },
  { code: '71', name: 'Saône et Loire' },
  { code: '72', name: 'Sarthe' },
  { code: '73', name: 'Savoie' },
  { code: '74', name: 'Haute Savoie' },
  { code: '75', name: 'Paris' },
  { code: '76', name: 'Seine Maritime' },
  { code: '77', name: 'Seine et Marne' },
  { code: '78', name: 'Yvelines' },
  { code: '79', name: 'Deux Sèvres' },
  { code: '80', name: 'Somme' },
  { code: '81', name: 'Tarn' },
  { code: '82', name: 'Tarn et Garonne' },
  { code: '83', name: 'Var' },
  { code: '84', name: 'Vaucluse' },
  { code: '85', name: 'Vendée' },
  { code: '86', name: 'Vienne' },
  { code: '87', name: 'Haute Vienne' },
  { code: '88', name: 'Vosges' },
  { code: '89', name: 'Yonne' },
  { code: '90', name: 'Territoire de Belfort' },
  { code: '91', name: 'Essonne' },
  { code: '92', name: 'Hauts de Seine' },
  { code: '93', name: 'Seine Saint Denis' },
  { code: '94', name: 'Val de Marne' },
  { code: '95', name: "Val d'Oise" },
  { code: '971', name: 'Guadeloupe' },
  { code: '972', name: 'Martinique' },
  { code: '973', name: 'Guyane' },
  { code: '974', name: 'Réunion' },
  { code: '976', name: 'Mayotte' },
  { code: '99', name: 'Hors France' },
];

export const countries = [
  { code: 'AD', name: 'Andorre' },
  { code: 'AE', name: 'Emirats arabes unis' },
  { code: 'AF', name: 'Afghanistan' },
  { code: 'AG', name: 'Antigua et Barbuda' },
  { code: 'AI', name: 'Anguilla' },
  { code: 'AL', name: 'Albanie' },
  { code: 'AM', name: 'Arménie' },
  { code: 'AO', name: 'Angola' },
  { code: 'AQ', name: 'Antarctique' },
  { code: 'AR', name: 'Argentine' },
  { code: 'AS', name: 'Samoa américaines' },
  { code: 'AT', name: 'Autriche' },
  { code: 'AU', name: 'Australie' },
  { code: 'AW', name: 'Aruba' },
  { code: 'AX', name: 'Îles Åland' },
  { code: 'AZ', name: 'Azerbaïdjan' },
  { code: 'BA', name: 'Bosnie-Herzégovine' },
  { code: 'BB', name: 'Barbade' },
  { code: 'BD', name: 'Bangladesh' },
  { code: 'BE', name: 'Belgique' },
  { code: 'BF', name: 'Burkina Faso' },
  { code: 'BG', name: 'Bulgarie' },
  { code: 'BH', name: 'Bahrein' },
  { code: 'BI', name: 'Burundi' },
  { code: 'BJ', name: 'Bénin' },
  { code: 'BL', name: 'Saint-Barthélemy' },
  { code: 'BM', name: 'Bermudes' },
  { code: 'BN', name: 'Brunéi Darussalam' },
  { code: 'BO', name: 'Bolivie (État plurinational de)' },
  { code: 'BQ', name: 'Bonaire, Saint-Eustache et Saba' },
  { code: 'BR', name: 'Brésil' },
  { code: 'BS', name: 'Bahamas' },
  { code: 'BT', name: 'Bhoutan' },
  { code: 'BV', name: 'Ile Bouvet' },
  { code: 'BW', name: 'Botswana' },
  { code: 'BY', name: 'Bélarus' },
  { code: 'BZ', name: 'Bélize' },
  { code: 'CA', name: 'Canada' },
  { code: 'CC', name: 'Cocos/Keeling (Îles)' },
  { code: 'CD', name: 'République démocratique du Congo' },
  { code: 'CF', name: 'République centrafricaine' },
  { code: 'CG', name: 'Congo' },
  { code: 'CH', name: 'Suisse' },
  { code: 'CI', name: "Côte d'Ivoire" },
  { code: 'CK', name: 'Cook, Iles' },
  { code: 'CL', name: 'Chili' },
  { code: 'CM', name: 'Cameroun' },
  { code: 'CN', name: 'Chine' },
  { code: 'CO', name: 'Colombie' },
  { code: 'CR', name: 'Costa Rica' },
  { code: 'CU', name: 'Cuba' },
  { code: 'CV', name: 'Cabo Verde' },
  { code: 'CW', name: 'Curaçao' },
  { code: 'CX', name: 'Christmas, île' },
  { code: 'CY', name: 'Chypre' },
  { code: 'CZ', name: 'République Tchèque' },
  { code: 'DE', name: 'Allemagne' },
  { code: 'DJ', name: 'Djibouti' },
  { code: 'DK', name: 'Danemark' },
  { code: 'DM', name: 'Dominique' },
  { code: 'DO', name: 'République Dominicaine' },
  { code: 'DZ', name: 'Algérie' },
  { code: 'EC', name: 'Equateur' },
  { code: 'EE', name: 'Estonie' },
  { code: 'EG', name: 'Egypte' },
  { code: 'EH', name: 'Sahara occidental' },
  { code: 'ER', name: 'Erythrée' },
  { code: 'ES', name: 'Espagne' },
  { code: 'ET', name: 'Ethiopie' },
  { code: 'FI', name: 'Finlande' },
  { code: 'FJ', name: 'Fidji' },
  { code: 'FK', name: 'Falkland/Malouines (Îles)' },
  { code: 'FM', name: 'Etats Fédérés de Micronésie' },
  { code: 'FO', name: 'Féroé, îles' },
  { code: 'FR', name: 'France' },
  { code: 'GA', name: 'Gabon' },
  { code: 'GB', name: "Royaume-Uni de Grande-Bretagne et d'Irlande du Nord" },
  { code: 'GD', name: 'Grenade' },
  { code: 'GE', name: 'Géorgie' },
  { code: 'GF', name: 'Guyane française' },
  { code: 'GG', name: 'Guernesey' },
  { code: 'GH', name: 'Ghana' },
  { code: 'GI', name: 'Gibraltar' },
  { code: 'GL', name: 'Groenland' },
  { code: 'GM', name: 'Gambie' },
  { code: 'GN', name: 'Guinée' },
  { code: 'GP', name: 'Guadeloupe' },
  { code: 'GQ', name: 'Guinée équatoriale' },
  { code: 'GR', name: 'Grèce' },
  { code: 'GS', name: 'Géorgie du sud et les îles Sandwich du sud' },
  { code: 'GT', name: 'Guatemala' },
  { code: 'GU', name: 'Guam' },
  { code: 'GW', name: 'Guinée-Bissau' },
  { code: 'GY', name: 'Guyana' },
  { code: 'HK', name: 'Hong Kong' },
  { code: 'HM', name: 'Ile Heard, et îles MacDonald' },
  { code: 'HN', name: 'Honduras' },
  { code: 'HR', name: 'Croatie' },
  { code: 'HT', name: 'Haïti' },
  { code: 'HU', name: 'Hongrie' },
  { code: 'ID', name: 'Indonésie' },
  { code: 'IE', name: 'Irlande' },
  { code: 'IL', name: 'Israël' },
  { code: 'IM', name: 'Île de Man' },
  { code: 'IN', name: 'Inde' },
  { code: 'IO', name: "Territoire britannique de l'océan Indien" },
  { code: 'IQ', name: 'Iraq' },
  { code: 'IR', name: "République islamique d'Iran" },
  { code: 'IS', name: 'Islande' },
  { code: 'IT', name: 'Italie' },
  { code: 'JE', name: 'Jersey' },
  { code: 'JM', name: 'Jamaïque' },
  { code: 'JO', name: 'Jordanie' },
  { code: 'JP', name: 'Japon' },
  { code: 'KE', name: 'Kenya' },
  { code: 'KG', name: 'Kirghizistan' },
  { code: 'KH', name: 'Cambodge' },
  { code: 'KI', name: 'Kiribati' },
  { code: 'KM', name: 'Comores' },
  { code: 'KN', name: 'Saint-Kitts-et-Nevis' },
  { code: 'KP', name: 'République populaire démocratique de Corée' },
  { code: 'KR', name: 'République de Corée' },
  { code: 'KW', name: 'Koweït' },
  { code: 'KY', name: 'Iles Caïmans' },
  { code: 'KZ', name: 'Kazakhstan' },
  { code: 'LA', name: 'République démocratique populaire Lao' },
  { code: 'LB', name: 'Liban' },
  { code: 'LC', name: 'Sainte-Lucie' },
  { code: 'LI', name: 'Liechtenstein' },
  { code: 'LK', name: 'Sri Lanka' },
  { code: 'LR', name: 'Libéria' },
  { code: 'LS', name: 'Lesotho' },
  { code: 'LT', name: 'Lituanie' },
  { code: 'LU', name: 'Luxembourg' },
  { code: 'LV', name: 'Lettonie' },
  { code: 'LY', name: 'Libye' },
  { code: 'MA', name: 'Maroc' },
  { code: 'MC', name: 'Monaco' },
  { code: 'MD', name: 'République de Moldova' },
  { code: 'ME', name: 'Monténégro' },
  { code: 'MF', name: 'Saint-Martin (partie française)' },
  { code: 'MG', name: 'Madagascar' },
  { code: 'MH', name: 'Iles Marshall' },
  { code: 'MK', name: "L'ex-République yougoslave de Macédoine" },
  { code: 'ML', name: 'Mali' },
  { code: 'MM', name: 'Myanmar' },
  { code: 'MN', name: 'Mongolie' },
  { code: 'MO', name: 'Macao' },
  { code: 'MP', name: 'Iles Mariannes du nord' },
  { code: 'MQ', name: 'Martinique' },
  { code: 'MR', name: 'Mauritanie' },
  { code: 'MS', name: 'Montserrat' },
  { code: 'MT', name: 'Malte' },
  { code: 'MU', name: 'Maurice' },
  { code: 'MV', name: 'Maldives' },
  { code: 'MW', name: 'Malawi' },
  { code: 'MX', name: 'Mexique' },
  { code: 'MY', name: 'Malaisie' },
  { code: 'MZ', name: 'Mozambique' },
  { code: 'NA', name: 'Namibie' },
  { code: 'NC', name: 'Nouvelle-Calédonie' },
  { code: 'NE', name: 'Niger' },
  { code: 'NF', name: 'Ile Norfolk' },
  { code: 'NG', name: 'Nigéria' },
  { code: 'NI', name: 'Nicaragua' },
  { code: 'NL', name: 'Pays-Bas' },
  { code: 'NO', name: 'Norvège' },
  { code: 'NP', name: 'Népal' },
  { code: 'NR', name: 'Nauru' },
  { code: 'NU', name: 'Niue' },
  { code: 'NZ', name: 'Nouvelle-Zélande' },
  { code: 'OM', name: 'Oman' },
  { code: 'PA', name: 'Panama' },
  { code: 'PE', name: 'Pérou' },
  { code: 'PF', name: 'Polynésie française' },
  { code: 'PG', name: 'Papouasie-Nouvelle-Guinée' },
  { code: 'PH', name: 'Philippines' },
  { code: 'PK', name: 'Pakistan' },
  { code: 'PL', name: 'Pologne' },
  { code: 'PM', name: 'Saint-Pierre-et-Miquelon' },
  { code: 'PN', name: 'Pitcairn' },
  { code: 'PR', name: 'Porto Rico' },
  { code: 'PS', name: 'Etat de Palestine' },
  { code: 'PT', name: 'Portugal' },
  { code: 'PW', name: 'Palaos' },
  { code: 'PY', name: 'Paraguay' },
  { code: 'QA', name: 'Qatar' },
  { code: 'RE', name: 'Réunion' },
  { code: 'RO', name: 'Roumanie' },
  { code: 'RS', name: 'Serbie' },
  { code: 'RU', name: 'Fédération de Russie' },
  { code: 'RW', name: 'Rwanda' },
  { code: 'SA', name: 'Arabie Saoudite' },
  { code: 'SB', name: 'Iles Salomon' },
  { code: 'SC', name: 'Seychelles' },
  { code: 'SD', name: 'Soudan' },
  { code: 'SE', name: 'Suède' },
  { code: 'SG', name: 'Singapour' },
  { code: 'SH', name: 'Sainte-Hélène, Ascension et Tristan da Cunha' },
  { code: 'SI', name: 'Slovénie' },
  { code: 'SJ', name: 'Svalbard et île Jan Mayen' },
  { code: 'SK', name: 'Slovaquie' },
  { code: 'SL', name: 'Sierra Leone' },
  { code: 'SM', name: 'Saint-Marin' },
  { code: 'SN', name: 'Sénégal' },
  { code: 'SO', name: 'Somalie' },
  { code: 'SR', name: 'Suriname' },
  { code: 'SS', name: 'Soudan du Sud' },
  { code: 'ST', name: 'Sao Tomé-et-Principe' },
  { code: 'SV', name: 'El Salvador' },
  { code: 'SX', name: 'Saint-Martin (partie néerlandaise)' },
  { code: 'SY', name: 'République arabe syrienne' },
  { code: 'SZ', name: 'Swaziland' },
  { code: 'TC', name: 'Îles Turks-et-Caïcos' },
  { code: 'TD', name: 'Tchad' },
  { code: 'TF', name: 'Terres australes françaises' },
  { code: 'TG', name: 'Togo' },
  { code: 'TH', name: 'Thaïlande' },
  { code: 'TJ', name: 'Tadjikistan' },
  { code: 'TK', name: 'Tokelau' },
  { code: 'TL', name: 'Timor-Leste' },
  { code: 'TM', name: 'Turkménistan' },
  { code: 'TN', name: 'Tunisie' },
  { code: 'TO', name: 'Tonga' },
  { code: 'TR', name: 'Turquie' },
  { code: 'TT', name: 'Trinité-et-Tobago' },
  { code: 'TV', name: 'Tuvalu' },
  { code: 'TW', name: 'Province de Chine Taïwan' },
  { code: 'TZ', name: 'République unie de Tanzanie' },
  { code: 'UA', name: 'Ukraine' },
  { code: 'UG', name: 'Ouganda' },
  { code: 'UM', name: 'Îles mineures éloignées des Etats-Unis' },
  { code: 'US', name: "Etats-Unis d'Amérique" },
  { code: 'UY', name: 'Uruguay' },
  { code: 'UZ', name: 'Ouzbékistan' },
  { code: 'VA', name: 'Saint-Siège (Vatican)' },
  { code: 'VC', name: 'Saint-Vincent-et-les-Grenadines' },
  { code: 'VE', name: 'Venezuela (République bolivarienne du)' },
  { code: 'VG', name: 'Îles vierges britanniques' },
  { code: 'VI', name: 'Îles vierges des Etats-Unis' },
  { code: 'VN', name: 'Viet Nam' },
  { code: 'VU', name: 'Vanuatu' },
  { code: 'WF', name: 'Wallis et Futuna' },
  { code: 'WS', name: 'Samoa' },
  { code: 'YE', name: 'Yémen' },
  { code: 'YT', name: 'Mayotte' },
  { code: 'ZA', name: 'Afrique du Sud' },
  { code: 'ZM', name: 'Zambie' },
  { code: 'ZW', name: 'Zimbabwe' },
];
