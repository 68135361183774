import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Scheme } from './scheme.class';
import { CartService } from './cart.service';
import { MsisdnComponent } from './cart-items/msisdn/msisdn.component';
import { WetoLoginComponent } from './cart-items/login/weto-login.component';
import { WetoVoipComponent } from './cart-items/voip/weto-voip.component';
import { PlanComponent } from './cart-items/plan/plan.component';
import { Plan } from '../../catalog/products/subscription/plan';
import { BoxFaiGenerique } from '../../catalog/products/subscription/box-fai-generique';
import { Phone } from '../../catalog/products/equipement/complex/phone';
import { Product } from '../../catalog/products/product';
import { Fai } from '../../catalog/products/subscription/plan/fai/fai';
import { FaimUnlimited } from '../../catalog/products/subscription/plan/fai/faim-unlimited';
import { Faim } from '../../catalog/products/subscription/plan/fai/faim';
import { FaimSensation } from '../../catalog/products/subscription/plan/premium/faim_sensation';
import { FaiService } from '../../fai-widget/fai.service';
import { CheckoutStepperService } from '../checkout-stepper.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { GlobalLoaderService } from '../../base/services/global-loader.service';
import { IPhoneNumber, IPlanConfiguration, IRio } from '../../catalog/products/subscription/IPlanConfiguration';
import { DataSim } from '../../catalog/products/subscription/plan/dataSim';
import { from, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { Sim } from '../../catalog/products/equipement/sim';
import { SimTerminalCompatibilityComponent } from '../../sim/sim-terminal-compatibility/sim-terminal-compatibility.component';
import { Prepaid } from '../../catalog/products/subscription/plan/prepaid';
import { Step } from '../../stepper/step.abstract';
import { PhoneComponent } from './cart-items/phone/phone.component';
import { FaiBoxComponent } from './cart-items/fai/fai-box.component';

const PRODUCTS_PRIORITY_MAP: Record<string, { priority: number; type: typeof Product }> = {
  plan: { priority: 1, type: Plan },
  phone: { priority: 2, type: Phone },
  boxFaiGeneric: { priority: 3, type: BoxFaiGenerique },
  fai: { priority: 4, type: Fai },
  faimUnlimited: { priority: 5, type: FaimUnlimited },
  faim: { priority: 6, type: Faim },
  faimSensation: { priority: 7, type: FaimSensation },
};

@Component({
  selector: 'rcbt-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
})
export class CartComponent extends Step implements AfterViewInit, OnInit, OnDestroy {
  @ViewChild('msisdn') msisdnComponent: MsisdnComponent;
  @ViewChild('wetoLogin') loginComponent: WetoLoginComponent;
  @ViewChild('wetoVoip') voipComponent: WetoVoipComponent;
  @ViewChild('planComponent') planComponent: PlanComponent;
  @ViewChild('phoneComponent') phoneComponent: PhoneComponent;
  @ViewChild('faiBoxComponent') faiBoxComponent: FaiBoxComponent;
  public scheme: Scheme;
  public errors: string[] = [];
  public plan: Plan;
  public faiBox: BoxFaiGenerique;
  public phone: Phone;
  public products: Product[] = [];
  public isLoginCompoVisible = false;
  public productType = {
    phone: Phone,
    plan: Plan,
    boxFai: BoxFaiGenerique,
    fai: Fai,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    faim_unlimited: FaimUnlimited,
    faim: Faim,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    faim_premium: FaimSensation,
  };
  private isFai = false;
  private subscription;

  constructor(
    public faiService: FaiService,
    private stepper: CheckoutStepperService,
    private cartService: CartService,
    private modalService: NgbModal,
    private globalLoaderService: GlobalLoaderService,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.scheme = this.cartService.getCurrentScheme();
    this.setCurrentStepComponent(this.stepper);
    this.subscription = this.cartService.onChangesSchemes.subscribe(() => {
      this.updateErrors();
      this.plan = this.cartService.getCurrentScheme().getProductByType<Plan>(Plan);
      // todo vérifier si mettre cette appel dans handleAfterRefreshCart ne suffit pas
      this.refreshProductList();
      // Note: The setTimeout places the sort function at the end of the callstack
      setTimeout(() => this.sortProducts());
    });
    this.cartService.modifiedOffer.subscribe(() => {
      // Note: The setTimeout places the sort function at the end of the callstack
      setTimeout(() => this.sortProducts());
    });
    this.sortProducts();

    this.refreshProductList();
    this.cartService.afterRefresh.subscribe(() => {
      this.handleAfterRefreshCart();
    });
    if (this.faiService.forceUnreserveVoipLogin) {
      this.faiService.reservedVoip = null;
      this.faiService.reservedLogin = null;
      this.faiService.forceUnreserveVoipLogin = false;
    } else if (this.faiService.reservedVoip) {
      this.isLoginCompoVisible = true;
    }
    this.faiService.isLoginCompoVisible$.subscribe(() => (this.isLoginCompoVisible = true));
    this.isFai = this.scheme.isFai();
  }

  public instanceofType(product: Product, type: typeof Product): boolean {
    return product instanceof type;
  }

  public validateSubmit(): boolean {
    if (!this.planComponent) {
      return true;
    }
    if (!this.planComponent.isMandatory) {
      return true;
    }
    return this.planComponent.hasSim();
  }

  public ngAfterViewInit(): void {
    this.stepper.changesCurrentStep.next(null);
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public updatePlanMsisdns(param): void {
    if (!!param && param.length > 0) {
      this.plan.msisdns = [...param];
      this.plan.msisdns.forEach((msisdn: IPhoneNumber) => (msisdn.selected = false));
      this.plan.msisdns[0].selected = true;
      this.plan.setConfiguration(<IPlanConfiguration>{ msisdns: this.plan.msisdns, rio: this.defineRio() });
      this.cartService.save();
      if (this.msisdnComponent) {
        this.msisdnComponent.setPlanConfig(this.plan.getConfiguration());
        this.msisdnComponent.plan = this.plan;
      }
      this.stepper.changesCurrentStep.next(null);
    } else {
      this.plan.msisdns = undefined;
    }
  }

  public displayProduct(product: Product): boolean {
    return (
      (product instanceof Plan && !(product instanceof DataSim)) ||
      product instanceof Phone ||
      product instanceof BoxFaiGenerique
    );
  }

  public submit(): Observable<boolean> {
    let obs = of(true);
    if (this.msisdnComponent) {
      obs = this.submitMsisdnComponent();
    } else if (this.voipComponent && this.loginComponent) {
      obs = this.submitFaiComponent();
    }
    return obs.pipe(
      mergeMap(res => {
        if (res) {
          return this.cartService.getCurrentScheme().isRenew() ? this.manageSimIncompatibilityModal() : of(true);
        }
      }),
    );
  }

  public valideSubmit(): boolean {
    if (!this.scheme) {
      // pcq le ticket component declenche cette methode avant meme que le OnInit de cette classe soit déclenché
      return false;
    }
    const plan = this.scheme.getProductByType(Plan);
    if (!plan) {
      return true;
    }
    this.errors = this.scheme.rules.isValid();
    if (this.errors.length) {
      return false;
    }

    const voipComp = this.voipComponent;
    const loginComp = this.loginComponent;
    return (
      (!this.msisdnComponent || this.msisdnComponent.valideSubmit()) &&
      (!voipComp || voipComp.isValid()) &&
      (!loginComp || loginComp.isValid()) &&
      this.validateSubmit()
    );
  }

  public updateErrors(): void {
    this.errors = this.scheme.rules.isValid();
  }

  public isRenew(): boolean {
    return this.cartService.getCurrentScheme().isRenew();
  }

  private submitMsisdnComponent(): Observable<boolean> {
    return this.msisdnComponent.submit().pipe(
      mergeMap(result => {
        if (result) {
          const plan = this.scheme.getProductByType(Plan);
          return this.cartService.update(plan, this.scheme.uniqueId).pipe(
            mergeMap(() => this.cartService.refreshCart().pipe(map(() => result))),
            catchError(e => {
              this.msisdnComponent.errors = [];
              this.msisdnComponent.errors.push(e.error.message);
              return of(false);
            }),
          );
        }
        return of(false);
      }),
    );
  }

  private submitFaiComponent(): Observable<boolean> {
    const voipComp = this.voipComponent;
    const loginComp = this.loginComponent;
    const faiPlan = this.cartService.getCurrentScheme().getProductByType(Fai);
    return voipComp.submit().pipe(
      mergeMap(() => loginComp.submit()),
      mergeMap(() => this.cartService.update(faiPlan, this.scheme.uniqueId)),
      mergeMap(() => this.cartService.refreshCart()),
      map(() => true),
      catchError(() => of(false)),
    );
  }

  private manageSimIncompatibilityModal(): Observable<boolean> {
    const simProduct = this.cartService.getCurrentScheme().getProductByType(Sim);
    if (!simProduct || this.planComponent.simComponent.isEquipmentCompatible) {
      return of(true);
    }
    const options: NgbModalOptions = <NgbModalOptions>{
      backdrop: 'static',
      keyboard: false,
    };
    this.globalLoaderService.setForceLoadingStatusOff(true);
    const component = this.modalService.open(SimTerminalCompatibilityComponent, options);
    return from(
      component.result.then(
        () => true /* on confirm */,
        () => false /* on cancel */,
      ),
    ).pipe(tap(() => this.globalLoaderService.setForceLoadingStatusOff(false)));
  }

  private sortProducts(): void {
    this.products = [...this.scheme.products];
    this.products.sort((a, b) => this.getProductPriority(a) - this.getProductPriority(b));
  }

  private getProductPriority(product: Product): number {
    const productPriorityMap = Object.entries(PRODUCTS_PRIORITY_MAP).find(([, { type }]) => product instanceof type);
    return productPriorityMap ? productPriorityMap[1].priority : Number.MAX_SAFE_INTEGER;
  }

  private defineRio(): IRio {
    const isPrepaid = this.plan instanceof Prepaid;
    return !isPrepaid ? undefined : this.plan.rio;
  }

  private handleAfterRefreshCart(): void {
    this.scheme = this.cartService.getCurrentScheme();
    this.refreshProductList();
    if (this.msisdnComponent) {
      this.msisdnComponent.onChangesSchemes();
    }
    this.valideSubmit();
    this.stepper.changesCurrentStep.next(null);
  }

  private refreshProductList(): void {
    const plan = this.scheme.getProductByType<Plan>(this.productType.plan);
    if (!(plan instanceof DataSim)) {
      this.plan = plan;
    }
    this.phone = this.scheme.getProductByType(this.productType.phone);
    this.faiBox = this.scheme.getProductByType(this.productType.boxFai);
  }
}
