import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Product } from '../../../catalog/products/product';
import { Scheme } from '../../../checkout/cart/scheme.class';
import { Catalog } from '../../../catalog/products/catalog';
import { CartService } from '../../../checkout/cart/cart.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'rcbt-modal-stock-choice',
  templateUrl: './modal-stock-choice.component.html',
  styleUrls: ['./modal-stock-choice.component.scss'],
})
export class StockChoiceComponent implements OnInit {
  @Input() public sourceProducts: Product[];
  @Input() public currentScheme: Scheme;
  public popinProducts: Product[] = [];
  public loading = false;

  constructor(
    private activeModal: NgbActiveModal,
    private cartService: CartService,
  ) {}

  public ngOnInit(): void {
    this.loading = true;
    this.sourceProducts.forEach((sourceProduct: Product) => {
      this.popinProducts.push(Catalog.getInstance(sourceProduct));
    });
    this.cartService
      .simulateProductPromotions(this.popinProducts)
      .pipe(finalize(() => (this.loading = false)))
      .subscribe();
  }

  public choose(product): void {
    this.activeModal.close(product);
  }

  public dismiss(): void {
    this.activeModal.dismiss();
  }
}
