import { forkJoin, forkJoin as observableForkJoin, Observable, of as observableOf, of, Subscription } from 'rxjs';
import { finalize, mergeMap, tap } from 'rxjs/operators';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CartService } from '../../checkout/cart/cart.service';
import { Scheme } from '../../checkout/cart/scheme.class';
import { JsonCatalog } from '../../catalog/products/interface/context';
import { Phone } from '../../catalog/products/equipement/complex/phone';
import { Oauth2RessourceService } from '../../oauth2/oauth2-resources.service';
import { InsuranceConfig, Part2Response, PartnerPaymentMethod } from '../partner.dto';
import { HttpErrorResponse } from '@angular/common/http';
import { AlertService } from '../../common/alert/alert.service';
import { Medi7, Medi7Offer, Medicis, Spb } from './insurance.class';
import { InsurancePartnerService } from '../insurance-partner.service';
import { GlobalLoaderService } from '../../base/services/global-loader.service';
import { ScanditService } from '../../scandit/scandit.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ModalDeleteWarningComponent } from './modal-delete-warning/modal-delete-warning.component';
import { IntraCommunicationService } from '../../intra-communication/intra-communication.service';
import { InsurancePartner } from '../../catalog/products/subscription/insurance-partner';
import { CustomerType } from '../../checkout/cart/customer/customer.interface';
import { CustomerService } from '../../checkout/cart/customer/customer.service';
import { StringObject } from '../../base/base.interfaces';
import { CheckoutStepperService } from '../../checkout/checkout-stepper.service';

const LOADING_ACTIONS = {
  initComponent: '[InsuranceListComponent] initComponent',
  toggleMedi7: '[InsuranceListComponent] toggleMedi7',
};

@Component({
  selector: 'rcbt-partner-insurance-list',
  templateUrl: './insurance-list.component.html',
  styleUrls: ['./insurance-list.component.scss'],
})
export class InsuranceListComponent implements OnInit, OnDestroy {
  @Input()
  public showHeader = false;
  public products: JsonCatalog;
  public phonesName: StringObject = {};
  public scheme: Scheme;
  public loading = false;
  public hasPhoneInScheme = false;
  public customerIbanBic: { iban: string; bic: string } = null;
  public spbInsuranceProduct: JsonCatalog;
  public addedInsurances: InsuranceConfig[] = [];
  public spb: Spb;
  public medicis: Medicis;
  public medi7: Medi7;
  public medi7VisibleOffers: Medi7Offer[] = [];
  public hasSubscribedInsurances = false;
  public isAppOnLoadingStatus$: Observable<boolean>;
  public onTablet = false;
  public isFai = false;
  private subscriptions: Subscription = new Subscription();
  private medi7InitialOffer: Medi7Offer; // utilisé pour le switch tablet <=> tpv

  constructor(
    protected cartService: CartService,
    private oauth2ResourceService: Oauth2RessourceService,
    private alertService: AlertService,
    private insurancePartnerService: InsurancePartnerService,
    private globalLoaderService: GlobalLoaderService,
    private scanditService: ScanditService,
    private modalService: NgbModal,
    private intraCommunicationService: IntraCommunicationService,
    private customerService: CustomerService,
    private checkoutStepperService: CheckoutStepperService,
  ) {
    this.scheme = this.cartService.getCurrentScheme();
    this.isAppOnLoadingStatus$ = this.globalLoaderService.isAppOnLoadingStatus$;
    this.spb = new Spb();
    this.medicis = new Medicis();
    this.medi7 = new Medi7();
    this.isFai = this.scheme.isFai();
  }

  public ngOnInit(): void {
    this.onTablet = this.scanditService.isOnTablet();
    this.scheme = this.cartService.getCurrentScheme();
    const phone: Phone = this.scheme.getProductByType<Phone>(Phone);
    this.hasPhoneInScheme = !!phone;
    this.cartService.cart.schemes.forEach((scheme: Scheme) => {
      this.phonesName[scheme.uniqueId] = this.getPhone(scheme);
    });

    this.subscriptions.add(
      this.intraCommunicationService.emitMedi7Refresh.subscribe(() => {
        this.initInitialOffer();
        this.initVisibleOffers();
      }),
    );

    this.globalLoaderService
      .showLoaderUntilFinalize(
        observableForkJoin([this.initMedicisAndMedi7(), this.initSpb(), this.getClientIban()]).pipe(
          tap(results => {
            this.customerIbanBic = results[2].length > 0 ? results[2][0] : { iban: '', bic: '' };
            this.hasSubscribedInsurances =
              this.spb.subscribed || this.spb.subscribedGe24 || this.medi7.subscribed || this.medicis.subscribed;
          }),
        ),
        LOADING_ACTIONS.initComponent,
      )
      .subscribe();
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public getClientIban(): Observable<{ iban: string; bic: string }[]> {
    const customerContext = this.customerService.customer;
    if (customerContext.type === CustomerType.prospect) {
      return observableOf([{ iban: '', bic: '' }]);
    }
    const personId = customerContext.personId;
    return this.oauth2ResourceService
      .personnes(personId)
      .comptesFacturation()
      .get()
      .pipe(
        mergeMap(res => {
          const accounts = [];
          res.items.map(account => {
            if (account.statut === 'ACTIF' && account.compteBancaire) {
              accounts.push({ iban: account.compteBancaire.iban, bic: account.compteBancaire.bic });
            }
          });
          return observableOf(accounts);
        }),
      );
  }

  public canShowSPB(): boolean {
    if (!this.customerIbanBic || this.cartService.getCurrentScheme().isAcquisitionFix()) {
      return false;
    }
    return true;
  }

  public toggleSpb(): void {
    this.insurancePartnerService.toggleSpb(this.spb, this.customerIbanBic);
    this.addedInsurances = this.cartService.getCurrentScheme().addedInsurances;
    this.checkoutStepperService.redirectIfEmptyScheme(this.cartService.getCurrentScheme().isEmpty());
  }

  public toggleMedi7(medi7Offer: Medi7Offer): void {
    if (medi7Offer.added && !this.onTablet) {
      // POPIN de confirmation
      const options: NgbModalOptions = <NgbModalOptions>{
        backdrop: 'static',
        size: 'lg',
        backdropClass: 'semi-opacity',
        windowClass: 'modal-delete-warning',
        keyboard: false,
      };
      const component = this.modalService.open(ModalDeleteWarningComponent, options).componentInstance;
      component.continue = (): void => {
        component.close();
        this.doToggleMedi7(medi7Offer);
      };
    } else {
      this.doToggleMedi7(medi7Offer);
    }
  }

  public onSubmit(): void {
    this.loading = true;
    this.spb.eligible = false;
    this.oauth2ResourceService
      .part2(this.spb.imei)
      .get()
      .pipe(finalize(() => (this.loading = false)))
      .subscribe(
        (data: Part2Response) => {
          if (data.eligibleSPB) {
            this.spb.eligible = true;
          } else {
            this.alertService.errorEmitter.next(data.messages[0].valeur);
          }
        },
        (error: HttpErrorResponse) => {
          switch (error.status) {
            case 500:
              this.spb.eligible = true;
              break;
            case 400:
            case 404:
              this.alertService.errorEmitter.next(error.error.error);
              break;
            default:
              this.alertService.errorEmitter.next("Une erreur technique s'est produite.");
          }
        },
      );
  }

  public onImeiChanged(): void {
    this.spb.eligible = false;
  }

  /**
   * [getPhone description]
   * @param  {Scheme} scheme [description]
   * @return {string}        [description]
   */
  public getPhone(scheme: Scheme): string {
    for (const product of scheme.products) {
      if (product.getData('type_id') === 'phone_simple') {
        return (
          product.name +
          ' ' +
          (product.getData('capacity') !== 'undefined' ? product.getData('capacity') + ' ' : '') +
          (product.getData('color') !== 'undefined' ? product.getData('color') : '')
        );
      }
    }
    return '';
  }

  private doToggleMedi7(medi7Offer: Medi7Offer): void {
    const isDeleteAction = !!medi7Offer.added;
    this.globalLoaderService
      .showLoaderUntilFinalize(
        this.insurancePartnerService.toggleMedi7(this.medi7, medi7Offer).pipe(
          mergeMap(() => (isDeleteAction ? this.initMedicisAndMedi7() : of(null))),
          tap(() => {
            this.initVisibleOffers();
            this.checkoutStepperService.redirectIfEmptyScheme(this.cartService.getCurrentScheme().isEmpty());
          }),
        ),
        LOADING_ACTIONS.toggleMedi7,
      )
      .subscribe();
  }

  private initVisibleOffers(): void {
    const addedOffer = Object.values(this.medi7.offers).find(offer => offer.added);
    this.medi7VisibleOffers = Object.values(this.medi7.offers).filter(offer => {
      if (this.onTablet) {
        return (
          offer.added ||
          (offer.product.partner_payment_method === PartnerPaymentMethod.prelevement &&
            offer.product.partner_line !== addedOffer?.product.partner_line)
        );
      }
      return offer.gencode === this.medi7InitialOffer?.gencode;
    });
    this.medi7VisibleOffers.sort((a, b) => a.product?.price - b.product?.price);
  }

  private initInitialOffer(): void {
    const product = this.cartService.getCurrentScheme().getProductByType<InsurancePartner>(InsurancePartner);
    if (!!product) {
      this.medi7InitialOffer = Object.values(this.medi7.offers).find(offer => offer.gencode === product.gencode);
    } else {
      this.medi7InitialOffer = undefined;
    }
  }

  private initMedicisAndMedi7(): Observable<void> {
    return this.insurancePartnerService.initMedicisAndMedi7(this.medicis, this.medi7).pipe(
      tap(() => {
        this.medi7InitialOffer = Object.values(this.medi7.offers).find(offer => offer.added);
        this.initVisibleOffers();
      }),
    );
  }

  private initSpb(): Observable<void[]> {
    const phone: Phone = this.scheme.getProductByType<Phone>(Phone);
    const relatedProductsIds: string = phone?.getData('relatedProduct');
    const loadSpbProductObs = relatedProductsIds?.length
      ? this.insurancePartnerService
          .loadSpbProduct(relatedProductsIds[0])
          .pipe(tap(spbInsuranceProduct => (this.spbInsuranceProduct = spbInsuranceProduct)))
      : of(null);
    return forkJoin([loadSpbProductObs, this.insurancePartnerService.initSpb(this.spb)]);
  }
}
