import { webShopEligibility } from '../../../../fai-widget/fai-webshop.interface';
import { IGenericDeviceConfiguration } from '../IGenericDeviceConfiguration';
import { ProductSerialized } from '../../interface/configurable';
import { CompatibilityMessages } from '../../product';
import { Sim } from '../sim';
import { BrowseType } from '../../../../checkout/cart/browse-type';
import { AddResult, ResultAddMessage, TypeResult } from '../../../../checkout/cart/cart.interfaces';

export class SimSav extends Sim {
  public deviceElement: webShopEligibility.DeviceLstElement;

  public setConfiguration(data: IGenericDeviceConfiguration): void {
    super.setConfiguration(data);
    this.deviceElement = data.element;
    if (data.element && data.element.imei) {
      this.setScanCode(data.element.imei);
    }
  }

  public getConfiguration(): IGenericDeviceConfiguration {
    return <IGenericDeviceConfiguration>{
      element: this.deviceElement,
      ...super.getConfiguration(),
    };
  }

  public serialize(): ProductSerialized {
    return Object.assign(super.serialize(), this.getConfiguration());
  }

  public canAdd(browseType: BrowseType, isPrepaid: boolean, isFnb: boolean): AddResult {
    const result: AddResult = { status: true, productsConflict: [] };

    if (browseType !== BrowseType.sav && browseType !== BrowseType.acquisitionFix) {
      result.status = false;
      result.addCheck = <ResultAddMessage>{
        type: TypeResult.error,
        message: CompatibilityMessages.simTypeNotCompatible,
      };
    }

    return result;
  }
}
