<div class="dialog-modal-container no-gutters">

    <div class="close-modal" (click)="closeModal()">
        <i class="icon-item">
            <span btlSvg file="rcbt-defs" title="close" name="shape-icon-close" class="icon-close"></span>
        </i>
    </div>
    <h4 class="title-border">Choix équipement</h4>
    <div class="row col-12">
        <div class="modal-text">
            <p>Le process de sélection automatique n'a pas été capable de déterminer l'équipement associé aux informations suivante :</p>
            <p>Code scanné : <b>{{scanCode}}</b></p>
            <p>Veuillez sélectionner manuellement l'équipement correspondant :</p>
            <button *ngFor="let type of types" class="btn btn-primary ml-1 mr-1" [attr.data-cy]="'type-'+type.internal" (click)="onTypeClick(type)">{{type.display}}</button>
        </div>
    </div>

</div>
