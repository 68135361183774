/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContainerComponent } from './container/container.component';
import { ForbiddenComponent } from './pages/403/403.component';
import { ContainerBootstrapComponent } from './base/container-bootstrap/container-bootstrap.component';
import { faiEligResolver } from './fai-widget/resolverFn';
import { contextResolver } from './context/contextResolverFn';

const appRoutes: Routes = [
  {
    path: '',
    component: ContainerComponent,
    resolve: { context: contextResolver },
    children: [
      {
        path: 'fai',
        component: ContainerBootstrapComponent,
        loadChildren: () => import('./fai-widget/fai-widget.module').then(m => m.FaiWidgetModule),
        resolve: { faiEligResolver },
      },
    ],
  },
  {
    path: '403',
    component: ContainerBootstrapComponent,
    children: [
      {
        path: '',
        component: ForbiddenComponent,
      },
    ],
  },
  {
    path: 'launcher',
    loadChildren: () =>
      import(/* webpackChunkName: "LauncherModule"*/ './launcher/launcher.module').then(m => m.LauncherModule),
  },
  {
    path: 'client-configuration',
    loadChildren: () =>
      import('./client-configuration/client-configuration.module').then(m => m.ClientConfigurationModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
