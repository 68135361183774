import { Step } from '../../stepper/step.abstract';
import { Injectable } from '@angular/core';
import { CartService } from '../cart/cart.service';
import { SchemeHelper } from '../cart/scheme.helper';
import { UserService } from '../../user/user.service';

@Injectable({
  providedIn: 'root',
})
export class DeliveryStepService extends Step {
  public code = 'delivery';
  public path = '/panier/livraison';
  public label = 'Livraison';

  constructor(
    protected cartService: CartService,
    private userService: UserService,
  ) {
    super();
  }

  get isAllowed(): boolean {
    const currentScheme = this.cartService.getCurrentScheme(false);
    if (!currentScheme || currentScheme.products.length === 0) {
      return false;
    }
    return (
      currentScheme.isAcquisitionFix() &&
      this.userService.user.isPBO &&
      !SchemeHelper.hasFaim(currentScheme) &&
      !SchemeHelper.isFaimUnlimited(currentScheme)
    );
  }
  set isAllowed(val: boolean) {
    return;
  }
}
