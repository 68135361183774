import { Directive, HostListener, Output, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { DOCUMENT } from '@angular/common';

@Directive({
  selector: '[rcbtInterceptors]',
})
export class InterceptorsDirective {
  @Output()
  private sequence: string[];

  private interceptorsCode: string[];

  private exclude: string[];

  constructor(@Inject(DOCUMENT) private document: Document) {
    this.sequence = [];
    this.interceptorsCode = ['m', 'i', 'l', 'o', '1', '2', '3'];
    this.exclude = ['CapsLock', 'Shift', 'Tab', 'Meta', 'Alt', 'Control'];
  }

  @HostListener('window:keydown', ['$event'])
  public handleKeyboardEvent(event: KeyboardEvent): void {
    if (event.key && !this.exclude.includes(event.key)) {
      this.sequence.push(event.key);

      if (this.sequence.length > this.interceptorsCode.length) {
        this.sequence.shift();
      }

      if (this.isInterceptorCode()) {
        this.loadFiles();
      }
    }
  }

  public loadFiles(): void {
    for (const script of Array.from(this.document.scripts)) {
      if (script.src.includes('interceptors/bundle')) {
        // already loaded
        return;
      }
    }
    this.loadScript('/assets/tools/interceptors/bundle.js').subscribe();
    this.loadCss('/assets/tools/interceptors/bundle.css').subscribe();
  }

  private isInterceptorCode(): boolean {
    return this.interceptorsCode.every((code: string, index: number) => code === this.sequence[index]);
  }

  private loadScript(path: string): Observable<boolean> {
    return new Observable(observer => {
      const script: HTMLScriptElement = this.document.createElement('script');
      const timestamp: number = new Date().getTime();
      script.type = 'text/javascript';
      script.src = path + '?timestamp=' + timestamp;
      script.onload = function (): void {
        observer.next(true);
        observer.complete();
      };
      script.onerror = function (e): void {
        observer.error(e);
      };
      this.document.body.appendChild(script);
    });
  }

  private loadCss(path: string): Observable<boolean> {
    return new Observable(observer => {
      const link: HTMLLinkElement = this.document.createElement('link');
      const timestamp: number = new Date().getTime();
      link.rel = 'stylesheet';
      link.href = path + '?timestamp=' + timestamp;
      link.onload = function (): void {
        observer.next(true);
        observer.complete();
      };
      link.onerror = function (e): void {
        observer.error(e);
      };
      this.document.body.appendChild(link);
    });
  }
}
