import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseModule } from '../../../base/base.module';
import { ReplaceSimComponent } from './replace-sim.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ReplaceSimRoutingModule } from './replace-sim-routing.module';
import { CartModule } from '../../../checkout/cart/cart.module';

@NgModule({
  imports: [CommonModule, BaseModule, ReactiveFormsModule, ReplaceSimRoutingModule, CartModule],
  declarations: [ReplaceSimComponent],
  exports: [ReplaceSimComponent],
})
export class ReplaceSimModule {}
