import { of as observableOf, Observable } from 'rxjs';
import { map, refCount, publishLast } from 'rxjs/operators';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpResponse as Response } from '@angular/common/http';
import { StoreStockType } from '../../scan/interfaces/types';
import { Oauth2RessourceService } from '../../oauth2/oauth2-resources.service';
import { JsonCatalog } from '../../catalog/products/interface/context';

@Injectable({
  providedIn: 'root',
})
export class StoreStockService implements Resolve<Object> {
  public data: StoreStockType = null;
  public products: JsonCatalog;
  private resolveCache: Observable<StoreStockType> = null;

  /**
   *
   * @param oauth2RessourceService
   */
  constructor(private oauth2RessourceService: Oauth2RessourceService) {}

  /**
   * catalog resolver
   * @param  {ActivatedRouteSnapshot} route [description]
   * @param  {RouterStateSnapshot}    state [description]
   * @return {Observable<string>}           [description]
   */
  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<StoreStockType> {
    if (this.data !== null) {
      return observableOf(this.data);
    } else {
      if (!this.resolveCache) {
        this.resolveCache = this.getStoreStock().pipe(
          map((res: Object) => (this.data = <StoreStockType>res)),
          publishLast(),
          refCount(),
        );
      }
      return this.resolveCache;
    }
  }

  /**
   * Returns the store stock filtered by material groups (CLE_MOBILE, TERMINAL, CARTE_SIM_SEUL, BOX_ET_STB, ACCESSOIRE)
   * Returns the entire store stock if materialGroups is empty
   * @returns {Observable<Object>}
   */
  public getStoreStock(
    materialGroups: string[] = ['TERMINAL', 'CLE_MOBILE', 'PRODUIT_CONNECTE_MOBILE'],
  ): Observable<Response<StoreStockType>> {
    return this.oauth2RessourceService
      .setLocalService()
      .ventes()
      .useSalesApi()
      .stockBoutique(materialGroups.join(','))
      .get();
  }
}
