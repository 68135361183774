/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient, HttpContext, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

export interface ICallOptions {
  body: any;
  pathParam: any;
  queryParam: any;
}

export type KeyOfType<T, U> = {
  [P in keyof T]-?: T[P] extends U ? P : never;
}[keyof T];

export type MethodFunction = (args: ICallOptions) => any;

export class SwagClient<T extends { [key in keyof T]: MethodFunction }> {
  private _instanceSpec: T;

  constructor(
    private spec: new () => T,
    private _httpClient: HttpClient,
    private defaultHeaders: { [index: string]: string },
    private _baseUrl: string,
  ) {
    this._instanceSpec = new spec();
  }

  public call<M extends KeyOfType<T, MethodFunction>>(
    method: M,
    args: Parameters<T[M]>[0],
    options: OptionSwagger = {},
  ): Observable<ReturnType<T[M]>> {
    const urlPattern = Reflect.getMetadata('path', this._instanceSpec, method as string | symbol);

    const path = Object.keys(args.pathParam ?? {}).reduce(
      (acc, key: keyof typeof args.pathParam) =>
        args.pathParam ? acc.replace(`{${key.toString()}}`, args.pathParam[key]) : acc,
      urlPattern,
    );

    const urlFull = this._baseUrl.concat(path);

    const paramsFiltered = Object.keys(args.queryParam).reduce((acc, key) => {
      if (args.queryParam[key]) {
        acc[key] = args.queryParam[key];
      }
      return acc;
    }, {});

    options.headers = Object.fromEntries(
      Object.entries({ ...options.headers, ...this.defaultHeaders }).filter(([_, value]) => value !== undefined),
    );
    return this._httpClient.request<ReturnType<T[M]>>(
      Reflect.getMetadata('method', this._instanceSpec, method as string | symbol).toUpperCase(),
      urlFull,
      {
        body: args.body,
        responseType: 'json',
        params: paramsFiltered,
        ...options,
      },
    );
  }
}

export type OptionSwagger = {
  body?: any;
  headers?:
    | HttpHeaders
    | {
        [header: string]: string | string[];
      };
  context?: HttpContext;
  reportProgress?: boolean;
  params?:
    | HttpParams
    | {
        [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
      };
  responseType?: 'json';
  withCredentials?: boolean;
};
