import { Product } from './product';
import { PriceTypes } from './interface/price-type.enum';
import { JsonProduct } from './interface/context';

export abstract class Subscription extends Product {
  public hasContract = true;

  constructor(data: JsonProduct, uniqueId: string = '') {
    super(data, uniqueId);
    this.priceType = PriceTypes.everyMonth;
  }

  protected applyAml(): boolean {
    return true;
  }
}
