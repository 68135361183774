import { Component } from '@angular/core';
import { PlansComponent } from '../plans.component';
import { IPostCatalogResponse } from '../../../../contextualized-catalog/dtos/contextualized-product-output.dto';

@Component({
  selector: 'rcbt-catalog-category-view-acquise',
  templateUrl: '../plans.component.html',
  styleUrls: ['../plans.component.scss'],
})
export class PlansAcquiseComponent extends PlansComponent {
  public filterPlans(): void {
    super.filterPlans();
    this.filterPlansForAcquise();
  }

  protected filterPlansForAcquise(): void {
    const catPlanChoosed = this.plansType.find(catPlan => catPlan.type === this.planType);
    const isClientPro: boolean = this.customerService.customer.isPro();
    if (catPlanChoosed) {
      this.produitsFiltres = this.produitsFiltres.filter(
        (p: IPostCatalogResponse) =>
          ((catPlanChoosed.isPro && p.excluPro) ||
            (!catPlanChoosed.isPro && !p.excluPro) ||
            (catPlanChoosed.isBoxPlan && isClientPro)) &&
          p.categories.includes(catPlanChoosed.category) &&
          !p.estNonVendableAcquisition,
      );
    }
  }
}
