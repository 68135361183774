import { Component, ViewChild } from '@angular/core';
import { ComparatorService } from '../comparator.service';
import { MultiSelectComponent } from '../../base/components/multi-select/multi-select.component';

@Component({
  selector: 'rcbt-comparator-parameters',
  templateUrl: './comparator-parameters.component.html',
  styleUrls: ['./comparator-parameters.component.scss'],
})
export class ComparatorParametersComponent {
  @ViewChild('multiSelect') multiSelect: MultiSelectComponent;

  constructor(public readonly comparatorService: ComparatorService) {}

  public resetComparator(): void {
    this.comparatorService.empty();
    this.multiSelect.reset();
  }
}
