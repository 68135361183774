import { Injectable } from '@angular/core';
import { Oauth2Service } from './oauth2.service';
import { MethodFunction, SwagClient } from './swaggerClient';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '../config.service';
import { v4 as uuidv4 } from 'uuid';
import { Oauth2StorageService } from './oauth2-storage.service';

@Injectable({
  providedIn: 'root',
})
export class HttpClientSwaggerService {
  constructor(
    private _httpClient: HttpClient,
    private _oauth2: Oauth2Service,
    private _configService: ConfigService,
    private _oauth2StorageService: Oauth2StorageService,
  ) {}

  public getClient<T extends { [key in keyof T]: MethodFunction }>(
    spec: new () => T,
    urlPrefix?: string,
  ): SwagClient<T> {
    const headers = {
      ['Authorization']: `Bearer ${this._oauth2.accessToken}`,
      ['x-source']: 'portailvente_rcbt',
      ['x-request-id']: uuidv4(),
      ['X-Message-Id']: uuidv4(),
      ['TrackerId']: this._oauth2StorageService.getItem(this._oauth2StorageService.key.trackerId),
    };

    if (this._configService.getEnv() !== 'PROD') {
      headers['x-banc'] = this._configService.getEnv();
    }

    let resourceUrl = this._configService.getResourceUrl();
    if (urlPrefix) {
      resourceUrl += '/' + urlPrefix;
    }
    return new SwagClient<T>(spec, this._httpClient, headers, resourceUrl);
  }
}
