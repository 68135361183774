import { IProductConfiguration } from './IProductConfiguration';
import { ProductSerialized } from './interface/configurable';

export interface IMobileTakeBackConfiguration extends IProductConfiguration {
  scanCode?: string;
  idCommandePartenaire?: string;
  idDevisPartenaire?: string;
  dataQuestion?: IDataQuestion;
  terminal?: IMobileTakeBackTerminal;
  criteresQualification?: IMobileTakeBackQualificationCriterion[];
  lienBonDeCession?: string;
  isMtbCessionCouponPrinted?: boolean;
}

export interface IMobileTakeBackSerialized extends ProductSerialized {
  scanCode?: string;
  idCommandePartenaire?: string;
  idDevisPartenaire?: string;
  dataQuestion?: IDataQuestion;
  terminal?: IMobileTakeBackTerminal;
  criteresQualification?: IMobileTakeBackQualificationCriterion;
  lienBonDeCession?: string;
  isMtbCessionCouponPrinted?: boolean;
}

export interface IMobileTakeBackTerminal {
  marque?: string;
  modele?: string;
  imei: string;
  idProduit: string;
  nom: string;
}

export interface IPartnerProduct {
  id: string;
  imei: string;
  marque: string;
  nom: string;
}

export interface IMobileTakeBackQualificationCriterion {
  critere?: string;
  valeur?: boolean;
}

export interface IDisposalTicket {
  dateCreation: string;
  idBoutiqueCommande: string;
  idCommandePartenaire: string;
  idDevis: string;
  libelleCanal: string;
  _links: {
    lienBonDeCession: {
      href: string;
    };
  };
  produitPartenaire: IPartnerProduct;
  tarif: number;
  tarifHT: number;
  tauxTVA: number;
}

export enum IErrorDisposalTicket {
  disposalTicketNotFound = 'BON_CESSION_INTROUVABLE',
  disposalTicketUsed = 'BON_CESSION_UTILISE',
  disposalTicketExpired = 'BON_CESSION_EXPIRE',
  disposalTicketInvalid = 'BON_CESSION_INVALID',
}

export interface IDataQuestion {
  acteurCreateur?: {
    login: string;
  };
  entiteReseauDistributionOrigine?: {
    codeEnseigne: string;
    codePointVente: string;
  };
  criteresReprise?: ICriteresReprise[];
  imei?: string;
}

export interface ICriteresReprise {
  reponse: {
    id: string;
  };
  id: string;
}
