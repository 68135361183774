<div class="is-tri customer-page">
    <section class="accordions" data-accordion="" data-cy="titulaire-steps">
        <rcbt-cart-customer-identity-pro data-cy="identityPro-step"
            #stepIdentityPro *ngIf="isPro"
            [isActive]="currentStep.value === customerSteps.identity"></rcbt-cart-customer-identity-pro>

        <rcbt-cart-customer-identity data-cy="identity-step"
            #stepIdentity *ngIf="!isPro"
            [isActive]="currentStep.value === customerSteps.identity"></rcbt-cart-customer-identity>

        <rcbt-cart-customer-address data-cy="address-step"
            #stepAddress *ngIf="customerService.nbSteps >= maxSteps.address"
            [isPro]="isPro"
            [isDisabled]="addressDisabled"
            [isActive]="currentStep.value === customerSteps.address"></rcbt-cart-customer-address>

        <rcbt-cart-customer-payment data-cy="payment-step"
            #stepPayment *ngIf="customerService.nbSteps === maxSteps.payment"
            [isDisabled]="paymentDisabled"
            [isActive]="currentStep.value === customerSteps.payment"></rcbt-cart-customer-payment>
    </section>
</div>
