<ng-container *ngIf="customer && user">
    <rcbt-top-bar *ngIf="errors.length == 0 " [isDispatchPage]="true"></rcbt-top-bar>
</ng-container>

<div class="is-tri top-bar-relative">
    <div *ngIf="errors.length > 0; else elseBlock">
        <h2>Incident technique. Veuillez relancer l'application.</h2>
        <ul>
            <li *ngFor="let error of errors">{{error}}</li>
            <li>
                <button *ngIf="browseConfig.callBackUrl" type="button" class="btn btn-lg btn-warning" (click)="exit()">Retour</button>
            </li>
        </ul>
    </div>

    <ng-template #elseBlock>

        <div class="container">
            <br>
            <h1 class="title">Choix de l'acte de vente</h1>
        </div>
        <hr>
        <rcbt-scoring></rcbt-scoring>
        <div *ngIf="customer" class="container">
            <div class="box">
                <div class="box-content">
                    <p class="is-info-color is-bold"><span *ngIf="!!customer.raisonSociale">{{customer.raisonSociale}} </span>{{customer.firstname}} {{customer.lastname}}</p>
                    <br>
                    <section class="accordions" data-accordion="">
                        <article class="accordion" data-accordion-context=""
                            [attr.data-cy]="(contract.typeLigne === 'MOBILE') ? 'dispatch_ligne_mobile' : 'dispatch_ligne_fixe'"
                                 *ngFor="let contract of customer.contracts"
                                 [ngClass]="{'is-active': selectedContract && selectedContract.id === contract.id,
                            'is-not-active': !selectedContract || selectedContract.id !== contract.id }"
                                 (click)="selectContract(contract.id);">
                            <div class="accordion-header title" data-accordion-toggle="">
                                <div class="plan">
                                    <ng-container *ngIf="contract.typeLigne === 'MOBILE'; else elseBlock">
                                        <span class="icon is-medium" aria-label="Icone avec texte">
                                            <i class="tri-mobile" aria-hidden="true"></i>
                                        </span>
                                    </ng-container>
                                    <ng-template #elseBlock>
                                        <span class="icon is-medium" aria-label="Icone avec texte">
                                            <i class="tri-box" aria-hidden="true"></i>
                                        </span>
                                    </ng-template>
                                    {{contract.abonnement.libelle}}
                                </div>
                                <div class="tel">
                                    {{contract.numeroTel | telephone}}
                                </div>
                                <div class="info">
                                    <span class="rob-loss-contract" *ngIf="hasRobbLossRestrictionOnContract && selectedContract.id === contract.id">
                                        <i class="icon-item icon-text">
                                            <span btlSvg file="rcbt-defs" name="shape-icon-padlock" class="icon"></span>
                                        </i>
                                        <p>INFO: Restriction Perte & Vol sur le contrat</p>
                                    </span>
                                </div>
                                <button class="toggle" aria-label="toggle"></button>
                            </div>
                            <div class="accordion-body">
                                <div class="accordion-content">
                                    <div class="buttons">
                                        <ng-container *ngFor="let key of contextTypes | keys">
                                            <div class="button-block" *ngIf="contextTypes[key].display">
                                                <button class="button is-secondary" id="{{key}}" (click)="submit(key)" [attr.data-cy]="'dispatch_'+key"
                                                        [disabled]="!contextTypes[key].isAvailable || loading || isScoringLoading"
                                                        [ngClass]="{'action-feedback': loading || contextTypes[key].loading}">
                                                    <span class="name">{{ contextTypes[key].label }}</span>
                                                </button>
                                                <div *ngIf="contextTypes[key].display && key === renouvellement" class="is-small">
                                                    <div *ngIf="edpRenouvElig[contract.id]">
                                                        EDP Renouvellement disponible
                                                    </div>
                                                    <div *ngIf="dateEligPrevRenouv[contract.id]">
                                                        éligible à partir du {{dateEligPrevRenouv[contract.id]}}
                                                    </div>
                                                    <div *ngIf="creditRenouvElig[contract.id]">
                                                        Crédit Renouvellement disponible
                                                    </div>
                                                    <p *ngIf="contract.error?.length > 0" class="has-text-danger is-size-8">
                                                        {{ contract.error }}
                                                    </p>
                                                </div>
                                                <div *ngIf="contextTypes[key].display && key === acquisition" class="is-small">
                                                    <div *ngIf="edpTNuElig[contract.id]">
                                                        EDP Terminal nu disponible
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </article>
                        <article class="accordion is-active" data-accordion-context="" data-cy="dispatch_ligne_prospect"
                                 *ngIf="(browseConfig && !browseConfig.personId) || (customer && customer.contracts.length === 0)">
                            <div class="accordion-header" data-accordion-toggle="">
                                <p *ngIf="['1', '2'].indexOf(customer.ligneMarche) === -1">NOUVEAU CLIENT</p>
                                <p *ngIf="['1', '2'].indexOf(customer.ligneMarche) !== -1">CLIENT PREPAYE {{ customer.telephonePrepaye | telephone}}</p>
                                <button class="toggle" aria-label="toggle"></button>
                            </div>
                            <div class="accordion-body">
                                <div class="accordion-content">
                                    <div class="buttons">
                                        <ng-container *ngFor="let key of contextTypes | keys; let i = index">
                                            <div *ngIf="key !== renouvellement && key !== continuiteService" class="button-block">
                                                <button class="button is-secondary" id="{{key}}" (click)="submit(key)" [attr.data-cy]="'dispatch_'+key"
                                                        [disabled]="!contextTypes[key].isAvailable || loading"
                                                        [ngClass]="{'action-feedback': loading || contextTypes[key].loading}">
                                                    <span class="name">{{ contextTypes[key].label }}</span>
                                                </button>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </article>
                    </section>
                </div>
            </div>
        </div>

    </ng-template>
</div>
